import * as React from "react";
import classNames from "classnames";
import { useUserId } from "./UserProvider";
import { useProfilePictureReload } from "../hooks/useProfilePictureReload";
import { Image as SuspendedImage } from "./Image";
import { ErrorBoundary } from "./ErrorBoundary";

export const DEFAULT_PROFILE_IMAGE_HEIGHT = 22;
export const DEFAULT_PROFILE_IMAGE_WIDTH = 22;

type ImageProps = {
  width?: number;
  height?: number;
  name: string;
  src: string;
};

const Container = ({
  width = DEFAULT_PROFILE_IMAGE_WIDTH,
  height = DEFAULT_PROFILE_IMAGE_HEIGHT,
  style = {},
  className,
  children,
  ...rest
}: React.PropsWithChildren<
  { width?: number; height?: number } & JSX.IntrinsicElements["div"]
>) => {
  return (
    <div
      style={{ width, height, ...style }}
      className={classNames("overflow-hidden", "box-content", className)}
      {...rest}
    >
      {children}
    </div>
  );
};

const makeSrc = (id: string) => `${process.env.REACT_APP_API_HOST}/user/${id}`;

const Image = ({
  width = DEFAULT_PROFILE_IMAGE_WIDTH,
  height = DEFAULT_PROFILE_IMAGE_HEIGHT,
  src,
  name,
}: ImageProps) => {
  return (
    <ErrorBoundary fallback={null}>
      <React.Suspense fallback={null}>
        <SuspendedImage
          alt={name}
          src={src}
          draggable={false}
          width={width}
          height={height}
        />
      </React.Suspense>
    </ErrorBoundary>
  );
};

const MyImage = (props: Omit<ImageProps, "src">) => {
  const id = useUserId();
  const [randomId] = useProfilePictureReload();
  return <Image {...props} src={makeSrc(`${id}?r=${randomId}`)} />;
};

export const ProfileImage = React.memo<
  {
    id: string;
    name: string;
    width?: number;
    height?: number;
  } & JSX.IntrinsicElements["div"]
>(
  ({
    id,
    name,
    width = DEFAULT_PROFILE_IMAGE_WIDTH,
    height = DEFAULT_PROFILE_IMAGE_HEIGHT,
    ...rest
  }) => {
    const myId = useUserId();
    return (
      <Container width={width} height={height} {...rest}>
        {myId === id ? (
          <MyImage name={name} width={width} height={height} />
        ) : (
          <Image width={width} height={height} name={name} src={makeSrc(id)} />
        )}
      </Container>
    );
  }
);
