import * as React from "react";
import ResizeObserver from "resize-observer-polyfill";

export type ObserveProps = {
  rect: DOMRectReadOnly | undefined;
  hasRectChanged: boolean;
  callbacks: Function[];
};

const observedNodes = new Map<Element, ObserveProps>();

const ro = new ResizeObserver((entries) => {
  for (const entry of entries) {
    const node = observedNodes.get(entry.target);
    if (node === undefined) continue;
    node.callbacks.forEach((cb) => cb(entry.contentRect));
  }
});

const observeNode = (node: HTMLElement, cb: Function) => {
  return {
    observe: () => {
      let shouldObserve = false;
      let observeNode = observedNodes.get(node);
      if (observeNode === undefined) {
        shouldObserve = true;
        observeNode = {
          rect: undefined,
          hasRectChanged: false,
          callbacks: [],
        };
      }

      observeNode.callbacks.push(cb);
      observedNodes.set(node, observeNode);
      if (shouldObserve) ro.observe(node);
    },
    unobserve: () => {
      let observeNode = observedNodes.get(node);
      if (observeNode === undefined) return;

      // Remove the callback
      const index = observeNode.callbacks.indexOf(cb);
      if (index >= 0) observeNode.callbacks.splice(index, 1);

      // Remove the node reference
      if (!observeNode.callbacks.length) {
        observedNodes.delete(node);
        ro.unobserve(node);
      }
    },
  };
};

export const useSizeObserve = <T extends HTMLElement = HTMLElement>(
  nodeRef: React.RefObject<T>,
  observe: boolean = true
) => {
  const observerRef = React.useRef<any>(null);
  const [rect, setRect] = React.useState<DOMRect | null>(null);
  React.useLayoutEffect(() => {
    if (!nodeRef.current) {
      console.warn("Ref has not been placed");
      return;
    }

    if (!observerRef.current) {
      observerRef.current = observeNode(
        nodeRef.current,
        (rect: DOMRectReadOnly) => setRect(rect)
      );
    }

    if (observe) observerRef.current.observe();

    return () => {
      observerRef.current && observerRef.current.unobserve();
    };
  }, [nodeRef, observe]);

  return rect;
};
