import * as React from "react";

const Context = React.createContext<{
  openAskSendEmailsPopup: boolean;
  setOpenAskSendEmailsPopup: (showPopup: boolean) => void;
  doAskSendEmailsOnLogin: boolean;
  setDoAskSendEmailsOnLogin: (showPopup: boolean) => void;
}>(undefined as any);

export function AskSendEmailsProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [doAskSendEmailsOnLogin, setDoAskSendEmailsOnLogin] = React.useState(
    false
  );
  const [openAskSendEmailsPopup, setOpenAskSendEmailsPopup] = React.useState(
    false
  );

  return (
    <Context.Provider
      value={{
        doAskSendEmailsOnLogin,
        setDoAskSendEmailsOnLogin,
        openAskSendEmailsPopup,
        setOpenAskSendEmailsPopup,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useAskSendEmails() {
  return React.useContext(Context);
}
