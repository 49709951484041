import * as React from "react";
import { useTranslation } from "react-i18next";
import { Layout } from "../Layout";
import { ExitButton, FullscreenButton, Header, MuteButton } from "../Header";

export function PlaceholderLayout({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation();
  return (
    <Layout
      header={
        <Header
          right={
            <div className="h-full flex justify-end items-center">
              <div className="flex -mx-1">
                <div className="px-1">
                  <MuteButton />
                </div>
                <div className="px-1">
                  <FullscreenButton />
                </div>
                <div className="px-1">
                  <ExitButton onClick={`/`} label={t("exit_area")} />
                </div>
              </div>
            </div>
          }
        />
      }
    >
      {children}
    </Layout>
  );
}
