import * as React from "react";
import { RoomProvider, useRoom } from "./RoomProvider";
import { useParams, useRouteMatch } from "react-router";
import { useLocation } from "react-router-dom";
import { GameLoader } from "../GameLoader";
import { Table } from "../Table";
import { CreateTableProvider } from "./CreateTable";
import { Layout } from "../Layout";
import { useTranslation } from "react-i18next";
import { useLeaderboards } from "../Leaderboards";
import { HeaderMenuItem } from "../Menu";
import { Header } from "../Header";
import { Tooltip } from "../Tooltip";
import {
  faChessKnight,
  faInfoCircle,
  faTrophy,
  faUser,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import styles from "./Area.module.css";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedNumber } from "../FormattedNumber";
import { Marquee } from "../MarqueeProvider";
import { TablesList } from "./TablesList";
import { LinkWithSound } from "../AudioProvider";
import { useNumberFormatter } from "../../hooks/useNumberFormatter";
import { useRelativeTimeFormatter } from "../../hooks/useRelativeTimeFormatter";
import { TournamentFragment } from "./graphql/TournamentFragment.generated";
import { RoomQuery } from "./RoomProvider/index.generated";
import {
  TournamentsSidebarProvider,
  useTournamentsSidebar,
} from "./ToournamentsSidebar";
import { CurrencyImage } from "../CurrencyImage";
import { RoomChat } from "./RoomChat";
import Balances from "../Balances";
import HeaderRight from "../Header/HeaderRight";
import * as Sentry from "@sentry/browser";

type Section = NonNullable<RoomQuery["room"]>["sections"][0];

const Counter = React.memo<{ value: number; icon: IconDefinition }>(
  ({ value, icon }) => {
    return (
      <div className="relative flex items-center">
        <div
          className={classNames(
            "flex",
            "items-center",
            "justify-center",
            "text-white"
          )}
        >
          <FontAwesomeIcon icon={icon} fixedWidth />
        </div>
        <div className={classNames("ml-2", "leading-none", "text-center")}>
          <span className="text-white font-bold text-sm">
            <FormattedNumber value={value} />
          </span>
        </div>
      </div>
    );
  }
);

const CountInfo = () => {
  const { t } = useTranslation();
  const room = useRoom();
  let openTables = 0;
  for (const section of room.sections) openTables += section.count;

  return (
    <div className="px-2">
      <div className="flex -mx-1">
        <div className="px-1">
          <Tooltip label={t("online_users_tooltip_info")}>
            <div>
              <Counter icon={faUser} value={room.onlineUsers} />
            </div>
          </Tooltip>
        </div>
        <div className="px-1">
          <Tooltip label={t("room_tables_tooltip_info")}>
            <div>
              <Counter icon={faChessKnight} value={openTables} />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

function AreaHeader({
  roomId,
  sections,
  tournamentId,
}: {
  roomId: string;
  sections: Array<Section>;
  tournamentId?: string;
}) {
  const { t } = useTranslation();
  const toggleLeaderboards = useLeaderboards();
  const sectionIds = React.useMemo(() => sections.map((s) => s.id), [sections]);
  return (
    <Header
      menu={
        <HeaderMenuItem
          icon={faTrophy}
          onSelect={() => toggleLeaderboards(sectionIds)}
        >
          {t("leaderboards")}
        </HeaderMenuItem>
      }
      right={<HeaderRight />}
      children={
        <div className="flex items-center w-full mx-4">
          <Balances />
          <CountInfo />
          {/* <div
            style={{
              minWidth: "284px",
              maxWidth: "284px",
            }}
          >
            <RoomChat roomId={roomId} />
          </div> */}
        </div>
      }
      sectionIds={sections.map((s) => s.id)}
    />
  );
}

const TournamentRow = React.memo<{
  tournament: TournamentFragment;
  tables: number;
}>(({ tournament, tables }) => {
  const route = `/area/${tournament.section.id}`;
  const match = useRouteMatch(route);
  const { t } = useTranslation();
  const numberFormatter = useNumberFormatter();
  const relativeTimeFormatter = useRelativeTimeFormatter();
  const toggleTournamentSidebar = useTournamentsSidebar();
  return (
    <tr key={tournament.id} className={match ? "bg-gray-700" : ""}>
      <td className="px-2 py-1">
        <div className="flex -mx-1">
          <div className="px-1">
            <Tooltip label={t("tournament_info")}>
              <button
                onClick={() => toggleTournamentSidebar(tournament.id)}
                className="text-white"
              >
                <FontAwesomeIcon icon={faInfoCircle} size="lg" fixedWidth />
              </button>
            </Tooltip>
          </div>
          <div className="px-1 truncate">{tournament.name}</div>
        </div>
      </td>
      <td className="px-2 py-1 truncate">
        {t(`game.${tournament.section.type}`)}
      </td>
      <td className="px-2 py-1 truncate">
        <div className="flex -mx-1 items-center">
          <span className="px-1">
            {numberFormatter.format(tournament.amount.value)}
          </span>
          <div className="px-1">
            <CurrencyImage
              currency={tournament.amount.currency}
              width={18}
              height={18}
            />
          </div>
        </div>
      </td>
      <td className="px-2 py-1 truncate">
        <div className="flex -mx-1 items-center">
          <span className="px-1">
            {numberFormatter.format(tournament.prices[0].amount.value)}
          </span>
          <div className="px-1">
            <CurrencyImage
              currency={tournament.prices[0].amount.currency}
              width={18}
              height={18}
            />
          </div>
        </div>
      </td>
      <td
        className={classNames("px-2", "py-1", "truncate", {
          "text-red-600": !tournament.active,
        })}
      >
        {relativeTimeFormatter.format(new Date(tournament.end), {
          addSuffix: true,
        })}
      </td>
      <td className="px-2 py-1 truncate text-center">{tables}</td>
      <td className="px-4 py-1">
        <LinkWithSound
          className="btn btn-orange-600 btn-sm rounded-full w-full"
          to={route}
        >
          <div className="flex -mx-1 items-center justify-center">
            <span className="px-1 text-center flex-1 truncate">
              {t("view")}
            </span>
          </div>
        </LinkWithSound>
      </td>
    </tr>
  );
});

function TournamentsList({
  tournaments,
  counters,
  mainArea,
  totalNormalTables,
}: {
  tournaments: Array<TournamentFragment>;
  counters: { [key in string]: number };
  mainArea: string;
  totalNormalTables: number;
}) {
  const { t } = useTranslation();
  return (
    <div className="bg-gray-900 w-full rounded-lg overflow-hidden">
      <table className="text-white w-full">
        <thead>
          <tr>
            <th className="px-2 py-2 text-left">{t("name")}</th>
            <th className="px-2 py-2 text-left">{t("game_label")}</th>
            <th className="px-2 py-2 text-left">{t("entry_fee")}</th>
            <th className="px-2 py-2 text-left">{t("1st_price")}</th>
            <th className="px-2 py-2 text-left">{t("ends")}</th>
            <th className="px-2 py-2 text-center">{t("tables")}</th>
            <th className="px-4 py-2 w-2/12 text-left">
              <LinkWithSound
                className="btn btn-blue-600 btn-sm rounded-full w-full"
                to={`/area/${mainArea}`}
              >
                <div className="flex -mx-1 items-center justify-center">
                  <span className="flex-1 px-1 text-center truncate">
                    {t("all_tables")}
                  </span>
                </div>
              </LinkWithSound>
            </th>
          </tr>
        </thead>
        <tbody>
          {tournaments.map((tournament) => (
            <TournamentRow
              tournament={tournament}
              tables={counters[tournament.section.id]}
              key={tournament.id}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

function Body({
  sections,
  section,
}: {
  sections: Array<Section>;
  section: Section;
}) {
  const { t } = useTranslation();
  const room = useRoom();
  const [counters, totalNormalTables, tournaments] = React.useMemo(() => {
    let result: { [key in string]: number } = {};
    let totalNormalTables = 0;
    let tournaments = [];
    for (const section of room.sections) {
      const count = section.count;
      result[section.id] = count;
      if (section.__typename === "GameSection") totalNormalTables += count;
      else tournaments.push(section.tournament);
    }
    return [result, totalNormalTables, tournaments];
  }, [room.sections]);

  const location = useLocation();

  React.useEffect(() => {
    if (location.state) {
      if ((location.state as any).doShowAd) {
        try {
          //Google Ads Placement API (https://developers.google.com/ad-placement/apis)
          (window as any).adBreak({
            type: "browse", // The player explores options outside of the gameplay.
            name: "after_game_end", // A descriptive name for this placement
          });
        } catch (e) {
          //If ad is not available do nothing
          Sentry.captureMessage("Error on ad after game: " + e);
        }
      }
    }
  }, [location.state]);

  return (
    <div className="h-full flex flex-col">
      <div className="flex-1 mx-auto w-5/6 overflow-hidden">
        <div className="absolute top-0 left-0 right-0">
          <Marquee />
        </div>
        <TablesList sections={sections} section={section} />
      </div>
      {tournaments.length > 0 && (
        <div className="flex-shrink-0 mx-auto w-5/6">
          <div className="text-center text-white text-xl font-bold py-2 font-mono text-uppercase">
            {t("tournaments")}
          </div>
          <TournamentsList
            tournaments={tournaments}
            counters={counters}
            totalNormalTables={totalNormalTables}
            mainArea={room.id}
          />
        </div>
      )}
    </div>
  );
}

function AreaLayout({
  sections,
  tableId,
  selected,
}: {
  sections: Array<Section>;
  selected: Section;
  tableId?: string;
}) {
  const idx = selected.id.indexOf(":");
  const roomId = idx < 0 ? selected.id : selected.id.substring(0, idx);

  return (
    <>
      {tableId === undefined ? (
        <TournamentsSidebarProvider>
          <CreateTableProvider section={selected}>
            <div
              className={classNames("h-full", "w-full", {
                [styles.tournamentArea]:
                  selected.__typename === "TournamentSection",
              })}
            >
              <Layout
                header={
                  <AreaHeader
                    roomId={roomId}
                    sections={sections}
                    tournamentId={
                      selected.__typename === "TournamentSection"
                        ? selected.tournament!.id
                        : undefined
                    }
                  />
                }
              >
                <div className="flex flex-col h-full">
                  <div className="flex-1 overflow-hidden py-6">
                    <Body sections={sections} section={selected} />
                  </div>
                </div>
              </Layout>
            </div>
          </CreateTableProvider>
        </TournamentsSidebarProvider>
      ) : (
        <GameLoader sectionId={selected.id}>
          <Table sectionId={selected.id} tableId={tableId} />
        </GameLoader>
      )}
    </>
  );
}

function AreaPanel({
  sectionId,
  tableId,
}: {
  sectionId: string;
  tableId?: string;
}) {
  const room = useRoom();
  const [sections, selected] = React.useMemo(() => {
    const foundSection = room.sections.find((s) => s.id === sectionId);
    if (
      foundSection === undefined ||
      foundSection.__typename === "GameSection"
    ) {
      const sections = room.sections.filter(
        (s) => s.__typename === "GameSection"
      );

      return [
        sections,
        foundSection === undefined ? sections[0] : foundSection,
      ];
    } else return [[foundSection], foundSection];
  }, [room.sections, sectionId]);
  return (
    <AreaLayout sections={[selected]} selected={selected} tableId={tableId} />
  );
}

export function Area() {
  const { sectionId: sectionIdParam, tableId } = useParams<{
    sectionId: string;
    tableId?: string;
  }>();
  const [roomId, sectionId] = React.useMemo(() => {
    const idx = sectionIdParam.indexOf(":");
    if (idx < 0) return [sectionIdParam, sectionIdParam];
    else return [sectionIdParam.substring(0, idx), sectionIdParam];
  }, [sectionIdParam]);
  return (
    <RoomProvider id={roomId} key={roomId}>
      <AreaPanel sectionId={sectionId} tableId={tableId} />
    </RoomProvider>
  );
}
