import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import type {
  Channel,
  ChannelFilters,
  ChannelOptions,
  ChannelSort,
  Event,
} from "stream-chat";
import {
  DefaultStreamChatGenerics,
  PaginatorProps,
} from "stream-chat-react/dist/types/types";
import {
  Avatar as DefaultAvatar,
  AvatarProps,
  ChannelListMessenger,
  ChannelListMessengerProps,
  ChannelPreview,
  ChannelPreviewUIComponentProps,
  ChannelSearchProps,
  ChatDown,
  ChatDownProps,
  EmptyStateIndicatorProps,
  EmptyStateIndicator as DefaultEmptyStateIndicator,
  LoadingChannels,
  LoadMorePaginator,
  LoadMorePaginatorProps,
  MAX_QUERY_CHANNELS_LIMIT,
  moveChannelUp,
  useChannelDeletedListener,
  useChannelHiddenListener,
  useChannelTruncatedListener,
  useChannelUpdatedListener,
  useChannelVisibleListener,
  useChatContext,
  useConnectionRecoveredListener,
  useMessageNewListener,
  useMobileNavigation,
  useNotificationAddedToChannelListener,
  useNotificationMessageNewListener,
  useNotificationRemovedFromChannelListener,
  usePaginatedChannels,
  useUserPresenceChangedListener,
} from "stream-chat-react";
import _debounce from "lodash.debounce";
import searchIcon from "./assets/searchIcon.svg";
import closeIcon from "./assets/closeIcon.svg";
import backIcon from "./assets/backIcon.svg";
import SearchedChannels from "./SearchedChannels";
import { useCustomChatContext, useUser } from "../UserProvider";
import classNames from "classnames";
import addGroup from "./assets/addGroup.svg";
import {
  changeChannelFilters as updateChannelsFilter,
  useDirectMessageNewListener,
} from "./utils";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

export type ChannelTypeSelect = "group" | "direct_friends" | "direct_others";

const DEFAULT_FILTERS = {};
const DEFAULT_OPTIONS = {};
const DEFAULT_SORT = {};

export type ChannelListProps<
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
> = {
  /** Additional props for underlying ChannelSearch component and channel search controller, [available props](https://getstream.io/chat/docs/sdk/react/utility-components/channel_search/#props) */
  additionalChannelSearchProps?: Omit<
    ChannelSearchProps<StreamChatGenerics>,
    "setChannels"
  >;
  /**
   * When the client receives `message.new`, `notification.message_new`, and `notification.added_to_channel` events, we automatically
   * push that channel to the top of the list. If the channel doesn't currently exist in the list, we grab the channel from
   * `client.activeChannels` and push it to the top of the list. You can disable this behavior by setting this prop
   * to false, which will prevent channels not in the list from incrementing the list. The default is true.
   */
  allowNewMessagesFromUnfilteredChannels?: boolean;
  /** Custom UI component to display user avatar, defaults to and accepts same props as: [Avatar](https://github.com/GetStream/stream-chat-react/blob/master/src/components/Avatar/Avatar.tsx) */
  Avatar?: React.ComponentType<AvatarProps>;
  /** Optional function to filter channels prior to loading in the DOM. Do not use any complex or async logic that would delay the loading of the ChannelList. We recommend using a pure function with array methods like filter/sort/reduce. */
  channelRenderFilterFn?: (
    channels: Array<Channel<StreamChatGenerics>>
  ) => Array<Channel<StreamChatGenerics>>;
  /** Custom UI component to display search results, defaults to and accepts same props as: [ChannelSearch](https://github.com/GetStream/stream-chat-react/blob/master/src/components/ChannelSearch/ChannelSearch.tsx) */
  ChannelSearch?: React.ComponentType<ChannelSearchProps<StreamChatGenerics>>;
  /** Set a channel (with this ID) to active and manually move it to the top of the list */
  customActiveChannel?: string;
  /** Custom UI component for rendering an empty list, defaults to and accepts same props as: [EmptyStateIndicator](https://github.com/GetStream/stream-chat-react/blob/master/src/components/EmptyStateIndicator/EmptyStateIndicator.tsx) */
  EmptyStateIndicator?: React.ComponentType<EmptyStateIndicatorProps>;
  /** An object containing channel query filters */
  filters?: ChannelFilters<StreamChatGenerics>;
  /** Custom UI component to display the container for the queried channels, defaults to and accepts same props as: [ChannelListMessenger](https://github.com/GetStream/stream-chat-react/blob/master/src/components/ChannelList/ChannelListMessenger.tsx) */
  List?: React.ComponentType<ChannelListMessengerProps<StreamChatGenerics>>;
  /** Custom UI component to display the loading error indicator, defaults to and accepts same props as: [ChatDown](https://github.com/GetStream/stream-chat-react/blob/master/src/components/ChatDown/ChatDown.tsx) */
  LoadingErrorIndicator?: React.ComponentType<ChatDownProps>;
  /** Custom UI component to display the loading state, defaults to and accepts same props as: [LoadingChannels](https://github.com/GetStream/stream-chat-react/blob/master/src/components/Loading/LoadingChannels.tsx) */
  LoadingIndicator?: React.ComponentType;
  /** When true, channels won't dynamically sort by most recent message */
  lockChannelOrder?: boolean;
  /** Function to override the default behavior when a user is added to a channel, corresponds to [notification.added\_to\_channel](https://getstream.io/chat/docs/javascript/event_object/?language=javascript) event */
  onAddedToChannel?: (
    setChannels: React.Dispatch<
      React.SetStateAction<Array<Channel<StreamChatGenerics>>>
    >,
    event: Event<StreamChatGenerics>
  ) => void;
  /** Function to override the default behavior when a channel is deleted, corresponds to [channel.deleted](https://getstream.io/chat/docs/javascript/event_object/?language=javascript) event */
  onChannelDeleted?: (
    setChannels: React.Dispatch<
      React.SetStateAction<Array<Channel<StreamChatGenerics>>>
    >,
    event: Event<StreamChatGenerics>
  ) => void;
  /** Function to override the default behavior when a channel is hidden, corresponds to [channel.hidden](https://getstream.io/chat/docs/javascript/event_object/?language=javascript) event */
  onChannelHidden?: (
    setChannels: React.Dispatch<
      React.SetStateAction<Array<Channel<StreamChatGenerics>>>
    >,
    event: Event<StreamChatGenerics>
  ) => void;
  /** Function to override the default behavior when a channel is truncated, corresponds to [channel.truncated](https://getstream.io/chat/docs/javascript/event_object/?language=javascript) event */
  onChannelTruncated?: (
    setChannels: React.Dispatch<
      React.SetStateAction<Array<Channel<StreamChatGenerics>>>
    >,
    event: Event<StreamChatGenerics>
  ) => void;
  /** Function to override the default behavior when a channel is updated, corresponds to [channel.updated](https://getstream.io/chat/docs/javascript/event_object/?language=javascript) event */
  onChannelUpdated?: (
    setChannels: React.Dispatch<
      React.SetStateAction<Array<Channel<StreamChatGenerics>>>
    >,
    event: Event<StreamChatGenerics>
  ) => void;
  /** Function to override the default channel visible behavior, corresponds to [channel.visible](https://getstream.io/chat/docs/javascript/event_object/?language=javascript) event */
  onChannelVisible?: (
    setChannels: React.Dispatch<
      React.SetStateAction<Array<Channel<StreamChatGenerics>>>
    >,
    event: Event<StreamChatGenerics>
  ) => void;
  /** Function to override the default behavior when a message is received on a channel not being watched, corresponds to [notification.message\_new](https://getstream.io/chat/docs/javascript/event_object/?language=javascript) event */
  onMessageNew?: (
    setChannels: React.Dispatch<
      React.SetStateAction<Array<Channel<StreamChatGenerics>>>
    >,
    event: Event<StreamChatGenerics>
  ) => void;
  /** Function to override the default behavior when a user gets removed from a channel, corresponds to [notification.removed\_from\_channel](https://getstream.io/chat/docs/javascript/event_object/?language=javascript) event */
  onRemovedFromChannel?: (
    setChannels: React.Dispatch<
      React.SetStateAction<Array<Channel<StreamChatGenerics>>>
    >,
    event: Event<StreamChatGenerics>
  ) => void;
  /** An object containing channel query options */
  options?: ChannelOptions;
  /** Custom UI component to handle channel pagination logic, defaults to and accepts same props as: [LoadMorePaginator](https://github.com/GetStream/stream-chat-react/blob/master/src/components/LoadMore/LoadMorePaginator.tsx) */
  Paginator?: React.ComponentType<PaginatorProps | LoadMorePaginatorProps>;
  /** Custom UI component to display the channel preview in the list, defaults to and accepts same props as: [ChannelPreviewMessenger](https://github.com/GetStream/stream-chat-react/blob/master/src/components/ChannelPreview/ChannelPreviewMessenger.tsx) */
  Preview?: React.ComponentType<
    ChannelPreviewUIComponentProps<StreamChatGenerics>
  >;
  /** Function to override the default behavior when rendering channels, so this function is called instead of rendering the Preview directly */
  renderChannels?: (
    channels: Channel<StreamChatGenerics>[],
    channelPreview: (item: Channel<StreamChatGenerics>) => React.ReactNode
  ) => React.ReactNode;
  /** If true, sends the list's currently loaded channels to the `List` component as the `loadedChannels` prop */
  sendChannelsToList?: boolean;
  /** Last channel will be set as active channel if true, defaults to true */
  setActiveChannelOnMount?: boolean;
  /** Whether or not to load the list with a search component, defaults to false */
  showChannelSearch?: boolean;
  /** An object containing channel query sort parameters */
  sort?: ChannelSort<StreamChatGenerics>;
  /** An object containing query parameters for fetching channel watchers */
  watchers?: { limit?: number; offset?: number };
  selectChannelType?: (type: ChannelTypeSelect) => void;
  channelType: ChannelTypeSelect;
  createNewChannel: () => void;
  isCreating: boolean;
  setChatsAvailable: React.Dispatch<
    React.SetStateAction<{
      loading: boolean;
      noResult: boolean;
    }>
  >;
  closeCreatingComponent: () => void;
};

const UnMemoizedChannelList = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  props: ChannelListProps<StreamChatGenerics>
) => {
  const {
    additionalChannelSearchProps,
    Avatar = DefaultAvatar,
    allowNewMessagesFromUnfilteredChannels,
    channelRenderFilterFn,
    customActiveChannel,
    EmptyStateIndicator = DefaultEmptyStateIndicator,
    filters,
    LoadingErrorIndicator = ChatDown,
    LoadingIndicator = LoadingChannels,
    List = ChannelListMessenger,
    lockChannelOrder,
    onAddedToChannel,
    onChannelDeleted,
    onChannelHidden,
    onChannelTruncated,
    onChannelUpdated,
    onChannelVisible,
    onMessageNew,
    onRemovedFromChannel,
    options,
    Paginator = LoadMorePaginator,
    Preview,
    renderChannels,
    sendChannelsToList = false,
    setActiveChannelOnMount = true,
    sort = DEFAULT_SORT,
    watchers = {},
    selectChannelType,
    channelType,
    createNewChannel,
    isCreating,
    setChatsAvailable,
    closeCreatingComponent,
  } = props;

  const {
    channel,
    channelsQueryState,
    client,
    closeMobileNav,
    customClasses,
    navOpen = false,
    setActiveChannel,
    theme,
    useImageFlagEmojisOnWindows,
  } = useChatContext<StreamChatGenerics>("ChannelList");

  const channelListRef = useRef<HTMLDivElement>(null);
  const [channelUpdateCount, setChannelUpdateCount] = useState(0);
  const [searchActive, setSearchActive] = useState(false);
  /**
   * Set a channel with id {customActiveChannel} as active and move it to the top of the list.
   * If customActiveChannel prop is absent, then set the first channel in list as active channel.
   */
  const activeChannelHandler = async (
    channels: Array<Channel<StreamChatGenerics>>,
    setChannels: React.Dispatch<
      React.SetStateAction<Array<Channel<StreamChatGenerics>>>
    >
  ) => {
    if (
      !channels.length ||
      channels.length > (options?.limit || MAX_QUERY_CHANNELS_LIMIT)
    ) {
      return;
    }
    if (customActiveChannel) {
      let customActiveChannelObject = channels.find(
        (chan) => chan.id === customActiveChannel
      );

      if (!customActiveChannelObject) {
        //@ts-expect-error
        [customActiveChannelObject] = await client.queryChannels({
          id: customActiveChannel,
        });
      }

      if (customActiveChannelObject) {
        setActiveChannel(customActiveChannelObject, watchers);

        const newChannels = moveChannelUp({
          activeChannel: customActiveChannelObject,
          channels,
          cid: customActiveChannelObject.cid,
        });

        setChannels(newChannels);
      }

      return;
    }

    if (setActiveChannelOnMount) {
      setActiveChannel(channels[0], watchers);
    }
  };

  /**
   * For some events, inner properties on the channel will update but the shallow comparison will not
   * force a re-render. Incrementing this dummy variable ensures the channel previews update.
   */
  const forceUpdate = () => setChannelUpdateCount((count) => count + 1);

  const {
    channels,
    hasNextPage,
    loadNextPage,
    setChannels,
  } = usePaginatedChannels(
    client,
    filters || DEFAULT_FILTERS,
    sort || DEFAULT_SORT,
    options || DEFAULT_OPTIONS,
    activeChannelHandler
  );

  const loadedChannels = channelRenderFilterFn
    ? channelRenderFilterFn(channels)
    : channels;

  useMobileNavigation(channelListRef, navOpen, closeMobileNav);

  useMessageNewListener(setChannels, lockChannelOrder, false);
  useDirectMessageNewListener(channelType);
  useNotificationMessageNewListener(setChannels, onMessageNew, false);
  useNotificationAddedToChannelListener(setChannels, onAddedToChannel, false);
  useNotificationRemovedFromChannelListener(setChannels, onRemovedFromChannel);
  useChannelDeletedListener(setChannels, onChannelDeleted);
  useChannelHiddenListener(setChannels, onChannelHidden);
  useChannelVisibleListener(setChannels, onChannelVisible);
  useChannelTruncatedListener(setChannels, onChannelTruncated, forceUpdate);
  useChannelUpdatedListener(setChannels, onChannelUpdated, forceUpdate);
  useConnectionRecoveredListener(forceUpdate);
  useUserPresenceChangedListener(setChannels);

  const [showChannels, setShowChannels] = useState<
    Channel<StreamChatGenerics>[]
  >(loadedChannels);

  const { t } = useTranslation();

  const { user } = useUser();
  const {
    sendMessage,
    setSendMessage,
    unreadFriends,
    setUnreadFriends,
    unreadOthers,
    setUnreadOthers,
  } = useCustomChatContext();
  const [query, setQuery] = useState<string>("");
  const [searching, setSearching] = useState<boolean>(false);
  const [noResults, setNoResults] = useState<boolean>(false);
  const [haveOthersChannels, setHaveOthersChannels] = useState<boolean>(false);
  const isVip =
    user.vipUntil && new Date(user.vipUntil).getTime() > new Date().getTime();

  const directChat =
    channelType === "direct_friends" || channelType === "direct_others";

  const changeChannelFilters = (text: string) => {
    switch (channelType) {
      case "group":
        return {
          type: { $in: ["room", "group"] },
          name: { $autocomplete: text },
        };
      case "direct_friends":
        return {
          type: channelType,
          members: { $in: [user.id] },
        };
      case "direct_others":
        return {
          type: channelType,
          members: { $in: [user.id] },
        };
    }
  };

  const haveOthersChats = async () => {
    try {
      const channelResponse = client.queryChannels(
        //@ts-ignore
        {
          type: "direct_others",
          members: { $in: [user.id] },
        },
        {},
        { limit: directChat ? 1000 : 30, presence: true }
      );

      const [channels] = await Promise.all([channelResponse]);
      if (channels.length) {
        setHaveOthersChannels(true);
      }
    } catch (event) {
      console.log(event);
    }
  };

  const directChatSearch = (
    channels: Channel<StreamChatGenerics>[],
    text: string
  ) => {
    let arr: Channel<StreamChatGenerics>[] = [];
    channels.map((channelData) => {
      const state = Object.values(channelData.state.read);
      state.map((userData) => {
        if (userData.user.id !== user.id) {
          const userName = userData.user.name
            ?.toLocaleLowerCase()
            .includes(text.toLowerCase());
          if (userName) arr = [...arr, channelData];
        }
      });
      if (arr.length) {
        setShowChannels(arr);
        setNoResults(false);
      } else {
        setNoResults(true);
      }
    });
  };

  const getChannels = async (text: string) => {
    try {
      const channelResponse = client.queryChannels(
        //@ts-ignore
        changeChannelFilters(text),
        {},
        { limit: directChat ? 1000 : 30, presence: true }
      );

      const [channels] = await Promise.all([channelResponse]);
      if (directChat) {
        directChatSearch(channels, text);
        return;
      }
      if (channels.length) {
        setShowChannels(channels);
        setNoResults(false);
      } else if (!channels.length) {
        setNoResults(true);
      }
    } catch (event) {
      setQuery("");
    }
  };

  const channelsUpdate = async () => {
    try {
      const channelResponse = client.queryChannels(
        //@ts-ignore
        updateChannelsFilter(channelType, user.id),
        {},
        { limit: 30, presence: true }
      );

      const [channels] = await Promise.all([channelResponse]);
      if (channels.length) {
        setShowChannels(channels);
      }
    } catch (event) {
      console.log(event);
    }
  };

  const haveUnread = async (channelType: ChannelTypeSelect) => {
    try {
      const channelResponse = client.queryChannels(
        //@ts-ignore
        updateChannelsFilter(channelType, user.id),
        {},
        { limit: 1000, presence: true }
      );

      const [channels] = await Promise.all([channelResponse]);
      if (channels.length) {
        const haveUnreadInChannels = channels.filter(
          (e) => e.state.unreadCount !== 0
        );
        if (haveUnreadInChannels.length && channelType === "direct_friends")
          setUnreadFriends(true);
        if (haveUnreadInChannels.length && channelType === "direct_others")
          setUnreadOthers(true);
      }
    } catch (event) {
      console.log(event);
    }
  };

  useEffect(() => {
    if (!isCreating) {
      channelsUpdate();
    }
  }, [isCreating]);

  const getChannelsDebounce = _debounce(getChannels, 300, {
    trailing: true,
  });

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setQuery(event.target.value);
    if (event.target.value.length < 2) {
      setSearching(false);
    }
    if (event.target.value.length > 1) {
      setSearching(true);
      getChannelsDebounce(event.target.value);
    }
  };

  const selectType = (type: ChannelTypeSelect) => {
    if (selectChannelType) selectChannelType(type);
  };

  const clearSearch = () => {
    setQuery("");
    setNoResults(false);
    setShowChannels(loadedChannels);
  };

  const showText = () => {
    if (channelType === "group") return t("add_new_group");
    else {
      return t("send_direct_message");
    }
  };

  //For send message button in profile.

  const createDirectChatChat = () => {
    const channelId = uuidv4();
    const newChannel = client?.channel(
      sendMessage.channelType,
      `${channelId}`,
      {
        members: sendMessage.users,
      }
    );

    newChannel?.watch().then((createChannelResponse) => {
      if (createChannelResponse.channel !== undefined) {
        setActiveChannel(newChannel);
        selectType(sendMessage.channelType);
      }
    });
  };

  const uploadedNewChannels =
    showChannels[0]?.type === "direct_others" ||
    showChannels[0]?.type === "direct_friends";

  const getChannelsForPrivatMessage = async () => {
    const filter = {
      type: { $in: ["direct_friends", "direct_others"] },
      members: { $eq: sendMessage.users },
    };
    if (client) {
      try {
        //@ts-ignore
        const channelResponse = client.queryChannels(filter, {}, {});
        const [channels] = await Promise.all([channelResponse]);
        if (channels.length) {
          //@ts-ignore
          selectType(channels[0].type);
          setActiveChannel(channels[0]);
          chatActive();
        } else {
          createDirectChatChat();
          chatActive();
        }
      } catch (event) {
        console.log(event);
      }
    }
  };

  const chatActive = async () => {
    if (uploadedNewChannels) {
      setSendMessage({
        isSending: false,
        channelType: channelType,
        users: [],
      });
    }
  };

  useEffect(() => {
    if (!searching) {
      const wait = setTimeout(() => {
        setShowChannels(loadedChannels);
      }, 500);
      return () => {
        clearTimeout(wait);
      };
    }
  }, [loadedChannels, searching]);

  useEffect(() => {
    if (channelsQueryState.queryInProgress === "reload") {
      setChatsAvailable({ loading: true, noResult: !!showChannels.length });
    } else {
      setChatsAvailable({ loading: false, noResult: !!showChannels.length });
    }
  }, [showChannels, channelsQueryState.queryInProgress]);

  useEffect(() => {
    clearSearch();
  }, [channelType, loadedChannels]);

  useEffect(() => {
    const handleEvent = (event: Event<StreamChatGenerics>) => {
      if (event.cid === channel?.cid) {
        setActiveChannel(showChannels[0]);
      }
    };

    client.on("channel.deleted", handleEvent);
    client.on("channel.hidden", handleEvent);

    return () => {
      client.off("channel.deleted", handleEvent);
      client.off("channel.hidden", handleEvent);
    };
  }, [channel?.cid]);

  useEffect(() => {
    haveOthersChats();
    client.on("notification.added_to_channel", haveOthersChats);

    return () => {
      client.off("notification.added_to_channel", haveOthersChats);
    };
  }, []);

  useEffect(() => {
    if (sendMessage.isSending) {
      getChannelsForPrivatMessage();
    }
  }, [sendMessage.isSending, uploadedNewChannels]);

  useEffect(() => {
    if (channelType !== "direct_others") {
      haveUnread("direct_others");
    }
    if (channelType !== "direct_friends") {
      haveUnread("direct_friends");
    }
  }, []);

  const showOthersChannels = isVip || haveOthersChannels;

  const renderChannel = (item: Channel<StreamChatGenerics>) => {
    const previewProps = {
      activeChannel: channel,
      Avatar,
      channel: item,
      // forces the update of preview component on channel update
      channelUpdateCount,
      key: item.id,
      Preview,
      setActiveChannel,
      watchers,
    };

    return <ChannelPreview {...previewProps} />;
  };

  const className = clsx(
    customClasses?.chat ?? "str-chat",
    theme,
    customClasses?.channelList ??
      "str-chat-channel-list str-chat__channel-list str-chat__channel-list-react",
    {
      "str-chat--windows-flags":
        useImageFlagEmojisOnWindows && navigator.userAgent.match(/Win/),
      "str-chat-channel-list--open": navOpen,
    }
  );

  const showChannelList = !searchActive && showChannels;
  return (
    <>
      <div className={className} ref={channelListRef}>
        {!channelsQueryState.error && (
          <div>
            <div className="relative flex search-container">
              <img
                src={query ? backIcon : searchIcon}
                alt="Search"
                className="pr-2 search-icon"
              />
              <input
                onChange={handleSearch}
                placeholder={t("search")}
                className="search-input"
                type="text"
                value={query}
              />
              {query && (
                <img
                  src={closeIcon}
                  alt="Close"
                  className="cursor-pointer close-icon"
                  onClick={clearSearch}
                />
              )}
            </div>
            <div>
              <div className="nav-bar">
                <div
                  className="direct-private-container"
                  style={{
                    paddingRight: showOthersChannels ? "6px" : "30px",
                    paddingLeft: showOthersChannels ? "6px" : "30px",
                  }}
                >
                  <div className="">
                    <button
                      className={classNames("button-style", {
                        "active-button": channelType === "group",
                      })}
                      onClick={() => {
                        selectType("group");
                        setChatsAvailable({ loading: false, noResult: true });
                        closeCreatingComponent();
                      }}
                    >
                      {t("groups")}
                    </button>
                  </div>
                  <div className="relative">
                  {unreadFriends && <div className="unreadMessage" />}
                    <button
                      className={classNames("button-style", {
                        "active-button": channelType === "direct_friends",
                      })}
                      onClick={() => {
                        selectType("direct_friends");
                        setChatsAvailable({ loading: false, noResult: true });
                        setUnreadFriends(false);
                        closeCreatingComponent();
                      }}
                    >
                      {t("chat_friends")}
                    </button>
                  </div>
                  {showOthersChannels && (
                    <div className="relative">
                      {unreadOthers && <div className="unreadMessage" />}
                      <button
                        className={classNames("button-style", {
                          "active-button": channelType === "direct_others",
                        })}
                        onClick={() => {
                          selectType("direct_others");
                          setChatsAvailable({ loading: false, noResult: true });
                          setUnreadOthers(false);
                          closeCreatingComponent();
                        }}
                      >
                        {t("others")}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="add-channel-container">
              {!isVip && channelType === "direct_others" ? (
                <></>
              ) : (
                <div className="flex items-center">
                  <button className="add-button" onClick={createNewChannel}>
                    <img src={addGroup} alt="" />
                  </button>
                  <span className="create-channel-text">{showText()}</span>
                </div>
              )}
            </div>
          </div>
        )}
        {showChannelList && (
          <List
            error={channelsQueryState.error}
            loadedChannels={sendChannelsToList ? showChannels : undefined}
            loading={channelsQueryState.queryInProgress === "reload"}
            LoadingErrorIndicator={LoadingErrorIndicator}
            LoadingIndicator={LoadingIndicator}
            setChannels={setChannels}
          >
            {!showChannels?.length ? (
              <></>
            ) : searching ? (
              <SearchedChannels
                renderChannels={renderChannels}
                renderChannel={renderChannel}
                showChannels={showChannels}
                noResults={noResults}
              />
            ) : (
              <Paginator
                hasNextPage={hasNextPage}
                isLoading={channelsQueryState.queryInProgress === "load-more"}
                loadNextPage={loadNextPage}
              >
                {renderChannels
                  ? renderChannels(showChannels, renderChannel)
                  : showChannels.map((channel) => renderChannel(channel))}
              </Paginator>
            )}
          </List>
        )}
      </div>
    </>
  );
};

/**
 * Renders a preview list of Channels, allowing you to select the Channel you want to open
 */

export const ChannelList = React.memo(
  UnMemoizedChannelList
) as typeof UnMemoizedChannelList;
