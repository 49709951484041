import * as React from "react";
import { usePromotion } from "../../hooks/usePromotion";
import { useClient } from "urql";
import { loader } from "graphql.macro";
import {
  RedeemSystemGiftMutationMutation,
  RedeemSystemGiftMutationMutationVariables,
} from "./graphql/RedeemSystemMutation.generated";
import { useIsMounted } from "../../hooks/useIsMounted";
import { useTranslation } from "react-i18next";
import { CurrencyImage } from "../CurrencyImage";
import { ButtonWithSound } from "../AudioProvider";
import { AbsoluteCenter } from "../AbsoluteCenter";

const REDEEM_SYSTEM_GIFT_MUTATION = loader(
  "./graphql/RedeemSystemMutation.graphql"
);

function Result({
  data,
  close,
}: {
  data: NonNullable<RedeemSystemGiftMutationMutation["redeemSystemGift"]>;
  close: () => void;
}) {
  const { t } = useTranslation();
  return (
    <>
      <div className="text-center text-white font-bold mb-8 text-2xl">
        {t("promotion_success")}
      </div>
      <div className="flex -mx-4 items-center">
        {data
          .filter((v) => v.value > 0)
          .map((v, index) => (
            <div
              className="px-4 flex-1 flex-shrink-0 overflow-hidden"
              key={index}
            >
              <div className="flex items-center justify-center">
                <CurrencyImage currency={v.currency} width={180} />
              </div>
              <div className="font-bold text-4xl text-white text-center mt-2 truncate">
                {v.value}
              </div>
            </div>
          ))}
      </div>
      <ButtonWithSound
        className="btn btn-gray-500 btn-sm w-full rounded mt-5"
        onClick={close}
      >
        {t("close")}
      </ButtonWithSound>
    </>
  );
}

function Invalid({ close }: { close: () => void }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="text-center text-white font-bold mb-2 text-2xl">
        {t("promotion_invalid_title")}
      </div>

      <div className="text-center text-white font-bold">
        {t("promotion_invalid_subtitle")}
      </div>
      <ButtonWithSound
        className="btn btn-gray-500 btn-sm w-full rounded mt-5"
        onClick={close}
      >
        {t("close")}
      </ButtonWithSound>
    </>
  );
}

const Popup = ({
  data,
  close,
}: {
  data: RedeemSystemGiftMutationMutation["redeemSystemGift"];
  close: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className="absolute bg-black-alpha-30 top-0 left-0 right-0 bottom-0 z-50">
      <AbsoluteCenter>
        <div
          aria-label={t("promotion")}
          className="w-1/3 p-4 rounded shadow-xl bg-blue-800 border-blue-900 border-2"
        >
          {(data ?? null) !== null ? (
            <Result data={data!} close={close} />
          ) : (
            <Invalid close={close} />
          )}
        </div>
      </AbsoluteCenter>
    </div>
  );
};

export const Promotion = ({ children }: { children?: React.ReactNode }) => {
  const [promotion, , clearPromotion] = usePromotion();
  const client = useClient();
  const isMounted = useIsMounted();
  const [result, setResult] = React.useState<
    RedeemSystemGiftMutationMutation["redeemSystemGift"]
  >();

  const close = React.useCallback(() => setResult(undefined), []);
  React.useEffect(() => {
    const promo =
      promotion !== null && promotion.trim().length > 0 ? promotion.trim() : "";
    if (promo === "") return;
    client
      .mutation<
        RedeemSystemGiftMutationMutation,
        RedeemSystemGiftMutationMutationVariables
      >(REDEEM_SYSTEM_GIFT_MUTATION, {
        gift: promo,
      })
      .toPromise()
      .then((data) => {
        if (data.error !== undefined) throw data.error;
        return data;
      })
      .then((data) => {
        if (!isMounted.current) return;
        setResult(data?.data?.redeemSystemGift);
      })
      .catch((e) => console.error("Error redeeming system gift", e))
      .finally(() => {
        clearPromotion();
      });
  }, [promotion, clearPromotion, client, isMounted]);
  return (
    <>
      {children}
      {result !== undefined && <Popup data={result} close={close} />}
    </>
  );
};
