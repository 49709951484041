import * as React from "react";
import { Redirect } from "react-router-dom";
import { loader } from "graphql.macro";
import { useQuery } from "../../hooks/useQuery";
import { TableFragmentFragment } from "../../graphql/TableFragment.generated";
import { useTranslation } from "react-i18next";
import {
  TableQueryQuery,
  TableQueryQueryVariables,
} from "./graphql/TableQuery.generated";
import GameDefinitions from "../Games";
import { AbsoluteCenter } from "../AbsoluteCenter";
import { Loader } from "../Loader";
import { ErrorBoundary, FallbackProps } from "../ErrorBoundary";
import { LinkWithSound } from "../AudioProvider";
import { useUser } from "../UserProvider";
import * as Sentry from "@sentry/browser";

const TABLE_QUERY = loader("./graphql/TableQuery.graphql");

const FallbackComponent = ({
  error,
  sectionId,
}: { sectionId: string } & FallbackProps) => {
  const { t } = useTranslation();
  return (
    <AbsoluteCenter>
      <div className="w-1/3 p-4 rounded shadow-xl bg-blue-800 border-blue-900 border-2">
        <span className="text-white font-bold">{t("game_error")}</span>
        {error !== undefined && (
          <div className="bg-red-700 p-2 text-white rounded mt-2">
            {error.message}
          </div>
        )}
        <LinkWithSound
          className="btn btn-gray-500 btn-sm w-full rounded mt-2"
          to={`/area/${sectionId}`}
        >
          {t("back")}
        </LinkWithSound>
      </div>
    </AbsoluteCenter>
  );
};

const Game = ({ section, ...rest }: TableFragmentFragment) => {
  const Component = GameDefinitions[section.type].GameComponent;
  const { t } = useTranslation();
  return (
    <ErrorBoundary
      fallback={(props) => (
        <FallbackComponent sectionId={section.id} {...props} />
      )}
    >
      <React.Suspense
        fallback={
          <AbsoluteCenter>
            <Loader description={t("loading_game")} />
          </AbsoluteCenter>
        }
      >
        <Component sectionId={section.id} game={section.type} {...rest} />
      </React.Suspense>
    </ErrorBoundary>
  );
};

export const Table = ({
  sectionId,
  tableId,
}: {
  sectionId: string;
  tableId: string;
}) => {
  const {
    user: {
      ads: {
        placementConfig: { beginningOfGame: doShowAdOnBeginngigOfGame },
      },
      vipUntil,
    },
  } = useUser();

  const isVip = React.useMemo(
    () => vipUntil && new Date(vipUntil).getTime() > new Date().getTime(),
    [vipUntil]
  );

  const [doShowInterstitialAd, setDoShowInterstitialAd] = React.useState(
    doShowAdOnBeginngigOfGame && !isVip
  );

  const [{ data }] = useQuery<TableQueryQuery, TableQueryQueryVariables>({
    query: TABLE_QUERY,
    variables: { tableId },
    pause: doShowInterstitialAd,
  });

  React.useEffect(() => {
    if (doShowInterstitialAd && !isVip) {
      const adConfigPromise = new Promise((resolve, reject) => {
        try {
          //Google Ads Placement API (https://developers.google.com/ad-placement/apis)
          (window as any).adBreak({
            type: "start", // before the gameplay starts
            name: "before_game_start", // A descriptive name for this placement
            beforeAd: () => {
              //Ad is ready
              resolve(true);
            }, // Prepare for the ad.
            adBreakDone: () => {
              setDoShowInterstitialAd(false);
            }, // Always called (if provided) even if an ad didn't show
          });
        } catch (e) {
          Sentry.captureMessage("Error on ad before game: " + e);
          setDoShowInterstitialAd(false);
        }
      });

      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          //Ad is not ready
          resolve(false);
        }, 2000);
      });

      // Whatever happens first resolves this promise to not block game loading
      Promise.race([adConfigPromise, timeoutPromise]).then((isAdReady) => {
        if (!isAdReady) {
          //start game loading
          setDoShowInterstitialAd(false);
        }
      });
    }
  }, [doShowInterstitialAd, isVip]);

  const { t } = useTranslation();
  const table = data === undefined ? undefined : data?.table ?? null;
  if (table === null) return <Redirect to={`/area/${sectionId}`} />;
  else if (table === undefined)
    return (
      <AbsoluteCenter>
        <Loader description={t("loading_game")} />
      </AbsoluteCenter>
    );
  else return <Game {...table} />;
};
