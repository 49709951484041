import { TableDataDefinition } from "../types";
import { tableData as belote} from "./TableData/Belote";
import { tableData as coinche} from "./TableData/Coinche";
import { CreateTable } from "./CreateTable";
import * as React from "react";
import { makeTournamentInfo } from "./TournamentInfo";

const GameComponent = React.lazy(() => import("./Game"));

const makeDefinition = (game: string, tableData: TableDataDefinition) => ({
  tableData,
  CreateTableComponent: CreateTable,
  GameComponent,
  TournamentInfoComponent: makeTournamentInfo(game),
});

const DEFINITIONS = {
  belote: belote,
  coinche: coinche,
};

const GameData = Object.entries(DEFINITIONS).reduce(
  (prev, [key, value]) => ({ ...prev, [key]: makeDefinition(key, value) }),
  {}
);

export default GameData;
