import * as React from "react";
import { Trans } from "react-i18next";
import { PlayerFragmentFragment } from "../../../graphql/PlayerFragment.generated";

export const UserReferActivity = ({
  actor,
  openProfile,
}: {
  actor: PlayerFragmentFragment | null;
  openProfile: () => void;
}) => {
  return (
    <>
      <Trans i18nKey="notification.user_refer_activity">
        <button onClick={openProfile}>
          <strong>{{ actor: actor!.name }}</strong>
        </button>{" "}
        has joined spectrum games
      </Trans>
    </>
  );
};
