const ordinals = require("make-plural/ordinals");

/*This file has to stay as js because it is also used from react-app-rewired to extract the locales*/
exports.REFETCH_PLAYER_INVITES_INTERVAL = 10000;
exports.SEND_CHIPS_INTERVAL = 5000;
exports.DEFAULT_CURRENCY = "EUR";
exports.FACEBOOK_API_VERSION = "v4.0";
exports.MAX_CHAT_MESSAGE_LENGTH = 256;
exports.USER_MESSAGE_MAX_LENGTH = 256;
exports.REFERRAL_QUERY_PARAM_NAME = "r";
exports.COMPANY_URL = "https://spectrum.games";
exports.HELP_URL = "https://spectrum.games/tutorials";
exports.FORUM_URL = "https://spectrum.games/forum";
exports.T_AND_C_URL = "https://spectrum.games/terms-of-service";
exports.PRIVACY_POLICY_URL = "https://spectrum.games/privacy";
exports.CONTACT_US_URL = "https://spectrum.games/contact-us";
exports.LIKE_BUTTON_URL = "https://apps.facebook.com/mpirimpa";
exports.COF_URL = "https://spectrum.games/credentials-on-file";
exports.SECURITY_URL = "https://spectrum.games/security";
exports.FACEBOOK_URL =
  "https://www.facebook.com/Spectrum-Games-103246174389670";
exports.TWITTER_URL = "https://twitter.com/spectrumgames4";
exports.INSTAGRAM_URL = "https://www.instagram.com/hnmspectrumgames/";
exports.YOUTUBE_URL =
  "https://www.youtube.com/channel/UCOuLtxP4Uz_PW5XBhNOYSzQ";
exports.GET_APPS_URL = "https://spectrum.games/mobile-games/";

/** @type {{[key in string]:
 * {facebook:string;
 * dateFns:string,
 * ordinal: (value:number) => string,
 * flag: string
 * }}} **/

//First defined is the default
exports.SUPPORTED_LOCALES = {
  en: {
    ordinal: ordinals["en"],
    flag: "gb",
    dateFns: "en-GB",
    facebook: "en_GB",
  },
  el: {
    flag: "gr",
    ordinal: ordinals["el"],
    dateFns: "el",
    facebook: "el_GR",
  },
  it: {
    flag: "it",
    ordinal: ordinals["it"],
    dateFns: "it",
    facebook: "it_IT",
  },
  es: {
    flag: "es",
    ordinal: ordinals["es"],
    dateFns: "es",
    facebook: "es_ES",
  },
  ru: {
    flag: "ru",
    ordinal: ordinals["ru"],
    dateFns: "ru",
    facebook: "ru_RU",
  },
  pt: {
    flag: "pt",
    ordinal: ordinals["pt"],
    dateFns: "pt",
    facebook: "pt_PT",
  },
  de: {
    flag: "de",
    ordinal: ordinals["de"],
    dateFns: "de",
    facebook: "de_DE",
  },
  fr: {
    flag: "fr",
    ordinal: ordinals["fr"],
    dateFns: "fr",
    facebook: "fr_FR",
  },
  tr: {
    flag: "tr",
    ordinal: ordinals["tr"],
    dateFns: "tr",
    facebook: "tr_TR",
  },
};
