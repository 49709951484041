import * as React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faEnvelope,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { AbsoluteCenter } from "../AbsoluteCenter";
import { Loader } from "../Loader";
import classNames from "classnames";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@reach/tabs";
import { BannedUsersQueryQuery } from "../../graphql/BannedUsersQuery.generated";
import { ProfileImage } from "../ProfileImage";
import { useIntersection } from "../../hooks/useIntersection";
import { TabValue, usePlayerProfile } from "../PlayerProfile";
import isEqual from "react-fast-compare";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import styles from "./Banned.module.scss";
import { Intent, JoinTable } from "../JoinTable";
import { GAME_IDS } from "../Games";
import { ButtonWithSound } from "../AudioProvider";
import { useBannedPlayers } from "../../hooks/useBannedPlayers";
import { useScreenType } from "../../hooks/useScreenType";
import { RIGHT_BAR_MOBILE_SIZE, RIGHT_BAR_TABLET_SIZE } from "../Bar/RightBar/Bar";

type NodesType = Array<
  NonNullable<BannedUsersQueryQuery["me"]>["bannedUsers"]["edges"][0]["node"]
>;
const BannedUsersContext = React.createContext<(open: boolean) => void>(() => {
  throw new Error("Provider not found");
});

const TABS = ["all", "online"];

const AVATAR_WIDTH = 46;
const AVATAR_HEIGHT = 46;
const BannedPlayer = React.memo<{
  bannedPlayer: NodesType[0];
  container: React.MutableRefObject<HTMLDivElement | null>;
  closePanel: () => void;
}>(({ bannedPlayer, container, closePanel }) => {
  const openPlayerProfile = usePlayerProfile();
  const target = React.useRef<HTMLDivElement>(null);
  const intersecting = useIntersection(target, {
    root: container,
    once: true,
    defaultIntersecting: false,
  });
  const { t } = useTranslation();

  const openProfile = (tab?: TabValue) =>
    openPlayerProfile({
      ...bannedPlayer.userB,
      games: GAME_IDS,
      tournament: false,
      tab,
    });
  const activeTable = bannedPlayer.userB.activeTables[0];
  return (
    <div ref={target} className="bg-blue-900 rounded p-2">
      <div className="overflow-hidden">
        <div className="flex -mx-1 items-center">
          <div className="flex-shrink-0 px-1">
            <div
              className="overflow-hidden bg-gray-200 rounded-full"
              style={{ width: AVATAR_WIDTH, height: AVATAR_HEIGHT }}
            >
              {intersecting && (
                <button className="block" onClick={() => openProfile()}>
                  <ProfileImage
                    name={bannedPlayer.userB.name}
                    id={bannedPlayer.userB.id}
                    width={AVATAR_WIDTH}
                    height={AVATAR_HEIGHT}
                  />
                </button>
              )}
            </div>
          </div>
          <div className="px-1 whitespace-normal flex-1 text-truncate">
            <button className="block" onClick={() => openProfile()}>
              <strong>{bannedPlayer.userB.name}</strong>
            </button>
            <div
              className={classNames("text-sm", "leading-5", {
                "text-red-500":
                  !bannedPlayer.userB.presence && activeTable === undefined,
                "text-green-500": bannedPlayer.userB.presence,
              })}
            >
              {activeTable !== undefined
                ? t("in_table")
                : bannedPlayer.userB.presence
                ? t("online")
                : t("offline")}
            </div>
          </div>
          <div className="px-1 flex-shrink-0 flex -mx-1">
            {activeTable !== undefined && (
              <JoinTable table={activeTable} intent={Intent.Friend}>
                {(disabled, full, join) => {
                  return (
                    !disabled && (
                      <div className="px-1">
                        <ButtonWithSound
                          className={classNames(
                            "btn",
                            "rounded",
                            "btn-sm",
                            full ? "btn-orange-600" : "btn-green-600"
                          )}
                          onClick={() => {
                            closePanel();
                            join();
                          }}
                        >
                          <FontAwesomeIcon icon={faArrowRight} fixedWidth />{" "}
                          {t(full ? "watch" : "join")}
                        </ButtonWithSound>
                      </div>
                    )
                  );
                }}
              </JoinTable>
            )}

            <div className="px-1">
              <ButtonWithSound
                className="btn btn-blue-600 rounded btn-sm"
                onClick={() => openProfile("message")}
              >
                <FontAwesomeIcon icon={faEnvelope} fixedWidth /> {t("message")}
              </ButtonWithSound>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}, isEqual);

const BannedPlayers = ({
  bannedPlayers,
  loadMore,
  closePanel,
}: {
  bannedPlayers: NodesType;
  loadMore?: () => Promise<unknown>;
  closePanel: () => void;
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const loadingRef = useInfiniteScroll<HTMLDivElement>(
    { root: containerRef, rootMargin: "100px" },
    () => {
      if (loadMore !== undefined) return loadMore();
      else return Promise.resolve();
    }
  );
  const { t } = useTranslation();
  if (bannedPlayers.length === 0 && loadMore === undefined)
    return (
      <div className="flex flex-col h-full">
        <div className="flex-1 relative">
          <AbsoluteCenter>
            <div className="p-4 text-center">
              <span className="text-white font-bold">
                {t("no_banned_players_available")}
              </span>
            </div>
          </AbsoluteCenter>
        </div>
      </div>
    );
  else
    return (
      <div className="flex flex-col h-full">
        <div
          className={classNames("flex-1", "overflow-auto", styles.scrollable)}
          ref={containerRef}
        >
          {bannedPlayers.map((friend) => (
            <div
              className={classNames("w-full mb-4", styles.bannedPlayer)}
              key={friend.userB.id}
            >
              <BannedPlayer
                bannedPlayer={friend}
                container={containerRef}
                closePanel={closePanel}
              />
            </div>
          ))}
          {loadMore !== undefined && (
            <div ref={loadingRef} className="py-2">
              <Loader description={""} />
            </div>
          )}
        </div>
      </div>
    );
};

const BannedPlayersPlaceHolder = ({
  bannedPlayers,
  children,
}: React.PropsWithChildren<{
  bannedPlayers:
    | NonNullable<BannedUsersQueryQuery["me"]>["bannedUsers"]
    | undefined;
}>) => {
  const { t } = useTranslation();
  if (bannedPlayers === undefined)
    return (
      <div className="flex flex-col h-full">
        <div className="flex-1 relative">
          <AbsoluteCenter>
            <Loader description={t("loading_banned_players")} />
          </AbsoluteCenter>
        </div>
      </div>
    );
  else if (bannedPlayers.edges.length === 0)
    return (
      <div className="flex flex-col h-full">
        <div className="flex-1 relative">
          <AbsoluteCenter>
            <div className="p-4 text-center">
              <span className="text-white font-bold">
                {t("no_banned_players_available")}
              </span>
            </div>
          </AbsoluteCenter>
        </div>
      </div>
    );
  else return <>{children}</>;
};
const BannedPlayersContent = ({ onDismiss }: { onDismiss: () => void }) => {
  const { bannedPlayers, fetchMore } = useBannedPlayers();
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = React.useState(0);

  const loadMore = bannedPlayers?.pageInfo?.hasNextPage
    ? () => {
        const endCursor = bannedPlayers?.pageInfo?.endCursor ?? null;
        if (endCursor === null) return Promise.reject();
        return fetchMore(endCursor);
      }
    : undefined;

  const [all, online] = React.useMemo(() => {
    const all: NodesType = [];
    const online: NodesType = [];
    for (const edge of bannedPlayers?.edges ?? []) {
      all.push(edge.node);
      if (edge.node.userB.presence) online.push(edge.node);
    }

    return [all, online];
  }, [bannedPlayers]);

  return (
    <Tabs
      className="h-full overflow-hidden"
      index={tabIndex}
      onChange={setTabIndex}
    >
      <div className="flex flex-col h-full text-white">
        <div
          className={classNames(
            "flex",
            "items-center",
            "flex-shrink-0",
            styles.header
          )}
        >
          <TabList className="flex-1">
            {TABS.map((tab, index) => (
              <Tab
                key={index}
                className={classNames(
                  "px-4",
                  "py-3",
                  "w-1/2",
                  "border-r",
                  "border-black",
                  styles.headerButton,
                  {
                    [styles.active]: index === tabIndex,
                  }
                )}
              >
                {t(tab)}
              </Tab>
            ))}
          </TabList>
          <div className="flex-shrink-0 px-4">
            <button onClick={onDismiss}>
              <FontAwesomeIcon icon={faTimesCircle} size="lg" />
            </button>
          </div>
        </div>
        <TabPanels className="flex-1 overflow-hidden">
          <TabPanel className="h-full p-4">
            <BannedPlayersPlaceHolder bannedPlayers={bannedPlayers}>
              <BannedPlayers
                bannedPlayers={all}
                loadMore={loadMore}
                closePanel={onDismiss}
              />
            </BannedPlayersPlaceHolder>
          </TabPanel>
          <TabPanel className="h-full p-4">
            <BannedPlayersPlaceHolder bannedPlayers={bannedPlayers}>
              <BannedPlayers
                bannedPlayers={online}
                loadMore={loadMore}
                closePanel={onDismiss}
              />
            </BannedPlayersPlaceHolder>
          </TabPanel>
        </TabPanels>
      </div>
    </Tabs>
  );
};

const Popup = ({ close }: { close: () => void }) => {
  const { t } = useTranslation();
  const screenType = useScreenType();
  const stylesMap = {
    DESKTOP: { bottom: 0, right: 0 },
    TABLET: { top: 0, right: 0, ...RIGHT_BAR_TABLET_SIZE },
    MOBILE: { top: 0, right: 0, ...RIGHT_BAR_MOBILE_SIZE },
  };

  const adjustedStyle = React.useMemo(() => stylesMap[screenType] || {}, [
    screenType,
  ]);
  
  return (
    <>
      <div
        className="absolute bg-black-alpha-30 top-0 left-0 right-0 bottom-0 z-20"
        onClick={close}
      />
      <div
        className="w-1/3 absolute shadow-2xl bg-blue-800 z-30 h-9/10"
        style={{ ...adjustedStyle }}
        aria-label={t("banned_players")}
      >
        <BannedPlayersContent onDismiss={close} />
      </div>
    </>
  );
};

export const BannedPlayersPanelProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const close = React.useCallback(() => setOpen(false), []);

  return (
    <BannedUsersContext.Provider value={setOpen}>
      {children}
      {isOpen && <Popup close={close} />}
    </BannedUsersContext.Provider>
  );
};

export const useBannedPlayersPanel = () => {
  return React.useContext(BannedUsersContext);
};
