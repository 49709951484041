import * as React from "react";
import styles from "./Loader.module.css";
import classNames from "classnames";

export const Loader = React.memo<{ description: string; dark?: boolean }>(
  ({ description, dark = false }) => {
    const mountTime = React.useRef(Date.now());
    const mountDelay = -(mountTime.current % 1000);
    return (
      <>
        <div className="text-center">
          <div
            className={styles.spinner}
            style={
              {
                "--spinner-delay": `${mountDelay}ms`,
              } as React.CSSProperties
            }
          />
          {description !== "" && (
            <div className={classNames("font-bold", { "text-white": !dark })}>
              {description}
            </div>
          )}
        </div>
      </>
    );
  }
);
