import * as React from "react";
import { loader } from "graphql.macro";
import { useQuery } from "./useQuery";
import {
  NotificationsQueryQuery,
  NotificationsQueryQueryVariables,
} from "../graphql/NotificationsQuery.generated";
import { useLocalStorage } from "./useStorage";
import { CombinedError } from "@urql/core";
import { useClient, OperationResult } from "urql";

const NOTIFICATIONS_QUERY = loader("../graphql/NotificationsQuery.graphql");

export const useNotifications = () => {
  const client = useClient();
  const [lastNotification, setLastRead] = useLocalStorage(
    "last_notification",
    "0"
  );

  const [{ fetching, error, data }] = useQuery<
    NotificationsQueryQuery,
    NotificationsQueryQueryVariables
  >({
    query: NOTIFICATIONS_QUERY,
    variables: {
      after: "",
    },
  });

  const lastRead = React.useMemo(() => new Date(parseInt(lastNotification)), [
    lastNotification,
  ]);

  const notifications =
    data === undefined ? undefined : data?.me?.activities ?? undefined;

  const unread = React.useMemo(() => {
    if (notifications === undefined) return 0;
    const l = lastRead.getTime();
    let i;
    for (i = 0; i < notifications.edges.length; i++) {
      const { created } = notifications.edges[i].node;
      if (new Date(created).getTime() <= l) break;
    }
    return i;
  }, [lastRead, notifications]);

  const setLast = React.useCallback(
    (value: Date) => {
      const currentValue = lastRead.getTime();
      const newValue = value.getTime();
      if (newValue <= currentValue) return;
      setLastRead(newValue.toString());
    },
    [lastRead, setLastRead]
  );

  const fetchMore = React.useCallback(
    (cursor: string) => {
      return client
        .query<NotificationsQueryQuery, NotificationsQueryQueryVariables>(
          NOTIFICATIONS_QUERY,
          { after: cursor }
        )
        .toPromise();
    },
    [client]
  );

  return React.useMemo<{
    fetching: boolean;
    error?: CombinedError;
    notifications?: NonNullable<NotificationsQueryQuery["me"]>["activities"];
    unread: number;
    lastRead: Date;
    setLastRead: (value: Date) => void;
    fetchMore: (
      cursor: string
    ) => Promise<OperationResult<NotificationsQueryQuery>>;
  }>(
    () => ({
      fetching,
      error,
      notifications,
      unread,
      lastRead,
      setLastRead: setLast,
      fetchMore,
    }),
    [error, fetching, notifications, unread, lastRead, setLast, fetchMore]
  );
};
