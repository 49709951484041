import i18n from "i18next";
import XHRBackend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { SUPPORTED_LOCALES } from "./config";

const isDevelopment = process.env.NODE_ENV === "development";
const locales = Object.keys(SUPPORTED_LOCALES);

i18n
  .use(XHRBackend)
  .use(LanguageDetector)
  .init({
    ns: ["common"],
    defaultNS: "common",
    fallbackNS: "",
    fallbackLng: locales[0],
    whitelist: locales,
    load: "currentOnly",
    debug: isDevelopment,
    detection: {
      order: ["querystring", "localStorage", "navigator"],
      lookupLocalStorage: "i18nlng",
      checkForSimilarInWhitelist: true,
    },
  });

export { i18n };
