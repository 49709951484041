export const CONVERT_GUEST_PATH = "/login";
const guestIdStorage =
  process.env.NODE_ENV === "production" ? localStorage : sessionStorage;
const GUEST_ID_KEY_NAME = "__MGSGI__";
const IS_GUEST_KEY_NAME = "__MGS_IG__";

let guestId: string | null = null;
let isLoggedAsGuest: boolean = false;

const parseId = () => {
  const raw = guestIdStorage.getItem(GUEST_ID_KEY_NAME);
  guestId = raw === null ? null : JSON.parse(raw);
};

const updateId = (updatedValue: string | null) => {
  if (updatedValue == null) guestIdStorage.removeItem(GUEST_ID_KEY_NAME);
  else guestIdStorage.setItem(GUEST_ID_KEY_NAME, JSON.stringify(updatedValue));

  parseId();
};

const parseIsGuest = () => {
  const raw = guestIdStorage.getItem(IS_GUEST_KEY_NAME);
  isLoggedAsGuest = raw === null ? false : JSON.parse(raw);
};

const updateIsGuest = (updatedValue: boolean | null) => {
  if (updatedValue == null) guestIdStorage.removeItem(IS_GUEST_KEY_NAME);
  else guestIdStorage.setItem(IS_GUEST_KEY_NAME, JSON.stringify(updatedValue));

  parseIsGuest();
};

export const getGuestId = () => {
  parseId();
  return guestId;
};

export const clearGuestId = () => {
  updateId(null);
};

export const setGuestId = (token: string) => {
  updateId(token);
};

export const getIsLoggedAsGuest = () => {
  parseIsGuest();
  return isLoggedAsGuest;
};

export const setIsLoggedAsGuest = (isGuest: boolean | null) => {
  updateIsGuest(isGuest);
};

export const clearIsLoggedAsGuest = () => {
  updateIsGuest(null);
};
