import * as React from "react";
import { useScript } from "../../hooks/useScript";
import { useFacebook } from "../Facebook";
import { useStylesheet } from "../../hooks/useStylesheet";

const Context = React.createContext<boolean>(false);

export const EcommpayProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [, isFacebookCanvas] = useFacebook();
  const [readyScript] = useScript(
    "https://paymentpage.ecommpay.com/shared/merchant.js",
    isFacebookCanvas
  );

  const [readyStylesheet] = useStylesheet(
    "https://paymentpage.ecommpay.com/shared/merchant.css",
    isFacebookCanvas
  );

  return (
    <Context.Provider value={readyScript && readyStylesheet}>
      {children}
    </Context.Provider>
  );
};

export const useEcommPay = () => {
  return React.useContext(Context);
};
