import * as React from "react";
import { useNumberFormatter } from "../../../hooks/useNumberFormatter";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";

export function makeTournamentInfo(game: string) {
  return function TournamentInfo({ config }: { config: any }) {
    const numberFormatter = useNumberFormatter();
    const { t } = useTranslation();
    return (
      <>
        <div className="flex justify-between -mx-2 py-1">
          <div className="px-2">{t("type")}</div>
          <div className="px-2">{t(`game.${game}`)}</div>
        </div>
        <div className="flex justify-between -mx-2 py-1">
          <div className="px-2">{t("players")}</div>
          <div className="px-2">
            {t(`mpirimpa.${config.players.toLowerCase()}`)}
          </div>
        </div>
        <div className="flex justify-between -mx-2 py-1">
          <div className="px-2">{t("turn_time")}</div>
          <div className="px-2">
            {t("seconds_value", { value: config.turnTime })}
          </div>
        </div>
        <div className="flex justify-between -mx-2 py-1">
          <div className="px-2">{t("belote.points")}</div>
          <div className="px-2">{numberFormatter.format(config.points)}</div>
        </div>
        {game === "mpirimpa_el" && (
          <div className="flex justify-between -mx-2 py-1">
            <div className="px-2">{t("belote.group_card_sets")}</div>
            <div className="px-2">
              <FontAwesomeIcon
                icon={config.groupCardSets ? faCheck : faTimes}
              />
            </div>
          </div>
        )}
      </>
    );
  };
}
