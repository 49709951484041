import * as React from "react";
import { PurchaseActivityContentFragmentFragment } from "../../../graphql/ActivityFragment.generated";
import { Trans, useTranslation } from "react-i18next";
import { useNumberFormatter } from "../../../hooks/useNumberFormatter";
import { PlayerFragmentFragment } from "../../../graphql/PlayerFragment.generated";

export const PurchaseActivity = ({
  content,
}: {
  actor: PlayerFragmentFragment | null;
  content: PurchaseActivityContentFragmentFragment;
  openProfile: () => void;
}) => {
  const formatter = useNumberFormatter();
  const { t } = useTranslation();
  return (
    <Trans i18nKey="notification.purchase_activity">
      Your purchase of{" "}
      <strong>
        {{ amount: `${formatter.format(content.purchased[0].value)}` }}
      </strong>{" "}
      {{
        currency: t(
          `currency.${content.purchased[0].currency.toString().toLowerCase()}`
        ),
      }}{" "}
      has been completed
    </Trans>
  );
};
