import { ClientOptions, SubscriptionClient } from "subscriptions-transport-ws";

export enum Status {
  Disconnected,
  Connecting,
  Reconnecting,
  Connected,
  Reconnected,
}

export class SubscriptionClientWithStatus extends SubscriptionClient {
  private _status: Status;

  constructor(
    url: string,
    options?: ClientOptions,
    webSocketImpl?: any,
    webSocketProtocols?: string | string[]
  ) {
    super(url, options, webSocketImpl, webSocketProtocols);
    this._status =
      options && options.lazy ? Status.Disconnected : Status.Connecting;
    this.onConnecting(() => (this._status = Status.Connecting));
    this.onConnected(() => (this._status = Status.Connected));
    this.onReconnecting(() => (this._status = Status.Reconnecting));
    this.onReconnected(() => (this._status = Status.Reconnected));
    this.onDisconnected(() => (this._status = Status.Disconnected));
  }

  public get connectionStatus(): Status {
    return this._status;
  }
}
