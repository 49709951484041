import * as React from "react";
import { useImage } from "react-image";

const imagePromise = ({ decode = true, crossOrigin = "" }) => (
  src: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const i = new window.Image();
    if (crossOrigin) i.crossOrigin = crossOrigin;
    i.onload = () => {
      decode && typeof i.decode === "function"
        ? i.decode().then(resolve).catch(reject)
        : resolve();
    };
    i.onerror = reject;
    i.src = src;
  });
};

export const Image = React.memo<
  JSX.IntrinsicElements["img"] & { src: string; alt: string }
>(({ src, alt, ...rest }) => {
  const { src: imgSrc } = useImage({
    srcList: src,
    imgPromise: imagePromise({ decode: true }),
  });
  return <img src={imgSrc} alt={alt} {...rest} />;
});
