import * as React from "react";
import { TableFragmentFragment } from "../../graphql/TableFragment.generated";
import GameDefinitions from "../Games";
import { Currency, TableStatus } from "../../graphql/generated";
import { useUser } from "../UserProvider";
import { useHistory } from "react-router-dom";
import { useIsInTable } from "../../hooks/useIsInTable";
import { useJoinTablePopup } from "../JoinTablePopup";
import { gql, useClient } from "urql";
import { HasBanningRelationsQuery } from "./JoinTable.generated";
import { HasBanningRelationsQueryVariables } from "./index.generated";

const HAS_BANNED_RELATIONS = gql`
  query hasBanningRelations($players: [ID!]!) {
    hasBanningRelations(players: $players)
  }
`;

export enum Intent {
  Friend = "FRIEND",
  List = "LIST",
}

export const JoinTable = ({
  table,
  intent,
  children,
}: {
  table: TableFragmentFragment;
  intent: Intent;
  children: (
    disabled: boolean,
    full: boolean,
    join: () => void
  ) => React.ReactNode;
}) => {
  const {
    user: { balances, tournamentParticipations },
  } = useUser();

  const client = useClient();
  const popup = useJoinTablePopup();
  const isInTable = useIsInTable();
  let entryFee = null;
  const { calculateEntryFee, getRequiredPlayers } = GameDefinitions[
    table.section.type
  ].tableData;
  if (!isInTable) entryFee = calculateEntryFee(table);

  const availableBalance =
    balances.find((balance) => balance.currency === Currency.BlueChips)
      ?.value ?? 0;

  const history = useHistory();
  let canJoin = entryFee != null;

  if (canJoin && table.status === TableStatus.Pending) {
    if ((entryFee ?? 0) > availableBalance) canJoin = false;
    if (canJoin && table.tournament !== null) {
      const participation = tournamentParticipations.find(
        (p) => p.leaderboard.id === table.tournament!.id
      );
      if (
        participation === undefined ||
        participation.gamesRemaining <= 0 ||
        intent === Intent.Friend
      )
        canJoin = false;
    }
  }

  const join = React.useCallback(() => {
    client
      .query<HasBanningRelationsQuery, HasBanningRelationsQueryVariables>(
        HAS_BANNED_RELATIONS,
        { players: table.players.map((player) => player.id) },
        { requestPolicy: "network-only" }
      )
      .toPromise()
      .then(({ data, error }) => {
        if (error) {
          throw error;
        } else if (data?.hasBanningRelations) {
          popup.openForbidJoinGamePopup();
        } else {
          history.push(`/area/${table.section.id}/${table.id}`);
        }
      });
  }, [history, table.id, table.section.id, table.players, client, popup]);
  const full = getRequiredPlayers(table) === table.players.length;

  return <>{children(!canJoin, full, join)}</>;
};
