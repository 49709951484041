import * as React from "react";
import { loader } from "graphql.macro";
import { useQuery } from "../hooks/useQuery";
import { AbsoluteCenter } from "./AbsoluteCenter";
import { Loader } from "./Loader";
import { useTranslation } from "react-i18next";
import { useAuthToken } from "../hooks/useAuthToken";
import {
  MeQueryQuery,
  MeQueryQueryVariables,
} from "../graphql/MeQuery.generated";
import { useClient, createRequest } from "urql";
import { pipe, subscribe } from "wonka";
import { PropsWithChildren } from "react";
import * as Sentry from "@sentry/browser";
import { ButtonWithSound } from "./AudioProvider";
import { ChannelTypeSelect } from "./PlatformChat/ChannelList";
const ME_QUERY = loader("../graphql/MeQuery.graphql");
const ME_SUBSCRIPTION = loader("../graphql/MeSubscription.graphql");

type User = NonNullable<MeQueryQuery["me"]>;

type UserContextType = {
  token: string;
  user: User;
  logout: () => void;
};

type ChatContextType = {
  openedChat: boolean;
  setOpenedChat: React.Dispatch<React.SetStateAction<boolean>>;
  sendMessage: {
    isSending: boolean;
    channelType: ChannelTypeSelect;
    users: string[];
  };
  setSendMessage: React.Dispatch<
    React.SetStateAction<{
      isSending: boolean;
      channelType: ChannelTypeSelect;
      users: string[];
    }>
  >;
  showChatButton: boolean;
  setShowChatButton: React.Dispatch<React.SetStateAction<boolean>>
  unreadFriends: boolean;
  setUnreadFriends: React.Dispatch<React.SetStateAction<boolean>>;
  unreadOthers: boolean;
  setUnreadOthers: React.Dispatch<React.SetStateAction<boolean>>;
};

const UserContext = React.createContext<UserContextType>(undefined as any);
const UserIdContext = React.createContext<string>(undefined as any);
const ChatContext = React.createContext<ChatContextType>(undefined as any);

const UserSubscription = ({ children }: { children?: React.ReactNode }) => {
  //Updates are handled via graphcache
  const client = useClient();
  React.useEffect(() => {
    const request = createRequest(ME_SUBSCRIPTION);

    const { unsubscribe } = pipe(
      client.executeSubscription(request),
      subscribe(({ data, error }) => {
        if (error) console.error(error);
      })
    );

    return () => unsubscribe();
  }, [client]);
  return <>{children}</>;
};

const SentryScopeProvider = ({
  token,
  user,
  children,
}: PropsWithChildren<{ token: string; user: User }>) => {
  React.useEffect(() => {
    if (Sentry.getCurrentHub().getClient() !== undefined)
      Sentry.configureScope(function (scope) {
        scope.setTag("token", token);
        scope.setTag("name", user.name);
        scope.setUser({
          id: user.id,
        });
      });
  }, [token, user]);
  return <>{children}</>;
};

const ErrorAndLogout = () => {
  const { t } = useTranslation();
  const [, , clear] = useAuthToken();
  return (
    <AbsoluteCenter>
      <div className="w-auto max-w-lg p-4 text-center bg-red-700 rounded">
        <span className="block font-bold text-white">
          {t("user_not_found")}
        </span>
        <ButtonWithSound
          className="w-full mt-2 rounded btn btn-gray-500 btn-sm"
          onClick={clear}
        >
          {t("reload")}
        </ButtonWithSound>
      </div>
    </AbsoluteCenter>
  );
};

export const UserProvider = ({
  token,
  logout,
  children,
  ...rest
}: React.PropsWithChildren<{
  token: string;
  logout: () => void;
}>) => {
  const [{ data }] = useQuery<MeQueryQuery, MeQueryQueryVariables>({
    query: ME_QUERY,
    requestPolicy: "network-only",
  });
  const [openedChat, setOpenedChat] = React.useState<boolean>(false);
  const [sendMessage, setSendMessage] = React.useState<{
    isSending: boolean;
    channelType: ChannelTypeSelect;
    users: string[];
  }>({
    isSending: false,
    channelType: "direct_friends",
    users: [],
  });
  const [showChatButton, setShowChatButton] = React.useState<boolean>(true);
  const [unreadFriends, setUnreadFriends] = React.useState<boolean>(false);
  const [unreadOthers, setUnreadOthers] = React.useState<boolean>(false);

  const me = data === undefined ? undefined : data?.me ?? null;
  const { t } = useTranslation();

  const wrappedLogout = React.useCallback(() => {
    if (Sentry.getCurrentHub().getClient() !== undefined)
      Sentry.configureScope((scope) => scope.setUser(null));
    logout();
  }, [logout]);

  return (
    <>
      {me === undefined ? (
        <AbsoluteCenter>
          <Loader description={t("loading_user")} />
        </AbsoluteCenter>
      ) : me === null ? (
        <ErrorAndLogout />
      ) : (
        <ChatContext.Provider
          value={{
            openedChat: openedChat,
            setOpenedChat: setOpenedChat,
            sendMessage: sendMessage,
            setSendMessage: setSendMessage,
            showChatButton: showChatButton,
            setShowChatButton: setShowChatButton,
            unreadFriends: unreadFriends,
            setUnreadFriends: setUnreadFriends,
            unreadOthers: unreadOthers,
            setUnreadOthers: setUnreadOthers,
          }}
        >
          <UserIdContext.Provider value={me.id}>
            <SentryScopeProvider user={me} token={token}>
              <UserSubscription {...rest}>
                <UserContext.Provider
                  value={{
                    token,
                    logout: wrappedLogout,
                    user: me,
                  }}
                >
                  {children}
                </UserContext.Provider>
              </UserSubscription>
            </SentryScopeProvider>
          </UserIdContext.Provider>
        </ChatContext.Provider>
      )}
    </>
  );
};

export const useUser = () => {
  return React.useContext<UserContextType>(UserContext);
};

export const useUserId = () => {
  return React.useContext<string>(UserIdContext);
};

export const useCustomChatContext = () => {
  return React.useContext<ChatContextType>(ChatContext)
}
