import * as React from "react";
import { useScript } from "../../../hooks/useScript";
import { useLogin } from "../useLogin";
import { clearGuestId, clearIsLoggedAsGuest } from "../../../guestId";
import { useTranslation } from "react-i18next";

const NAME = "apple";
const CLIENT_ID: string = process.env.REACT_APP_APPLE_CLIENT_ID!;

declare global {
  interface Window {
    AppleID: any;
  }
}

type SignInResponse = {
  user?: AppleUser;
  authorization: Authorization;
};

type AppleUser = {
  email: string;
  name: {
    firstName: string;
    lastName: string;
  };
};

type Authorization = {
  code: string;
  id_token: string;
  state: string;
};

export const AppleLogin = ({
  guestId,
  children,
  setError,
}: {
  guestId: string | null;
  children: (disabled: boolean, login: () => void) => void;
  setError: (errorMessage: string | null) => void;
}) => {
  const { t } = useTranslation();
  const [loaded, error] = useScript(
    `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js`
  );
  const login = useLogin(NAME, "website");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (!loaded) return;

    const params = {
      clientId: CLIENT_ID,
      redirectURI: window.location.origin,
      scope: "name email",
      usePopup: true,
    };
    window.AppleID.auth.init(params);
  }, [loaded]);

  const onClick = React.useCallback(async () => {
    return new Promise<SignInResponse>((data, error) => {
      window.AppleID.auth.signIn().then(data).catch(error);
    });
  }, []);

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  React.useEffect(() => {
    const errorText = errorMessage;
    if (errorText != null) {
      setErrorMessage(null);
      setError(t(errorText));
    }
  }, [errorMessage, setErrorMessage, setError, t]);

  const loginFacade = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await onClick();
      let extra: { [key: string]: string } = {
        origin: window.location.origin,
      };
      if (response.user) {
        const { firstName, lastName } = response.user.name;
        extra = {
          ...extra,
          name: `${firstName}, ${lastName}`,
        };
      }
      if (guestId != null) {
        return await login(response.authorization.code, {
          ...extra,
          guestId,
        }).then(() => {
          if (guestId) {
            clearIsLoggedAsGuest();
            clearGuestId();
            window.location.href = "/";
          }
        });
      } else {
        return await login(response.authorization.code, extra);
      }
    } catch (e) {
      console.log("Error on apple login", e);
      setLoading(false);
      if (guestId != null) setErrorMessage("user_with_account_already_exists");
    }
  }, [login, onClick, guestId]);

  return <>{children(error || loading, loginFacade)}</>;
};
