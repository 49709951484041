import * as React from "react";
import { BalanceUpdateScratchCardPurchaseDetailsFragmentFragment } from "../../../hooks/useTransactions/graphql/TransactionsFragment.generated";
import { Trans } from "react-i18next";

export const BalanceUpdateScratchCardPurchaseDetails = ({
  details,
}: {
  details: BalanceUpdateScratchCardPurchaseDetailsFragmentFragment;
}) => {
  const name = `${details.name} - ${details.displayId}`;
  return (
    <Trans i18nKey="transaction.BalanceUpdateScratchCardPurchaseDetails_details">
      Purchased scratch card <strong>{{ name }}</strong>
    </Trans>
  );
};
