import * as React from "react";
import { Tooltip } from "../Tooltip";
import { useTranslation } from "react-i18next";
import helpIcon from "../../assets/helpIcon.svg";
import { HELP_URL } from "../../config";

export const HelpButton = React.memo(() => {
  const { t } = useTranslation();

  return (
    <Tooltip label={t("faq")}>
      <a href={HELP_URL} target="_blank" rel="noopener noreferrer">
        <img src={helpIcon} alt="" />
      </a>
    </Tooltip>
  );
});
