import React, { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "../ProductionLogin/Login.module.scss";
import LoginInput from "../LoginInput";
import SubmitButton from "../SubmitButton";
import {
  getValueFromLocalStorage,
  isValidOtp,
  isValidPassword,
  saveToLocalStorageWithExpiry,
  validateEmail,
} from "../utils";
import {
  emailSignInRequest,
  signUpConfirmRequest,
  signUpRequest,
} from "../api";
import { useAskSendEmails } from "../../AskSendEmails";
import { useAuthToken } from "../../../hooks/useAuthToken";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { SignUpPath } from "../ProductionLogin/EmailLogin";
import { DEFAULT_OTP_TTL_IN_SEC } from "../ProductionLogin/email/AssignPassword";
import Tooltip from "../ProductionLogin/Tooltip";

const ConfirmSignUp = () => {
  const [password, setPassword] = useState<string>("");
  const [otpCode, setOtpCode] = useState<string>("");
  const [otpCodeError, setOtpCodeError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [, setToken] = useAuthToken();
  const { setDoAskSendEmailsOnLogin } = useAskSendEmails();
  const { t } = useTranslation();
  const history = useHistory();
  const [email, setEmail] = useState<string>("");
  const [userName, setUserName] = useState<string>("");

  useEffect(() => {
    const email = getValueFromLocalStorage("email");
    const userName = getValueFromLocalStorage("userName");
    setEmail(email ? email : "");
    setUserName(userName ? userName : "");
  }, []);

  const getPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setPasswordError(false);
  };

  const getCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtpCode(e.target.value);
    setOtpCodeError(false);
  };

  const resendCode = async () => {
    const userName = getValueFromLocalStorage("userName");
    const validEmail = email !== "";
    const validUserName = userName;
    if (validEmail && validUserName) {
      const response: string = await signUpRequest({
        email,
        userName,
      });
      saveToLocalStorageWithExpiry({
        key: "otpId",
        value: response,
        ttlInSeconds:
          process.env.REACT_APP_OTP_TTL_IN_SEC === undefined
            ? DEFAULT_OTP_TTL_IN_SEC
            : parseInt(process.env.REACT_APP_OTP_TTL_IN_SEC, 10),
      });
    }
  };

  const submitForm = async () => {
    const otpId = getValueFromLocalStorage("otpId");
    const email = getValueFromLocalStorage("email");
    const validPassword = isValidPassword(password);
    const validOtp = isValidOtp(otpCode);
    if (!validPassword) setPasswordError(true);
    if (!validOtp) setOtpCodeError(true);
    if (validPassword && validOtp) {
      const response = await signUpConfirmRequest({ password, otpCode, otpId });
      if (response === true) {
        //redirect to login page
        history.push("/email");
      }
      if (response.includes("Error")) {
        setOtpCodeError(true);
        return;
      } else {
        return console.log(response);
      }
    }
  };
  return (
    <div className="w-full py-1 md:py-2">
      <div className="flex flex-col items-center justify-center w-full -my-2">
        <p className={classNames(styles.emailLoginHeader, "mb-3 text-white")}>
          Sign Up
        </p>
        <p
          className={classNames(
            styles.emailLoginSubHeader,
            "text-white font-medium"
          )}
        >
          <Trans i18nKey="set_password_instruction">
            {t("signinScreens.code_sent")}
            {{ email: email ?? "" }}. {t("signinScreens.code_sent_after")}
          </Trans>
        </p>

        <div className={classNames(styles.inputWrapper, "relative")}>
          <div className={classNames(styles.inputWrapper, "mb-6")}>
            <div className={styles.passwordWithTooltipContainer}>
              <LoginInput
                name={t("signinScreens.code")}
                getValue={getCode}
                error={otpCodeError}
                isNewPassword={true}
              />
            </div>
            {otpCodeError && (
              <p className={styles.errorMessage}>Invalid code.</p>
            )}
            <div className={styles.passwordWithTooltipContainer}>
              <div>
                <LoginInput
                  name={t("signinScreens.create_password")}
                  icon={true}
                  getValue={getPassword}
                  error={passwordError}
                  isNewPassword={true}
                />
              </div>
              <Tooltip />
            </div>
            {passwordError && (
              <p className={styles.errorMessage}>
                {t("signinScreens.invalid_password_message")}{" "}
              </p>
            )}
          </div>
          <SubmitButton name="Sign Up" onClick={submitForm} />
          <p className={styles.resendCodeText}>
            {t("signinScreens.still_no_code")}
          </p>
          <div
            className={classNames(
              styles.emailLoginLinks,
              "flex items-center justify-center"
            )}
            style={{ marginTop: "6px" }}
          >
            <div
              className={classNames(styles.forgotPassword, "flex items-center")}
            >
              <Link
                to={SignUpPath}
                className={classNames(styles.emailLoginLinkText, "underline")}
              >
                <span>{t("signinScreens.use_other_email")}</span>
              </Link>
            </div>
            <div className={styles.emailLoginLinkSeparator} />
            <div
              className={classNames(
                styles.dontHavePassword,
                "flex items-center"
              )}
            >
              <span
                className={classNames(styles.emailLoginLinkText, "underline")}
                onClick={resendCode}
              >
                {t("signinScreens.resend_code")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmSignUp;
