import { Cache, Data, Variables } from "@urql/exchange-graphcache";
import { gql } from "graphql.macro";
import { RoomUserCountFragmentFragment } from "../../graphql/RoomUserCountFragment.generated";
import { RoomSectionsCountFragmentFragment } from "../../graphql/RoomSectionsCountFragment.generated";

const SECTIONS_TO_SEARCH = ["GameSection", "TournamentSection"];
const ROOM_USER_COUNT_FRAGMENT = gql`
  fragment JoinRoomCountFragment on Room {
    id
    onlineUsers
  }
`;

const SECTION_TABLE_COUNT_FRAGMENT = gql`
  fragment JoinRoonSectionTableCountFragment on Section {
    id
    count
    __typename
  }
`;

const isRoomSectionsCount = (data: {
  __typename?: string;
}): data is RoomSectionsCountFragmentFragment => {
  return (
    (data as RoomSectionsCountFragmentFragment).__typename ===
    "RoomSectionsCount"
  );
};

const isRoomUserCount = (data: {
  __typename?: string;
}): data is RoomUserCountFragmentFragment => {
  return (data as RoomUserCountFragmentFragment).__typename === "RoomUserCount";
};

export const joinRoom = (result: Data, args: Variables, cache: Cache) => {
  const { id }: { id: string } = args as any;
  const { roomData } = result as any;
  if (isRoomUserCount(roomData)) {
    const room = cache.readFragment(ROOM_USER_COUNT_FRAGMENT, {
      id,
      __typename: "Room",
    });
    if (room !== null)
      cache.writeFragment(ROOM_USER_COUNT_FRAGMENT, {
        id: id,
        onlineUsers: roomData.count,
        __typename: "Room",
      });
  } else if (isRoomSectionsCount(roomData)) {
    const counters = roomData.counters;
    for (const counter of counters) {
      let section = null;
      for (const s of SECTIONS_TO_SEARCH) {
        section = cache.readFragment(SECTION_TABLE_COUNT_FRAGMENT, {
          id: counter.sectionId,
          __typename: s,
        });
        if (section !== null) break;
      }
      if (section !== null) {
        cache.writeFragment(SECTION_TABLE_COUNT_FRAGMENT, {
          id: counter.sectionId,
          count: counter.count,
          __typename: section.__typename,
        });
      }
    }
  }
};
