import React from "react";

const GoogleIcon = ({
  backgroundColor,
  fillColor,
}: {
  backgroundColor?: string;
  fillColor?: string;
}) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill={backgroundColor} />
    <path
      d="M30.9999 20.2473C30.9999 19.5232 30.9344 18.8357 30.822 18.1665H20.2388V22.3006H26.2984C26.0268 23.6573 25.2307 24.8031 24.0506 25.5823V28.3323H27.6657C29.7824 26.4165 30.9999 23.5931 30.9999 20.2473Z"
      fill={fillColor}
    />
    <path
      d="M20.2386 30.9999C23.2731 30.9999 25.8112 30.0099 27.6656 28.3324L24.0505 25.5824C23.039 26.2424 21.7559 26.6458 20.2386 26.6458C17.3072 26.6458 14.8253 24.7116 13.9355 22.0991H10.208V24.9316C12.053 28.5249 15.8461 30.9999 20.2386 30.9999Z"
      fill={fillColor}
    />
    <path
      d="M13.9357 22.0987C13.7016 21.4387 13.5798 20.7328 13.5798 19.9995C13.5798 19.2662 13.7109 18.5604 13.9357 17.9004V15.0679H10.2082C9.44019 16.5529 9 18.2212 9 19.9995C9 21.7778 9.44019 23.4462 10.2082 24.9311L13.9357 22.0987Z"
      fill={fillColor}
    />
    <path
      d="M20.2386 13.3541C21.8964 13.3541 23.3761 13.9133 24.5469 15.0041L27.7499 11.8691C25.8112 10.0908 23.2731 9 20.2386 9C15.8461 9 12.053 11.475 10.208 15.0683L13.9355 17.9008C14.8253 15.2883 17.3072 13.3541 20.2386 13.3541Z"
      fill={fillColor}
    />
  </svg>
);

export default GoogleIcon;
