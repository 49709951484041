import mpirimpaDefintions from "./mpirimpa";
import ticTacToeDefinition from "./tictactoe";
import tichuDefinition from "./tichu";
import hangmanDefinition from "./hangman";
import beloteDefinition from "./belote"
import { GameDefinition } from "./types";

const DEFINITIONS: { [key: string]: GameDefinition } = {
  ...mpirimpaDefintions,
  ...ticTacToeDefinition,
  ...tichuDefinition,
  ...hangmanDefinition,
  ...beloteDefinition,
};

export const GAME_IDS = Object.keys(DEFINITIONS);

export default DEFINITIONS;
