import * as React from "react";
import { useTooltip, TooltipPopup, TooltipPopupProps } from "@reach/tooltip";
import { PRect } from "@reach/rect";
import Portal from "@reach/portal";

import "./style.css";

const HAS_TOUCH =
  "ontouchstart" in window ||
  navigator.maxTouchPoints > 0 ||
  navigator.msMaxTouchPoints > 0;

const centered = (triggerRect?: PRect | null, tooltipRect?: PRect | null) => {
  if (triggerRect == null || tooltipRect == null) return {};
  const triggerCenter = triggerRect.left + triggerRect.width / 2;
  const left = triggerCenter - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - 2;
  return {
    left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
    top: triggerRect.bottom + 8 + window.scrollY,
  };
};

const ARROW_STYLE: React.CSSProperties = {
  position: "absolute",
  width: 0,
  height: 0,
  borderLeft: "10px solid transparent",
  borderRight: "10px solid transparent",
  borderBottom: "10px solid #1B1F28",
};

const InnerTooltip = ({
  children,
  ...rest
}: Omit<TooltipPopupProps, "triggerRect">) => {
  const [trigger, tooltip] = useTooltip();
  // destructure off what we need to position the triangle
  const { isVisible, triggerRect } = tooltip;

  if (!React.isValidElement(children)) return null;

  return (
    <>
      {React.cloneElement(children, trigger)}
      {isVisible && (
        // The Triangle. We position it relative to the trigger, not the popup
        // so that collisions don't have a triangle pointing off to nowhere.
        // Using a Portal may seem a little extreme, but we can keep the
        // positioning logic simpler here instead of needing to consider
        // the popup's position relative to the trigger and collisions
        <Portal>
          <div
            style={{
              left: triggerRect
                ? triggerRect.left - 10 + triggerRect.width / 2
                : undefined,
              top: triggerRect
                ? triggerRect.bottom + window.scrollY
                : undefined,
              ...ARROW_STYLE,
            }}
          />
        </Portal>
      )}
      <TooltipPopup
        {...tooltip}
        {...rest}
        className="text-white rounded overflow-hidden border-none shadow-lg"
        style={{ background: "#1B1F28" }}
        position={centered}
      />
    </>
  );
};

export const Tooltip = ({
  children,
  ...rest
}: Omit<TooltipPopupProps, "triggerRect">) => {
  if (HAS_TOUCH) return <>{children}</>;
  else return <InnerTooltip {...rest} children={children} />;
};
