import * as React from "react";
import { useFacebook } from "../Facebook";
import { useCustomChatContext, useUser } from "../UserProvider";
import { useNotifications } from "../../hooks/useNotifications";
import { useNotificationsPanel } from "../Notifications";
import { usePlayerProfile } from "../PlayerProfile";
import { useShop } from "../Shop";
import { useScratchCards } from "../ScratchCards";
import { useGuestId } from "../../hooks/useGuestId";
import { useSyncedTimeProvider } from "../SyncedTimeProvider";
import { useRegisterPopup } from "../Register";
import { useTranslation } from "react-i18next";
import { Currency } from "../../graphql/generated";
import styles from "../Lobby/Lobby.module.scss";
import message from "../../assets/message.svg";
import shop from "../../assets/shop.svg";
import bell from "../../assets/bell.svg";
import flag from "./assets/flag.png";
import burgerButton from "./assets/burgerButton.svg";
import { GAME_IDS } from "../Games";
import { ProfileImage } from "../ProfileImage";
import { Link } from "react-router-dom";
import { CONVERT_GUEST_PATH } from "../../guestId";
import { LogoutButton } from "./LogoutButton";
import { Tooltip } from "../Tooltip";
import headerStyles from "./Header.module.css";
import { isNotMainScreenDesktopWidth } from "../../utils";
import classNames from "classnames";
import { useRightBar } from "../Bar/RightBar";
import { screenType, TABLET_MIN_WIDTH } from "../../other/screenType";

const HeaderRight = React.memo(({ mainScreen }: { mainScreen?: boolean }) => {
  const [, isFacebookCanvas] = useFacebook();
  const {
    user: { id, name, balances, nextFreeScratchCard },
  } = useUser();
  const { unread } = useNotifications();
  const openNotifications = useNotificationsPanel();
  const openProfile = usePlayerProfile();
  const openShop = useShop();
  const [, , , getIsLoggedAsGuest] = useGuestId();
  const syncedTimeProvider = useSyncedTimeProvider();
  const [hasFreeScratchCard, setHasFreeScratchCard] = React.useState(false);
  const registration = useRegisterPopup();
  const { setOpenedChat } = useCustomChatContext();
  const [, openRightBar] = useRightBar();
  const [screenType, setScreenType] = React.useState<screenType>(
    isNotMainScreenDesktopWidth("/")
      ? document.documentElement.clientWidth < TABLET_MIN_WIDTH
        ? "MOBILE"
        : "TABLET"
      : "DESKTOP"
  );

  React.useEffect(() => {
    const onResize = () => {
      const isNotDesktop = isNotMainScreenDesktopWidth("/");
      setScreenType(
        isNotDesktop
          ? document.documentElement.clientWidth < TABLET_MIN_WIDTH
            ? "MOBILE"
            : "TABLET"
          : "DESKTOP"
      );
    };

    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [setScreenType]);

  React.useEffect(() => {
    let hasFreeScratchCard = false;
    if (nextFreeScratchCard !== null) {
      const date = new Date(nextFreeScratchCard);
      hasFreeScratchCard = date <= new Date(syncedTimeProvider());
    }
    setHasFreeScratchCard(hasFreeScratchCard);
  }, [nextFreeScratchCard, syncedTimeProvider]);

  const { t } = useTranslation();

  const notificationsOnClick = React.useCallback(
    () => openNotifications(true),
    [openNotifications]
  );

  return (
    <div className="flex items-center justify-end h-full">
      <div className="flex">
        {(screenType === "DESKTOP" || !mainScreen) && (
          <div className="flex">
            <Tooltip label={t("chat")}>
              <div className={styles.rightHeaderIcon}>
                <img
                  src={message}
                  alt="Chat"
                  onClick={() => {
                    if (getIsLoggedAsGuest()) registration.openRegisterPopup();
                    else setOpenedChat(true);
                  }}
                />
              </div>
            </Tooltip>
            <Tooltip label={t("shop")}>
              <div
                className={styles.rightHeaderIcon}
                onClick={() => openShop(Currency.BlueChips)}
                style={{ minWidth: "40px" }}
              >
                <img src={shop} alt="" />
              </div>
            </Tooltip>

            <Tooltip label={t("notifications")}>
              <div className="relative flex">
                {unread > 0 && (
                  <div className={headerStyles.flag}>
                    <img src={flag} alt="" />
                  </div>
                )}
                <div
                  className={styles.rightHeaderIcon}
                  onClick={notificationsOnClick}
                  style={{ minWidth: "40px" }}
                >
                  <img src={bell} alt="" />
                </div>
              </div>
            </Tooltip>
          </div>
        )}
        {(screenType === "DESKTOP" || !mainScreen) && (
          <div className="flex">
            <div className="relative flex">
              <div
                className={classNames(
                  styles.profileContainer,
                  screenType === "DESKTOP" || !mainScreen
                    ? styles.profileButton
                    : ""
                )}
                onClick={() => {
                  if (screenType === "DESKTOP" || !mainScreen)
                    openProfile(
                      {
                        id,
                        name,
                        games: GAME_IDS,
                        tournament: false,
                      },
                      true
                    );
                }}
              >
                <ProfileImage
                  id={id}
                  name={name}
                  height={38}
                  width={38}
                  className={styles.profileImage}
                />
                <p className={styles.profileName}>{name}</p>
              </div>
            </div>

            {getIsLoggedAsGuest() && (
              <Link to={CONVERT_GUEST_PATH} className={styles.registerButton}>
                {t("register")}
              </Link>
            )}

            {!isFacebookCanvas && (
              <div className="flex items-center">
                <LogoutButton isMainScreen={mainScreen} />
              </div>
            )}
          </div>
        )}
        {screenType !== "DESKTOP" && mainScreen && (
          <div className={styles.rightHeaderIcon}>
            <img src={burgerButton} alt="BurgerButton" onClick={openRightBar} />
          </div>
        )}
      </div>
    </div>
  );
});

export default HeaderRight;
