import React, { useEffect, useState } from "react";
import { ChannelSort, DefaultGenerics, StreamChat } from "stream-chat";
import {
  Chat,
  LoadingIndicator,
  Channel,
  Window,
  MessageList,
  MessageInput,
  Thread,
  ChannelListMessengerProps,
} from "stream-chat-react";
import "stream-chat-react/dist/css/index.css";
import "./custom.scss";
import CustomChannelListMessenger from "./CustomListContainer";
import { CustomChannelPreviewMessenger } from "./CustomListItem";
import closeChat from "./assets/closeChat.svg";
import CustomChannelHeader from "./CustomChannelHeader";
import { CustomMessageInput } from "./CustomInput";
import { CustomMessage } from "./CustomMessage";
import { loader } from "graphql.macro";
import { useQuery } from "../../hooks/useQuery";
import {
  ChatTokenQuery,
  ChatTokenQueryVariables,
} from "./graphql/ChatTokenQuery.generated";
import { useCustomChatContext, useUser, useUserId } from "../UserProvider";
import { useProfilePictureReload } from "../../hooks/useProfilePictureReload";
import classNames from "classnames";
import CreateGroupForm from "./CreateChannelForm";
import { ChannelList, ChannelTypeSelect } from "./ChannelList";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { changeChannelFilters } from "./utils";
import CreateFriendDirectChannel from "./CreateFriendDirectChannel";
import CreateOthersDirectChannel from "./CreateOthersDirectChat";
import { useTranslation } from "react-i18next";
import NoAvailableChat from "./NoAvailableChats";
import ChannelSkeleton from "./ChannelSkeleton";
import { ChannelOnSendMessageProvider } from "./ChannelOnSendMessage";
import { ChatTheme } from "../../graphql/generated";
import { useScreenType } from "../../hooks/useScreenType";

export const CHAT_TOKEN_QUERY = loader("./graphql/ChatTokenQuery.graphql");

export const MessagesComponent = ({
  isWithoutHeader,
  onChat,
}: {
  isWithoutHeader?: boolean;
  onChat?: (message: string) => void;
}) => {
  return (
    <ChannelOnSendMessageProvider onChat={onChat}>
      <Channel Input={CustomMessageInput} Message={CustomMessage}>
        <Window>
          {!isWithoutHeader && <CustomChannelHeader />}
          <MessageList />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </ChannelOnSendMessageProvider>
  );
};

export const StreamChatComponent = () => {
  const [client, setClient] = useState<StreamChat<DefaultGenerics> | null>(
    null
  );
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [channelType, setChannelType] = useState<ChannelTypeSelect>("group");
  const apiKey = `${process.env.REACT_APP_CHAT_TOKEN}`;
  const { user } = useUser();
  const { openedChat, setOpenedChat } = useCustomChatContext();
  const { t } = useTranslation();
  const [chatsAvailable, setChatsAvailable] = useState<{
    loading: boolean;
    noResult: boolean;
  }>({ loading: false, noResult: false });

  const [{ fetching, data }, getToken] = useQuery<
    ChatTokenQuery,
    ChatTokenQueryVariables
  >({
    query: CHAT_TOKEN_QUERY,
    requestPolicy: "network-only",
  });

  const id = useUserId();
  const [randomId] = useProfilePictureReload();
  useEffect(() => {
    getToken();
  }, []);

  // @ts-ignore
  useEffect(() => {
    async function init() {
      const chatClient = StreamChat.getInstance(apiKey);
      if (!fetching) {
        await chatClient.connectUser(
          {
            id: user.id,
            name: user.name,
            image: `${process.env.REACT_APP_API_HOST}/user/${id}?r=${randomId}`,
          },
          data?.chatToken
        );
        setClient(chatClient);
      }
    }
    init();
    if (client) return () => client.disconnectUser();
  }, [fetching]);

  const selectChannelType = (type: ChannelTypeSelect) => {
    setChannelType(type);
  };

  const createNewChannel = () => {
    setIsCreating(true);
  };

  const closeCreatingComponent = React.useCallback(() => setIsCreating(false), [
    setIsCreating,
  ]);

  const showSkeleton = () => {
    if (chatsAvailable.loading && !isCreating) return <ChannelSkeleton />;
    if (!chatsAvailable.noResult && !isCreating) return <NoAvailableChat />;
    if (isCreating) return channelCreating();
    return <MessagesComponent />;
  };

  const channelCreating = () => {
    switch (channelType) {
      case "group":
        return <CreateGroupForm setIsCreating={setIsCreating} />;
      case "direct_friends":
        return (
          <CreateFriendDirectChannel
            closeCreateFriendDirectChannel={closeCreatingComponent}
          />
        );
      case "direct_others":
        return (
          <CreateOthersDirectChannel
            closeCreateFriendDirectChannel={closeCreatingComponent}
          />
        );
    }
  };

  const screenType = useScreenType();
  const alignStyle =
    screenType === "DESKTOP" ? { bottom: "28px" } : { top: "28px" };

  if (!client) return <></>;

  return (
    <div
      className={classNames("custom-styles", {
        "show-chat": openedChat,
        "hidden-chat": !openedChat,
        "theme-dark": user.chatTheme === ChatTheme.Dark,
        "theme-light": user.chatTheme === ChatTheme.Light,
      })}
      style={{ ...alignStyle }}
    >
      <div className="header-container ">
        <div className="header-text">{t("chat")}</div>
        <button
          onClick={() => {
            setOpenedChat(false);
            client.disconnectUser();
          }}
        >
          <img src={closeChat} alt="close" />
        </button>
      </div>
      <Chat client={client} theme="messaging dark">
        <ChannelList
          filters={changeChannelFilters(channelType, user.id)}
          selectChannelType={selectChannelType}
          createNewChannel={createNewChannel}
          closeCreatingComponent={closeCreatingComponent}
          channelType={channelType}
          setChatsAvailable={setChatsAvailable}
          List={(
            listProps: JSX.IntrinsicAttributes &
              ChannelListMessengerProps<DefaultStreamChatGenerics> & {
                children?: React.ReactNode;
              }
          ) => (
            <CustomChannelListMessenger
              {...listProps}
              channelType={channelType}
            />
          )}
          Preview={CustomChannelPreviewMessenger}
          additionalChannelSearchProps={{ searchForChannels: true }}
          isCreating={isCreating}
        />
        {showSkeleton()}
      </Chat>
    </div>
  );
};
