import * as React from "react";
import {
  ErrorBoundary as DefaultErrorBoundary,
  FallbackProps as DefaultFallbackProps,
} from "react-error-boundary";
import DefaultErrorBoundaryFallbackComponent from "./DefaultFallbackComponent";
import * as Sentry from "@sentry/browser";
import { PropsWithChildren } from "react";

const isDevelopment = process.env.NODE_ENV === "development";

export type FallbackProps = DefaultFallbackProps;
export const ErrorBoundary = ({
  onError,
  fallback,
  ...rest
}: PropsWithChildren<{
  onError?: (error: Error, componentStack: string) => void;
  fallback?:
    | ((props: DefaultFallbackProps) => React.ReactElement<any, any>)
    | null;
}>) => {
  return (
    <DefaultErrorBoundary
      fallbackRender={(props: DefaultFallbackProps) => {
        if (fallback === undefined)
          return <DefaultErrorBoundaryFallbackComponent {...props} />;
        else if (fallback === null) return null;
        else return fallback(props);
      }}
      onError={(error, componentStack) => {
        if (
          Sentry.getCurrentHub().getClient() !== undefined &&
          fallback !== null
        )
          Sentry.withScope((scope) => {
            scope.setExtras({ componentStack });
            const eventId = Sentry.captureException(error);
            //Dont show report dialog when we have a fallback component or we are in development
            if (fallback === undefined && !isDevelopment)
              Sentry.showReportDialog({ eventId });
          });

        if (onError !== undefined) onError(error, componentStack);
      }}
      {...rest}
    />
  );
};
