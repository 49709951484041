import * as React from "react";
import { BalanceUpdateTournamentPurchaseDetailsFragmentFragment } from "../../../hooks/useTransactions/graphql/TransactionsFragment.generated";
import { Trans } from "react-i18next";

export const BalanceUpdateTournamentPurchaseDetails = ({
  details,
}: {
  details: BalanceUpdateTournamentPurchaseDetailsFragmentFragment;
}) => {
  return (
    <Trans i18nKey="transaction.BalanceUpdateTournamentPurchaseDetails_details">
      Purchased tournament entry <strong>{{ name: details.name }}</strong>
    </Trans>
  );
};
