import * as React from "react";
import { loader } from "graphql.macro";
import { ChatMessage } from "../PlatformChat/InlineChat/types";
import { useSubscription } from "../../hooks/useSubscription";
// import { RoomChatMessagesFragmentFragment } from "./graphql/RoomChatMessagesFragment.generated";
import {
  JoinRoomSubscription,
  JoinRoomSubscriptionVariables,
} from "./graphql/JoinRoomSubscription.generated";
import { useUser } from "../UserProvider";

const JOIN_ROOM_SUBSCRIPTION = loader("./graphql/JoinRoomSubscription.graphql");

type RoomSubscriptionState = [Array<ChatMessage>, (message: string) => void];

const RoomSubscriptionContext = React.createContext<RoomSubscriptionState>(
  undefined as any
);

// const isChatMessages = (data: {
//   __typename?: string;
// }): data is RoomChatMessagesFragmentFragment => {
//   return (
//     (data as RoomChatMessagesFragmentFragment).__typename === "RoomChatMessages"
//   );
// };
const MAX_CHAT_MESSAGES = 100;
let localMessageId = 0;

const mergeMessages = (
  prev: Array<ChatMessage>,
  newMessage: Array<ChatMessage>
) => {
  let result = [...prev, ...newMessage];
  if (result.length > MAX_CHAT_MESSAGES)
    result = result.slice(result.length - MAX_CHAT_MESSAGES);

  return result;
};

export function SubscribeToRoom({
  id,
  children,
}: React.PropsWithChildren<{ id: string }>) {
  const [{ data: subscriptionData }] = useSubscription<
    JoinRoomSubscription,
    JoinRoomSubscription,
    JoinRoomSubscriptionVariables
  >({
    query: JOIN_ROOM_SUBSCRIPTION,
    variables: { id },
  });

  const {
    user: { id: userId, name },
  } = useUser();

  const [chatMessages, setChatMessages] = React.useState<Array<ChatMessage>>(
    []
  );
  // TODO commented because of removing inline chat
  // React.useEffect(() => {
  //   if (subscriptionData === undefined || subscriptionData.roomData === null)
  //     return;

  //   const { roomData } = subscriptionData;
  //   if (isChatMessages(roomData)) {
  //     const messages = roomData.messages;
  //     setChatMessages((prev) => mergeMessages(prev, messages));
  //   }
  // }, [subscriptionData]);

  const chat = React.useCallback(
    (message: string) => {
      setChatMessages((prev) =>
        mergeMessages(prev, [
          {
            id: localMessageId.toString(),
            created: new Date().toString(),
            message: message,
            player: {
              id: userId,
              name,
            },
          },
        ])
      );
    },
    [id, userId, name]
  );
  return (
    <RoomSubscriptionContext.Provider
      value={React.useMemo(() => [chatMessages, chat], [chatMessages, chat])}
    >
      {children}
    </RoomSubscriptionContext.Provider>
  );
}

export function useRoomChat() {
  return React.useContext(RoomSubscriptionContext);
}
