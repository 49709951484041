import { map, pipe } from "wonka";
import { Exchange } from "urql";
import { clearAccessToken } from "../authToken";
import { clearIsLoggedAsGuest } from "../guestId";

export const unauthorizedExchange = (): Exchange => {
  return ({ forward }) => {
    return (ops$) =>
      pipe(
        ops$,
        forward,
        map((result) => {
          const errors = result.error?.graphQLErrors;
          if (errors !== undefined) {
            const isUnauthorized =
              errors.find((error) => {
                if (error.extensions === undefined) return false;

                const code = error.extensions["code"];
                return typeof code === "number" && code === 401;
              }) !== undefined;
            if (isUnauthorized) {
              clearIsLoggedAsGuest();
              clearAccessToken();
              //Clear the errors.We will redirect anyways once the token is cleared
              result = {
                ...result,
                error: undefined,
              };
            }
          }
          return result;
        })
      );
  };
};
