import * as React from "react";
import classNames from "classnames";
import styles from "./FieldContainer.module.scss";

export const FieldContainer = ({
  name,
  children,
  className,
  ...rest
}: React.PropsWithChildren<
  { name?: string } & JSX.IntrinsicElements["div"]
>) => {
  return (
    <div
      className={classNames(
        "w-full",
        "mb-4",
        "flex",
        "flex-col",
        "items-center",
        "bg-blue-900",
        "p-4",
        "rounded-lg",
        styles.fieldContainer,
        className
      )}
      {...rest}
    >
      {name !== undefined && (
        <span className="text-white uppercase font-bold mb-2">{name}</span>
      )}
      {children}
    </div>
  );
};
