import * as React from "react";

export const MultiProvider = ({
  children,
  providers,
}: React.PropsWithChildren<{ providers: Array<React.ReactElement> }>) => {
  const combinedProvider = providers.reduceRight(
    (prev, current) => React.cloneElement(current, undefined, prev),
    children
  );

  return <>{combinedProvider}</>;
};
