import * as React from "react";
import { BalanceUpdateAvatarPurchaseDetailsFragmentFragment } from "../../../hooks/useTransactions/graphql/TransactionsFragment.generated";
import { Trans } from "react-i18next";

export const BalanceUpdateAvatarPurchaseDetails = ({
  details,
}: {
  details: BalanceUpdateAvatarPurchaseDetailsFragmentFragment;
}) => {
  return (
    <Trans i18nKey="transaction.BalanceUpdateAvatarPurchaseDetails_details">
      Purchased avatar <strong>{{ name: details.name }}</strong>
    </Trans>
  );
};
