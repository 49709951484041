import * as React from "react";
import { BalanceUpdateTableDetailsFragmentFragment } from "../../../hooks/useTransactions/graphql/TransactionsFragment.generated";
import { Trans, useTranslation } from "react-i18next";

export const BalanceUpdateTableDetails = ({
  details,
}: {
  details: BalanceUpdateTableDetailsFragmentFragment;
}) => {
  const { t } = useTranslation();
  const status = t(
    `transaction.BalanceUpdateTableDetails_details_${details.action
      .toString()
      .toLowerCase()}`
  );
  return (
    <Trans i18nKey="transaction.BalanceUpdateTableDetails_details">
      A game of <strong>{{ game: t(`game.${details.game}`) }}</strong> and id{" "}
      <strong>{{ id: details.displayId }}</strong> was{" "}
      <strong>{{ status }}</strong>
    </Trans>
  );
};
