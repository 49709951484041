import React from "react";

const FacebookIcon = ({
  backgroundColor,
  fillColor,
}: {
  backgroundColor?: string;
  fillColor?: string;
}) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill={backgroundColor} />
    <path
      d="M26.0826 21.3981L26.8082 17.0874H22.2694V14.29C22.2694 13.1107 22.9034 11.9612 24.9364 11.9612H27V8.29126C27 8.29126 25.1272 8 23.3367 8C19.5985 8 17.1553 10.0645 17.1553 13.8019V17.0874H13V21.3981H17.1553V31.8188C17.9884 31.938 18.8424 32 19.7123 32C20.5822 32 21.4362 31.938 22.2694 31.8188V21.3981H26.0826Z"
      fill={fillColor}
    />
  </svg>
);

export default FacebookIcon;
