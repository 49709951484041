import * as React from "react";
import { loader } from "graphql.macro";
import {
  FriendsChatUserListQueryQuery,
  FriendsChatUserListQueryQueryVariables,
} from "../graphql/FriendsChatUserListQuery.generated";
import { useClient, CombinedError } from "urql";
import { useIsMounted } from "./useIsMounted";
import { DefaultGenerics, StreamChat } from "stream-chat";

export const CHAT_FRIENDS_REQUEST_LIMIT = 20;

export type ChatFriendsResponse = {
  hasNextPage: boolean;
  friends: ChatFriendType[];
};

export type ChatFriendType = {
  id: string;
  name: string;
  image: string;
};

const FRIEND_CHAT_USERS_QUERY = loader(
  "../graphql/FriendsChatUserListQuery.graphql"
);

const mergePages = (
  prev: NonNullable<ChatFriendsResponse>,
  current: NonNullable<ChatFriendsResponse>
) => {
  const existingIds = prev.friends.map((friend) => friend.id);
  for (const friend of current.friends)
    if (existingIds.indexOf(friend.id) >= 0) return prev;

  return {
    friends: [...prev.friends, ...current.friends],
    hasNextPage: current.hasNextPage,
  };
};

/*Unfortunately we cannot use relay pagination for these.Due to network refresh
Check out https://github.com/FormidableLabs/urql/issues/672*/
export const useFriendChatUsers = (chatClient: StreamChat<DefaultGenerics>) => {
  const graphQlClient = useClient();
  const [error, setError] = React.useState<CombinedError | undefined>(
    undefined
  );
  const [friends, setFriends] = React.useState<
    ChatFriendsResponse | undefined
  >();
  const isMounted = useIsMounted();
  const fetch = React.useCallback(
    (name: string, offset: number, more: boolean = true) => {
      return graphQlClient
        .query<
          FriendsChatUserListQueryQuery,
          FriendsChatUserListQueryQueryVariables
        >(
          FRIEND_CHAT_USERS_QUERY,
          {
            input: {
              name: name,
              limit: CHAT_FRIENDS_REQUEST_LIMIT,
              offset: offset,
            },
          },
          { requestPolicy: "network-only" }
        )
        .toPromise()
        .then(({ data, error }) => {
          if (error !== undefined) {
            throw error;
          } else if ((data?.friendsChatUserList ?? null) === null) {
            setFriends(undefined);
          } else if (isMounted.current) {
            const friends = data!.friendsChatUserList!;
            if (data!.friendsChatUserList!.ids.length === 0) {
              setFriends((prev) => {
                if (prev === undefined || prev === null || !more)
                  return { hasNextPage: false, friends: [] };
                return mergePages(prev, { hasNextPage: false, friends: [] });
              });
            } else {
              chatClient
                .queryUsers({
                  id: {
                    $in: friends.ids,
                  },
                })
                .then((chatClientsResponse) => {
                  const chatClients = chatClientsResponse.users.map((user) => ({
                    id: user.id,
                    name: user.name ?? "",
                    image: typeof user.image === "string" ? user.image : "",
                  }));
                  const current = {
                    hasNextPage: data!.friendsChatUserList!.hasNextPage,
                    friends: chatClients,
                  };
                  setFriends((prev) => {
                    if (prev === undefined || prev === null || !more)
                      return current;
                    return mergePages(prev, current);
                  });
                });
            }
          }
        })
        .catch((error) => {
          if (isMounted.current) setError(error);
        });
    },
    [graphQlClient, isMounted, chatClient]
  );

  React.useEffect(() => {
    fetch("", 0);
  }, [fetch]);

  if (error !== undefined) throw error;

  return React.useMemo(
    () => ({
      friends: friends,
      fetch: fetch,
    }),
    [fetch, friends]
  );
};
