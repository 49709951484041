import * as React from "react";
import { isFunction } from "../utils";

export type AssignableRef<ValueType> =
  | {
      bivarianceHack(instance: ValueType | null): void;
    }["bivarianceHack"]
  | React.MutableRefObject<ValueType | null>
  | null;

function assignRef<RefValueType = any>(
  ref: AssignableRef<RefValueType> | undefined,
  value: any
) {
  if (ref == null) return;
  if (isFunction(ref)) ref(value);
  else
    try {
      ref.current = value;
    } catch (error) {
      throw new Error(`Cannot assign value "${value}" to ref "${ref}"`);
    }
}

export const useMergedRef = <RefValueType = any>(
  ...refs: (AssignableRef<RefValueType> | undefined)[]
) => {
  return React.useMemo(() => {
    if (refs.every((ref) => ref == null)) {
      return null;
    }
    return (node: any) => {
      refs.forEach((ref) => {
        assignRef(ref, node);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refs);
};
