import * as React from "react";
import { useLocalStorage } from "./useStorage";
export const useProfilePictureReload = (): [string, () => void] => {
  const [random, setRandom] = useLocalStorage("profile_pic", "0");

  const reload = React.useCallback(
    () => setRandom(new Date().getTime().toString()),
    [setRandom]
  );

  return React.useMemo(() => [random, reload], [random, reload]);
};
