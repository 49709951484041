import classNames from "classnames";
import React, { useState } from "react";
import styles from "./ProductionLogin/Login.module.scss";
import passwordHideIcon from "./ProductionLogin/assets/passwordHide.svg";
import passworShowdIcon from "./ProductionLogin/assets/passwordShow.svg";

type LoginInputType = {
  name: string;
  icon?: boolean;
  className?: string;
  getValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  type?: string;
  legend?: string;
  isNewPassword?: boolean;
};

const LoginInput = ({
  name,
  icon,
  className,
  getValue,
  error,
  type,
  legend,
  isNewPassword,
}: LoginInputType) => {
  const [showText, setShowText] = useState(false);
  const [inputValue, setInputValue] = useState<string>();
  const getInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    getValue(e);
  };

  const inputType = type ? type : icon && !showText ? "password" : "text";

  return (
    <fieldset
      className={classNames(
        styles.loginInput,
        inputValue && styles.fullfiledInput,
        error && styles.errorColor,
        className
      )}
    >
      <legend className={classNames(styles.inputName, "relative px-1 ml-4")}>
        {legend ?? name}
      </legend>
      <input
        onChange={(e) => getInputValue(e)}
        className={classNames(styles.textInput, "relative")}
        style={{ paddingRight: icon ? "56px" : "16px" }}
        type={inputType}
        placeholder={name}
        autoComplete={isNewPassword ? "new-password" : "false"}
      ></input>
      {icon && (
        <button
          className={styles.showPasswordButton}
          onClick={() => setShowText(!showText)}
        >
          <img src={showText ? passworShowdIcon : passwordHideIcon} alt="" />
        </button>
      )}
    </fieldset>
  );
};
export default LoginInput;
