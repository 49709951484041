import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faTimesCircle, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "../Tooltip";

export const ExitButton = React.memo<{
  onClick: (() => void) | string;
  label: string;
}>(({ onClick, label }) => {
  return typeof onClick === "string" ? (
    <Tooltip label={label}>
      <Link to={onClick} className="text-white">
        <FontAwesomeIcon icon={faTimes} size="lg" fixedWidth />
      </Link>
    </Tooltip>
  ) : (
    <Tooltip label={label}>
      <button onClick={onClick} className="text-white">
        <FontAwesomeIcon icon={faTimesCircle} size="lg" fixedWidth />
      </button>
    </Tooltip>
  );
});
