import * as React from "react";
import { useFullScreen } from "../FullScreenProvider";
import { Tooltip } from "../Tooltip";
import { useTranslation } from "react-i18next";
import fullScreenIcon from "./assets/fullScreen.svg";
import exitFullScreenIcon from "./assets/exitFullScreen.svg";

export const FullscreenButton = React.memo(() => {
  const [isFullScreen, toggleFullScreen, canFullScreen] = useFullScreen();
  const { t } = useTranslation();
  if (!canFullScreen) return null;

  return (
    <Tooltip label={t(isFullScreen ? "exit_fullscreen" : "enter_fullscreen")}>
      <button onClick={toggleFullScreen} className="text-white">
        <img src={isFullScreen ? exitFullScreenIcon : fullScreenIcon} alt="" />
      </button>
    </Tooltip>
  );
});
