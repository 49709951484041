import * as React from "react";
import { useQuery } from "../../../hooks/useQuery";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import styles from "./Challenge.module.scss";
import { Image } from "../../Image";
import challengeImg from "./daily_challenges.jpg";
import { useSyncedTimeProvider } from "../../SyncedTimeProvider";
import { gql } from "graphql.macro";
import { ROOM_IMAGE_WIDTH, ROOM_IMAGE_HEIGHT } from "../index";
import {
  NextChallengeQuery,
  NextChallengeQueryVariables,
} from "./HangmanChallenge.generated";
import { LinkWithSound } from "../../AudioProvider";

const NEXT_CHALLENGE_QUERY = gql`
  query nextChallenge($sectionId: ID!) {
    nextChallenge(sectionId: $sectionId)
  }
`;

const calculateNextAvailableChallengeTime = (
  nextAvailableChallenge: string | null,
  current: Date
) => {
  if (nextAvailableChallenge == null) return null;

  const date = new Date(nextAvailableChallenge);
  return Math.max(date.getTime() - current.getTime(), 0);
};

const countDown = (remaining: number) => {
  const pad = (n: number) => (n < 10 ? "0" + n : n);

  const days = Math.floor(remaining / (1000 * 60 * 60 * 24));
  let hours = Math.floor(
    (remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((remaining % (1000 * 60)) / 1000);
  if (days > 0) hours += days * 24;
  if (hours > 99) hours = 99;

  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};

export const HangmanChallenge = React.memo(
  ({ sectionId }: { sectionId: string }) => {
    const { t } = useTranslation();
    const [hovered, setHovered] = React.useState(false);

    const [{ data: payload }] = useQuery<
      NextChallengeQuery,
      NextChallengeQueryVariables
    >({
      query: NEXT_CHALLENGE_QUERY,
      variables: {
        sectionId,
      },
      requestPolicy: "cache-and-network",
    });

    const syncedTimeProvider = useSyncedTimeProvider();

    const [
      nextAvailableChallengeTime,
      setNextAvailableChallengeTime,
    ] = React.useState<number | null>(
      payload === undefined
        ? null
        : calculateNextAvailableChallengeTime(
            payload?.nextChallenge,
            new Date(syncedTimeProvider())
          )
    );

    React.useEffect(() => {
      let intervalRef: number | undefined;
      if (payload !== undefined) {
        const currentRemaining = calculateNextAvailableChallengeTime(
          payload?.nextChallenge,
          new Date(syncedTimeProvider())
        );

        if ((currentRemaining ?? 0) <= 0) {
          setNextAvailableChallengeTime(0);
          return;
        }

        intervalRef = window.setInterval(() => {
          setNextAvailableChallengeTime(
            calculateNextAvailableChallengeTime(
              payload?.nextChallenge,
              new Date(syncedTimeProvider())
            )
          );
        }, 1000);
      }
      return () => {
        window.clearInterval(intervalRef);
      };
    }, [payload, syncedTimeProvider]);

    return (
      <div className="flex items-center justify-center">
        <LinkWithSound
          className={classNames(
            "inline-block",
            "rounded-md",
            "bg-blue-700",
            "overflow-hidden",
            "shadow-2xl"
          )}
          to={`/hangman/challenge/${sectionId}`}
          onMouseOver={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <div
            className="relative"
            style={{ width: ROOM_IMAGE_WIDTH, height: ROOM_IMAGE_HEIGHT }}
          >
            <Image
              className={classNames("rounded-md")}
              draggable={false}
              src={challengeImg}
              alt={t("challenge")}
            />
            <div
              className={classNames(
                styles.playContainer,
                "flex",
                "items-center",
                "justify-center",
                "overflow-hidden",
                "truncate",
                "leading-none"
              )}
            >
              {nextAvailableChallengeTime !== null &&
              nextAvailableChallengeTime > 0 ? (
                <span className="font-mono text-2xl">
                  {countDown(nextAvailableChallengeTime)}
                </span>
              ) : nextAvailableChallengeTime !== null &&
                nextAvailableChallengeTime <= 0 ? (
                <span className="text-xl font-bold pulse-animate">
                  {t("hangman.play").toLocaleUpperCase()}
                </span>
              ) : (
                <div className={styles.spinner} />
              )}
            </div>
          </div>
        </LinkWithSound>
      </div>
    );
  }
);
