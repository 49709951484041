import "intersection-observer";
import * as React from "react";
import { useIsMounted } from "./useIsMounted";

const useSentinel = <T extends Element>(
  callback: (
    intersecting: boolean,
    isFetching: boolean,
    setFetching: (value: boolean) => void
  ) => void,
  options: IntersectionObserverInit
): React.MutableRefObject<T | null> => {
  const isMounted = useIsMounted();
  const [isFetching, setIsFetching] = React.useState(false);
  const ref = React.useRef<T | null>(null);
  React.useEffect(() => {
    const { current } = ref;
    const observer = new IntersectionObserver(([entry]) => {
      callback &&
        callback(entry.isIntersecting, isFetching, (value: boolean) => {
          if (isMounted.current) setIsFetching(value);
        });
    }, options);
    if (current) observer.observe(current);
    return () => observer.disconnect();
  }, [callback, isFetching, isMounted, options]);

  return ref;
};

//TODO:Can we use (useIntersection hook) instead of custom new IntersectionObserver?
export const useInfiniteScroll = <T extends Element>(
  options: {
    root: React.RefObject<Element>;
    rootMargin?: string;
    threshold?: number | number[];
  },
  callback: () => Promise<unknown>
) => {
  return useSentinel<T>(
    async (onScreen, isFetching, setIsFetching) => {
      if (!isFetching && onScreen) {
        setIsFetching(true);
        try {
          await callback();
        } finally {
          setIsFetching(false);
        }
      }
    },
    {
      ...options,
      root: options.root.current,
    }
  );
};
