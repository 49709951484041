import * as React from "react";

export const Versus = React.memo<{ nameA: string; nameB: string }>(
  ({ nameA, nameB }) => {
    return (
      <div className="overflow-hidden mb-4">
        <div className="flex font-bold -mx-1">
          <div className="w-2/5 px-1 truncate text-center border-orange-500 border-b-2">
            {nameA}
          </div>
          <div className="w-1/5 px-1 text-center text-3xl leading-none">VS</div>
          <div className="w-2/5 px-1 truncate text-center border-orange-500 border-b-2">
            {nameB}
          </div>
        </div>
      </div>
    );
  }
);
