import * as React from "react";
import { Currency } from "../../graphql/generated";
import { useScreenState } from "../ScreenState";

const Shop = React.lazy(() => import("./Shop"));

const ShopContext = React.createContext<(currency: Currency) => void>(() => {
  throw new Error("Provider not found");
});

export const ShopProvider = ({ children }: { children?: React.ReactNode }) => {
  const [doForceDesktopScreenSize, resetScreenSize] = useScreenState();
  const [currency, setCurrency] = React.useState<Currency>();
  const close = React.useCallback(() => {
    if (doForceDesktopScreenSize) {
      resetScreenSize();
    }
    return setCurrency(undefined);
  }, [doForceDesktopScreenSize, resetScreenSize]);
  const setCurrencyCallback = React.useCallback(
    (currency: Currency) => setCurrency(currency),
    []
  );
  return (
    <ShopContext.Provider value={setCurrencyCallback}>
      {children}
      {currency !== undefined && (
        <React.Suspense fallback={null}>
          <Shop
            close={close}
            currency={currency}
            setCurrency={setCurrencyCallback}
          />
        </React.Suspense>
      )}
    </ShopContext.Provider>
  );
};

export const useShop = () => {
  return React.useContext(ShopContext);
};
