import * as React from "react";
import { BalanceUpdateVirtualItemGiftDetailsFragmentFragment } from "../../../hooks/useTransactions/graphql/TransactionsFragment.generated";
import { Trans } from "react-i18next";

export const BalanceUpdateVirtualItemGiftDetails = ({
  details,
}: {
  details: BalanceUpdateVirtualItemGiftDetailsFragmentFragment;
}) => {
  return (
    <Trans i18nKey="transaction.BalanceUpdateVirtualItemGiftDetails_details">
      Send virtual gift <strong>{{ name: details.name }}</strong>
    </Trans>
  );
};
