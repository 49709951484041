import { loader } from "graphql.macro";
import { Cache, Data, Variables } from "@urql/exchange-graphcache";
import { MeQueryQuery } from "../../graphql/MeQuery.generated";
import { PurchaseEntryMutation } from "../../components/Area/PurchaseTournamentEntryButton/index.generated";

const ME_QUERY = loader("../../graphql/MeQuery.graphql");

export const purchaseEntry = (result: Data, args: Variables, cache: Cache) => {
  if (result !== null) {
    const entry = ((result as unknown) as PurchaseEntryMutation).purchaseEntry;
    if (entry !== null)
      cache.updateQuery<MeQueryQuery>({ query: ME_QUERY }, (data) => {
        if (data !== null) {
          const participations = (data as MeQueryQuery).me!
            .tournamentParticipations;

          const index = participations.findIndex((p) => p.id === entry.id);
          if (index >= 0) participations[index] = entry;
          else participations.push(entry);
        }
        return data;
      });
  }
};
