import classNames from "classnames";
import * as React from "react";
import styles from "./ButtonsSelect.module.scss";
import { ButtonWithSound } from "../../../../AudioProvider";

type Option = {
  id: string;
  name: string;
};
export const ButtonsSelect = ({
  disabled = false,
  className,
  value,
  options,
  onChange,
  ...rest
}: {
  disabled?: boolean;
  value: string;
  options: Array<Option>;
  onChange: (value: Option) => void;
} & Omit<JSX.IntrinsicElements["div"], "onChange">) => {
  return (
    <div className={classNames("w-full", className)} {...rest}>
      {options.map((option) => {
        return (
          <ButtonWithSound
            disabled={disabled}
            type="button"
            onClick={() => onChange(option)}
            className={classNames(
              "btn",
              "block",
              "mb-3",
              "w-full",
              "rounded",
              styles.button,
              {
                "btn-green-600": value === option.id,
                "btn-gray-700": value !== option.id,
              }
            )}
            key={option.id}
          >
            {option.name}
          </ButtonWithSound>
        );
      })}
    </div>
  );
};
