import classNames from "classnames";
import React from "react";
import { useChatContext } from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import noChats from "./assets/noChats.svg";
import noChatsContrast from "./assets/ContrastTheme/noChats.svg";
import noChatsDark from "./assets/DarkTheme/noChats.svg";
import { useUser } from "../UserProvider";
import { ChatTheme } from "../../graphql/generated";

const NoAvailableChat = () => {
  const { channelsQueryState } = useChatContext<DefaultStreamChatGenerics>(
    "ChannelList"
  );
  const {user} = useUser();
  const imageSelection = () => {
    if(user.chatTheme === ChatTheme.Light) return noChats;
    if(user.chatTheme === ChatTheme.Dark) return noChatsDark;
    return noChatsContrast
  }
  return (
    <>
      {!channelsQueryState.error && (
        <div
          className={classNames(
            "flex flex-col items-center justify-center",
            "no-chat-container"
          )}
        >
          <div className="flex flex-col items-center justify-center mb-20">
            <img src={imageSelection()} alt="" className="mb-3" />
            <span className="mb-2 header-text">No Chats yet</span>
            <span className="regular-text">
              It’s nice to chat with someone.
            </span>
            <span className="regular-text">
              Pick a person and start your conversation
            </span>
          </div>
        </div>
      )}
    </>
  );
};
export default NoAvailableChat;
