import React from "react";
import clsx from "clsx";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import {
  areMessageUIPropsEqual,
  Avatar as DefaultAvatar,
  EditMessageForm as DefaultEditMessageForm,
  MessageContextValue,
  MessageDeleted as DefaultMessageDeleted,
  MessageErrorIcon,
  messageHasAttachments,
  messageHasReactions,
  MessageInput,
  MessageOptions as DefaultMessageOptions,
  MessageRepliesCountButton as DefaultMessageRepliesCountButton,
  MessageStatus,
  MessageText,
  MessageTimestamp as DefaultMessageTimestamp,
  MessageUIComponentProps,
  MML,
  Modal,
  ReactionSelector as DefaultReactionSelector,
  ReactionsList as DefaultReactionList,
  useChannelActionContext,
  useChatContext,
  useComponentContext,
  useMessageContext,
} from "stream-chat-react";
import { usePlayerProfile } from "../PlayerProfile";
import { GAME_IDS } from "../Games";
import classNames from "classnames";
import reportIcon from "./assets/reportIcon.svg";
import { useFlagHandler } from "./hooks/useFlagHandler";

type MessageSimpleWithContextProps<
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
> = MessageContextValue<StreamChatGenerics>;

const MessageSimpleWithContext = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  props: MessageSimpleWithContextProps<StreamChatGenerics>
) => {
  const {
    additionalMessageInputProps,
    clearEditingState,
    editing,
    endOfGroup,
    firstOfGroup,
    groupedByUser,
    handleAction,
    handleOpenThread,
    handleRetry,
    highlighted,
    isMyMessage,
    isReactionEnabled,
    message,
    onUserClick,
    onUserHover,
    reactionSelectorRef,
    renderText,
    showDetailedReactions,
    threadList,
  } = props;

  const {
    Attachment,
    Avatar = DefaultAvatar,
    EditMessageInput = DefaultEditMessageForm,
    MessageDeleted = DefaultMessageDeleted,
    MessageOptions = DefaultMessageOptions,
    MessageRepliesCountButton = DefaultMessageRepliesCountButton,
    MessageTimestamp = DefaultMessageTimestamp,
    ReactionSelector = DefaultReactionSelector,
    ReactionsList = DefaultReactionList,
  } = useComponentContext<StreamChatGenerics>("MessageSimple");
  const { themeVersion } = useChatContext("MessageSimple");

  const hasAttachment = messageHasAttachments(message);
  const hasReactions = messageHasReactions(message);

  const { addNotification } = useChannelActionContext();
  const handleFlagging = useFlagHandler(message, { notify: addNotification });

  const showProfile = usePlayerProfile();

  const onProfileClick = React.useCallback(
    (profile: { id: string; name: string }) => {
      showProfile({ ...profile, games: GAME_IDS, tournament: false });
      return true;
    },
    [showProfile]
  );

  if (message.deleted_at || message.type === "deleted") {
    return <MessageDeleted message={message} />;
  }

  const showMetadata = !groupedByUser || endOfGroup;
  const allowRetry =
    message.status === "failed" && message.errorStatusCode !== 403;

  const rootClassName = clsx(
    "str-chat__message str-chat__message-simple",
    `str-chat__message--${message.type}`,
    `str-chat__message--${message.status}`,
    isMyMessage()
      ? "str-chat__message--me str-chat__message-simple--me"
      : "str-chat__message--other",
    message.text ? "str-chat__message--has-text" : "has-no-text",
    {
      "pinned-message": message.pinned,
      "str-chat__message--has-attachment": hasAttachment,
      "str-chat__message--highlighted": highlighted,
      "str-chat__message--with-reactions str-chat__message-with-thread-link":
        hasReactions && isReactionEnabled,
      "str-chat__message-send-can-be-retried":
        message?.status === "failed" && message?.errorStatusCode !== 403,
      "str-chat__virtual-message__wrapper--end": endOfGroup,
      "str-chat__virtual-message__wrapper--first": firstOfGroup,
      "str-chat__virtual-message__wrapper--group": groupedByUser,
    }
  );

  return (
    <>
      {editing && (
        <Modal onClose={clearEditingState} open={editing}>
          <MessageInput
            clearEditingState={clearEditingState}
            grow
            Input={EditMessageInput}
            message={message}
            {...additionalMessageInputProps}
          />
        </Modal>
      )}
      {
        <div className={classNames(rootClassName, "relative")} key={message.id}>
          {themeVersion === "1" && <MessageStatus />}
          {message.user && !isMyMessage() && (
            <Avatar
              image={message.user.image}
              name={message.user.name || message.user.id}
              onClick={(event) => {
                onUserClick(event);
                if (message.user?.id && message.user?.name)
                  onProfileClick({
                    id: message.user.id,
                    name: message.user.name,
                  });
              }}
              onMouseOver={onUserHover}
              user={message.user}
            />
          )}
          <div
            className={clsx("str-chat__message-inner", {
              "str-chat__simple-message--error-failed": allowRetry,
            })}
            data-testid="message-inner"
            onClick={allowRetry ? () => handleRetry(message) : undefined}
            onKeyUp={allowRetry ? () => handleRetry(message) : undefined}
          >
            {message.user && !isMyMessage() && (
              <button className="report-button" onClick={handleFlagging}>
                <img src={reportIcon} alt="Report message" />
              </button>
            )}
            <div>
              {message.attachments?.length && !message.quoted_message ? (
                <Attachment
                  actionHandler={handleAction}
                  attachments={message.attachments}
                />
              ) : null}
              <MessageText message={message} renderText={renderText} />
              {message.mml && (
                <MML
                  actionHandler={handleAction}
                  align={isMyMessage() ? "right" : "left"}
                  source={message.mml}
                />
              )}
              {themeVersion === "2" && <MessageErrorIcon />}
            </div>
          </div>
          {showMetadata && themeVersion === "2" && (
            <div className="str-chat__message-data str-chat__message-simple-data str-chat__message-metadata">
              <MessageStatus />
              {!isMyMessage() && !!message.user && (
                <span className="str-chat__message-simple-name">
                  {message.user.name || message.user.id}
                </span>
              )}
            </div>
          )}
        </div>
      }
    </>
  );
};

const MemoizedMessageSimple = React.memo(
  MessageSimpleWithContext,
  areMessageUIPropsEqual
) as typeof MessageSimpleWithContext;

/**
 * The default UI component that renders a message and receives functionality and logic from the MessageContext.
 */
export const CustomMessage = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  props: MessageUIComponentProps<StreamChatGenerics>
) => {
  const messageContext = useMessageContext<StreamChatGenerics>("MessageSimple");

  return <MemoizedMessageSimple {...messageContext} {...props} />;
};
