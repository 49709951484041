import React, { useEffect, useState } from "react";
import backArrow from "./assets/backArrow.svg";
import addPhoto from "./assets/addPhoto.svg";
import warningImg from "./assets/warningImg.svg";
import { v4 as uuidv4 } from "uuid";
import { loader } from "graphql.macro";
import { useMutation } from "../../hooks/useMutation";
import {
  UploadGroupChatImageMutation,
  UploadGroupChatImageMutationVariables,
} from "./graphql/UploadGroupImageMutation.generated";
import { useChatContext } from "stream-chat-react";
import { Channel } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import chatDefault from "./assets/chatDefault.svg";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

type StateType = {
  name: string;
  file: File | undefined;
};

const GROUP_IMAGE_UPLOAD = loader("./graphql/UploadGroupImageMutation.graphql");

const CreateGroupForm = ({
  setIsCreating,
}: {
  setIsCreating: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [channelState, setChannelState] = useState<StateType>({
    name: "",
    file: undefined,
  });

  const [showUploadedImage, setShowUploadedImage] = useState<
    string | undefined
  >(undefined);

  const [disabled, setDisabled] = useState<boolean>(false);

  const { t } = useTranslation();

  const [channels, setChannels] = useState<
    Channel<DefaultStreamChatGenerics>[]
  >([]);

  const [errorCreating, setErrorCreating] = useState<boolean>(false);
  const [fileError, setFileError] = useState<boolean>(false);

  const { client, setActiveChannel } = useChatContext();

  const emptyInput = channelState.name === "";

  const [, uploadImage] = useMutation<
    UploadGroupChatImageMutation,
    UploadGroupChatImageMutationVariables
  >(GROUP_IMAGE_UPLOAD);

  useEffect(() => {
    getChannels();
  }, []);

  const getChannels = async () => {
    const channels = await client.queryChannels(
      { type: "group" },
      {},
      { limit: 10000 }
    );
    setChannels(channels);
  };

  const createChanel = async (name: string, file?: File) => {
    const channelId = uuidv4();
    const index = file?.name.lastIndexOf(".") as number
    const fileDimension = file?.name.substring(index)
    const image = `${process.env.REACT_APP_DIGITAL_OCEAN_SPACE}/chats/group/${channelId}${fileDimension}`;
    if (showUploadedImage)
      await uploadImage({
        input: { chatId: `${channelId}`, file: file },
      });
    const newChannel = client?.channel("group", `${channelId}`, {
      name: name,
      image: showUploadedImage ? image : chatDefault,
    });
    if (newChannel) {
      await newChannel.watch();
      await setActiveChannel(newChannel);
      setDisabled(false);
      setIsCreating(false);
    }
  };

  const handleTextInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nameFromInput = e.target.value;
    setErrorCreating(false);
    setChannelState({ name: nameFromInput, file: channelState.file });
  };

  const chooseAvatar = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileError(false);
    const target = e.target as HTMLInputElement;
    const file = (target.files as FileList)[0];
    const url = URL.createObjectURL(file);
    setShowUploadedImage(url);
    setChannelState({ name: channelState.name, file: file });
  };
  const handleSubmit = () => {
    const notUnique = channels.filter(
      (e) => e?.data?.name === channelState.name
    );
    const toBigImage = channelState.file && channelState.file?.size > 1200000;
    const unique = notUnique.length === 0;
    if (toBigImage) setFileError(true);
    if (!unique || emptyInput) setErrorCreating(true);
    if (channelState.name && unique && !toBigImage) {
      setDisabled(true);
      createChanel(channelState.name, channelState.file);
    }
  };

  return (
    <div className="create-channel-container">
      <div className="create-channel-form-container">
        <div className="create-channel-header">
          <button
            className="back-button"
            onClick={() => {
              setIsCreating(false);
            }}
          >
            <img src={backArrow} alt="Back" />
          </button>
          <span className="header-text">{t("add_new_group")}</span>
        </div>
        <div className="create-channel-add-photo">
          <label htmlFor="loadImg" className="cursor-pointer">
            <img
              src={showUploadedImage ? showUploadedImage : addPhoto}
              alt="Add avatar"
              className="photo-size"
              draggable={false}
            />
          </label>
          <input
            type="file"
            id="loadImg"
            className="hidden"
            required
            onClick={(e: any) => {
              e.target.value = null;
            }}
            onChange={chooseAvatar}
            accept="image/*"
          />
          {!fileError ? (
            <span className="add-photo-text">
              {t("choose_image")}
            </span>
          ) : (
            <div className="error-creating-channel mt-2">
              <img
                src={warningImg}
                alt="Name already exists"
                className="mr-2"
              />
              <span className="error-text">{t("to_big_image")}</span>
            </div>
          )}
        </div>
        <div className="create-channel-input-container">
          <input
            className="create-channel-input"
            type="text"
            placeholder={t("enter_group_name")}
            onChange={(e) => handleTextInput(e)}
          />
        </div>
        {errorCreating && (
          <div className="error-creating-channel">
            <img src={warningImg} alt="Name already exists" className="mr-2" />
            <span className="error-text">
              {emptyInput ? t("group_name_mandatory") : t("name_exists")}
            </span>
          </div>
        )}
        <div
          className={classNames("create-channel-button-container", {
            "margin-top": !errorCreating,
            "mt-10": errorCreating,
          })}
        >
          <button
            className={
              disabled
                ? "create-channel-button-disabled"
                : "create-channel-button"
            }
            onClick={() => handleSubmit()}
            disabled={disabled}
          >
            <span className="button-text">{t("create_group")}</span>
          </button>
        </div>
      </div>
    </div>
  );
};
export default CreateGroupForm;
