import * as React from "react";
import { useIsMounted } from "./useIsMounted";

const cachedStyles: Array<string> = [];
export function useStylesheet(
  src: string,
  pause: boolean = false,
  media = "all"
) {
  // Keeping track of script loaded and error state
  const [state, setState] = React.useState({
    loaded: false,
    error: false,
  });
  const isMounted = useIsMounted();
  React.useEffect(() => {
    if (pause) return;
    // If cachedScripts array already includes src that means another instance ...
    // ... of this hook already loaded this script, so no need to load again.
    if (cachedStyles.includes(src)) {
      setState({
        loaded: true,
        error: false,
      });
    } else {
      cachedStyles.push(src);

      // Script event listener callbacks for load and error
      const onScriptLoad = () => {
        if (isMounted.current)
          setState({
            loaded: true,
            error: false,
          });
      };

      const onScriptError = () => {
        // Remove from cachedScripts we can try loading again
        const index = cachedStyles.indexOf(src);
        if (index >= 0) cachedStyles.splice(index, 1);
        link.remove();
        if (isMounted.current)
          setState({
            loaded: false,
            error: true,
          });
      };

      // Create script
      let link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = src;
      link.media = media;
      link.onload = onScriptLoad;
      link.onerror = onScriptError;
      document.body.appendChild(link);
    }
  }, [isMounted, media, pause, src]); // Only re-run effect if script src changes

  return [state.loaded, state.error];
}
