import * as React from "react";
import { useTranslation } from "react-i18next";
import { AbsoluteCenter } from "../AbsoluteCenter";
import { ButtonWithSound } from "../AudioProvider";
import InputMask from "react-input-mask";
import { gql } from "graphql.macro";
import { TopupMutation, TopupMutationVariables } from "./index.generated";
import { useMutation } from "../../hooks/useMutation";
import { CurrencyImage } from "../CurrencyImage";
import { useScreenType } from "../../hooks/useScreenType";

const TOPUP_MUTATION = gql`
  mutation Topup($topup: ID!) {
    redeemTopup(topup: $topup) {
      currency
      value
    }
  }
`;

const Context = React.createContext<() => void>(undefined as any);

function Result({
  data,
  close,
}: {
  data: NonNullable<TopupMutation["redeemTopup"]>;
  close: () => void;
}) {
  const { t } = useTranslation();
  return (
    <>
      <div className="text-center text-white font-bold mb-8 text-2xl">
        {t("topup_sucess")}
      </div>
      <div className="flex -mx-4 items-center">
        {data
          .filter((v) => v.value > 0)
          .map((v, index) => (
            <div
              className="px-4 flex-1 flex-shrink-0 overflow-hidden"
              key={index}
            >
              <div className="flex items-center justify-center">
                <CurrencyImage currency={v.currency} width={180} />
              </div>
              <div className="font-bold text-4xl text-white text-center mt-2 truncate">
                {v.value}
              </div>
            </div>
          ))}
      </div>
      <ButtonWithSound
        className="btn btn-gray-500 btn-sm w-full rounded mt-5"
        onClick={close}
      >
        {t("close")}
      </ButtonWithSound>
    </>
  );
}

const PopupContainer = ({ children }: { children?: React.ReactNode }) => {
  const screenType = useScreenType();

  return (
    <div className="absolute bg-black-alpha-30 top-0 left-0 right-0 bottom-0 z-50">
      {screenType === "DESKTOP" ? (
        <AbsoluteCenter>{children}</AbsoluteCenter>
      ) : (
        <div className="flex justify-center items-center h-screen">
          {children}
        </div>
      )}
    </div>
  );
};

const Popup = ({ close }: { close: () => void }) => {
  const [{ fetching, data }, redeem] = useMutation<
    TopupMutation,
    TopupMutationVariables
  >(TOPUP_MUTATION);
  const [value, setValue] = React.useState("");

  const { t } = useTranslation();
  const finalValue = React.useMemo(() => {
    return value.split(" - ").join("").split("_").join("");
  }, [value]);
  return (
    <PopupContainer>
      <div
        aria-label={t("topup")}
        className="w-1/3 p-4 rounded shadow-xl bg-blue-800 border-blue-900 border-2"
        style={{ minWidth: 300 }}
      >
        {(data?.redeemTopup ?? null) !== null ? (
          <Result data={data?.redeemTopup!} close={close} />
        ) : (
          <>
            {data?.redeemTopup === null && (
              <div className="p-2 text-center rounded bg-red-700 mb-2">
                <span className="text-white font-bold block">
                  {t("topup_invalid")}
                </span>
              </div>
            )}

            <InputMask
              value={value}
              onChange={(e) => setValue(e.target.value)}
              mask="9999 - 9999 - 9999 - 9999"
              maskChar="_"
              className="form-input w-full py-1 block rounded text-center"
              alwaysShowMask
              disabled={fetching}
            />
            <div className="flex -mx-2 mt-4">
              <div className="flex-1 px-2">
                <ButtonWithSound
                  onClick={() => redeem({ topup: finalValue })}
                  disabled={finalValue.length !== 16 || fetching}
                  className="btn btn-green-600 btn-sm w-full rounded"
                >
                  {t("submit")}
                </ButtonWithSound>
              </div>
              <div className="flex-1 px-2">
                <ButtonWithSound
                  className="btn btn-gray-500 btn-sm w-full rounded"
                  onClick={close}
                >
                  {t("cancel")}
                </ButtonWithSound>
              </div>
            </div>
          </>
        )}
      </div>
    </PopupContainer>
  );
};

let key = 0;

export function TopupPanelProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [isOpen, setOpen] = React.useState(false);

  const toggle = React.useCallback(() => setOpen(true), []);
  const close = React.useCallback(() => {
    key++;
    setOpen(false);
  }, []);

  return (
    <Context.Provider value={toggle}>
      {children}
      {isOpen && <Popup close={close} key={key} />}
    </Context.Provider>
  );
}

export function useTopupPanel() {
  return React.useContext(Context);
}
