import * as React from "react";
import { Authenticated } from "./Authenticated";
import { Login } from "./Login";
import { useAuthToken } from "../hooks/useAuthToken";
import queryString from "query-string";
import { useReferral } from "../hooks/useReferral";
import { REFERRAL_QUERY_PARAM_NAME } from "../config";
import { usePromotion } from "../hooks/usePromotion";
import { AskSendEmailsProvider } from "./AskSendEmails";

const PROMOTION_QUERY_PARAM_NAME = "p";

export const Main = () => {
  const [, setReferral] = useReferral();
  const [, setPromotion] = usePromotion();
  React.useEffect(() => {
    const params = queryString.parse(document.location.search);
    const referral = params[REFERRAL_QUERY_PARAM_NAME];
    let clearQuery = false;
    if (typeof referral === "string" && referral.trim().length > 0) {
      setReferral(referral.trim());
      clearQuery = true;
    }

    const promotion = params[PROMOTION_QUERY_PARAM_NAME];
    if (typeof promotion === "string" && promotion.trim().length > 0) {
      setPromotion(promotion.trim());
      clearQuery = true;
    }

    if (clearQuery && window.history.replaceState)
      window.history.replaceState(null, "", window.location.pathname);
  }, [setReferral, setPromotion]);
  const [accessToken, , clearToken] = useAuthToken();
  return (
    <AskSendEmailsProvider>
      {accessToken === null ? (
        <Login />
      ) : (
        <Authenticated token={accessToken} clearToken={clearToken} />
      )}
    </AskSendEmailsProvider>
  );
};
