import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import styles from "../ProductionLogin/Login.module.scss";
import { Link, useHistory } from "react-router-dom";
import LoginInput from "../LoginInput";
import SubmitButton from "../SubmitButton";
import { saveToLocalStorageWithExpiry, validateEmail } from "../utils";
import { signUpRequest } from "../api";
import {
  DEFAULT_OTP_TTL_IN_SEC,
  RequestAssignPasswordPath,
} from "../ProductionLogin/email/AssignPassword";
import { EmailPath } from "../ProductionLogin/ChooseLogin";

export const FinishSignUpPath = "/finish-sign-up";

const SignUp = () => {
  const [emailError, setEmailError] = useState(false);
  const [alreadyExist, setAlreadyExist] = useState(false);
  const [userNameError, setUserNameError] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const history = useHistory();
  const { t } = useTranslation();

  const getEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError(false);
    setAlreadyExist(false);
  };

  const getName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
    setUserNameError(false);
  };

  const submitForm = async () => {
    const validEmail = validateEmail(email);
    const formatedUserName = userName.replace(/\s/g, "");
    const validUserName = formatedUserName !== "";
    if (!validUserName) setUserNameError(true);
    if (!validEmail) setEmailError(true);
    if (validEmail && validUserName) {
      const response: string = await signUpRequest({
        email,
        userName: formatedUserName,
      });
      if (response.includes("Error")) {
        setAlreadyExist(true);
      } else {
        saveToLocalStorageWithExpiry({
          key: "otpId",
          value: response,
          ttlInSeconds:
            process.env.REACT_APP_OTP_TTL_IN_SEC === undefined
              ? DEFAULT_OTP_TTL_IN_SEC
              : parseInt(process.env.REACT_APP_OTP_TTL_IN_SEC, 10),
        });
        saveToLocalStorageWithExpiry({
          key: "email",
          value: email,
          ttlInSeconds:
            process.env.REACT_APP_OTP_TTL_IN_SEC === undefined
              ? DEFAULT_OTP_TTL_IN_SEC
              : parseInt(process.env.REACT_APP_OTP_TTL_IN_SEC, 10),
        });
        saveToLocalStorageWithExpiry({
          key: "userName",
          value: formatedUserName,
          ttlInSeconds:
            process.env.REACT_APP_OTP_TTL_IN_SEC === undefined
              ? DEFAULT_OTP_TTL_IN_SEC
              : parseInt(process.env.REACT_APP_OTP_TTL_IN_SEC, 10),
        });
        history.push(FinishSignUpPath);
      }
    }
  };

  return (
    <div className="w-full py-1 md:py-2">
      <div className="flex flex-col items-center justify-center w-full -my-2">
        <p className={classNames(styles.emailLoginHeader, "mb-3 text-white")}>
          {t("signinScreens.sign_up")}
        </p>
        <div className={classNames(styles.emailLoginSubHeader, "text-white")}>
          {t("signinScreens.have_account")}{" "}
          <Link
            to={EmailPath}
            className={classNames(styles.emailLoginLinkText, "underline")}
          >
            <span>{t("signinScreens.sign_in")}</span>
          </Link>
          <div>
            <span> {t("signinScreens.or")} </span>
            <Link
              to={RequestAssignPasswordPath}
              className={classNames(styles.emailLoginLinkText, "underline")}
            >
              <span>{t("signinScreens.dont_have_password")}</span>
            </Link>
          </div>
        </div>

        <div className={classNames(styles.inputWrapper, "relative")}>
          <div className={classNames(styles.inputWrapper, "mb-6")}>
            <div className={styles.passwordWithTooltipContainer}>
              <LoginInput
                name={t("signinScreens.create_user_name")}
                getValue={getName}
                error={userNameError}
              />
            </div>
            {userNameError && (
              <p className={styles.errorMessage}>Required field</p>
            )}
            <div className={styles.passwordWithTooltipContainer}>
              <LoginInput
                name={t("signinScreens.email")}
                getValue={getEmail}
                error={emailError || alreadyExist}
              />
            </div>

            {(emailError || alreadyExist) && (
              <p className={styles.errorMessage}>
                {emailError
                  ? t("signinScreens.invalid_email_format")
                  : t("signinScreens.has_game_account")}
              </p>
            )}
          </div>
          <SubmitButton
            name={t("signinScreens.sign_up")}
            onClick={submitForm}
          />
          <div
            className={classNames(
              styles.emailLoginLinks,
              "flex items-center justify-center"
            )}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
