import React, { useState } from "react";
import backArrow from "./assets/backArrow.svg";
import { v4 as uuidv4 } from "uuid";
import { useChatContext } from "stream-chat-react";
import { useTranslation } from "react-i18next";
import { AbsoluteCenter } from "../AbsoluteCenter";
import { Loader } from "../Loader";
import createChatButton from "./assets/createChatButton.svg";
import loadMoreButton from "./assets/loadMoreButton.svg";
import searchIcon from "./assets/searchIcon.svg";
import closeIcon from "./assets/closeIcon.svg";
import _debounce from "lodash.debounce";
import { useUser, useUserId } from "../UserProvider";
import noResultsLight from "./assets/noResults.svg";
import noResultsDark from "./assets/DarkTheme/noResults.svg";
import noResultsContrast from "./assets/ContrastTheme/noResults.svg";
import { usePlayerProfile } from "../PlayerProfile";
import { GAME_IDS } from "../Games";
import {
  ChatOthersResponse,
  useOthersChatUsers,
  CHAT_OTHERS_REQUEST_LIMIT,
  ChatOtherType,
} from "../../hooks/useOthersChatUsers";
import classNames from "classnames";
import { ChatTheme } from "../../graphql/generated";

const Search = ({
  searchedName,
  setSearchedName,
}: {
  searchedName: string;
  setSearchedName: (name: string) => void;
}) => {
  const [query, setQuery] = useState<string>(searchedName);
  const getFriendsDebounced = _debounce(setSearchedName, 300, {
    trailing: true,
  });

  const { t } = useTranslation();

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setQuery(event.target.value);
    if (event.target.value.length === 0 || event.target.value.length > 1) {
      getFriendsDebounced(event.target.value);
    }
  };

  const clearSearch = () => {
    setQuery("");
    setSearchedName("");
  };

  return (
    <div className="container relative flex direct-chat_create-container">
      <img
        src={searchIcon}
        alt="Search"
        className={classNames("search-icon pr-2", { hidden: query })}
      />
      <input
        onChange={handleSearch}
        placeholder={t("search")}
        style={{ paddingLeft: query ? "15px" : "30px" }}
        className={query ? "search-input-active" : "search-input"}
        type="text"
        value={query}
      />
      {query && (
        <img
          src={closeIcon}
          alt="Close"
          className="cursor-pointer close-icon"
          onClick={clearSearch}
        />
      )}
    </div>
  );
};

const Other = ({
  other,
  createChat,
}: {
  other: ChatOtherType;
  createChat: () => void;
}) => {
  const showProfile = usePlayerProfile();

  const onProfileClick = React.useCallback(
    (profile: { id: string; name: string }) => {
      showProfile({ ...profile, games: GAME_IDS, tournament: false });
      return true;
    },
    [showProfile]
  );

  return (
    <div className="flex justify-between mt-2">
      <div className="chat-list-item-avatar-container">
        <img
          className="w-10 h-10 cursor-pointer"
          src={other.image}
          alt={`avatar_${other.id}`}
          onClick={() => onProfileClick({ id: other.id, name: other.name })}
        />
      </div>
      <div className="chat-friends-list__friend-name">{other.name}</div>
      <div className="chat-friends-list__create-chat-arrow">
        <img
          src={createChatButton}
          alt="create_chat_button"
          onClick={createChat}
        />
      </div>
    </div>
  );
};

const Others = ({
  othersResponse,
  loadMore,
  createChat,
}: {
  othersResponse: ChatOthersResponse;
  loadMore: (() => Promise<void>) | undefined;
  createChat: (friendId: string) => void;
}) => {
  return (
    <div className="chat-friends-list__container">
      {othersResponse.others.map((other) => {
        const createChatWithUser = () => createChat(other.id);
        return (
          <div key={other.id}>
            <Other other={other} createChat={createChatWithUser} />
          </div>
        );
      })}
      {othersResponse?.hasNextPage && (
        <div className="chat-friends-list__load-more-container">
          <img
            className="chat-friends-list__load-more-image"
            src={loadMoreButton}
            alt="load_more_button"
            onClick={loadMore}
          />
        </div>
      )}
    </div>
  );
};

const OthersPlaceHolder = ({
  others,
  children,
}: React.PropsWithChildren<{
  others: ChatOthersResponse | undefined;
}>) => {
  const {user} = useUser();
  const imageSelection = () => {
    if(user.chatTheme === ChatTheme.Light) return noResultsLight;
    if(user.chatTheme === ChatTheme.Dark) return noResultsDark;
    return noResultsContrast
  }
  const { t } = useTranslation();
  if (others === undefined)
    return (
      <div className="flex flex-col h-full">
        <div className="relative flex-1">
          <AbsoluteCenter>
            <Loader description={t("loading_friends")} />
          </AbsoluteCenter>
        </div>
      </div>
    );
  else if (others.others.length === 0)
    return (
      <div className="flex flex-col no-friends-placeholder">
        <div className="flex justify-center align-center">
          <div className="flex flex-col items-center justify-center">
            <img src={imageSelection()} alt="No results" className="mb-2" />
            <span>{t("no_results_found")}</span>
          </div>
        </div>
      </div>
    );
  else return <>{children}</>;
};

const ChatOthersList = ({
  othersResponse,
  loadMore,
  createChat,
}: {
  othersResponse: ChatOthersResponse | undefined;
  loadMore: (() => Promise<void>) | undefined;
  createChat: (friendId: string) => void;
}) => {
  return (
    <OthersPlaceHolder others={othersResponse}>
      {othersResponse !== undefined && othersResponse.others.length > 0 && (
        <Others
          othersResponse={othersResponse}
          loadMore={loadMore}
          createChat={createChat}
        />
      )}
    </OthersPlaceHolder>
  );
};

const CreateOthersDirectChannel = ({
  closeCreateFriendDirectChannel,
}: {
  closeCreateFriendDirectChannel: () => void;
}) => {
  const userId = useUserId();
  const { t } = useTranslation();
  const { client: chatClient, setActiveChannel } = useChatContext();
  const [nameFilter, setNameFilter] = useState("");
  const [offset, setOffset] = useState(0);
  const { others: othersResponse, fetch: loadOthers } = useOthersChatUsers(
    chatClient
  );

  const loadMore = othersResponse?.hasNextPage
    ? () => {
        const newOffset = offset + CHAT_OTHERS_REQUEST_LIMIT;
        setOffset(newOffset);
        return loadOthers(nameFilter, newOffset);
      }
    : undefined;

  React.useEffect(() => {
    const newOffset = 0;
    setOffset(newOffset);
    loadOthers(nameFilter, newOffset, false);
  }, [nameFilter, loadOthers]);

  const createChat = React.useCallback(
    (friendId: string) => {
      const channelId = uuidv4();
      const newChannel = chatClient?.channel("direct_others", `${channelId}`, {
        members: [userId, friendId],
      });

      newChannel.watch().then((createChannelResponse) => {
        if (createChannelResponse.channel !== undefined) {
          setActiveChannel(newChannel);
          closeCreateFriendDirectChannel();
        }
      });
    },
    [chatClient, closeCreateFriendDirectChannel, setActiveChannel, userId]
  );

  return (
    <div className="create-channel-container">
      <div className="overflow-hidden create-channel-form-container">
        <div className="create-channel-send-message-header">
          <button
            className="back-button"
            onClick={closeCreateFriendDirectChannel}
          >
            <img src={backArrow} alt="Back" />
          </button>
          <span className="header-text">{t("send_direct_message")}</span>
        </div>
        <Search searchedName={nameFilter} setSearchedName={setNameFilter} />
        <ChatOthersList
          othersResponse={othersResponse}
          loadMore={loadMore}
          createChat={createChat}
        />
      </div>
    </div>
  );
};
export default CreateOthersDirectChannel;
