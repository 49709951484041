import * as React from "react";
import { Trans } from "react-i18next";

export const BalanceUpdateRollbackDetails = () => {
  return (
    <Trans i18nKey="transaction.BalanceUpdateRollbackDetails_details">
      Transaction canceled
    </Trans>
  );
};
