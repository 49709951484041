import classNames from "classnames";
import React, { useEffect, useState } from "react";
import {
  assignPasswordRequest,
  emailSignInRequest,
  finishAssignPasswordRequest,
} from "../../api";
import {
  saveToLocalStorageWithExpiry,
  getValueFromLocalStorage,
  isValidOtp,
  isValidPassword,
  removeFromLocalStorage,
} from "../../utils";
import LoginInput from "../../LoginInput";
import SubmitButton from "../../SubmitButton";
import { validateEmail } from "../../utils";
import styles from "../Login.module.scss";
import { Trans, useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { useAuthToken } from "../../../../hooks/useAuthToken";
import Tooltip from "../Tooltip";

export const LOCAL_STORAGE_KEY = "assignPassword";
export const DEFAULT_OTP_TTL_IN_SEC = 300;
export const RequestAssignPasswordPath = "/request-assign-password";
export const FinishAssignPasswordPath = "/finish-assign-password";

export const RequestAssignPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState(false);
  const [trySignInError, setTrySignInError] = useState(false);
  const [generalError, setGeneralError] = useState(false);
  const { push } = useHistory();

  const getEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError(false);
    setGeneralError(false);
    setTrySignInError(false);
  };

  const onFormSubmit = async () => {
    const validEmail = validateEmail(email);
    if (!validEmail) setEmailError(true);
    if (validEmail) {
      assignPasswordRequest(email).then(({ error, otpId, cause }) => {
        if (error) {
          const errorType = error.split(":")[1].replace(/\s/g, "");
          console.log(errorType);
          setGeneralError(true);
        } else if (otpId === null && cause !== undefined) {
          if (cause === "Invalid email") setEmailError(true);
          if (cause === "Try sign in") setTrySignInError(true);
        } else {
          saveToLocalStorageWithExpiry({
            key: LOCAL_STORAGE_KEY,
            value: { email: email, otpId: otpId },
            ttlInSeconds:
              process.env.REACT_APP_OTP_TTL_IN_SEC === undefined
                ? DEFAULT_OTP_TTL_IN_SEC
                : parseInt(process.env.REACT_APP_OTP_TTL_IN_SEC, 10),
          });
          push(FinishAssignPasswordPath);
        }
      });
    }
  };

  return (
    <div className="w-full py-1 md:py-2">
      <div className="flex flex-col items-center justify-center w-full -my-2">
        <div className={classNames(styles.emailLoginHeader, "mb-3 text-white")}>
          <Trans i18nKey="signinScreens.do_not_have_password">
            <p>Don't have </p>
            <p>password?</p>
          </Trans>
        </div>
        <p className={classNames(styles.emailHaveNoPasswordSubHeader)}>
          {t("signinScreens.do_not_have_password_instruction")}
        </p>

        <div className={classNames(styles.inputWrapper, "relative")}>
          <div className={classNames(styles.inputWrapper, "mb-6")}>
            <div className={styles.passwordWithTooltipContainer}>
              <LoginInput
                name={t("signinScreens.email")}
                getValue={getEmail}
                error={emailError || trySignInError || generalError}
              />
            </div>
            {(emailError || trySignInError || generalError) && (
              <p className={styles.errorMessage}>
                {t(
                  emailError
                    ? "signinScreens.invalid_email_format"
                    : trySignInError
                    ? "signinScreens.try_sing_in_error"
                    : "signinScreens.general_error"
                )}
              </p>
            )}
          </div>
          <SubmitButton
            name={t("signinScreens.send_code")}
            onClick={onFormSubmit}
          />
          <div
            className={classNames(
              styles.emailLoginLinks,
              "flex items-center justify-center"
            )}
          ></div>
        </div>
      </div>
    </div>
  );
};

export const FinishAssignPassword = () => {
  const { t } = useTranslation();
  const [, setToken] = useAuthToken();
  const { push } = useHistory();
  const [email, setEmail] = useState<string>("");
  const [haveOtpId, setHaveOtpId] = useState(false);

  useEffect(() => {
    const storedData = getValueFromLocalStorage(LOCAL_STORAGE_KEY);
    setEmail(storedData ? storedData.email : "");
    if (storedData && storedData.otpId) {
      setHaveOtpId(true);
    }
  }, []);

  const [otp, setOtp] = useState<string>("");
  const [otpError, setOtpError] = useState<string | undefined>(undefined);
  const onOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
    setOtpError(undefined);
  };

  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string | undefined>(
    undefined
  );
  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setPasswordError(undefined);
  };

  const resendCode = async () => {
    const validEmail = email !== "";
    if (validEmail) {
      assignPasswordRequest(email).then(({ error, otpId }) => {
        if (error) {
          const errorType = error.split(":")[1].replace(/\s/g, "");
          console.log(errorType);
        }
        saveToLocalStorageWithExpiry({
          key: LOCAL_STORAGE_KEY,
          value: { email: email, otpId: otpId },
          ttlInSeconds:
            process.env.REACT_APP_OTP_TTL_IN_SEC === undefined
              ? DEFAULT_OTP_TTL_IN_SEC
              : parseInt(process.env.REACT_APP_OTP_TTL_IN_SEC, 10),
        });
      });
    }
  };

  const onFormSubmit = async () => {
    const storedData = getValueFromLocalStorage(LOCAL_STORAGE_KEY);
    const otpId = storedData ? storedData.otpId : null;
    if (!otpId || !email || !isValidOtp(otp)) {
      setOtpError("signinScreens.otp_invalid_code");
    }

    if (!password || !isValidPassword(password)) {
      setPasswordError("signinScreens.invalid_password_message");
    }

    if (
      otpId &&
      email &&
      isValidOtp(otp) &&
      password &&
      isValidPassword(password)
    ) {
      setOtpError(undefined);
      setPasswordError(undefined);
      finishAssignPasswordRequest({
        otp,
        otpId,
        password,
      }).then((result) => {
        if (result.error !== null) {
          //Can have here an issue if prod server do not return custom error
          if (result.error?.includes("Code is not correct")) {
            setOtpError("signinScreens.otp_invalid_code");
          } else if (result.error?.includes("Password is not valid")) {
            setPasswordError("signinScreens.invalid_password_message");
          } else {
            //If custom error is not send by server, most probably otp is incorrect
            setOtpError("signinScreens.otp_invalid_code");
          }
        } else {
          //Clear otpId and email
          removeFromLocalStorage(LOCAL_STORAGE_KEY);
          //redirect to login page
          push("/email");
        }
      });
    }
  };

  return (
    <div className="w-full py-1 md:py-2">
      <div className="flex flex-col items-center justify-center w-full -my-2">
        <p className={classNames(styles.emailLoginHeader, "mb-1 text-white")}>
          {t("signinScreens.set_password")}
        </p>
        <p className={classNames(styles.emailHaveNoPasswordSubHeader)}>
          {t(
            haveOtpId
              ? "signinScreens.set_password_instruction"
              : "signinScreens.set_password_instruction_if_exist",
            { email: email }
          )}
        </p>

        <div className={classNames(styles.inputWrapper, "relative")}>
          <div className={classNames(styles.inputWrapper, "mb-6")}>
            <div className={styles.passwordWithTooltipContainer}>
              <LoginInput
                name={t("signinScreens.code")}
                getValue={onOtpChange}
                error={otpError !== undefined}
                type="number"
              />
            </div>
            {otpError && <p className={styles.errorMessage}>{t(otpError)}</p>}

            <div className={styles.passwordWithTooltipContainer}>
              <div>
                <LoginInput
                  name={t("signinScreens.create_password")}
                  getValue={onPasswordChange}
                  error={passwordError !== undefined}
                  icon={true}
                  legend={t("password")}
                  isNewPassword={true}
                />
              </div>
              <Tooltip />
            </div>
            {passwordError && (
              <p className={styles.errorMessage}>{t(passwordError)}</p>
            )}
          </div>

          <SubmitButton
            name={t("signinScreens.set_password")}
            onClick={onFormSubmit}
          />
          <p className={styles.resendCodeText}>
            {t("signinScreens.still_no_code")}
          </p>
          <div
            className={classNames(
              styles.emailLoginLinks,
              "flex items-center justify-center"
            )}
            style={{ marginTop: "6px" }}
          >
            <div
              className={classNames(styles.forgotPassword, "flex items-center")}
            >
              <Link
                to={RequestAssignPasswordPath}
                className={classNames(styles.emailLoginLinkText, "underline")}
              >
                <span>{t("signinScreens.use_other_email")}</span>
              </Link>
            </div>
            <div className={styles.emailLoginLinkSeparator} />
            <div
              className={classNames(
                styles.dontHavePassword,
                "flex items-center"
              )}
            >
              <span
                className={classNames(styles.emailLoginLinkText, "underline")}
                onClick={resendCode}
              >
                {t("signinScreens.resend_code")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
