import { GameStatisticsFragmentFragment } from "../../graphql/GameStatisticsFragment.generated";
import { useTranslation } from "react-i18next";
import * as React from "react";
import {
  ListboxButton,
  ListboxInput,
  ListboxList,
  ListboxOption,
  ListboxPopover,
} from "../ListBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { Row } from "./Row";
import classNames from "classnames";

const STATISTICS: Array<string> = ["win", "lose", "draw", "quit", "ranking"];
type Statistics = GameStatisticsFragmentFragment["statistics"];
type Stat = {
  game: string;
  win: number;
  lose: number;
  draw: number;
  quit: number;
  ranking: number;
};

const GameStatisticsComponent = React.memo<{
  mine: Stat | null;
  other: Stat | null;
}>(({ mine, other }) => {
  const { t } = useTranslation();
  return (
    <>
      {STATISTICS.map((key) => (
        <Row
          key={key}
          left={(mine?.[key as keyof Stat] as number) ?? null}
          right={(other?.[key as keyof Stat] as number) ?? null}
          center={t(key)}
        />
      ))}
    </>
  );
});

export const GameStatisticsCompare = ({
  other,
  me,
  games,
  tournament: initialTournament,
}: {
  other: Statistics | null;
  me: Statistics | null;
  games: Array<string>;
  tournament: boolean;
}) => {
  const { t } = useTranslation();
  const [tournament, setTournament] = React.useState(initialTournament);
  const [selectedGame, setSelectedGame] = React.useState(games[0]);
  const myGameStatistics =
    (tournament ? me?.tournaments : me?.normal)?.find(
      (stats) => stats.game === selectedGame
    ) ?? null;
  const otherGameStatistics =
    (tournament ? other?.tournaments : other?.normal)?.find(
      (stats) => stats.game === selectedGame
    ) ?? null;
  return (
    <>
      <div
        className={classNames(other === null || me === null ? "-mx-2" : "px-2")}
      >
        <div className="flex w-full items-center justify-between -px-1">
          <div className="py-2 flex justify-center px-1">
            {games.length > 1 ? (
              <ListboxInput value={selectedGame} onChange={setSelectedGame}>
                <ListboxButton className="border-0 p-0">
                  {({ label, expanded }) => {
                    return (
                      <div className="overflow-hidden">
                        <div className="flex items-center -mx-1">
                          <div className="px-1">
                            <div className="font-bold text-lg text-white text-center">
                              {label}
                            </div>
                          </div>
                          <div className="flex-shrink-0 px-1">
                            <FontAwesomeIcon
                              icon={!expanded ? faChevronDown : faChevronUp}
                              size="sm"
                            />
                          </div>
                        </div>
                      </div>
                    );
                  }}
                </ListboxButton>
                <ListboxPopover className="rounded border-0 p-0">
                  <ListboxList>
                    {games.map((game) => (
                      <ListboxOption value={game} key={game}>
                        {t(`game.${game}`)}
                      </ListboxOption>
                    ))}
                  </ListboxList>
                </ListboxPopover>
              </ListboxInput>
            ) : (
              <div className="font-bold text-lg text-white text-center px-1">
                {t(`game.${selectedGame}`)}
              </div>
            )}
          </div>
          <div className="px-1">
            <label className="inline-flex items-center justify-between w-full -mx-1">
              <span className="text-white text-lg font-bold px-1">
                {t("tournaments")}
              </span>
              <div className="px-1">
                <input
                  type="checkbox"
                  className="form-checkbox h-6 w-6 text-green-600"
                  onChange={() => setTournament((prev) => !prev)}
                  checked={tournament}
                />
              </div>
            </label>
          </div>
        </div>
      </div>
      <GameStatisticsComponent
        mine={myGameStatistics}
        other={otherGameStatistics}
      />
    </>
  );
};
