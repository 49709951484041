import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  ChannelPreviewUIComponentProps,
  useChatContext,
} from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import classNames from "classnames";
import { useUserId } from "../UserProvider";
import chatDefault from "./assets/chatDefault.svg";
import userDefault from "./assets/userDefault.svg";

const UnMemoizedChannelPreviewMessenger = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  props: ChannelPreviewUIComponentProps<StreamChatGenerics>
) => {
  const {
    active,
    Avatar,
    channel,
    className: customClassName = "",
    displayImage,
    displayTitle,
    latestMessage,
    onSelect: customOnSelectChannel,
    setActiveChannel,
    unread,
    watchers,
  } = props;

  const channelPreviewButton = useRef<HTMLButtonElement | null>(null);
  const [showUserImage, setShowUserImage] = useState<boolean>(false);
  const [showChannelImage, setShowChannelImage] = useState<boolean>(false);

  const { client } = useChatContext();
  const directChat =
    channel.data?.type === "direct_others" ||
    channel.data?.type === "direct_friends";

  const mountedRef = useRef(true);

  const lastMessageAt = new Date(channel.data?.last_message_at as string);
  const minutes = lastMessageAt.getMinutes().toLocaleString().length > 1 ? lastMessageAt.getMinutes() : `0${lastMessageAt.getMinutes()}`
  const hoursAndMinutes =
    lastMessageAt.getHours() + ":" + minutes;

  const noLastMessage = hoursAndMinutes === "NaN:NaN";

  const [online, setOnline] = useState<boolean | undefined>(undefined);
  const [user, setUser] = useState<{
    name: string | undefined;
    image: string | undefined;
  }>();
  const userId = useUserId();

  const getUserProfile = () => {
    const state = Object.values(channel.state.read);
    state.map((e) => {
      if (e.user.id !== userId) {
        setUser({ name: e.user.name, image: e.user.image });
      }
    });
  };

  const onlineStatus = useCallback(async () => {
    if (directChat) {
      const usersId: string[] = Object.keys(channel.state.members);
      const users = await client.queryUsers(
        {
          id: {
            $in: [usersId[0], usersId[1]],
          },
        },
        { id: -1 },
        { presence: true }
      );
      if (mountedRef.current) {
        setOnline(users.users[0].online && users.users[1].online);
      }
    }
  }, [mountedRef]);

  useEffect(() => {
    if (directChat) {
      channel.watch({ presence: true });
      onlineStatus();
    }
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    getUserProfile();
    const presence = async () => {
      if (directChat) {
        client.on("user.presence.changed", onlineStatus);
      }
    };
    presence();
    return () => {
      client.off("user.presence.changed", onlineStatus);
    };
  }, [channel, client]);

  const isMember = () => {
    const usersId: string[] = Object.keys(channel.state.members);
    return usersId.includes(userId);
  };

  const avatarName =
    displayTitle ||
    channel.state.messages[channel.state.messages.length - 1]?.user?.id;

  const onSelectChannel = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (customOnSelectChannel) {
      customOnSelectChannel(e);
    } else if (setActiveChannel) {
      setActiveChannel(channel, watchers);
    }
    if (channelPreviewButton?.current) {
      channelPreviewButton.current.blur();
    }
  };

  const toMuchUnread = unread && unread > 99;

  function testImage(url: string) {
    var tester = new Image();
    tester.addEventListener("load", () => {
      if(directChat)setShowUserImage(true);
      if(!directChat) setShowChannelImage(true)
    });
    tester.addEventListener("error", () => {
      if(directChat)setShowUserImage(false);
      if(!directChat) setShowChannelImage(false)
    });
    tester.src = url;
  }

  useEffect(() => {
    if (directChat && user?.image) {
      testImage(user?.image);
    }
    if(!directChat && displayImage)
    testImage(displayImage);
  }, [user?.image, displayImage]);

  const channelImage = () => {
    if (directChat && user?.image && showUserImage) return user?.image;
    if (directChat) return userDefault;
    if (displayImage && showChannelImage) return displayImage;
    return chatDefault;
  };

  return (
    <button
      aria-label={`Select Channel: ${
        directChat ? user?.name : displayTitle || ""
      }`}
      aria-selected={active}
      data-testid="channel-preview-button"
      onClick={onSelectChannel}
      ref={channelPreviewButton}
      role="option"
      className={classNames("chat-list-item-container", {
        "active-channel": active,
      })}
    >
      {online && <div className="online-status" />}
      <div className="chat-list-item-avatar-container">
        <img
          src={channelImage()}
          alt={avatarName}
          className="w-10 h-10"
        />
      </div>
      <div className="str-chat__channel-preview-messenger--right str-chat__channel-preview-end">
        <div className="str-chat__channel-preview-end-first-row">
          <div className="list-item-title">
            <span className="list-item-overflow">
              {directChat ? user?.name : displayTitle}
            </span>
          </div>
          {!!unread && isMember() && (
            <div
              className="str-chat__channel-preview-unread-badge"
              data-testid="unread-badge"
            >
              <span className="unread-count">
                {toMuchUnread ? "99+" : unread}
              </span>
            </div>
          )}
          {!noLastMessage && (
            <span className="time-message-text">{hoursAndMinutes}</span>
          )}
        </div>
        <div className="latest-message">{latestMessage}</div>
      </div>
    </button>
  );
};

/**
 * Used as preview component for channel item in [ChannelList](#channellist) component.
 * Its best suited for messenger type chat.
 */
export const CustomChannelPreviewMessenger = React.memo(
  UnMemoizedChannelPreviewMessenger
) as typeof UnMemoizedChannelPreviewMessenger;
