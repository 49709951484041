import * as React from "react";
import { Currency } from "../../graphql/generated";
import { useScreenState } from "../ScreenState";
import { GlobalLoaderProvider } from "./GlobalLoader";

const ScratchCards = React.lazy(() => import("./SelectScratchCard"));

const ScratchCardsContext = React.createContext<(currency: Currency) => void>(
  () => {
    throw new Error("Provider not found");
  }
);

export const ScratchCardsProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [doForceDesktopScreenSize, resetScreenSize] = useScreenState();
  const [currency, setCurrency] = React.useState<Currency>();
  const close = React.useCallback(() => {
    if (doForceDesktopScreenSize) {
      resetScreenSize();
    }

    return setCurrency(undefined);
  }, [doForceDesktopScreenSize, resetScreenSize]);
  const setCurrencyCallback = React.useCallback(
    (currency: Currency) => setCurrency(currency),
    []
  );
  return (
    <ScratchCardsContext.Provider value={setCurrencyCallback}>
      {children}
      <React.Suspense fallback={null}>
        <GlobalLoaderProvider>
          {currency !== undefined && (
            <ScratchCards
              onClose={close}
              currency={currency}
              setCurrency={setCurrency}
            />
          )}
        </GlobalLoaderProvider>
      </React.Suspense>
    </ScratchCardsContext.Provider>
  );
};

export const useScratchCards = () => {
  return React.useContext(ScratchCardsContext);
};
