import * as React from "react";
import { Tables } from "./Tables";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { AbsoluteCenter } from "../../AbsoluteCenter";
import { Loader } from "../../Loader";
import { ButtonWithSound, LinkWithSound } from "../../AudioProvider";
import { RoomQuery } from "../RoomProvider/index.generated";
import { useUser } from "../../UserProvider";
import { TournamentFragment } from "../graphql/TournamentFragment.generated";
import { useNumberFormatter } from "../../../hooks/useNumberFormatter";
import { PurchaseTournamentEntryButton } from "../PurchaseTournamentEntryButton";
import { useLeaderboards } from "../../Leaderboards";

const DebouncedTables = ({
  debounce = true,
  children,
}: React.PropsWithChildren<{ debounce?: boolean }>) => {
  const [ready, setReady] = React.useState(!debounce);
  React.useEffect(() => {
    if (!debounce) return;

    const id = window.setTimeout(() => setReady(true), 250);
    return () => window.clearTimeout(id);
  }, [debounce]);

  const { t } = useTranslation();

  if (!ready)
    return (
      <AbsoluteCenter>
        <Loader description={t("loading_tables")} />
      </AbsoluteCenter>
    );
  else return <>{children}</>;
};

function TournamentCompleted({
  joined,
  tournament,
}: {
  joined: boolean;
  tournament: TournamentFragment;
}) {
  const { t } = useTranslation();
  const toggleLeaderboard = useLeaderboards();
  return (
    <AbsoluteCenter>
      <div className="w-2/5 text-center">
        <span className="text-white font-bold block">
          {t("tournament_completed")}
        </span>
        <ButtonWithSound
          className="btn btn-blue-600 btn-sm text-sm rounded mt-2 w-full"
          onClick={() => toggleLeaderboard([tournament.section.id])}
        >
          {t("leaderboards")}
        </ButtonWithSound>
      </div>
    </AbsoluteCenter>
  );
}

function JoinTournament({
  joined,
  tournament,
  children,
}: React.PropsWithChildren<{
  joined: boolean;
  tournament: TournamentFragment;
}>) {
  const numberFormatter = useNumberFormatter();
  const { t } = useTranslation();
  const price = `${numberFormatter.format(tournament.amount.value)} ${t(
    `currency.${tournament.amount.currency.toLowerCase()}`
  )}`;
  return (
    <>
      {children}
      <AbsoluteCenter>
        <div className="w-2/5 text-center p-4 bg-gray-900">
          <span className="text-white font-bold block">
            {joined
              ? t("tournament_join_instructions_already_joined", {
                  price: price,
                  games: tournament.gamesPerPurchase,
                })
              : t("tournament_join_instructions", {
                  price: price,
                  games: tournament.gamesPerPurchase,
                })}
          </span>
          <PurchaseTournamentEntryButton
            className="btn btn-blue-600 btn-sm text-sm rounded mt-2 w-full"
            tournament={tournament}
          >
            {t("purchase")}
          </PurchaseTournamentEntryButton>
        </div>
      </AbsoluteCenter>
    </>
  );
}

function TablesOrJoinTournamentOrExpiredTournament({
  section,
}: {
  section: NonNullable<RoomQuery["room"]>["sections"][0];
}) {
  const tournament =
    section.__typename === "TournamentSection" ? section.tournament : undefined;

  const { user } = useUser();
  const participation = React.useMemo(() => {
    if (tournament === undefined) return undefined;
    const participation = user.tournamentParticipations.find(
      (p) => p.leaderboard.id === tournament.id
    );

    return participation ?? null;
  }, [tournament, user.tournamentParticipations]);

  return tournament !== undefined && !tournament.active ? (
    <TournamentCompleted
      tournament={tournament!}
      joined={participation !== null}
    />
  ) : participation === undefined ||
    (participation?.gamesRemaining ?? 0) > 0 ? (
    <DebouncedTables>
      <Tables
        sectionId={section.id}
        gameType={section.type}
        tournamentId={tournament?.id}
      />
    </DebouncedTables>
  ) : (
    <JoinTournament tournament={tournament!} joined={participation !== null}>
      <DebouncedTables>
        <Tables
          sectionId={section.id}
          gameType={section.type}
          tournamentId={tournament?.id}
        />
      </DebouncedTables>
    </JoinTournament>
  );
}

export const TablesList = React.memo<{
  sections: Array<NonNullable<RoomQuery["room"]>["sections"][0]>;
  section: NonNullable<RoomQuery["room"]>["sections"][0];
}>(({ sections, section }) => {
  const { t } = useTranslation();
  return (
    <div className="h-full flex flex-col">
      {sections.length > 1 && (
        <div className="flex flex-shrink-0">
          {sections.map((currSection) => {
            const isActive = currSection === section;
            return (
              <LinkWithSound
                to={`/area/${currSection.id}`}
                key={currSection.id}
                className={classNames(
                  "py-2",
                  "px-4",
                  "text-white",
                  "rounded-t-lg",
                  "font-extrabold",
                  "bg-gray-800",
                  {
                    "bg-gray-900 text-blue-600": isActive,
                  }
                )}
              >
                <div className="flex -mx-1 items-center">
                  <span className="px-1">{t(`game.${currSection.type}`)}</span>
                  <div className="px-1">
                    <div
                      className={classNames("rounded-full", "px-2", "text-xs", {
                        "bg-white text-black": !isActive,
                        "bg-blue-600 text-white": isActive,
                      })}
                    >
                      {currSection.count}
                    </div>
                  </div>
                </div>
              </LinkWithSound>
            );
          })}
        </div>
      )}
      <div
        className={classNames(
          "flex-1",
          "rounded-b-lg",
          "overflow-hidden",
          "bg-gray-900",
          {
            "rounded-t-lg": sections.length === 1,
          }
        )}
      >
        <div key={section.id} className="h-full relative">
          <TablesOrJoinTournamentOrExpiredTournament section={section} />
        </div>
      </div>
    </div>
  );
});
