import * as React from "react";
import { Trans } from "react-i18next";
import { BalanceUpdateReferDetailsFragmentFragment } from "../../../hooks/useTransactions/graphql/TransactionsFragment.generated";
import { usePlayerProfile } from "../../PlayerProfile";
import { GAME_IDS } from "../../Games";

export const BalanceUpdateReferDetails = ({
  details,
}: {
  details: BalanceUpdateReferDetailsFragmentFragment;
}) => {
  const openProfile = usePlayerProfile();
  return (
    <Trans i18nKey="transaction.BalanceUpdateReferDetails_details">
      Referred user{" "}
      <button
        onClick={() =>
          openProfile({
            id: details.userId,
            name: details.name,
            games: GAME_IDS,
            tournament: false,
          })
        }
      >
        <strong>{{ user: details.name }}</strong>
      </button>
    </Trans>
  );
};
