import * as React from "react";
import styles from "./Amount.module.css";
import { CurrencyImage } from "../CurrencyImage";
import { useNumberFormatter } from "../../hooks/useNumberFormatter";
import { Currency } from "../../graphql/generated";
import classNames from "classnames";
import { useAnimatedCounter } from "../../hooks/useAnimatedCounter";

const BalanceValue = ({ value }: { value: number }) => {
  const numberFormatter = useNumberFormatter();
  const animatedValue = useAnimatedCounter(value, { duration: 3 });
  return (
    <span className={styles.balanceText}>
      {numberFormatter.format(Math.floor(animatedValue))}
    </span>
  );
};

export const Amount = React.memo<{ value: number; currency: Currency }>(
  ({ value, currency }) => {
    return (
      <div className="relative">
        <CurrencyImage
          currency={currency}
          width={36}
          height={36}
          className="absolute"
        />
        <div
          className={classNames("leading-none", "text-center", styles.balance)}
        >
          <BalanceValue value={value} />
        </div>
      </div>
    );
  }
);
