import * as React from "react";
import { useTranslation } from "react-i18next";
import { ProfileImage } from "../ProfileImage";
import { loader } from "graphql.macro";
import { useQuery } from "../../hooks/useQuery";
import { Loader } from "../Loader";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Flag } from "../Flag";
import { useCustomChatContext, useUser, useUserId } from "../UserProvider";
import classNames from "classnames";
import styles from "./PlayerProfile.module.scss";
import { useNumberFormatter } from "../../hooks/useNumberFormatter";
import { ChatTheme, Currency } from "../../graphql/generated";
import { AbsoluteCenter } from "../AbsoluteCenter";
import { Slider } from "../Slider";
import { useMutation } from "../../hooks/useMutation";
import {
  SendBalanceMutation,
  SendBalanceMutationVariables,
} from "../../graphql/SendBalanceMutation.generated";
import {
  PlayerQuery,
  PlayerQueryVariables,
} from "./graphql/PlayerQuery.generated";
import {
  CreateFriendRequestMutationMutation,
  CreateFriendRequestMutationMutationVariables,
} from "./graphql/CreateFriendRequestMutation.generated";
import {
  CreateBanMutationMutation,
  CreateBanMutationMutationVariables,
} from "./graphql/CreateBanMutation.generated";
import { usePendingFriendRequests } from "../../hooks/usePendingFriendRequests";
import {
  AnswerFriendRequestMutation,
  AnswerFriendRequestMutationVariables,
} from "../../graphql/AnswerFriendRequestMutation.generated";
import {
  CancelFriendshipMutationMutation,
  CancelFriendshipMutationMutationVariables,
} from "./graphql/CancelFriendshipMutation.generated";
import {
  CancelBanMutationMutation,
  CancelBanMutationMutationVariables,
} from "./graphql/CancelBanMutation.generated";

import {
  UpsertAcceptDirectMessagesMutation,
  UpsertAcceptDirectMessagesMutationVariables,
} from "./graphql/UpsertAcceptDirectMessagesMutation.generated";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@reach/tabs";
import { USER_MESSAGE_MAX_LENGTH, SEND_CHIPS_INTERVAL } from "../../config";
import {
  SendMessageMutationMutation,
  SendMessageMutationMutationVariables,
} from "./graphql/SendMessageMutation.generated";
import { useIsMounted } from "../../hooks/useIsMounted";
import { Presence } from "../Presence";
import { useIsInTable } from "../../hooks/useIsInTable";
import { useMyStatistics } from "../../hooks/useMyStatistics";
import { Versus } from "../Versus";
import {
  BalanceStatisticsCompare,
  PlayerStatisticsCompare,
} from "../Statistics";
import { Avatars } from "./Avatars";
import { ButtonWithSound } from "../AudioProvider";
import { ErrorMessage, Field, Formik } from "formik";
import * as Yup from "yup";
import { Profile, TabValue as TabType } from "./types";
import { useRegisterPopup } from "../Register";
import { useGuestId } from "../../hooks/useGuestId";
import vipIcon from "../../assets/vip-icon.png";
import directChatOnButton from "./direct_chat_on_button.png";
import directChatOffButton from "./direct_chat_off_button.png";
import {
  UpsertChatThemeMutation,
  UpsertChatThemeMutationVariables,
} from "./graphql/UpsertChatThemeMutation.generated";
import { DeleteMePanelProvider, useDeleteMePanel } from "../DeleteMe";
import deleteBucket from "./delete-bucket.svg";
import { useScreenType } from "../../hooks/useScreenType";
import {
  RenameUserMutation,
  RenameUserMutationVariables,
} from "./graphql/RenameUserMutation.generated";

const PLAYER_QUERY = loader("./graphql/PlayerQuery.graphql");
const SEND_BALANCE_MUTATION = loader(
  "../../graphql/SendBalanceMutation.graphql"
);

const ANSWER_FRIEND_REQUEST = loader(
  "../../graphql/AnswerFriendRequestMutation.graphql"
);
const CREATE_FRIEND_REQUEST_MUTATION = loader(
  "./graphql/CreateFriendRequestMutation.graphql"
);

const CANCEL_FRIENDSHIP_MUTATION = loader(
  "./graphql/CancelFriendshipMutation.graphql"
);

const UPSERT_CHAT_THEME_MUTATION = loader(
  "./graphql/UpsertChatThemeMutation.graphql"
);

const CREATE_BAN_MUTATION = loader("./graphql/CreateBanMutation.graphql");

const CANCEL_BAN_MUTATION = loader("./graphql/CancelBanMutation.graphql");

const SEND_MESSAGE_MUTATION = loader("./graphql/SendMessageMutation.graphql");

const UPDATE_ACCEPT_DIRECT_MESSAGES_MUTATION = loader(
  "./graphql/UpsertAcceptDirectMessagesMutation.graphql"
);

const RENAME_USER_MUTATION = loader("./graphql/RenameUserMutation.graphql");

type Player = NonNullable<PlayerQuery["player"]>;

const AddFriendButton = ({ id }: { id: string }) => {
  const [{ fetching }, addFriend] = useMutation<
    CreateFriendRequestMutationMutation,
    CreateFriendRequestMutationMutationVariables
  >(CREATE_FRIEND_REQUEST_MUTATION);
  const registration = useRegisterPopup();
  const [, , , getIsLoggedAsGuest] = useGuestId();

  const onClick = React.useCallback(() => {
    if (getIsLoggedAsGuest()) {
      registration.openRegisterPopup();
    } else {
      addFriend({ withUserId: id });
    }
  }, [id, addFriend, getIsLoggedAsGuest, registration]);
  const { t } = useTranslation();
  return (
    <ButtonWithSound
      disabled={fetching}
      className="w-full rounded btn btn-orange-600"
      onClick={onClick}
    >
      {t("add_friend")}
    </ButtonWithSound>
  );
};

const SendMessageButton = ({
  player,
  disabled,
  setDisabled,
}: {
  player: Player;
  disabled: boolean;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const { setOpenedChat, setSendMessage } = useCustomChatContext();
  const userId = useUserId();
  const sendMessage = () => {
    setOpenedChat(true);
    setSendMessage({
      isSending: true,
      channelType: player.friendship.areFriends
        ? "direct_friends"
        : "direct_others",
      users: [player.id, userId],
    });
  };
  return (
    <ButtonWithSound
      className="w-full rounded btn btn-orange-600"
      onClick={sendMessage}
      disabled={disabled}
    >
      {t("send_message")}
    </ButtonWithSound>
  );
};

const BanPlayerButton = ({ id }: { id: string }) => {
  const [{ fetching }, createBan] = useMutation<
    CreateBanMutationMutation,
    CreateBanMutationMutationVariables
  >(CREATE_BAN_MUTATION);
  const registration = useRegisterPopup();
  const [, , , getIsLoggedAsGuest] = useGuestId();

  const onClick = React.useCallback(() => {
    if (getIsLoggedAsGuest()) {
      registration.openRegisterPopup();
    } else {
      createBan({ withUserId: id });
    }
  }, [id, createBan, getIsLoggedAsGuest, registration]);
  const { t } = useTranslation();
  return (
    <ButtonWithSound
      disabled={fetching}
      className="w-full rounded btn btn-orange-600"
      onClick={onClick}
    >
      {t("ban_player")}
    </ButtonWithSound>
  );
};

const CancelFriendshipButton = ({ id }: { id: string }) => {
  const [{ fetching }, cancelFriendship] = useMutation<
    CancelFriendshipMutationMutation,
    CancelFriendshipMutationMutationVariables
  >(CANCEL_FRIENDSHIP_MUTATION);
  const registration = useRegisterPopup();
  const [, , , getIsLoggedAsGuest] = useGuestId();

  const onClick = React.useCallback(() => {
    if (getIsLoggedAsGuest()) {
      registration.openRegisterPopup();
    } else {
      cancelFriendship({ withUserId: id });
    }
  }, [id, cancelFriendship, getIsLoggedAsGuest, registration]);
  const { t } = useTranslation();
  return (
    <ButtonWithSound
      disabled={fetching}
      className="w-full rounded btn btn-red-600"
      onClick={onClick}
    >
      {t("unfriend")}
    </ButtonWithSound>
  );
};

const CancelBanButton = ({ id }: { id: string }) => {
  const [{ fetching }, cancelBan] = useMutation<
    CancelBanMutationMutation,
    CancelBanMutationMutationVariables
  >(CANCEL_BAN_MUTATION);
  const registration = useRegisterPopup();
  const [, , , getIsLoggedAsGuest] = useGuestId();

  const onClick = React.useCallback(() => {
    if (getIsLoggedAsGuest()) {
      registration.openRegisterPopup();
    } else {
      cancelBan({ toUser: id });
    }
  }, [id, cancelBan, getIsLoggedAsGuest, registration]);
  const { t } = useTranslation();
  return (
    <ButtonWithSound
      disabled={fetching}
      className="w-full rounded btn btn-red-600"
      onClick={onClick}
    >
      {t("unban")}
    </ButtonWithSound>
  );
};

const AcceptFriendRequestButtons = ({ id }: { id: string }) => {
  const [{ fetching }, answer] = useMutation<
    AnswerFriendRequestMutation,
    AnswerFriendRequestMutationVariables
  >(ANSWER_FRIEND_REQUEST);
  const pendingFriendRequest = usePendingFriendRequests();
  const hasPendingFriendRequest = React.useMemo(() => {
    if (pendingFriendRequest === undefined) return false;
    return (
      pendingFriendRequest.find(
        (friendRequest) => friendRequest.from.id === id
      ) !== undefined
    );
  }, [pendingFriendRequest, id]);

  const registration = useRegisterPopup();
  const [, , , getIsLoggedAsGuest] = useGuestId();

  const answerFriendRequest = React.useCallback(
    (value: boolean) => {
      if (getIsLoggedAsGuest()) {
        return registration.openRegisterPopup();
      } else {
        return answer({ answer: value, fromUser: id });
      }
    },
    [id, answer, getIsLoggedAsGuest, registration]
  );

  const { t } = useTranslation();
  if (!hasPendingFriendRequest) return null;

  return (
    <div className="flex flex-col items-center w-full">
      <span className="mb-2 font-bold text-white uppercase">
        {t("friend_request")}
      </span>
      <div className="w-full overflow-hidden">
        <div className="flex -mx-1">
          <div className="flex-1 px-1">
            <ButtonWithSound
              className="w-full rounded btn btn-green-600"
              disabled={fetching}
              onClick={() => answerFriendRequest(true)}
            >
              {t("accept")}
            </ButtonWithSound>
          </div>
          <div className="flex-1 px-1">
            <ButtonWithSound
              className="w-full rounded btn btn-gray-500"
              disabled={fetching}
              onClick={() => answerFriendRequest(false)}
            >
              {t("deny")}
            </ButtonWithSound>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProfileActions = ({ player }: { player: Player }) => {
  if (player.friendship.canAskForFriendship)
    return <AddFriendButton id={player.id} />;
  else if (player.friendship.areFriends)
    return <CancelFriendshipButton id={player.id} />;
  else return <AcceptFriendRequestButtons id={player.id} />;
};

const BanPlayerActions = ({ player }: { player: Player }) => {
  if (player.banning.canAskForBanning)
    return <BanPlayerButton id={player.id} />;
  else if (player.banning.areBanned) return <CancelBanButton id={player.id} />;
  else return null;
};

const SendMessageAction = ({
  player,
  isVip,
}: {
  player: Player;
  isVip: boolean;
}) => {
  const [disabled, setDisabled] = React.useState<boolean>(false);
  if (
    (isVip && player.acceptDirectMessages) ||
    (player.acceptDirectMessages && player.friendship.areFriends)
  ) {
    return (
      <SendMessageButton
        player={player}
        disabled={disabled}
        setDisabled={setDisabled}
      />
    );
  }
  return null;
};

const SendBalanceForm = ({
  currency,
  value,
  onSubmit,
}: {
  currency: Currency;
  value: number;
  onSubmit: (value: number) => Promise<any>;
}) => {
  const isMounted = useIsMounted();
  const [countDown, setCountDown] = React.useState<number>(0);
  const { t } = useTranslation();
  const numberFormatter = useNumberFormatter();
  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        forTransfer: Yup.number().min(1).max(value).required(),
      }),
    [value]
  );

  React.useEffect(() => {
    if (countDown < 0) return;
    const id = window.setTimeout(() => {
      setCountDown(countDown - 1);
    }, 1000);

    return () => window.clearTimeout(id);
  }, [countDown]);

  return (
    <Formik
      initialValues={{
        forTransfer: 1,
      }}
      onSubmit={(values, formikHelpers) => {
        setCountDown(SEND_CHIPS_INTERVAL / 1000);
        return new Promise<any>((resolve) => {
          window.setTimeout(resolve, SEND_CHIPS_INTERVAL);
        }).then(() => {
          if (isMounted.current)
            return onSubmit(values.forTransfer).finally(() =>
              formikHelpers.resetForm()
            );
          else return Promise.reject();
        });
      }}
      validationSchema={validationSchema}
    >
      {({
        values,
        isValid,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
      }) => {
        return (
          <form onSubmit={handleSubmit} className="flex flex-col items-center">
            <ErrorMessage
              name="forTransfer"
              className="mt-2 font-bold text-red-600"
              component="div"
            />
            <span className="mb-2 font-bold text-white uppercase">
              {t("send_chips")}
            </span>
            <Slider
              min={1}
              max={value}
              value={
                typeof values.forTransfer !== "number" ? 1 : values.forTransfer
              }
              disabled={isSubmitting}
              onChange={(value) => {
                setFieldTouched("forTransfer", true);
                setFieldValue("forTransfer", value);
              }}
            />
            <Field
              disabled={isSubmitting}
              name="forTransfer"
              type="number"
              className="w-2/5 py-0 text-sm text-center text-black rounded shadow-xl form-input"
            />
            <ButtonWithSound
              disabled={isSubmitting || !isValid}
              type="submit"
              className="flex-shrink-0 w-full mt-4 rounded btn btn-blue-600"
            >
              {countDown > 0
                ? t("sending_chips_button_text", {
                    counter: countDown,
                  })
                : t("send_chips_button_text", {
                    value: numberFormatter.format(values.forTransfer),
                    currency: t(
                      `currency.${currency.toString().toLowerCase()}`
                    ),
                  })}
            </ButtonWithSound>
          </form>
        );
      }}
    </Formik>
  );
};

function CanSendChipsValidatorError({
  error,
}: {
  error: NonNullable<Player["canSendChips"]>;
}) {
  const { t } = useTranslation();
  const formatter = useNumberFormatter();
  return (
    <div className="p-2 mt-2 text-center text-white bg-red-700 rounded">
      {error.numberOfGames !== null
        ? t("send_chips_disabled_due_to_minimum_games", {
            minimumGames: error.numberOfGames,
          })
        : error.maximumChips !== null
        ? t("send_chips_disabled_due_to_maximum_balance", {
            maximumBalance: formatter.format(error.maximumChips),
            currency: t("currency.blue_chips"),
          })
        : null}
    </div>
  );
}

const Balances = ({ player }: { player: Player }) => {
  const {
    user: { id, balances },
  } = useUser();

  const availableBalanceForTransfer = balances.find(
    ({ currency }) => currency === Currency.BlueChips
  )!;
  const [, sendBalance] = useMutation<
    SendBalanceMutation,
    SendBalanceMutationVariables
  >(SEND_BALANCE_MUTATION, false);
  const { t } = useTranslation();
  return (
    <div className="flex flex-col h-full">
      <div className="flex-1">
        <div className="mb-4">
          <Versus nameA={t("you")} nameB={player.name} />
        </div>
        <div className="bg-blue-900 rounded">
          <BalanceStatisticsCompare me={balances} other={player.balances} />
        </div>
      </div>
      <div className="flex-shrink-0">
        {player.canSendChips !== null ? (
          <CanSendChipsValidatorError error={player.canSendChips} />
        ) : (
          availableBalanceForTransfer.value > 0 &&
          player.id !== id && (
            <SendBalanceForm
              {...availableBalanceForTransfer}
              onSubmit={(amount) => {
                return sendBalance({
                  input: {
                    playerId: player.id,
                    items: [
                      {
                        currency: availableBalanceForTransfer.currency,
                        amount,
                      },
                    ],
                  },
                });
              }}
            />
          )
        )}
      </div>
    </div>
  );
};

const CompareStatisticsPanel = ({
  player,
  games,
  tournament,
}: {
  player: Player;
  games: Array<string>;
  tournament: boolean;
}) => {
  const myStatistics = useMyStatistics("cache-and-network");
  const {
    user: { balances, vipUntil, isChatEnabled },
  } = useUser();
  const { t } = useTranslation();
  if (myStatistics === null)
    return (
      <AbsoluteCenter>
        <Loader description={t("loading_my_statistics")} />
      </AbsoluteCenter>
    );

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1">
        <PlayerStatisticsCompare
          other={{
            balances: player.balances,
            gaming: player.statistics,
            name: player.name,
          }}
          me={{ balances, gaming: myStatistics, name: t("you") }}
          games={games}
          tournament={tournament}
        />
      </div>
      {isChatEnabled && (
        <div className="flex-shrink-0">
          <SendMessageAction
            player={player}
            isVip={
              vipUntil != null &&
              new Date(vipUntil).getTime() > new Date().getTime()
            }
          />
        </div>
      )}
      <div className="flex-shrink-0 pt-6">
        <ProfileActions player={player} />
      </div>
      <div className="flex-shrink-0 pt-6">
        <BanPlayerActions player={player} />
      </div>
    </div>
  );
};

const RenameUser = () => {
  const { t } = useTranslation();
  const nameRequiredField = React.useMemo(() => t("name_required_field"), [t]);
  const nameIsTooLong = React.useMemo(() => t("max_name_length"), [t]);
  const [, renameUser] = useMutation<
    RenameUserMutation,
    RenameUserMutationVariables
  >(RENAME_USER_MUTATION, false);

  const {
    user: { name },
  } = useUser();

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .min(1)
          .max(128, t("max_name_length"))
          .required(t("name_required_field")),
      }),
    [t]
  );

  return (
    <Formik
      initialValues={{
        name: name,
      }}
      onSubmit={(values, formikHelpers) => {
        renameUser({ newName: values.name }).then((result) => {
          if (result.error) {
            const resultErrorMessage = result.error.message;

            const errorMessage = resultErrorMessage.includes(
              "Name cannot be empty"
            )
              ? nameRequiredField
              : resultErrorMessage.includes("Name is too long")
              ? nameIsTooLong
              : resultErrorMessage.substring(0, 48);
            formikHelpers.setSubmitting(false);
            formikHelpers.setFieldError("name", errorMessage);
          } else {
            formikHelpers.resetForm();
            formikHelpers.setFieldValue("name", values.name);
          }
        });
      }}
      validationSchema={validationSchema}
    >
      {({
        values,
        isValid,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
      }) => {
        return (
          <form onSubmit={handleSubmit} className="block mt-4">
            <Field
              disabled={isSubmitting}
              name="name"
              type="string"
              className="w-3/5 py-0 text-lg text-black rounded shadow-xl form-input border-2 border border-black border-solid"
            />
            <button
              type="submit"
              className={classNames(
                "ml-2 text-white",
                "transition-all",
                "duration-300",
                "ease-in-out",
                "text-white",
                "font-bold",
                "py-1",
                "px-4",
                "rounded",
                "bg-gray-500",
                {
                  "bg-blue-500 hover:bg-blue-700": !isSubmitting && isValid,
                }
              )}
            >
              {t("change_name")}
            </button>
            <ErrorMessage
              name="name"
              className="mt-1 text-sm text-red-600"
              component="div"
            />
          </form>
        );
      }}
    </Formik>
  );
};

const AvailableForDirectChat = () => {
  const { t } = useTranslation();
  const { user } = useUser();

  const [, setAcceptDirectMessages] = useMutation<
    UpsertAcceptDirectMessagesMutation,
    UpsertAcceptDirectMessagesMutationVariables
  >(UPDATE_ACCEPT_DIRECT_MESSAGES_MUTATION);

  return (
    <div className={styles.availableForDirectChatContainer}>
      <img
        src={
          user.acceptDirectMessages ? directChatOnButton : directChatOffButton
        }
        alt="direct-chat-icon"
        className="h-8 cursor-pointer w-15"
        onClick={() =>
          setAcceptDirectMessages({
            acceptDirectMessages:
              user.acceptDirectMessages === true ? false : true,
          })
        }
      />
      <p className="ml-5">{t("available_for_direct_chat")}</p>
    </div>
  );
};

const ChatThemeChoosing = () => {
  const [{ fetching }, upsertTheme] = useMutation<
    UpsertChatThemeMutation,
    UpsertChatThemeMutationVariables
  >(UPSERT_CHAT_THEME_MUTATION);
  const { user } = useUser();
  return (
    <div className="flex items-center my-4">
      <div className="mr-8">Chat Theme</div>
      <div className="flex justify-center align-middle">
        <button
          className={classNames(
            user.chatTheme === ChatTheme.Contrast
              ? styles.activeThemeColor
              : "",
            "rounded-full mr-10 "
          )}
          onClick={() => {
            upsertTheme({ chatTheme: ChatTheme.Contrast });
          }}
          disabled={fetching || user.chatTheme === ChatTheme.Contrast}
        >
          <div
            className={classNames(
              styles.themeChooseColor,
              "rounded-full",
              user.chatTheme === ChatTheme.Contrast ? styles.activeCircle : ""
            )}
            style={{ backgroundColor: "#196FFF" }}
          />
        </button>
        <button
          className={classNames(
            user.chatTheme === ChatTheme.Dark ? styles.activeThemeColor : "",
            "rounded-full mr-10 "
          )}
          onClick={() => {
            upsertTheme({ chatTheme: ChatTheme.Dark });
          }}
          disabled={fetching || user.chatTheme === ChatTheme.Dark}
        >
          <div
            className={classNames(
              styles.themeChooseColor,
              "rounded-full",
              user.chatTheme === ChatTheme.Dark ? styles.activeCircle : ""
            )}
            style={{ backgroundColor: "#21327A" }}
          />
        </button>
        <button
          className={classNames(
            user.chatTheme === ChatTheme.Light ? styles.activeThemeColor : "",
            "rounded-full"
          )}
          onClick={() => {
            upsertTheme({ chatTheme: ChatTheme.Light });
          }}
          disabled={fetching || user.chatTheme === ChatTheme.Light}
        >
          <div
            className={classNames(
              styles.themeChooseColor,
              "rounded-full",
              user.chatTheme === ChatTheme.Light ? styles.activeCircle : ""
            )}
            style={{ backgroundColor: "#C3ECF7" }}
          />
        </button>
      </div>
    </div>
  );
};

const GamesStatisticsPanel = ({
  player,
  games,
  tournament,
}: {
  player: Player;
  games: Array<string>;
  tournament: boolean;
}) => {
  const id = useUserId();
  if (player.id === id)
    return (
      <>
        <PlayerStatisticsCompare
          other={{
            balances: player.balances,
            gaming: player.statistics,
            name: player.name,
          }}
          me={null}
          games={games}
          tournament={tournament}
        />
      </>
    );
  else
    return (
      <CompareStatisticsPanel
        player={player}
        games={games}
        tournament={tournament}
      />
    );
};

const SendMessage = React.memo<{ id: string }>(({ id }) => {
  const inTable = useIsInTable();
  const [{ fetching }, sendMessage] = useMutation<
    SendMessageMutationMutation,
    SendMessageMutationMutationVariables
  >(SEND_MESSAGE_MUTATION);
  const isMounted = useIsMounted();
  const [message, setMessage] = React.useState<string>("");
  const { t } = useTranslation();
  return (
    <div className="flex flex-col h-full">
      <div className="flex-1">
        <textarea
          disabled={fetching || inTable}
          className="block w-full bg-transparent bg-blue-900 border-none rounded form-textarea"
          rows={10}
          placeholder={t("your_message")}
          value={message}
          onChange={(event) => {
            let message = event.target.value;
            if (message.length > USER_MESSAGE_MAX_LENGTH)
              message = message.substring(0, USER_MESSAGE_MAX_LENGTH);
            setMessage(message);
          }}
        />
        {inTable && (
          <div
            className="flex items-center px-4 py-3 mt-4 text-sm font-bold text-white bg-red-600"
            role="alert"
          >
            {t("message_disabled_because_in_table")}
          </div>
        )}
      </div>
      <div className="flex-shrink-0">
        <ButtonWithSound
          disabled={fetching || !/\S/.test(message) || inTable}
          className="w-full rounded btn btn-blue-600"
          onClick={() =>
            sendMessage({ input: { message: message, playerId: id } }).finally(
              () => {
                if (isMounted.current) setMessage("");
              }
            )
          }
        >
          {t("send_message")}
        </ButtonWithSound>
      </div>
    </div>
  );
});

const Settings = React.memo(() => {
  const deleteMyProfile = useDeleteMePanel();
  const [, , , getIsLoggedAsGuest] = useGuestId();
  const { t } = useTranslation();
  const {
    user: { vipUntil },
  } = useUser();

  const isVip = React.useMemo(() => {
    return (
      vipUntil != null && new Date(vipUntil).getTime() > new Date().getTime()
    );
  }, [vipUntil]);

  return (
    <div className="flex flex-col h-full">
      <AvailableForDirectChat />
      <ChatThemeChoosing />
      {!getIsLoggedAsGuest() && (
        <div className="flex mt-4" onClick={deleteMyProfile}>
          <img className="mr-2" src={deleteBucket} alt="" />
          <span>{t("delete_me")}</span>
        </div>
      )}
      {isVip && <RenameUser />}
    </div>
  );
});

const TABS: Array<TabType> = ["statistics"];
const OTHER_TABS: Array<TabType> = [...TABS, "transfer"];
const FRIEND_TABS: Array<TabType> = [...OTHER_TABS, "message"];
const MY_TABS: Array<TabType> = [...TABS, "avatar", "settings"];

const ProfileTabs = ({
  player,
  tab,
  games,
  tournament,
}: {
  player: Player;
  games: Array<string>;
  tab?: TabType;
  tournament: boolean;
}) => {
  const myId = useUserId();
  const { t } = useTranslation();
  const finalTabs =
    myId === player.id
      ? MY_TABS
      : player.friendship.areFriends
      ? FRIEND_TABS
      : OTHER_TABS;

  //Math.max to handle the case that we want to open message tab but the user is not a friend any more
  //Original indexOf will return -1 and we will set it back to 0
  const [tabIndex, setTabIndex] = React.useState(
    tab !== undefined ? Math.max(finalTabs.indexOf(tab), 0) : 0
  );

  React.useEffect(() => {
    if (tabIndex > finalTabs.length - 1) setTabIndex(0);
  }, [tabIndex, finalTabs]);

  return (
    <Tabs
      className="h-full overflow-hidden"
      index={tabIndex}
      onChange={setTabIndex}
    >
      <div className="flex flex-col h-full text-white">
        <div
          className={classNames(
            "flex",
            "items-center",
            "flex-shrink-0",
            styles.header
          )}
        >
          <TabList className="flex-1">
            {finalTabs.map((tab, index) => (
              <Tab
                key={index}
                className={classNames(
                  "px-4",
                  "py-3",
                  "border-r",
                  "border-black",
                  styles.headerButton,
                  {
                    "w-1/3": finalTabs.length === 3,
                    "w-1/2": finalTabs.length === 2,
                    [styles.active]: index === tabIndex,
                  }
                )}
              >
                {t(tab)}
              </Tab>
            ))}
          </TabList>
        </div>
        <TabPanels className="flex-1 overflow-hidden">
          <TabPanel className="relative h-full p-4">
            {/*Delay rendering statistics component until its active.
                  This way we will avoid executing the query if not needed*/}
            {tabIndex === finalTabs.indexOf("statistics") && (
              <GamesStatisticsPanel
                player={player}
                games={games}
                tournament={tournament}
              />
            )}
          </TabPanel>
          {finalTabs.indexOf("transfer") >= 0 && (
            <TabPanel className="relative h-full p-4">
              <Balances player={player} />
            </TabPanel>
          )}
          {finalTabs.indexOf("message") >= 0 && (
            <TabPanel className="relative h-full p-4">
              <SendMessage id={player.id} />
            </TabPanel>
          )}
          {finalTabs.indexOf("avatar") >= 0 && (
            <TabPanel className="relative h-full p-4">
              {/*Delay rendering avatars component until its active.
                  This way we will avoid executing the query if not needed*/}
              {tabIndex === finalTabs.indexOf("avatar") && <Avatars />}
            </TabPanel>
          )}
          {finalTabs.indexOf("settings") >= 0 && (
            <TabPanel className="h-full p-4">
              {/*Delay rendering settings component until its active.
                  This way we will avoid executing the query if not needed*/}
              {tabIndex === finalTabs.indexOf("settings") && (
                <DeleteMePanelProvider>
                  <Settings />
                </DeleteMePanelProvider>
              )}
            </TabPanel>
          )}
        </TabPanels>
      </div>
    </Tabs>
  );
};

export const PlayerProfile = ({
  profile,
  close,
}: {
  profile: Profile;
  close: () => void;
}) => {
  const screenType = useScreenType();
  const { user } = useUser();
  const [{ data }] = useQuery<PlayerQuery, PlayerQueryVariables>({
    query: PLAYER_QUERY,
    variables: { userId: profile.id },
    requestPolicy: "cache-and-network",
  });

  const player = React.useMemo(() => data?.player ?? null, [data?.player]);
  const isMyId = React.useMemo(() => user.id === player?.id, [user.id, player]);
  const userName = React.useMemo(() => (isMyId ? user.name : profile.name), [
    isMyId,
    user.name,
    profile.name,
  ]);

  const { t } = useTranslation();
  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between">
        <div className="flex flex-shrink-0 p-4 -mx-2 justify-items-center">
          <div className="flex items-center flex-shrink-0 px-2">
            <ProfileImage
              className="bg-white rounded"
              id={profile.id}
              name={profile.name}
              width={52}
              height={52}
            />
          </div>
          <div className="flex flex-col justify-between flex-1 px-2 overflow-hidden text-white">
            <div className="flex -mx-2">
              <div className="flex items-center flex-1 px-2 text-lg font-bold truncate">
                {userName}
                {player && (
                  <div className="pl-2">
                    <Presence presence={player.presence} />
                  </div>
                )}
              </div>
            </div>
            {player === null ? (
              <span className="text-sm">{t("loading")}</span>
            ) : player.country === null ? (
              <span className="text-sm">{t("unknown_country")}</span>
            ) : (
              <div className="flex items-center -mx-1">
                <div className="px-1">
                  <Flag country={player.country} />
                </div>
                <span className="px-1 text-sm">
                  {t(`country.${player.country}`)}
                </span>
              </div>
            )}
            {isMyId && <span className="text-xs">ID: {user.id}</span>}
          </div>
        </div>
        <div
          className={classNames(
            "flex justify-between text-white",
            screenType === "DESKTOP" ? "px-4" : ""
          )}
        >
          <div className="flex items-center w-full h-full mr-2">
            {player?.vipUntil &&
              new Date(player.vipUntil).getTime() > new Date().getTime() && (
                <img src={vipIcon} alt="vip-icon" className="w-16 h-16" />
              )}
          </div>
          <button
            onClick={close}
            className="items-start flex-shrink-0 w-6 h-6 px-2 my-4"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      </div>
      {player === null ? (
        <AbsoluteCenter>
          <Loader description={t("loading_profile")} />
        </AbsoluteCenter>
      ) : (
        <ProfileTabs
          player={player}
          tab={profile.tab}
          games={profile.games}
          tournament={profile.tournament}
        />
      )}
    </div>
  );
};
