import { useAvatars } from "../../hooks/useAvatars";
import { useTranslation } from "react-i18next";
import { AbsoluteCenter } from "../AbsoluteCenter";
import { Loader } from "../Loader";
import * as React from "react";
import { loader } from "graphql.macro";
import { Currency } from "../../graphql/generated";
import classNames from "classnames";
import { Amount } from "../Amount";
import { useUser } from "../UserProvider";
import { useMutation } from "../../hooks/useMutation";
import styles from "../Games/common/game/SendGift/SendGift.module.scss";
import { useProfilePictureReload } from "../../hooks/useProfilePictureReload";
import { ButtonWithSound } from "../AudioProvider";
import addAvatar from "./add-avatar-button.png";
import {
  UpsertProfilePhotoMutation,
  UpsertProfilePhotoMutationVariables,
} from "./graphql/UploadPhotoMutation.generated";
import {
  UseAvatarMutationMutation,
  UseAvatarMutationMutationVariables,
} from "./graphql/UseAvatarMutation.generated";
import {
  AvatarsQueryQuery,
  AvatarsQueryQueryVariables,
} from "../../graphql/AvatarsQuery.generated";
import { useQuery } from "../../hooks/useQuery";
import { OperationContext } from "urql";

const UPSERT_PHOTO_MUTATION = loader("./graphql/UploadPhotoMutation.graphql");
const AVATARS_QUERY = loader("../../graphql/AvatarsQuery.graphql");

const AVATAR_WIDTH = 64;
const AVATAR_HEIGHT = 64;

const USE_AVATAR_MUTATION = loader("./graphql/UseAvatarMutation.graphql");

const AvatarContainer = ({
  active,
  url,
  name,
  setActive,
  price,
}: {
  active: boolean;
  url: string;
  name: string;
  price: {
    value: number;
    currency: Currency;
  } | null;
  setActive: () => void;
}) => {
  return (
    <button className="block w-full" onClick={setActive}>
      <div className="flex items-center justify-center mb-3">
        <div
          style={{ width: AVATAR_WIDTH, height: AVATAR_HEIGHT }}
          className={classNames(
            "overflow-hidden",
            "box-content",
            "rounded",
            "transition-colors",
            "duration-300",
            "ease-in-out",
            "bg-gray-200",
            "border-4",
            "shadow",
            { "border-orange-500": active },
            { "border-blue-900": !active }
          )}
        >
          <img
            width={AVATAR_WIDTH}
            height={AVATAR_HEIGHT}
            src={url}
            alt={name}
          />
        </div>
      </div>
      {price && (
        <div className="pb-1">
          <Amount value={price.value} currency={price.currency} />
        </div>
      )}
    </button>
  );
};

const HaveAvatars = ({
  avatars,
  fetchAvatars,
}: {
  avatars: NonNullable<ReturnType<typeof useAvatars>>;
  fetchAvatars: (opts?: Partial<OperationContext> | undefined) => void;
}) => {
  const {
    user: { balances },
    user,
  } = useUser();
  const [, reload] = useProfilePictureReload();
  const [{ fetching }, executeUseAvatar] = useMutation<
    UseAvatarMutationMutation,
    UseAvatarMutationMutationVariables
  >(USE_AVATAR_MUTATION);
  const defaultActive = React.useMemo(
    () => avatars.find((avatar) => avatar.active),
    [avatars]
  );
  const { t } = useTranslation();
  const [active, setActive] = React.useState<number | undefined>(
    defaultActive !== undefined ? avatars.indexOf(defaultActive) : undefined
  );

  const [customActive, setCustomActive] = React.useState<boolean>(false);
  const addCustom = () => {
    setCustomActive(true);
    setActive(undefined);
  };

  const [, upsertPhoto] = useMutation<
    UpsertProfilePhotoMutation,
    UpsertProfilePhotoMutationVariables
  >(UPSERT_PHOTO_MUTATION);

  const chooseAvatar = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const file = (target.files as FileList)[0];
    if (file.size < 1200000) {
      await upsertPhoto({ input: { file: file, id: `${user.id}` } })
        .then(() => fetchAvatars())
        .then(reload);
    }
  };

  const canUse = React.useMemo(() => {
    if (active === undefined || avatars[active] === defaultActive) return false;

    const avatar = avatars[active];
    if (avatar.price === null) return true;
    else {
      const userBalance = balances.find(
        (balance) => balance.currency === avatar.price!.currency
      );

      return (
        userBalance !== undefined && userBalance.value >= avatar.price.value
      );
    }
  }, [active, avatars, balances, defaultActive]);

  const isVip =
    user.vipUntil != null &&
    new Date(user.vipUntil).getTime() > new Date().getTime();

  return (
    <>
      <div className="flex flex-col h-full">
        <div
          className={classNames("flex-1", "overflow-auto", styles.scrollable)}
        >
          <div className="overflow-hidden">
            <div className="flex flex-wrap -mb-4 -mx-2">
              {isVip && (
                <div className="w-1/3 mb-4 px-2">
                  <div className="flex items-center justify-center mb-3">
                    <div
                      style={{
                        width: AVATAR_WIDTH,
                        height: AVATAR_HEIGHT,
                        backgroundColor: "#10478f",
                      }}
                      onClick={addCustom}
                      className={classNames(
                        "box-content",
                        "rounded",
                        "transition-colors",
                        "duration-300",
                        "ease-in-out",
                        "border-4",
                        "shadow",
                        { "border-orange-500": customActive },
                        { "border-blue-900": !customActive }
                      )}
                    >
                      <label htmlFor="loadImg" className="cursor-pointer">
                        <img
                          src={addAvatar}
                          alt="Add avatar"
                          width={AVATAR_WIDTH}
                          height={AVATAR_HEIGHT}
                          draggable={false}
                        />
                      </label>
                      <input
                        type="file"
                        id="loadImg"
                        className="hidden"
                        required
                        onClick={(e: any) => {
                          e.target.value = null;
                        }}
                        onChange={chooseAvatar}
                        accept="image/*"
                      />
                    </div>
                  </div>
                </div>
              )}
              {avatars.map((avatar, index) => (
                <div className="w-1/3 mb-4 px-2" key={avatar.id}>
                  <AvatarContainer
                    {...avatar}
                    setActive={() => {
                      setActive(index);
                      setCustomActive(false);
                    }}
                    active={active === index}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        {active !== undefined && (
          <div className="flex-shrink-0 mt-4">
            <ButtonWithSound
              className="btn btn-blue-600 w-full rounded"
              disabled={!canUse || fetching}
              onClick={() =>
                executeUseAvatar({
                  input: {
                    id: avatars[active].id,
                    //@ts-ignore
                    avatarType: avatars[active].photoType,
                  },
                }).then(reload)
              }
            >
              {t(
                avatars[active].price === null
                  ? "activate"
                  : "purchase_and_activate"
              )}
            </ButtonWithSound>
          </div>
        )}
      </div>
    </>
  );
};

export const Avatars = React.memo(() => {
  const [{ data }, fetchAvatars] = useQuery<
    AvatarsQueryQuery,
    AvatarsQueryQueryVariables
  >({
    query: AVATARS_QUERY,
    requestPolicy: "network-only",
  });
  const avatars =
    data === undefined ? undefined : data?.me?.avatars ?? undefined;
  const { t } = useTranslation();
  if (avatars === undefined)
    return (
      <AbsoluteCenter>
        <Loader description={t("loading_avatars")} />
      </AbsoluteCenter>
    );
  else if (avatars.length === 0)
    return (
      <AbsoluteCenter>
        <div className="p-4 text-center rounded bg-red-700">
          <span className="text-white font-bold block">
            {t("no_avatars_available")}
          </span>
        </div>
      </AbsoluteCenter>
    );
  return <HaveAvatars avatars={avatars} fetchAvatars={fetchAvatars} />;
});
