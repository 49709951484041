import * as React from "react";
import { useQuery } from "../../hooks/useQuery";
import { loader } from "graphql.macro";
import { Loader } from "../Loader";
import { useTranslation } from "react-i18next";
import { Layout } from "../Layout";
import { Header } from "../Header";
import classNames from "classnames";
import { AbsoluteCenter } from "../AbsoluteCenter";
import styles from "./Lobby.module.scss";
import {
  isNotMainScreenDesktopWidth,
  splitToChunks,
  getRoomImageSize,
  isNotEmptyString,
} from "../../utils";
import { useNumberFormatter } from "../../hooks/useNumberFormatter";
import {
  LobbyRoomsQueryQuery,
  LobbyRoomsQueryQueryVariables,
} from "./graphql/RoomsQuery.generated";
import {
  MainScreenItemListQuery,
  MainScreenItemListQueryVariables,
} from "./graphql/NewsQuery.generated";
import { Image } from "../Image";
import { LinkWithSound } from "../AudioProvider";
import { Marquee } from "../MarqueeProvider";
import { useShop } from "../Shop";
import { useLocation } from "react-router-dom";
import { Currency, SettingCategory } from "../../graphql/generated";
import Balances from "../Balances";
import HeaderRight from "../Header/HeaderRight";
import newsBackground from "../../../public/news-background.png";
import chessKnightIcon from "./assets/chess_knight_icon.png";
import usersIcon from "./assets/users_icon.png";
import blueAarrowLeft from "./assets/blue_arrow_left.png";
import blueArrowRight from "./assets/blue_arrow_right.png";
import blueArrowLeftHovered from "./assets/blue_arrow_left_hovered.png";
import blueArrowRightHovered from "./assets/blue_arrow_right_hovered.png";
import darkArrowLeft from "./assets/dark_arrow_left.png";
import darkArrowRight from "./assets/dark_arrow_right.png";
import darkArrowLeftHovered from "./assets/dark_arrow_left_hovered.png";
import darkArrowRightHovered from "./assets/dark_arrow_right_hovered.png";
import appleStoreRoom from "./assets/apple_store_room_icon.png";
import googlePlayRoom from "./assets/google_play_room_icon.png";
import appleStoreSection from "./assets/apple_store_section_icon.png";
import googlePlaySection from "./assets/google_play_section_icon.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "react-spring-3d-carousel";
import { config } from "react-spring";
import { CSSTransition } from "react-transition-group";
import "./Animations.css";
import { HangmanChallenge } from "./ChallengeButton/HangmanChallenge";
import { useRightBar } from "../Bar/RightBar";
import { useScreenType } from "../../hooks/useScreenType";

const RightBar = React.lazy(() => import("../Bar/RightBar/Bar"));

const ROOMS_QUERY = loader("./graphql/RoomsQuery.graphql");
const NEWS_QUERY = loader("./graphql/NewsQuery.graphql");

export const ROOM_IMAGE_WIDTH = 260;
export const ROOM_IMAGE_HEIGHT = 140;

type Rooms = NonNullable<LobbyRoomsQueryQuery["rooms"]>;
type Room = Rooms[0];
type Section = Room["sections"][0] & { isChallenge: boolean };

const Arrow = (props: any) => {
  const { className, style, onClick } = props;
  const [hovered, setHovered] = React.useState(false);

  return (
    <div
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
    >
      {hovered ? (
        <Image
          src={props.imageHovered}
          alt="arrow"
          className={classNames(props.customStyleClass, {
            ...className,
          })}
          style={{ zIndex: props?.zIndex, ...style }}
        />
      ) : (
        <Image
          src={props.image}
          alt="arrow"
          className={classNames(props.customStyleClass, {
            ...className,
          })}
          style={{ zIndex: props?.zIndex, ...style }}
        />
      )}
    </div>
  );
};

const LogoImage = ({ url }: { url: string }) => {
  return (
    <div className="text-white inline-block rounded-md bg-blue-700 overflow-hidden shadow-2xl">
      <div className="relative" style={getRoomImageSize()}>
        <React.Suspense
          fallback={
            <AbsoluteCenter>
              <Loader description="" />
            </AbsoluteCenter>
          }
        >
          <div
            className={classNames(
              "bg-black",
              "absolute top-0",
              "left-0",
              "right-0",
              "bottom-0",
              "transition-opacity",
              "ease-in-out",
              "duration-300"
            )}
          >
            <Image
              draggable={false}
              src={`${process.env.REACT_APP_API_HOST}/rooms/${url}`}
              alt=""
              className={styles.roomImage}
              style={getRoomImageSize()}
            />
          </div>
          <div className={classNames(styles.roomImageGradient)}></div>
        </React.Suspense>
      </div>
    </div>
  );
};

const RegularSectionData = ({
  section,
  isCurrent,
}: {
  section: Section;
  isCurrent: boolean;
}) => {
  const { t } = useTranslation();
  const roomId = section.id.split(":")[0];

  return (
    <div className="relative" style={getRoomImageSize()}>
      {isCurrent && (
        <div className={styles.sectionInnerContainer}>
          {section.googlePlayLink && (
            <a
              href={section.googlePlayLink}
              target="_blank"
              rel="noreferrer"
              className={styles.sectionStoreIcon}
            >
              <Image src={googlePlaySection} alt="google_play_icon" />
            </a>
          )}
          {section.appStoreLink && (
            <a
              href={section.appStoreLink}
              target="_blank"
              rel="noreferrer"
              className={styles.sectionStoreIcon}
            >
              <Image src={appleStoreSection} alt="apple_store_icon" />
            </a>
          )}
          <LinkWithSound
            to={`/area/${section.id}`}
            className={styles.playTextContainer}
          >
            <p className={styles.playText}> {t("play")}</p>
          </LinkWithSound>
        </div>
      )}
      <LogoImage url={`${roomId}/${section.logo}`} />
    </div>
  );
};

const SectionData = ({
  section,
  isCurrent,
}: {
  section: Section;
  isCurrent: boolean;
}) => {
  if (section.isChallenge) {
    return <HangmanChallenge sectionId={section.id} />;
  }

  return <RegularSectionData section={section} isCurrent={isCurrent} />;
};

const Sections = ({ sections }: { sections: Array<Section> }) => {
  const [currentSection, setCurrentSection] = React.useState<number>(0);
  const lastSectionIndex = React.useMemo(
    () => (sections.length === 2 ? 3 : sections.length - 1),
    [sections]
  );

  const isPrevious = React.useCallback(
    (idx: number) => {
      if (sections.length === 1) {
        return false;
      }
      if (currentSection === 0) {
        return idx === lastSectionIndex;
      }
      return idx === currentSection - 1;
    },
    [sections, currentSection, lastSectionIndex]
  );

  const isNext = React.useCallback(
    (idx: number) => {
      if (sections.length === 1) {
        return false;
      }
      if (currentSection === lastSectionIndex) {
        return idx === 0;
      }
      return idx === currentSection + 1;
    },
    [sections, currentSection, lastSectionIndex]
  );

  const sectionsArray = React.useMemo(() => {
    let finalSections = sections;
    //In case when sections have 2 elements carousel will not show non-active element, so we need to handle this case specifically
    if (sections.length === 2) {
      finalSections = sections.concat(sections);
    }
    let result = finalSections
      .filter((s) => s.logo !== undefined && s.logo !== null)
      .map((section, index) => {
        return {
          key: index,
          content: (
            <div
              className={
                isPrevious(index)
                  ? styles.previousSection
                  : isNext(index)
                  ? styles.nextSection
                  : index === currentSection
                  ? styles.currentSection
                  : "hidden"
              }
            >
              <SectionData
                section={section}
                isCurrent={index === currentSection}
              />
            </div>
          ),
        };
      });

    return result;
  }, [sections, currentSection, isPrevious, isNext]);

  const goToNext = () =>
    setCurrentSection((prev) => {
      if (prev === sectionsArray.length - 1) return 0;
      return prev + 1;
    });

  const goToPrevious = () =>
    setCurrentSection((prev) => {
      if (prev === 0) {
        return sectionsArray.length - 1;
      }
      return prev - 1;
    });

  const offsetFn = (offsetFromCenter: number, index: number) => {
    if (offsetFromCenter > 1 || offsetFromCenter < -1) {
      return {
        opacity: 0,
      };
    }
    //Special case when have 2 sections (see sectionsArray variable initialization)
    if (offsetFromCenter !== 0 && sections.length === 2) {
      //If current element is with event number index so do not show previos slide
      if (currentSection % 2 === 0 && offsetFromCenter === -1) {
        return {
          opacity: 0,
        };
      } else if (currentSection % 2 === 1 && offsetFromCenter === 1) {
        return {
          opacity: 0,
        };
      }
    }
    if (offsetFromCenter === -1) {
      return {
        opacity: 0.8,
        transform: "translateY(-57%) translateX(-52%) scale(1)",
        left: "49%",
      };
    }
    if (offsetFromCenter === 1) {
      return {
        opacity: 0.8,
        transform: "translateY(-57%) translateX(-48%) scale(1)",
        left: "51%",
      };
    }

    return {};
  };

  const { width: imageWidth, height: imageHeight } = getRoomImageSize();

  const Dot = ({ isSelected }: { isSelected: () => boolean }) => (
    <div
      style={{
        background: isSelected() ? "white" : "",
        width: "6px",
        height: "6px",
        borderRadius: "6px",
        border: "1px solid white",
        margin: 4,
      }}
    />
  );

  return (
    <div className="sectionsContainer">
      {sectionsArray.length > 1 && (
        <Arrow
          image={darkArrowLeft}
          imageHovered={darkArrowLeftHovered}
          customStyleClass={styles.prevSectionArrow}
          onClick={goToPrevious}
        />
      )}
      {sectionsArray.length > 1 && (
        <Arrow
          image={darkArrowRight}
          imageHovered={darkArrowRightHovered}
          customStyleClass={styles.nextSectionArrow}
          onClick={goToNext}
        />
      )}
      {sectionsArray.length > 1 && (
        <div className={styles.dotsContainer}>
          {sections.map((_s, i) => {
            const isSelected =
              sections.length === 2
                ? () => currentSection % 2 === i
                : () => i === currentSection;

            return (
              <div key={i}>
                <Dot isSelected={isSelected} />
              </div>
            );
          })}
        </div>
      )}

      <div
        className={classNames(styles.sections)}
        style={{
          width: imageWidth * 1.17,
          height: imageHeight * 1.2,
          top: "-8%",
          left: "-9%",
        }}
      >
        <Carousel
          slides={sectionsArray}
          goToSlide={currentSection}
          goToSlideDelay={500}
          offsetRadius={3}
          showNavigation={false}
          animationConfig={config.slow}
          offsetFn={offsetFn}
        />
      </div>
    </div>
  );
};

const RoomInfo = ({ room, hovered }: { room: Room; hovered: boolean }) => {
  const storesRef = React.useRef(null);
  const numbersRef = React.useRef(null);
  const { googlePlayLink, appStoreLink } = room;
  const doHaveLinks =
    isNotEmptyString(googlePlayLink) && isNotEmptyString(appStoreLink);

  const { width } = getRoomImageSize();
  const openTables = React.useMemo(() => {
    let count = 0;
    for (const section of room.sections) count += section.count;
    return count;
  }, [room.sections]);
  const numberFormatter = useNumberFormatter();

  const roundNumber = (num: number): string => {
    if (num > 0) {
      if (num > 1000) {
        return numberFormatter.format(Math.round(num / 1000)) + "K";
      }
      return numberFormatter.format(num);
    }
    return numberFormatter.format(0);
  };

  return (
    <div
      style={{
        width: width - 16,
        height: 28,
        position: "absolute",
      }}
      className={classNames("overflow-hidden", styles.roomInfoContainer)}
    >
      <div className={classNames("mr-1", styles.roomInfoName)}>{room.name}</div>
      <div className={styles.roomInfoRightContainer}>
        <CSSTransition
          in={(doHaveLinks && !hovered) || !doHaveLinks}
          nodeRef={numbersRef}
          classNames="numbers"
          timeout={500}
          mountOnEnter
          unmountOnExit
        >
          <div className="flex items-center" ref={numbersRef}>
            <div
              className={classNames(
                "flex items-center",
                styles.roomTableCounter
              )}
            >
              <div className={classNames(styles.roomTableCounterImage)}>
                <Image src={chessKnightIcon} alt="chess_knight_icon" />
              </div>
              <div className={classNames(styles.roomInfoNumbers)}>
                <span>{roundNumber(openTables)}</span>
              </div>
            </div>

            <div
              className={classNames(
                "flex items-center",
                styles.roomUserCounter
              )}
            >
              <div className={classNames(styles.roomUserCounterImage)}>
                <Image src={usersIcon} alt="users_icon" />
              </div>
              <div className={classNames(styles.roomInfoNumbers)}>
                <span>{roundNumber(room.onlineUsers)}</span>
              </div>
            </div>
          </div>
        </CSSTransition>

        <CSSTransition
          in={doHaveLinks && hovered}
          nodeRef={storesRef}
          classNames="stores"
          timeout={500}
          mountOnEnter
          unmountOnExit
        >
          <div className="flex gap-2 items-center" ref={storesRef}>
            {googlePlayLink && (
              <a
                href={googlePlayLink}
                target="_blank"
                rel="noreferrer"
                className={styles.storeIcon}
              >
                <Image src={googlePlayRoom} alt="google_play_icon" />
              </a>
            )}
            {appStoreLink && (
              <a
                href={appStoreLink}
                target="_blank"
                rel="noreferrer"
                className={styles.storeIcon}
              >
                <Image src={appleStoreRoom} alt="apple_store_icon" />
              </a>
            )}
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

const ExternalRoomLink = ({ room }: { room: Room }) => {
  const { t } = useTranslation();
  return (
    <div className={"relative"} style={getRoomImageSize()}>
      <div className={styles.sectionInnerContainer}>
        <a
          href={room.externalLink!}
          target="_blank"
          rel="noreferrer"
          className={styles.playTextContainer}
        >
          <p className={styles.playText}> {t("play")}</p>
        </a>
      </div>

      <LogoImage url={room.logo} />
    </div>
  );
};

const InnerRoom = ({ room }: { room: Room }) => {
  if (room.externalLink && room.externalLink.length > 0) {
    return <ExternalRoomLink room={room} />;
  }
  const sectionsWithHangmanFlag = room.sections
    .filter((s) => s.logo !== undefined && s.logo !== null)
    .map((section) => ({
      isHangman: section.type === "hangman",
      ...section,
    }));

  let sectionsFinal: Array<Section> = [];

  for (let i = 0; i < sectionsWithHangmanFlag.length; i++) {
    const finalSection = sectionsWithHangmanFlag[i];
    sectionsFinal.push({ isChallenge: false, ...finalSection });
    if (finalSection.isHangman) {
      sectionsFinal.push({
        isChallenge: true,
        ...finalSection,
      });
    }
  }

  return (
    <React.Suspense
      fallback={
        <AbsoluteCenter>
          <Loader description="" />
        </AbsoluteCenter>
      }
    >
      <Sections sections={sectionsFinal} />
    </React.Suspense>
  );
};

const RoomData = ({
  room,
  onHover,
  onMouseLeave,
}: {
  room: Room;
  onHover: () => void;
  onMouseLeave: () => void;
}) => {
  const [hovered, setHovered] = React.useState(false);
  const { width: imageWidth, height: imageHeight } = getRoomImageSize();

  return (
    <div
      onMouseOver={() => {
        setHovered(true);
        onHover();
      }}
      onMouseLeave={() => {
        setHovered(false);
        onMouseLeave();
      }}
      style={{
        height: imageHeight * 1.2,
        width: imageWidth,
        position: "relative",
        overflow: hovered ? "visible" : "hidden",
        zIndex: hovered ? 5 : 0,
      }}
    >
      <div>
        {hovered ? <InnerRoom room={room} /> : <LogoImage url={room.logo} />}
      </div>
      <RoomInfo room={room} hovered={hovered} />
    </div>
  );
};

const SlickSlider = React.memo(
  ({
    shouldSlide,
    doShowArrows,
    hideNextArrow,
    hidePrevArrow,
    children,
  }: {
    shouldSlide: boolean;
    doShowArrows: boolean;
    hideNextArrow: boolean;
    hidePrevArrow: boolean;
    children?: React.ReactNode;
  }) => {
    const [roomsPage, setRoomsPage] = React.useState(0);
    if (shouldSlide) {
      let slider: Slider | null = null;
      const onNext = () => {
        slider?.slickNext();
      };

      const onPrev = () => {
        slider?.slickPrev();
      };
      const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        afterChange: (currentSlide: number) => {
          setRoomsPage(currentSlide);
        },
        customPaging: (i: number) => (
          <div
            style={{
              width: "6px",
              height: "6px",
              borderRadius: "6px",
              border: "1px solid white",
              background: i === roomsPage ? "white" : "",
            }}
          />
        ),
      };
      return (
        <div>
          {doShowArrows && (
            <Arrow
              image={blueAarrowLeft}
              imageHovered={blueArrowLeftHovered}
              customStyleClass={styles.prevArrow}
              onClick={onPrev}
              zIndex={hidePrevArrow ? 0 : 1}
            />
          )}
          {doShowArrows && (
            <Arrow
              image={blueArrowRight}
              imageHovered={blueArrowRightHovered}
              customStyleClass={styles.nextArrow}
              onClick={onNext}
              zIndex={hideNextArrow ? 0 : 1}
            />
          )}

          <Slider ref={(c) => (slider = c)} {...sliderSettings}>
            {children}
          </Slider>
        </div>
      );
    }

    return <div>{children}</div>;
  }
);

const RoomsData = React.memo<{ rooms: Rooms }>(
  ({ rooms }) => {
    const [roomsState, setRoomsState] = React.useState<Rooms>();
    const [roomsRow, setRoomsRow] = React.useState<number>(3);
    const [isNotMainScreenDesk, setIsNotMainScreenDesk] = React.useState<
      boolean
    >(false);
    const [hideNextRoomPageArrow, setHideNextRoomPageArrow] = React.useState(
      false
    );
    const [hidePrevRoomPageArrow, setHidePrevRoomPageArrow] = React.useState(
      false
    );

    React.useEffect(() => {
      const onResize = () => {
        const isScreen = isNotMainScreenDesktopWidth("/");
        setRoomsRow(
          isScreen ? (document.documentElement.clientWidth < 834 ? 1 : 2) : 3
        );
        setIsNotMainScreenDesk(isScreen);
      };

      onResize();
      window.addEventListener("resize", onResize);
      return () => window.removeEventListener("resize", onResize);
    }, [rooms]);

    React.useEffect(() => {
      setRoomsState(rooms);
      setRoomsRow(
        isNotMainScreenDesk
          ? document.documentElement.clientWidth < 834
            ? 1
            : 2
          : 3
      );
    }, [isNotMainScreenDesk, rooms]);

    if (!roomsState) return <></>;

    const roomsFinal: Array<Room> = roomsState!.filter((r) => r !== undefined);
    const roomsByPages = splitToChunks(
      roomsFinal,
      isNotMainScreenDesk ? roomsFinal.length : 9
    ).map((page) => splitToChunks(page, roomsRow));

    return (
      <div className={classNames(styles.roomsDataWrapper)}>
        <SlickSlider
          shouldSlide={!isNotMainScreenDesk && roomsByPages.length > 0}
          doShowArrows={roomsByPages.length > 1}
          hideNextArrow={hideNextRoomPageArrow}
          hidePrevArrow={hidePrevRoomPageArrow}
        >
          {roomsByPages.map((page, index) => {
            return (
              <div key={index}>
                {page?.length > 0 &&
                  page.map((chunk, index) => {
                    return (
                      <div
                        className={classNames("flex", "justify-between", {
                          "my-4": index !== roomsState!.length - 1,
                          "px-4": !isNotMainScreenDesk,
                        })}
                        key={index}
                      >
                        {chunk.map((room, idx) => {
                          const onHover = () => {
                            if (
                              !isNotMainScreenDesk &&
                              roomsByPages.length > 0 &&
                              index === 1
                            ) {
                              if (idx === 0) {
                                setHidePrevRoomPageArrow(true);
                              }
                              if (idx === 2) {
                                setHideNextRoomPageArrow(true);
                              }
                            }
                          };
                          const onMouseLeave = () => {
                            if (
                              !isNotMainScreenDesk &&
                              roomsByPages.length > 0 &&
                              index === 1
                            ) {
                              if (idx === 0) {
                                setHidePrevRoomPageArrow(false);
                              }
                              if (idx === 2) {
                                setHideNextRoomPageArrow(false);
                              }
                            }
                          };

                          return (
                            <div key={idx}>
                              <RoomData
                                room={room}
                                onHover={onHover}
                                onMouseLeave={onMouseLeave}
                              />
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
              </div>
            );
          })}
        </SlickSlider>
      </div>
    );
  },
  (prevProps, nextProps) => {
    if (prevProps.rooms.length !== nextProps.rooms.length) return false;

    //We know that room are immutable.So if their keys are equal then skip rendering.This way we avoid redoing the animations when fetch-and-network
    const getKeys = (rooms: Array<Room>) => {
      return new Set(Object.values(rooms).map((room) => room.id));
    };

    const previousKeys = getKeys(prevProps.rooms);
    const nextKeys = getKeys(nextProps.rooms);
    for (let key of previousKeys) if (!nextKeys.has(key)) return false;

    return true;
  }
);

const News = () => {
  const { t } = useTranslation();
  const [newsImage, setNewsImage] = React.useState<string | null>(null);

  const [{ data }] = useQuery<
    MainScreenItemListQuery,
    MainScreenItemListQueryVariables
  >({
    query: NEWS_QUERY,
    variables: {
      category: SettingCategory.News,
    },
    requestPolicy: "network-only",
  });

  const newsData = data?.mainScreenItemList ?? null;

  const setImage = () => {
    if (!newsData?.length) {
      setNewsImage(null);
    } else {
      let currentNewsImage = newsData![0].settingValue.mobileImage;

      if (document.documentElement.clientWidth >= 1240) {
        currentNewsImage = newsData![0].settingValue.desktopImage;
      } else if (
        document.documentElement.clientWidth < 1240 &&
        document.documentElement.clientWidth > 833
      ) {
        currentNewsImage = newsData![0].settingValue.tabletImage;
      }

      setNewsImage(currentNewsImage);
    }
  };

  React.useEffect(() => {
    if (newsData) {
      setImage();
    }

    function handleResize() {
      if (newsData) {
        setImage();
      }
    }

    window.addEventListener("resize", handleResize);
  }, [newsData]);

  const getNewsImage = () => {
    if (
      newsData![0].settingValue.link &&
      newsData![0].settingValue.link.length
    ) {
      return (
        <div className={styles.imageWrapper}>
          <a
            href={newsData![0].settingValue.link}
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={"m-auto"}
              src={`${process.env.REACT_APP_API_HOST}/news/${newsImage!}`}
              alt="news image"
            />
          </a>
        </div>
      );
    } else {
      return (
        <div className={styles.imageWrapper}>
          <img
            className={"m-auto"}
            src={`${process.env.REACT_APP_API_HOST}/news/${newsImage!}`}
            alt="news image"
          />
        </div>
      );
    }
  };

  return (
    <div
      className={styles.newsImageSectionWrapper}
      style={{
        background: "#1A212D",
        backgroundImage: `url(${newsBackground})`,
        backgroundSize: "cover",
      }}
    >
      <p className={styles.newsTitle}>{t("platform_news")}</p>
      {newsImage && getNewsImage()}
    </div>
  );
};

const Body = React.memo(() => {
  const [{ data }] = useQuery<
    LobbyRoomsQueryQuery,
    LobbyRoomsQueryQueryVariables
  >({
    query: ROOMS_QUERY,
    requestPolicy: "cache-and-network",
  });
  const { t } = useTranslation();
  const rooms = data?.rooms ?? null;
  const [isMainScreenDesk, setIsMainScreenDesk] = React.useState<boolean>(
    false
  );

  React.useEffect(() => {
    const onResize = () => {
      const isScreen = isNotMainScreenDesktopWidth("/");

      setIsMainScreenDesk(isScreen);
    };
    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  const openShop = useShop();
  const location = useLocation();

  React.useEffect(() => {
    if (
      location.pathname === `/${process.env.REACT_APP_VIVA_SUCCESS_PATH}` ||
      location.pathname === `/${process.env.REACT_APP_VIVA_FAIL_PATH}`
    ) {
      openShop(Currency.BlueChips);
    }
  }, [location, openShop]);

  return (
    <div className="flex flex-col h-full">
      <div className="relative flex-1">
        {rooms === null ? (
          <AbsoluteCenter style={styles}>
            <Loader description={t("loading_rooms")} />
          </AbsoluteCenter>
        ) : rooms.length === 0 ? (
          <AbsoluteCenter style={styles}>
            <div className="w-1/4 p-4 text-center bg-red-700 rounded">
              <span className="block font-bold text-white">
                {t("no_rooms_configured")}
              </span>
            </div>
          </AbsoluteCenter>
        ) : (
          <div
            className={classNames(
              isMainScreenDesk ? "" : "absolute",
              "top-0",
              "bottom-0",
              "left-0",
              "right-0"
            )}
          >
            <div className={styles.mainScreen}>
              <div className={styles.newsWrapper}>
                <News />
              </div>
              <div className={styles.roomsWrapper}>
                <p className={styles.roomsTitle}>{t("our_games")}</p>
                <RoomsData rooms={rooms} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export const Lobby = () => {
  const [isRightBarOpened, ,] = useRightBar();
  const screenType = useScreenType();

  return (
    <div>
      {screenType !== "DESKTOP" && isRightBarOpened && <RightBar />}
      <Layout
        header={
          <Header
            mainScreen
            right={<HeaderRight mainScreen />}
            children={<Balances />}
          />
        }
        style={{ overflow: "visible", paddingTop: 16 }}
      >
        <Body />
        <div className="absolute top-0 left-0 right-0 text-white">
          <Marquee />
        </div>
      </Layout>
    </div>
  );
};
