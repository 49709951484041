import { loader } from "graphql.macro";
import { Cache, Data, Variables } from "@urql/exchange-graphcache";
import { FriendsQueryQuery } from "../../graphql/FriendsQuery.generated";

const PLAYER_FRIENDSHIP_FRAGMENT = loader(
  "../../graphql/PlayerFriendshipFragment.graphql"
);

const FRIENDS_QUERY = loader("../../graphql/FriendsQuery.graphql");

export const cancelFriendship = (
  result: Data,
  args: Variables,
  cache: Cache
) => {
  if (result !== null) {
    const { withUser: userId } = args;
    cache.writeFragment(PLAYER_FRIENDSHIP_FRAGMENT, {
      id: userId as string,
      friendship: {
        canAskForFriendship: true,
        areFriends: false,
        __typename: "FriendshipType",
      },
      __typename: "Player",
    });
    cache.updateQuery<FriendsQueryQuery>(
      { query: FRIENDS_QUERY, variables: { after: "" } },
      (data) => {
        if (data !== null)
          (data as FriendsQueryQuery).me!.friends.edges = (data as FriendsQueryQuery).me!.friends.edges.filter(
            (edge) => edge.node.userB.id !== userId
          );
        return data;
      }
    );
  }
};
