import { Amount, Currency } from "../graphql/generated";
import { ROOM_IMAGE_HEIGHT, ROOM_IMAGE_WIDTH } from "../components/Lobby";
import { DESKTOP_MIN_WIDTH, TABLET_MIN_WIDTH } from "../other/screenType";

export function isFunction(x: any): x is Function {
  return typeof x === "function";
}

export const calculateTimePercent = (from: number, to: number, now: number) => {
  const nowRelative = Math.max(now - from, 0);
  const targetRealtive = to - from;

  return Math.min(((nowRelative / targetRealtive) * 100) / 100, 1);
};

export const clamp = (number: number, lower: number, upper: number) => {
  const lowerClampedValue = Math.max(number, lower);
  return Math.min(lowerClampedValue, upper);
};

export const splitToChunks = <T>(array: Array<T>, size: number) => {
  const chunked_arr = [];
  let index = 0;
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index));
    index += size;
  }
  return chunked_arr;
};

export const compareAmounts = (a: Amount, b: Amount) => {
  const aCurrencySortValue = getCurrencySortValue(a.currency);
  const bCurrencySortValue = getCurrencySortValue(b.currency);
  if (aCurrencySortValue < bCurrencySortValue) return -1;
  else if (aCurrencySortValue > bCurrencySortValue) return 1;
  else {
    if (a.value < b.value) return -1;
    else if (a.value > b.value) return 1;
    else return 0;
  }
};

const getCurrencySortValue = (currency: Currency) => {
  switch (currency) {
    case Currency.RealMoney:
      return 0;
    case Currency.GoldChips:
      return 1;
    case Currency.BlueChips:
      return 2;
    case Currency.RewardPoints:
      return 3;
  }
};

export const groupBy = <T>(
  arr: Array<T>,
  criteria: (value: T) => string | number
) => {
  return arr.reduce((obj, item) => {
    // Check if the criteria is a function to run on the item or a property of it
    const key = criteria(item);

    // If the key doesn't exist yet, create it
    if (!obj.hasOwnProperty(key)) {
      obj[key] = [];
    }

    // Push the value to the object
    obj[key].push(item);

    // Return the object to the next item in the loop
    return obj;
  }, {} as { [k in string | number]: Array<T> });
};

export const isNotMainScreenDesktopWidth = (path: string) => {
  return path === "/" && document.documentElement.clientWidth < DESKTOP_MIN_WIDTH;
};

export const getRoomImageSize = () => {
  let defaultSize = {
    width: ROOM_IMAGE_WIDTH,
    height: ROOM_IMAGE_HEIGHT,
  };

  if (document.documentElement.clientWidth < TABLET_MIN_WIDTH) {
    defaultSize = {
      width: 343,
      height: 185,
    };
  } else if (document.documentElement.clientWidth < DESKTOP_MIN_WIDTH) {
    defaultSize = {
      width: 387,
      height: 209,
    };
  }
  return defaultSize;
};

export const isNotEmptyString = (value: string | undefined | null): boolean => {
  return value !== null && value !== undefined && value.length > 0;
};
