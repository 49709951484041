import * as React from "react";
import { gql, loader } from "graphql.macro";
import { useQuery } from "../../../hooks/useQuery";
import { useTranslation } from "react-i18next";
import { PlaceholderLayout } from "../PlaceholderLayout";
import { AbsoluteCenter } from "../../AbsoluteCenter";
import { Loader } from "../../Loader";
import { Redirect } from "react-router";
import { RoomQuery, RoomQueryVariables } from "./index.generated";
import { SubscribeToRoom } from "../SubscribeToRoom";

const TOURNAMENT_FRAGMENT = loader("../graphql/TournamentFragment.graphql");
const ROOM_QUERY = gql`
  query Room($id: ID!) {
    room(id: $id) {
      id
      name
      onlineUsers
      logo
      sections {
        __typename
        id
        count
        type
        ... on TournamentSection {
          tournament {
            ...Tournament
          }
        }
      }
    }
  }
  ${TOURNAMENT_FRAGMENT}
`;

const RoomContext = React.createContext<NonNullable<RoomQuery["room"]>>(
  undefined as any
);

export function RoomProvider({
  id,
  children,
}: React.PropsWithChildren<{ id: string }>) {

  const [{ fetching, data }] = useQuery<RoomQuery, RoomQueryVariables>({
    query: ROOM_QUERY,
    variables: { id },
    requestPolicy: "cache-and-network",
  });
  const { t } = useTranslation();
  if (fetching)
    return (
      <PlaceholderLayout>
        <AbsoluteCenter>
          <Loader description={t("loading_room")} />
        </AbsoluteCenter>
      </PlaceholderLayout>
    );
  else if ((data?.room ?? null) === null) return <Redirect to="/" />;

  return (
    <RoomContext.Provider value={data!.room!}>
      <SubscribeToRoom id={id}>{children}</SubscribeToRoom>
    </RoomContext.Provider>
  );
}

export function useRoom() {
  return React.useContext(RoomContext);
}
