import React, {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { FileUploadButton, ImageDropzone } from "react-file-utils";
import type { Event } from "stream-chat";
import clsx from "clsx";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import {
  ChatAutoComplete,
  SendButton as DefaultSendButton,
  Tooltip,
  UploadsPreview,
  useChannelActionContext,
  useChannelStateContext,
  useChatContext,
  useComponentContext,
  useMessageInputContext,
  CooldownTimer as DefaultCooldownTimer,
  QuotedMessagePreview as DefaultQuotedMessagePreview,
  EmojiPicker,
} from "stream-chat-react";
import emojiIcon from "./assets/emojiIcon.svg";
import addIcon from "./assets/addIcon.svg";
import giftIcon from "./assets/giftIcon.svg";
import sendIcon from "./assets/sendIcon.svg";
import { loader } from "graphql.macro";
import { useMutation } from "../../hooks/useMutation";
import {
  PurchaseVirtualItemInChatMutation,
  PurchaseVirtualItemInChatMutationVariables,
} from "./graphql/PurchaseVirtualItemInChat.generated";
import { SendGiftModal } from "./SendGift";
import { useUser, useUserId } from "../UserProvider";
import { useTranslation } from "react-i18next";
import { useGuestId } from "../../hooks/useGuestId";
import { useRegisterPopup } from "../Register";
import { useChannelOnSendMessage } from "./ChannelOnSendMessage";

const PURCHASE_GIFT_MUTATION = loader(
  "./graphql/PurchaseVirtualItemInChat.graphql"
);

export const CustomMessageInput = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>() => {
  const { quotedMessage } = useChannelStateContext<StreamChatGenerics>(
    "MessageInputFlat"
  );
  const { setQuotedMessage } = useChannelActionContext("MessageInputFlat");
  const { channel } = useChatContext<StreamChatGenerics>("MessageInputFlat");

  useEffect(() => {
    const handleQuotedMessageUpdate = (e: Event<StreamChatGenerics>) => {
      if (e.message?.id !== quotedMessage?.id) return;
      if (e.type === "message.deleted") {
        setQuotedMessage(undefined);
        return;
      }
      setQuotedMessage(e.message);
    };
    channel?.on("message.deleted", handleQuotedMessageUpdate);
    channel?.on("message.updated", handleQuotedMessageUpdate);

    return () => {
      channel?.off("message.deleted", handleQuotedMessageUpdate);
      channel?.off("message.updated", handleQuotedMessageUpdate);
    };
  }, [channel, quotedMessage]);

  return <MessageInputV1<StreamChatGenerics> />;
};

const MessageInputV1 = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>() => {
  const {
    acceptedFiles,
    multipleUploads,
    quotedMessage,
  } = useChannelStateContext<StreamChatGenerics>("MessageInputFlat");
  const {
    closeEmojiPicker,
    cooldownRemaining,
    emojiPickerIsOpen,
    handleSubmit,
    isUploadEnabled,
    maxFilesLeft,
    numberOfUploads,
    openEmojiPicker,
    setCooldownRemaining,
    uploadNewFiles,
  } = useMessageInputContext<StreamChatGenerics>("MessageInputFlat");

  const {
    CooldownTimer = DefaultCooldownTimer,
    QuotedMessagePreview = DefaultQuotedMessagePreview,
    SendButton = DefaultSendButton,
  } = useComponentContext<StreamChatGenerics>("MessageInputFlat");

  const { t } = useTranslation();

  const registration = useRegisterPopup();
  const [, , , getIsLoggedAsGuest] = useGuestId();

  const userId = useUserId();
  const { channel } = useChannelStateContext();

  const onSendMessage = useChannelOnSendMessage();

  const channelMemberFiltration = () => {
    const users = Object.keys(channel.state.members);
    const membership = users.filter((e) => e === userId);
    if (membership.length) return true;
    return false;
  };

  const addMemberToGroupChannel = async () => {
    if (channel.data?.type === "group" || channel.data?.type === "room") {
      const isMember = channelMemberFiltration();
      if (!isMember) {
        await channel.addMembers([userId]);
      }
    }
  };

  const submitMessage = (
    event: BaseSyntheticEvent<object, any, any>,
    text: string
  ) => {
    handleSubmit(event);
    addMemberToGroupChannel();
    if (onSendMessage) onSendMessage(text);
  };

  const [openModal, setOpenModal] = useState(false);

  const [, sendGift] = useMutation<
    PurchaseVirtualItemInChatMutation,
    PurchaseVirtualItemInChatMutationVariables
  >(PURCHASE_GIFT_MUTATION);

  const onSendGift = useCallback(
    async ({ item, logo }: { item: string; logo: string }) => {
      const imgUrl = `${process.env.REACT_APP_API_HOST}/virtual-items/${logo}`;
      if (channel.id) {
        await sendGift({ input: { chatId: channel.id, item } });
        close();
        addMemberToGroupChannel();
        channel.sendMessage({
          attachments: [
            {
              type: "image",
              image_url: imgUrl,
            },
          ],
        });
      }
    },
    []
  );

  const open = () => {
    setOpenModal(true);
  };

  const close = () => {
    setOpenModal(false);
  };

  const {
    user: { vipUntil },
  } = useUser();

  const isVip =
    vipUntil != null && new Date(vipUntil).getTime() > new Date().getTime();

  return (
    <>
      {openModal && (
        <SendGiftModal name={""} close={close} onSendGift={onSendGift} />
      )}
      <div
        className={clsx(
          "str-chat__input-flat",
          isVip ? "str-chat__input-flat-vip" : "str-chat__message-input",
          {
            "str-chat__input-flat--send-button-active": !!SendButton,
            "str-chat__input-flat-has-attachments": numberOfUploads,
            "str-chat__input-flat-quoted":
              quotedMessage && !quotedMessage.parent_id,
          },
          "input-container",
          "message-input"
        )}
      >
        <ImageDropzone
          accept={acceptedFiles}
          disabled={
            !isVip ||
            !isUploadEnabled ||
            maxFilesLeft === 9 ||
            !!cooldownRemaining
          }
          handleFiles={(files) => {
            if (getIsLoggedAsGuest()) {
              registration.openRegisterPopup();
            } else {
              uploadNewFiles(files);
            }
          }}
          maxNumberOfFiles={maxFilesLeft}
          multiple={multipleUploads}
        >
          {quotedMessage && !quotedMessage.parent_id && (
            <QuotedMessagePreview quotedMessage={quotedMessage} />
          )}
          <div className="str-chat__input-flat-wrapper">
            {isUploadEnabled && <UploadsPreview />}
            <div className="w-full str-chat__input-flat--textarea-wrapper">
              <div className="str-chat__emojiselect-wrapper">
                {/* <Tooltip>
                {emojiPickerIsOpen
                  ? t<string>("Close emoji picker")
                  : t<string>("Open emoji picker")}
              </Tooltip> */}
                <button
                  aria-label="Emoji picker"
                  className="str-chat__input-flat-emojiselect"
                  onClick={(event) => {
                    if (getIsLoggedAsGuest()) {
                      registration.openRegisterPopup();
                    } else {
                      emojiPickerIsOpen
                        ? closeEmojiPicker(event)
                        : openEmojiPicker(event);
                    }
                  }}
                >
                  {cooldownRemaining ? (
                    <div className="str-chat__input-flat-cooldown">
                      <CooldownTimer
                        cooldownInterval={cooldownRemaining}
                        setCooldownRemaining={setCooldownRemaining}
                      />
                    </div>
                  ) : (
                    <img src={emojiIcon} alt="emoji" />
                  )}
                </button>
              </div>
              <EmojiPicker />
              {isVip && isUploadEnabled && !cooldownRemaining && (
                <div
                  className="str-chat__fileupload-wrapper"
                  data-testid="fileinput"
                >
                  {/* <Tooltip>
                  {maxFilesLeft
                    ? t<string>("Attach files")
                    : t<string>("You've reached the maximum number of files")}
                </Tooltip> */}
                  <FileUploadButton
                    accepts={acceptedFiles}
                    disabled={maxFilesLeft === 9}
                    handleFiles={(files) => {
                      if (getIsLoggedAsGuest())
                        registration.openRegisterPopup();
                      else uploadNewFiles(files);
                    }}
                    multiple={multipleUploads}
                  >
                    <span className="str-chat__input-flat-fileupload">
                      <img src={addIcon} alt="file-upload" />
                    </span>
                  </FileUploadButton>
                </div>
              )}
              <div
                className={isVip ? "send-gift-button-vip" : "send-gift-button"}
                onClick={() => {
                  if (getIsLoggedAsGuest()) registration.openRegisterPopup();
                  else open();
                }}
              >
                <img src={giftIcon} alt="Send gift" />
              </div>
              <ChatAutoComplete
                handleSubmit={(event) => {
                  if (getIsLoggedAsGuest()) registration.openRegisterPopup();
                  else submitMessage(event, event.target.value);
                }}
                placeholder={t("type_your_message")}
              />
            </div>
            <button
              onClick={(e) => {
                if (getIsLoggedAsGuest()) registration.openRegisterPopup();
                else {
                  const textarea = document.querySelector(
                    ".str-chat__message-textarea"
                  );
                  const inputValue = textarea
                    ? (textarea as HTMLTextAreaElement).value
                    : "";
                  submitMessage(e, inputValue);
                }
              }}
            >
              <img src={sendIcon} alt="Send message" />
            </button>
          </div>
        </ImageDropzone>
      </div>
    </>
  );
};
