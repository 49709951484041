import * as React from "react";
import { useTranslation } from "react-i18next";
import { Currency } from "../../graphql/generated";
import blueChips from "./images/blue_chips.svg";
import goldChips from "./images/gold_chips.svg";
import rewardPoints from "./images/VIP.svg";

const BALANCE_IMAGES: { [key: string]: string } = {
  BLUE_CHIPS: blueChips,
  GOLD_CHIPS: goldChips,
  REWARD_POINTS: rewardPoints,
};

export const CurrencyImage = React.memo<
  { currency: Currency } & Omit<JSX.IntrinsicElements["img"], "alt">
>(({ currency, ...rest }) => {
  const { t } = useTranslation();
  if (BALANCE_IMAGES[currency] === undefined) return null;
  return (
    <img
      src={BALANCE_IMAGES[currency]}
      alt={t(`currency.${currency.toLowerCase()}`)}
      {...rest}
    />
  );
});
