import * as React from "react";
import { BalancesFragmentFragment } from "../../graphql/BalancesFragment.generated";
import { CurrencyImage } from "../CurrencyImage";
import { Row } from "./Row";

type Balances = BalancesFragmentFragment["balances"];

export const BalanceStatisticsCompare = ({
  me,
  other,
}: {
  me: Balances | null;
  other: Balances | null;
}) => {
  if (me === null && other === null) return null;
  else if (me === null || other === null) {
    const compare = me ?? other;
    return (
      <>
        {compare!.map((balance) => {
          return (
            <Row
              key={balance.currency}
              left={null}
              right={balance.value}
              center={
                <CurrencyImage
                  className="self-center"
                  currency={balance.currency}
                  width={18}
                  height={18}
                />
              }
            />
          );
        })}
      </>
    );
  } else
    return (
      <>
        {me.map((balance) => {
          const otherBalance = other.find(
            ({ currency }) => currency === balance.currency
          );
          if (otherBalance === undefined) return null;
          return (
            <Row
              key={balance.currency}
              left={balance.value}
              right={otherBalance.value}
              center={
                <CurrencyImage
                  className="self-center"
                  currency={balance.currency}
                  width={18}
                  height={18}
                />
              }
            />
          );
        })}
      </>
    );
};
