import { loader } from "graphql.macro";
import { Cache, Data, Variables } from "@urql/exchange-graphcache";
import { WatchedAdsMutation } from "../../components/FreeChips/FreeChipsPopup.generated";
import { MeQueryQuery } from "../../graphql/MeQuery.generated";

const ME_QUERY = loader("../../graphql/MeQuery.graphql");

export const watchedAds = (result: Data, args: Variables, cache: Cache) => {
  if (result !== null) {
    const watchedAds = ((result as unknown) as WatchedAdsMutation).watchedAds;
    if (watchedAds !== null)
      cache.updateQuery<MeQueryQuery>({ query: ME_QUERY }, (data) => {
        if (data !== null) {
          const ads = (data as MeQueryQuery).me!.ads;
          ads.count = watchedAds.count;
          ads.firstAdWatched = watchedAds.firstAdWatched;
          ads.currentAdWatched = watchedAds.currentAdWatched;
        }
        return data;
      });
  }
};
