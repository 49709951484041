import * as React from "react";
import { useTranslation } from "react-i18next";
import { FACEBOOK_API_VERSION, SUPPORTED_LOCALES } from "../../config";
import { useScript } from "../../hooks/useScript";
import { useHotKeys } from "../../hooks/useHotKeys";

const APP_ID: string = process.env.REACT_APP_FACEBOOK_APP_ID!;
const SEARCH_STRING = "iframe_canvas_fb";
const TOGGLE_HOTKEY = "ctrl+i";

declare global {
  interface Window {
    fbAsyncInit?: () => void;
  }
}

const Context = React.createContext<[boolean, boolean]>([false, false]);

export const FacebookProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const {
    i18n: { language },
  } = useTranslation();
  const [isFacebookCanvas, setFacebookCanvas] = React.useState(
    window.name.indexOf(SEARCH_STRING) !== -1
  );
  useHotKeys(
    TOGGLE_HOTKEY,
    React.useCallback(
      () => setFacebookCanvas((isFacebookCanvas) => !isFacebookCanvas),
      []
    )
  );
  const locale = SUPPORTED_LOCALES[language].facebook;
  const [sdkLoaded, setSdkLoaded] = React.useState(false);
  React.useEffect(() => {
    let isCurrent = true;
    const fbAsyncInit = () => {
      FB.init({
        appId: APP_ID,
        version: FACEBOOK_API_VERSION,
        status: true,
      });
      if (isCurrent) setSdkLoaded(true);
    };
    window.fbAsyncInit = window.fbAsyncInit || fbAsyncInit;
    return () => {
      isCurrent = false;
      delete window.fbAsyncInit;
    };
  }, []);
  useScript(`https://connect.facebook.net/${locale}/sdk.js`);
  return (
    <Context.Provider value={[sdkLoaded, isFacebookCanvas]}>
      {children}
    </Context.Provider>
  );
};

export const useFacebook = () => {
  return React.useContext(Context);
};
