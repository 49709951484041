import * as React from "react";
import { BalanceUpdateSendActivityContentFragmentFragment } from "../../../graphql/ActivityFragment.generated";
import { Trans, useTranslation } from "react-i18next";
import { useNumberFormatter } from "../../../hooks/useNumberFormatter";
import { PlayerFragmentFragment } from "../../../graphql/PlayerFragment.generated";

export const BalanceUpdateSendActivity = ({
  actor,
  content,
  openProfile,
}: {
  actor: PlayerFragmentFragment | null;
  content: BalanceUpdateSendActivityContentFragmentFragment;
  openProfile: () => void;
}) => {
  const formatter = useNumberFormatter();
  const { t } = useTranslation();
  return (
    <Trans i18nKey="notification.balance_update_send_activity">
      <button onClick={openProfile}>
        <strong>{{ actor: actor!.name }}</strong>
      </button>{" "}
      has send you{" "}
      <strong>
        {{ amount: `${formatter.format(content.amounts[0].value)}` }}
      </strong>{" "}
      {{
        currency: t(
          `currency.${content.amounts[0].currency.toString().toLowerCase()}`
        ),
      }}
    </Trans>
  );
};
