import * as React from "react";
import { useLocalStorage } from "./useStorage";
export const usePromotion = (): [
  string | null,
  (referral: string) => void,
  () => void
] => {
  const [promotion, setPromotion, reset] = useLocalStorage<string | null>(
    "promotion",
    null
  );

  const setValue = React.useCallback(
    (promotion: string) => setPromotion(promotion),
    [setPromotion]
  );

  return React.useMemo(() => [promotion, setValue, reset], [
    promotion,
    setValue,
    reset,
  ]);
};
