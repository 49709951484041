import * as React from "react";
import { Trans } from "react-i18next";
import { PlayerFragmentFragment } from "../../../graphql/PlayerFragment.generated";

export const FriendRequestAcceptedActivity = ({
  actor,
  openProfile,
}: {
  actor: PlayerFragmentFragment | null;
  openProfile: () => void;
}) => {
  return (
    <>
      <Trans i18nKey="notification.friend_request_accepted_activity">
        <button onClick={openProfile}>
          <strong>{{ actor: actor!.name }}</strong>
        </button>{" "}
        accepted your friend request
      </Trans>
    </>
  );
};
