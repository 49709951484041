import * as React from "react";
import { Trans } from "react-i18next";

export const BalanceUpdateRewardedAdDetails = () => {
  return (
    <Trans i18nKey="transaction.BalanceUpdateRewardedAdDetails_details">
      Rewarded video
    </Trans>
  );
};
