import * as React from "react";

const Leaderboards = React.lazy(() => import("./Leaderboards"));

const LeaderboardContext = React.createContext<
  (sectionIds: Array<string>) => void
>(undefined as any);

export const LeaderboardsProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [sectionIds, setSectionIds] = React.useState<Array<string>>([]);
  const toggle = React.useCallback(
    (sectionIds: Array<string>) => setSectionIds(sectionIds),
    []
  );
  const onDismiss = React.useCallback(() => setSectionIds([]), []);
  return (
    <LeaderboardContext.Provider value={toggle}>
      {children}
      {sectionIds.length > 0 && (
        <React.Suspense fallback={null}>
          <Leaderboards sectionIds={sectionIds} onDismiss={onDismiss} />
        </React.Suspense>
      )}
    </LeaderboardContext.Provider>
  );
};

export const useLeaderboards = () => {
  return React.useContext(LeaderboardContext);
};
