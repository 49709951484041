import React from "react";
import { StreamChatComponent } from ".";
import { useCustomChatContext } from "../UserProvider";

const Chat = () => {
  const { openedChat } = useCustomChatContext();

  return <>{openedChat && <StreamChatComponent />}</>;
};

export default Chat;
