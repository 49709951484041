import * as React from "react";
import { Currency } from "../../graphql/generated";
import { useScreenType } from "../../hooks/useScreenType";

const ScreenStateContext = React.createContext<[boolean, () => void]>([
  false,
  () => null,
]);

export const ScreenStateProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [doForceDesktopScreenSize, setForceDesktopScreenSize] = React.useState(
    false
  );
  const screenType = useScreenType();
  const reset = React.useCallback(
    () =>
      setForceDesktopScreenSize((prev) => {
        if (screenType === "DESKTOP") return false;
        return !prev;
      }),
    [screenType]
  );

  const contextValue: [boolean, () => void] = React.useMemo(
    () => [doForceDesktopScreenSize, reset],
    [doForceDesktopScreenSize, reset]
  );

  return (
    <ScreenStateContext.Provider value={contextValue}>
      {children}
    </ScreenStateContext.Provider>
  );
};

export const useScreenState = () => {
  return React.useContext(ScreenStateContext);
};
