import * as React from "react";
import { BalanceUpdateAdjustmentDetailsFragmentFragment } from "../../../hooks/useTransactions/graphql/TransactionsFragment.generated";
import { Trans } from "react-i18next";

export const BalanceUpdateAdjustmentDetails = ({
  details,
}: {
  details: BalanceUpdateAdjustmentDetailsFragmentFragment;
}) => {
  return (
    <Trans i18nKey="transaction.BalanceUpdateAdjustment_details">
      Balance adjustment <strong>{{ notes: details.notes }}</strong>
    </Trans>
  );
};
