import * as React from "react";
import { HangmanDefaultConfigQueryQuery } from "./graphql/HangmanDefaultConfigQuery.generated";
import { useTranslation } from "react-i18next";
import { CombinedError } from "@urql/core/dist/types/utils/error";
import { useIsMounted } from "../../../../hooks/useIsMounted";
import { ErrorMessage, Field, Formik } from "formik";
import classNames from "classnames";
import {
  Currency,
  HangmanTableInput,
  HangmanTableType,
  HangmanGameMode,
} from "../../../../graphql/generated";
import { useCustomChatContext, useUser } from "../../../UserProvider";
import styles from "./CreateTable.module.scss";
import { Slider } from "../../../Slider";
import { FieldContainer } from "../../common/createtable/FieldContainer";
import { ButtonWithSound } from "../../../AudioProvider";
import * as Yup from "yup";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuPopover,
} from "@reach/menu-button";
import { positionMatchWidth } from "@reach/popover";
import { FormErrorMessage } from "../../common/createtable/FormErrorMessage";

const betByPoints = (
  array: Array<{ maxRounds: number; minBet: number }>,
  numberOfRounds: number
) => {
  const availableRanges = array.filter((e) => e.maxRounds >= numberOfRounds);
  return availableRanges[0].minBet;
};

export const HangmanForm = ({
  sectionId,
  config,
  onSubmit,
}: {
  sectionId: string;
  config: NonNullable<HangmanDefaultConfigQueryQuery["hangmanDefaultConfig"]>;
  onSubmit: (input: HangmanTableInput) => Promise<CombinedError | null>;
}) => {
  const [error, setError] = React.useState<string>();
  const isMounted = useIsMounted();
  const { user } = useUser();
  const { t } = useTranslation();
  const [numberOfPlayers, setPlayersNumber] = React.useState<number>(1);
  const [currentLanguage, setCurrentLanguage] = React.useState<string>(
    config.settings[0].language
  );
  const multiplayer = numberOfPlayers > 1;
  const { setOpenedChat } = useCustomChatContext();

  const [roundsNumber, setRoundsNumber] = React.useState(
    multiplayer ? config?.multiPlayer.numberOfRounds.default : 1
  );
  const minimalValue = betByPoints(
    config.multiPlayer.betByNumberOfRounds,
    roundsNumber
  );
  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        bet: Yup.number()
          .min(
            multiplayer
              ? config.multiPlayer.bet.minimum
              : config.singlePlayer.bet.minimum
          )
          .max(
            multiplayer
              ? config.multiPlayer.bet.maximum
              : config.singlePlayer.bet.maximum
          )
          // eslint-disable-next-line no-template-curly-in-string
          .test("is-within-range", "${path} is not within range", (value) => {
            return (
              value !== null &&
              value !== undefined &&
              value %
                (multiplayer
                  ? config.multiPlayer.bet.step
                  : config.singlePlayer.bet.step) ===
                0
            );
          })
          .required(),
        levelOfDifficulty: Yup.number()
          .min(
            multiplayer
              ? config.multiPlayer.levelOfDifficulty.minimum
              : config.singlePlayer.levelOfDifficulty.minimum
          )
          .max(
            multiplayer
              ? config.multiPlayer.levelOfDifficulty.maximum
              : config.singlePlayer.levelOfDifficulty.maximum
          )
          // eslint-disable-next-line no-template-curly-in-string
          .test("is-within-range", "${path} is not within range", (value) => {
            return (
              value !== null &&
              value !== undefined &&
              value %
                (multiplayer
                  ? config.multiPlayer.levelOfDifficulty.step
                  : config.singlePlayer.levelOfDifficulty.step) ===
                0
            );
          })
          .required(),
        numberOfRounds: Yup.number()
          .min(multiplayer ? config.multiPlayer.numberOfRounds.minimum : 1)
          .max(multiplayer ? config.multiPlayer.numberOfRounds.maximum : 1)
          // eslint-disable-next-line no-template-curly-in-string
          .test("is-within-range", "${path} is not within range", (value) => {
            return (
              value !== null &&
              value !== undefined &&
              value %
                (multiplayer ? config.multiPlayer.numberOfRounds.step : 1) ===
                0
            );
          })
          .required(),
        numberOfPlayers: Yup.number()
          .min(1)
          .max(config.multiPlayer.maxNumberOfPlayers)
          // eslint-disable-next-line no-template-curly-in-string
          .test("is-within-range", "${path} is not within range", (value) => {
            return (
              value !== null &&
              value !== undefined &&
              value <= config.multiPlayer.maxNumberOfPlayers
            );
          })
          .required(),
      }),
    [config, multiplayer]
  );
  const levelValue = (language: string) => {
    const modeConfigs = config.multiPlayer;
    const languageSet = config.settings.find((s) => s.language === language);
    const settingsLanguageMinLevel = languageSet?.levels[0] ?? 1;
    const settingLanguageMaxLevel =
      languageSet?.levels[languageSet.levels.length - 1 ?? 1] ?? 1;

    const min =
      modeConfigs.levelOfDifficulty.minimum < settingsLanguageMinLevel
        ? settingsLanguageMinLevel
        : modeConfigs.levelOfDifficulty.minimum;
    const max =
      modeConfigs.levelOfDifficulty.maximum > settingLanguageMaxLevel
        ? settingLanguageMaxLevel
        : modeConfigs.levelOfDifficulty.maximum;

    return { min, max };
  };

  function round(num: number) {
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return (Math.round(m) / 100) * Math.sign(num);
  }
  return (
    <Formik
      initialValues={{
        name: user.name,
        bet: multiplayer
          ? config.multiPlayer.bet.default
          : config.singlePlayer.bet.default,
        levelOfDifficulty: multiplayer
          ? config.multiPlayer.levelOfDifficulty.default
          : config.singlePlayer.levelOfDifficulty.default,
        type: HangmanTableType.Public,
        numberOfRounds: multiplayer
          ? config.multiPlayer.numberOfRounds.default
          : 1,
        numberOfPlayers: multiplayer ? numberOfPlayers : 1,
        language: config.settings[0].language,
        mode: multiplayer
          ? HangmanGameMode.MultiPlayer
          : HangmanGameMode.SinglePlayer,
      }}
      validationSchema={validationSchema}
      validate={(values) => {
        const errors: { [key: string]: string | undefined } = {};
        const availableBalance =
          user.balances.find(
            (balance) => balance.currency === Currency.BlueChips
          )?.value ?? 0;
        let multiplier = 1;
        if (values.bet * multiplier > availableBalance)
          errors.bet = t("insufficient_funds");
        return errors;
      }}
      onSubmit={(values) => {
        setOpenedChat(false);
        return onSubmit({
          sectionId,
          ...values,
        }).then((error) => {
          if (error != null && isMounted.current) {
            if (error.graphQLErrors.length > 0)
              setError(error.graphQLErrors[0].message);
            else if (error.networkError) setError(error.networkError.message);
          }
        });
      }}
    >
      {({
        values,
        isValid,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
      }) => {
        return (
          <form onSubmit={handleSubmit} className="flex flex-col h-full">
            {error && FormErrorMessage(error)}
            <div
              className={classNames(
                "flex-1",
                "overflow-auto",
                styles.scrollable
              )}
            >
              <FieldContainer name={t("name")}>
                <Field
                  name="name"
                  className="form-input w-full py-1 block rounded"
                />
              </FieldContainer>
              <FieldContainer name={t("language_field")}>
                <Menu>
                  <MenuButton className="block w-2/5">
                    <div className="rounded-md bg-white shadow-xs p-1 flex justify-between">
                      <div className="w-1/3" />
                      <span className="p-1">{currentLanguage}</span>
                      <span className="w-1/3 px-1 text-right text-xl">▾</span>
                    </div>
                  </MenuButton>
                  <MenuPopover position={positionMatchWidth}>
                    <MenuItems className="p-0 overflow-hidden border-none shadow-lg text-center">
                      <div className="rounded-md bg-white shadow-xs">
                        {config.settings.map((value) => {
                          return (
                            <div className="py-1" key={value.language}>
                              <MenuItem
                                onSelect={() => {
                                  setFieldTouched("language", true);
                                  setFieldValue("language", value.language);
                                  setCurrentLanguage(value.language);
                                }}
                                className="px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-blue-700 hover:text-white"
                              >
                                <div className="flex-1 px-1">
                                  {value.language}
                                </div>
                              </MenuItem>
                            </div>
                          );
                        })}
                      </div>
                    </MenuItems>
                  </MenuPopover>
                </Menu>
              </FieldContainer>
              <FieldContainer name={t("number_of_players")}>
                <Menu>
                  <MenuButton className="block w-2/5">
                    <div className="rounded-md bg-white shadow-xs p-1 flex justify-between">
                      <div className="w-1/3" />
                      <span className="w-1/3 p-1">{numberOfPlayers}</span>
                      <span className="w-1/3 px-1 text-right text-xl">▾</span>
                    </div>
                  </MenuButton>
                  <MenuPopover className="w-2/5" position={positionMatchWidth}>
                    <MenuItems className="p-0 overflow-hidden border-none shadow-lg text-center">
                      <div className="rounded-md bg-white shadow-xs">
                        {[
                          ...Array(
                            config.multiPlayer.maxNumberOfPlayers + 1
                          ).keys(),
                        ]
                          .filter((n) => n > 0)
                          .map((num) => {
                            return (
                              <div className="py-1" key={num}>
                                <MenuItem
                                  onSelect={() => {
                                    setFieldTouched("numberOfPlayers", true);
                                    setFieldValue("numberOfPlayers", num);
                                    setPlayersNumber(num);
                                    setFieldValue(
                                      "mode",
                                      num > 1
                                        ? HangmanGameMode.MultiPlayer
                                        : HangmanGameMode.SinglePlayer
                                    );
                                    setFieldValue(
                                      "bet",
                                      num > 1
                                        ? config.multiPlayer.bet.default
                                        : config.singlePlayer.bet.default
                                    );
                                    num === 1 &&
                                      setFieldValue("numberOfRounds", 1);
                                    num === 1 &&
                                      setFieldValue(
                                        "levelOfDifficulty",
                                        config.singlePlayer.levelOfDifficulty
                                          .default
                                      );
                                    num === 1 && setRoundsNumber(1);
                                    num > 1 &&
                                      numberOfPlayers === 1 &&
                                      setFieldValue(
                                        "numberOfRounds",
                                        config.multiPlayer.numberOfRounds
                                          .default
                                      );
                                    num > 1 &&
                                      numberOfPlayers === 1 &&
                                      setRoundsNumber(
                                        config.multiPlayer.numberOfRounds
                                          .default
                                      );

                                    num > 1 &&
                                      numberOfPlayers === 1 &&
                                      setFieldValue(
                                        "levelOfDifficulty",
                                        config.multiPlayer.levelOfDifficulty
                                          .default
                                      );
                                  }}
                                  className="px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-blue-700 hover:text-white"
                                >
                                  <div className="flex-1 px-1">{num}</div>
                                </MenuItem>
                              </div>
                            );
                          })}
                      </div>
                    </MenuItems>
                  </MenuPopover>
                </Menu>
              </FieldContainer>
              {multiplayer ? (
                <FieldContainer name={t("number_of_rounds")}>
                  <Slider
                    disabled={isSubmitting}
                    value={values.numberOfRounds}
                    min={config.multiPlayer.numberOfRounds.minimum}
                    max={config.multiPlayer.numberOfRounds.maximum}
                    step={config.multiPlayer.numberOfRounds.step}
                    onChange={(value) => {
                      setFieldTouched("numberOfRounds", true);
                      setFieldValue("numberOfRounds", value);
                      setRoundsNumber(value);
                    }}
                  />
                  <Field
                    disabled={isSubmitting}
                    name="numberOfRounds"
                    type="number"
                    className="form-input rounded py-0  text-sm rounded shadow-xl text-center w-2/5"
                  />
                </FieldContainer>
              ) : null}
              <FieldContainer name={t("level_of_difficulty")}>
                <Slider
                  disabled={isSubmitting}
                  value={values.levelOfDifficulty}
                  min={levelValue(values.language).min}
                  max={levelValue(values.language).max}
                  step={
                    multiplayer
                      ? config.multiPlayer.levelOfDifficulty.step
                      : config.singlePlayer.levelOfDifficulty.step
                  }
                  onChange={(value) => {
                    setFieldTouched("levelOfDifficulty", true);
                    setFieldValue("levelOfDifficulty", value);
                  }}
                />
                <Field
                  disabled={isSubmitting}
                  name="levelOfDifficulty"
                  type="number"
                  className="form-input rounded py-0  text-sm rounded shadow-xl text-center w-2/5"
                />
                <ErrorMessage
                  name="levelOfDifficulty"
                  className="mt-2 font-bold text-red-600"
                  component="div"
                />
              </FieldContainer>
              <FieldContainer name={t("bet")}>
                <Slider
                  disabled={isSubmitting}
                  value={
                    typeof values.bet !== "number"
                      ? multiplayer
                        ? minimalValue
                        : config.singlePlayer.bet.minimum
                      : values.bet
                  }
                  min={
                    multiplayer ? minimalValue : config.singlePlayer.bet.minimum
                  }
                  max={
                    multiplayer
                      ? config.multiPlayer.bet.maximum
                      : config.singlePlayer.bet.maximum
                  }
                  step={
                    multiplayer
                      ? config.multiPlayer.bet.step
                      : config.singlePlayer.bet.step
                  }
                  onChange={(value) => {
                    setFieldTouched("bet", true);
                    setFieldValue("bet", value);
                  }}
                />
                {multiplayer &&
                  values.bet < minimalValue &&
                  setFieldValue("bet", minimalValue)}
                <Field
                  disabled={isSubmitting}
                  name="bet"
                  type="number"
                  className="form-input rounded py-0  text-sm rounded shadow-xl text-center w-2/5"
                />
                <ErrorMessage
                  name="bet"
                  className="mt-2 font-bold text-red-600"
                  component="div"
                />
              </FieldContainer>
              <FieldContainer name={t("hangman.prize")}>
                <div className="form-input rounded py-0  text-sm rounded shadow-xl text-center w-2/5">
                  {multiplayer
                    ? values.bet * values.numberOfPlayers -
                      (values.bet *
                        values.numberOfPlayers *
                        round(
                          config.multiPlayer.commissions.find(
                            (c) => c.maxBet >= values.bet
                          )?.commission ?? 0
                        )) /
                        100
                    : values.bet * 2 -
                      (values.bet *
                        round(
                          config.singlePlayer.commissions.find(
                            (c) => c.maxBet >= values.bet
                          )?.commission ?? 0
                        )) /
                        100}
                </div>
              </FieldContainer>
            </div>
            <ButtonWithSound
              type="submit"
              disabled={isSubmitting || !isValid}
              className="btn w-full btn-blue-600 rounded mt-4 flex-shrink-0"
            >
              {!isSubmitting ? t("create_table") : t("loading")}
            </ButtonWithSound>
          </form>
        );
      }}
    </Formik>
  );
};
