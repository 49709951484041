import React from "react";

const EmailIcon = ({
  backgroundColor,
  fillColor,
}: {
  backgroundColor?: string;
  fillColor?: string;
}) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill={backgroundColor} />
    <path
      d="M28 12H12C10.9 12 10.01 12.9 10.01 14L10 26C10 27.1 10.9 28 12 28H28C29.1 28 30 27.1 30 26V14C30 12.9 29.1 12 28 12ZM28 16L20 21L12 16V14L20 19L28 14V16Z"
      fill={fillColor}
    />
  </svg>
);

export default EmailIcon;
