import * as React from "react";
import { Tooltip } from "../Tooltip";
import message from "../../assets/message.svg";
import facebookChat from "../../assets/facebook-messenger.svg";

export const ChatBotButton = React.memo(() => {
  return (
    <Tooltip label={"Facbook chat"}>
      <a
        target="_blank"
        href={process.env.REACT_APP_FACEBOOK_CHAT}
        className="text-white "
      >
        <img src={facebookChat} alt="" className="w-4 h-4" />
      </a>
    </Tooltip>
  );
});
