import * as React from "react";
import { useAudio } from "./AudioProvider";
import * as H from "history";
import { Link, LinkProps } from "react-router-dom";

export function LinkWithSound<S = H.LocationState>({
  onClick,
  ...rest
}: React.PropsWithoutRef<LinkProps<S>> &
  React.RefAttributes<HTMLAnchorElement>) {
  const { play } = useAudio();
  return (
    <Link
      onClick={(props) => {
        play("button");
        if (onClick !== undefined) return onClick(props);
      }}
      {...rest}
    />
  );
}
