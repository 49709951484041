import * as React from "react";
import { useNumberFormatter } from "../hooks/useNumberFormatter";

export const FormattedNumber = React.memo<{
  value: number;
  options?: Intl.NumberFormatOptions;
}>(({ value, options }) => {
  const formatter = useNumberFormatter(options);
  return formatter.format(value);
});
