import * as React from "react";

const RightBarContext = React.createContext<[boolean, () => void, () => void]>([
  false,
  () => null,
  () => null,
]);

export const RightBarProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [isOpen, setOpen] = React.useState<boolean>(false);

  const close = React.useCallback(() => setOpen(false), [setOpen]);
  const open = React.useCallback(() => setOpen(true), [setOpen]);

  const contextValue: [boolean, () => void, () => void] = React.useMemo(
    () => [isOpen, open, close],
    [isOpen, open, close]
  );

  return (
    <RightBarContext.Provider value={contextValue}>
      {children}
    </RightBarContext.Provider>
  );
};

export const useRightBar = () => {
  return React.useContext(RightBarContext);
};
