import * as React from "react";
import { Trans } from "react-i18next";
import { PlayerFragmentFragment } from "../../../graphql/PlayerFragment.generated";
import { UserMessageContentFragmentFragment } from "../../../graphql/ActivityFragment.generated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/pro-solid-svg-icons";
import { useMessageTranslation } from "../../TranslationProvider";

export const UserMessageActivity = ({
  id,
  actor,
  openProfile,
  content,
}: {
  id: string;
  actor: PlayerFragmentFragment | null;
  content: UserMessageContentFragmentFragment;
  openProfile: () => void;
}) => {
  const [message, translate] = useMessageTranslation(id, content.message);
  return (
    <>
      <div className="flex -mx-2 items-center justify-between">
        <div className="px-2">
          <Trans i18nKey="notification.user_message_activity">
            <button onClick={openProfile}>
              <strong>{{ actor: actor!.name }}</strong>
            </button>{" "}
            has sent you a message
          </Trans>
        </div>
        <div className="px-2 text-gray-900">
          <button disabled={message.fetching} onClick={translate}>
            {message.fetching ? (
              <span className="flex h-3 w-3 relative">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-500 opacity-75" />
                <span className="relative inline-flex rounded-full h-3 w-3 bg-blue-600" />
              </span>
            ) : (
              <FontAwesomeIcon
                icon={faLanguage}
                className={
                  !message.showTranslated ? "text-gray-500" : "text-blue-500"
                }
              />
            )}
          </button>
        </div>
      </div>
      <div className="text-sm">
        {message.showTranslated ? message.data : message.original}
      </div>
    </>
  );
};
