import {
  getGuestId,
  setGuestId,
  clearGuestId,
  getIsLoggedAsGuest,
  setIsLoggedAsGuest,
  clearIsLoggedAsGuest,
} from "../guestId";

export const useGuestId = (): [
  () => string | null,
  (token: string) => void,
  () => void,
  () => boolean,
  (isGuest: boolean) => void,
  () => void
] => {
  return [
    getGuestId,
    setGuestId,
    clearGuestId,
    getIsLoggedAsGuest,
    setIsLoggedAsGuest,
    clearIsLoggedAsGuest,
  ];
};
