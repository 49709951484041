import * as React from "react";
import screenfull from "screenfull";

const FullScreenContext = React.createContext<[boolean, () => void, boolean]>([
  false,
  () => null,
  false,
]);

export const FullScreenProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [isFullScreen, setFullScreen] = React.useState<boolean>(
    screenfull.isEnabled && screenfull.isFullscreen
  );

  React.useEffect(() => {
    if (screenfull.isEnabled) {
      const callback = () =>
        setFullScreen(screenfull.isEnabled && screenfull.isFullscreen);
      screenfull.on("change", callback);
      return () => {
        if (screenfull.isEnabled) screenfull.off("change", callback);
      };
    }
  }, []);

  const contextValue: [boolean, () => void, boolean] = React.useMemo(
    () => [
      isFullScreen,
      () => {
        if (screenfull.isEnabled) {
          screenfull.toggle();
        }
      },
      screenfull.isEnabled,
    ],
    [isFullScreen]
  );
  return (
    <FullScreenContext.Provider value={contextValue}>
      {children}
    </FullScreenContext.Provider>
  );
};

export const useFullScreen = () => {
  return React.useContext(FullScreenContext);
};
