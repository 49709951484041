import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faVolumeMute, faVolume } from "@fortawesome/pro-solid-svg-icons";
import { useAudio } from "../AudioProvider";
import { Tooltip } from "../Tooltip";
import { useTranslation } from "react-i18next";
import musicOn from "./assets/musicOn.svg";
import musicOff from "./assets/musicOff.svg";

export const MuteButton = React.memo(() => {
  const { muted, toggleMute } = useAudio();
  const { t } = useTranslation();
  return (
    <Tooltip label={t(muted ? "unmute" : "mute")}>
      <button onClick={toggleMute} className="text-white">
        <img src={muted ? musicOff : musicOn} alt="" />
      </button>
    </Tooltip>
  );
});
