import * as React from "react";
import classNames from "classnames";
import { useMergedRef } from "../../hooks/useMergedRef";
import { useSizeObserve } from "../../hooks/useSizeObserve";
import { isFunction, isNotMainScreenDesktopWidth } from "../../utils";
import { useFullScreen } from "../FullScreenProvider";
import paymentsFooter from "./payments_footer.png";
import logoMain from "./logo.jpg";
import chatButton from "./chatButton.png";
import { Trans, useTranslation } from "react-i18next";
import { gsap } from "gsap";
import {
  PRIVACY_POLICY_URL,
  T_AND_C_URL,
  HELP_URL,
  CONTACT_US_URL,
  LIKE_BUTTON_URL,
} from "../../config";
import styles from "./Authenticated.module.scss";
import { AbsoluteCenter } from "../AbsoluteCenter";
import { useIsMounted } from "../../hooks/useIsMounted";
import { useFacebook } from "../Facebook";
import { ErrorBoundary } from "../ErrorBoundary";
import { useScript } from "../../hooks/useScript";
import { useIsInTable } from "../../hooks/useIsInTable";
import { useCustomChatContext, useUser } from "../UserProvider";
import { useLocation } from "react-router";
import { useRegisterPopup } from "../Register";
import { useGuestId } from "../../hooks/useGuestId";
import { useRightBar } from "../Bar/RightBar";
import { LIKE_BUTTON_HEIGHT } from "../../other/screenType";
import { useScreenState } from "../ScreenState";
import { useVipInstructionPopup } from "../VipInformation";

let DEFAULT_CONTENT_AREA_WIDTH = 1280;
const DEFAULT_CONTENT_AREA_WIDTH_FULL_SCREEN = 1320;
const DEFAULT_CONTENT_AREA_HEIGHT = 800;
const SPLASH_SCREEN_TIMEOUT_IN_SECONDS = 2;
const isDevelopment = process.env.NODE_ENV === "development";

const CENTER_STYLE = {
  left: "50%",
  top: "50%",
  transform: `translate(-50%, -50%)`,
};
const DEFAULT_SIZE: {
  width: number | string;
  height: number | string;
  scale: number;
} = {
  width: DEFAULT_CONTENT_AREA_WIDTH,
  height: DEFAULT_CONTENT_AREA_HEIGHT,
  scale: 1,
};

const calculateScale = (isFullScreen: boolean) => {
  const scale = Math.min(
    document.documentElement.clientWidth /
      (isFullScreen
        ? DEFAULT_CONTENT_AREA_WIDTH_FULL_SCREEN
        : DEFAULT_CONTENT_AREA_WIDTH),
    document.documentElement.clientHeight / DEFAULT_CONTENT_AREA_HEIGHT
  );
  if (isFullScreen) return scale;
  return Math.min(1, scale);
};

const Footer = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [isMainScreen, setIsMainScreen] = React.useState<boolean>(false);
  const [doForceDesktopScreenSize] = useScreenState();

  React.useEffect(() => {
    const isScreen = isNotMainScreenDesktopWidth(pathname);
    setIsMainScreen(isScreen);
  }, [pathname]);

  React.useEffect(() => {
    const onResize = () => {
      const isScreen = isNotMainScreenDesktopWidth(pathname);
      setIsMainScreen(doForceDesktopScreenSize ? false : isScreen);
    };
    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [doForceDesktopScreenSize]);

  const [, isFacebookCanvas] = useFacebook();
  return (
    <div
      className={classNames(
        "flex",
        "items-center",
        "flex-col",
        "justify-between",
        "mt-2",
        "text-gray-400",
        "text-xs",
        "leading-none",
        isMainScreen && "pt-2 pb-4"
      )}
    >
      <div
        className={classNames(
          "flex",
          "items-center",
          "justify-between",
          "-mx-2",
          "w-full",
          isMainScreen && "flex-col justify-center"
        )}
      >
        <div
          className={classNames(
            "flex",
            "-mx-2",
            "px-2",
            "text-white",
            isMainScreen && "mb-2"
          )}
          style={{
            fontFamily: "PoppinsRegular",
            fontSize: "12px",
          }}
        >
          <a
            href={HELP_URL}
            className="px-2 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("help")}
          </a>
          <span className={styles.divider} />
          <a
            href={PRIVACY_POLICY_URL}
            className="px-2 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("privacy_policy")}
          </a>
          <span className={styles.divider} />
          <a
            href={T_AND_C_URL}
            className="px-2 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("terms_and_conditions")}
          </a>
          <span className={styles.divider} />
          <a
            href={CONTACT_US_URL}
            className="px-2 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("contact_us")}
          </a>
        </div>
        {!isFacebookCanvas && (
          <div className="px-2">
            <img src={paymentsFooter} alt={t("logo")} width={68} height={14} />
          </div>
        )}
      </div>
      <div
        className={classNames(
          "flex",
          "flex-row",
          "mt-2",
          "px-2",
          "text-white",
          isMainScreen && "mb-2"
        )}
        style={{
          fontFamily: "PoppinsRegular",
          fontSize: "12px",
        }}
      >
        <Trans i18nKey="google_privacy_policy">
          <p>
            The games are intended for an adult audience. The games do not offer
            "real money gambling" or an opportunity to win real money or prizes.
            Practice or success at social casino gaming does not imply future
            success at "real money gambling." This site is protected by
            reCAPTCHA and the Google
            <a
              href="https://policies.google.com/privacy"
              className="text-decoration-line: underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            and
            <a
              href="https://policies.google.com/terms"
              className="text-decoration-line: underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            apply.
          </p>
        </Trans>
      </div>
    </div>
  );
};

const FirstLoadBanner = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = React.useState(true);
  const mounted = useIsMounted();
  const ref = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    const opacityTween = gsap.fromTo(
      ref.current,
      { alpha: 1 },
      {
        alpha: 0,
        duration: 0.33,
        delay: SPLASH_SCREEN_TIMEOUT_IN_SECONDS,
        onComplete: () => {
          if (mounted.current) setVisible(false);
        },
      }
    );

    return () => {
      opacityTween.kill();
    };
  }, [mounted]);

  return (
    <>
      {visible && (
        <AbsoluteCenter className="z-50 bg-white" ref={ref}>
          <img src={logoMain} alt={t("logo")} />
        </AbsoluteCenter>
      )}
    </>
  );
};

const LikeButton = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isReady] = useFacebook();

  React.useEffect(() => {
    if (!isReady) return;
    FB.XFBML.parse(ref.current);
  }, [isReady]);

  return (
    <ErrorBoundary fallback={null}>
      <div
        ref={ref}
        className="mb-2 leading-none"
        style={{ height: LIKE_BUTTON_HEIGHT }}
      >
        <div
          className="fb-like"
          data-href={LIKE_BUTTON_URL}
          data-width=""
          data-layout="button_count"
          data-action="like"
          data-size="small"
          data-share="true"
        />
      </div>
    </ErrorBoundary>
  );
};

export const ContentArea = React.forwardRef<
  HTMLDivElement,
  {
    banner?: boolean;
    children: ((rect: DOMRect | null) => React.ReactNode) | React.ReactNode;
  } & JSX.IntrinsicElements["div"]
>(
  (
    { children, style: currentStyle, className, banner = false, ...rest },
    ref
  ) => {
    const [isMobileSize, setIsMobileSize] = React.useState(false);
    const [isFullScreen] = useFullScreen();
    const { pathname } = useLocation();
    const [isSmallWidth, setIsSmallWidth] = React.useState<boolean>(false);

    const [doForceDesktopScreenSize] = useScreenState();
    const { isOpen } = useVipInstructionPopup();
    const [{ width, height, scale }, setSize] = React.useState(
      !isSmallWidth || doForceDesktopScreenSize
        ? {
            ...DEFAULT_SIZE,
            scale: calculateScale(isFullScreen),
          }
        : {
            width: document.documentElement.clientWidth,
            height: "100%",
            scale: 1,
          }
    );

    React.useEffect(() => {
      const onResize = () => {
        const isScreen = isNotMainScreenDesktopWidth(pathname);
        setIsSmallWidth(isScreen && !doForceDesktopScreenSize);

        setIsMobileSize(
          document.documentElement.clientWidth < 834 &&
            !doForceDesktopScreenSize
        );

        if (isScreen && !doForceDesktopScreenSize) {
          setSize({
            width: document.documentElement.clientWidth,
            height: "100%",
            scale: 1,
          });
        } else {
          setSize({
            ...DEFAULT_SIZE,
            scale: calculateScale(isFullScreen),
          });
        }
      };
      onResize();
      window.addEventListener("resize", onResize);
      return () => window.removeEventListener("resize", onResize);
    }, [isFullScreen, doForceDesktopScreenSize]);

    React.useEffect(() => {
      DEFAULT_CONTENT_AREA_WIDTH = 1600;
      window.dispatchEvent(new Event("resize"));

      const isScreen = isNotMainScreenDesktopWidth(pathname);
      setIsSmallWidth(isScreen && !doForceDesktopScreenSize);
    }, []);

    React.useEffect(() => {
      const isScreen = isNotMainScreenDesktopWidth(pathname);
      setIsSmallWidth(isScreen && !doForceDesktopScreenSize);

      if (isScreen && !doForceDesktopScreenSize) {
        setSize({
          width: document.documentElement.clientWidth,
          height: "100%",
          scale: 1,
        });
      } else {
        setSize({
          ...DEFAULT_SIZE,
          scale: calculateScale(isFullScreen),
        });
      }
    }, [pathname, doForceDesktopScreenSize]);

    const style = React.useMemo(
      () => ({
        ...currentStyle,
        width,
        height,
      }),
      [currentStyle, height, width]
    );
    const scaleStyle = React.useMemo(
      () => ({
        transform: isOpen ? "none" : `scale(${scale})`,
      }),
      [scale, isOpen]
    );

    const target = React.useRef<HTMLDivElement>(null);
    const multiRef = useMergedRef(ref, target);
    const rect = useSizeObserve(target, isFunction(children));
    const [isRightBarOpened, ,] = useRightBar();

    return (
      <div
        className={isSmallWidth ? "" : "fixed"}
        style={isSmallWidth ? {} : CENTER_STYLE}
      >
        <div style={scaleStyle}>
          <div
            className={classNames(
              "flex",
              "items-center",
              isSmallWidth ? "" : "-mx-6",
              isSmallWidth ? "flex-col" : "",
              isRightBarOpened ? "h-screen" : "",
              isRightBarOpened ? "overflow-y-hidden" : ""
            )}
          >
            <div
              className={classNames(
                "flex",
                "flex-col",
                isSmallWidth ? "p-0" : "px-6"
              )}
            >
              {!isFullScreen && !isMobileSize && <LikeButton />}
              <div
                className={classNames(
                  "overflow-hidden",
                  "h-full",
                  "w-full",
                  "relative",
                  className,
                  {
                    "rounded-lg": 0,
                  }
                )}
                style={style}
                ref={multiRef}
                {...rest}
              >
                {!isDevelopment && !isSmallWidth && banner && (
                  <FirstLoadBanner />
                )}
                {isFunction(children) ? children(rect) : children}
              </div>
              {!isFullScreen && <Footer />}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
