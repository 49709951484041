import * as React from "react";

import { useUser } from "../UserProvider";
import { useShop } from "../Shop";
import { Amount } from "../Amount";
import vipIcon from "../../assets/vip-popup.png";
import { useVipInstructionPopup } from "../VipInformation";

const Balances = () => {
  const {
    user: { balances },
  } = useUser();
  const openShop = useShop();
  const { setOpen } = useVipInstructionPopup();

  return (
    <>
      <div className="flex w-full">
        {balances.map((balance) => (
          <button
            className="w-1/3"
            key={balance.currency}
            onClick={() => openShop(balance.currency)}
          >
            <Amount value={balance.value} currency={balance.currency} />
          </button>
        ))}
      </div>
      <div className="flex items-center w-10 h-10 mr-2">
        <img
          src={vipIcon}
          alt="vip-icon"
          className="cursor-pointer"
          onClick={setOpen}
        />
      </div>
    </>
  );
};

export default Balances;
