import { Subscription, useSubscription } from "use-subscription";
import * as React from "react";

const EVENT_NAME = "storage_change";

let evtTarget: EventTarget | HTMLDivElement;

try {
  evtTarget = new EventTarget();
} catch (error) {
  evtTarget = document.createElement("div");
}

function makeStorage(storage: Storage) {
  return function useInnerStorage<V = any>(
    key: string,
    defaultValue: V
  ): [V, (v: V) => void, () => void] {
    const subscription = React.useMemo(
      (): Subscription<V> => ({
        getCurrentValue: () => {
          const raw = storage.getItem(key);
          return raw !== null ? JSON.parse(raw) : defaultValue;
        },
        subscribe: (callback) => {
          const listener = (evt: Event) => {
            if (evt instanceof CustomEvent) {
              const { detail } = evt;
              if (detail.key === key) {
                callback();
              }
            }
          };
          evtTarget.addEventListener(EVENT_NAME, listener);
          return () => evtTarget.removeEventListener(EVENT_NAME, listener);
        },
      }),
      [defaultValue, key]
    );

    const updater = React.useCallback(
      (updatedValue: V) => {
        if (updatedValue == null) storage.removeItem(key);
        else storage.setItem(key, JSON.stringify(updatedValue));

        evtTarget.dispatchEvent(
          new CustomEvent<{ key: string }>(EVENT_NAME, { detail: { key } })
        );
      },
      [key]
    );

    const reset = React.useCallback(() => updater(defaultValue), [
      defaultValue,
      updater,
    ]);

    const value = useSubscription(subscription);

    return [value, updater, reset];
  };
}

export const useLocalStorage = makeStorage(localStorage);

export const useSessionStorage = makeStorage(sessionStorage);
