import * as React from "react";
import { useTranslation } from "react-i18next";

export function TournamentInfo({ config }: { config: any }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex justify-between -mx-2 py-1">
        <div className="px-2">{t("type")}</div>
        <div className="px-2">{t(`game.tictactoe`)}</div>
      </div>
      <div className="flex justify-between -mx-2 py-1">
        <div className="px-2">{t("turn_time")}</div>
        <div className="px-2">
          {t("seconds_value", { value: config.turnTime })}
        </div>
      </div>
      <div className="flex justify-between -mx-2 py-1">
        <div className="px-2">{t("tictactoe.grid_size")}</div>
        <div className="px-2">{`${config.gridSize} x ${config.gridSize}`}</div>
      </div>
      <div className="flex justify-between -mx-2 py-1">
        <div className="px-2">{t("tictactoe.wins_to_complete")}</div>
        <div className="px-2">{config.winsToComplete}</div>
      </div>
    </>
  );
}
