import React, { useEffect, useState } from "react";
import { TypingIndicator, useChannelStateContext } from "stream-chat-react";
import { useUserId } from "../UserProvider";
import chatDefault from "./assets/chatDefault.svg";
import userDefault from "./assets/userDefault.svg";

const CustomChannelHeader = () => {
  const { channel } = useChannelStateContext();
  const { name } = channel.data || {};

  const directChat =
    channel.data?.type === "direct_others" ||
    channel.data?.type === "direct_friends";
  const userId = useUserId();
  const [user, setUser] = useState<{
    name: string | undefined;
    image: string | undefined;
  }>();

  const getUserProfile = () => {
    const state = Object.values(channel.state.read);
    state.map((e) => {
      if (e.user.id !== userId) {
        setUser({ name: e.user.name, image: e.user.image });
      }
    });
  };

  const [showUserImage, setShowUserImage] = useState<boolean>(false);
  const [showChannelImage, setShowChannelImage] = useState<boolean>(false);

  function testImage(url: string) {
    var tester = new Image();
    tester.addEventListener("load", () => {
      if(directChat)setShowUserImage(true);
      if(!directChat) setShowChannelImage(true)
    });
    tester.addEventListener("error", () => {
      if(directChat)setShowUserImage(false);
      if(!directChat) setShowChannelImage(false)
    });
    tester.src = url;
  }

  useEffect(() => {
    if (directChat && user?.image) {
      testImage(user?.image);
    }
    if(!directChat && channel.data?.image) {
      testImage(channel.data?.image)
    }
  }, [user?.image, channel.data?.image]);

  const channelImage = () => {
    if (directChat && user?.image && showUserImage) return user?.image;
    if (directChat) return userDefault;
    if (channel.data?.image && showChannelImage) return channel.data?.image;
    return chatDefault;
  };

  useEffect(() => {
    getUserProfile();
  }, [channel]);

  return (
    <div className="chat-header-container">
      <div className="flex">
        <div>
          <img src={channelImage()} alt="avatar" className="avatar-wrapper" />
        </div>
        <span className="name-text">{directChat ? user?.name : name}</span>
      </div>
    </div>
  );
};
export default CustomChannelHeader;
