import * as React from "react";
import { Layout } from "../Layout";
import { Header } from "../Header";
import { PixiStage } from "../PixiStage";
import { Sprite, Text } from "@inlet/react-pixi";
import bgImage from "./assets/bg.png";
import boardImage from "./assets/blankBoard.png";
import closeInfo from "./assets/closeInfo.png";
import info from "./assets/info.png";
import closeImage from "./assets/close.png";
import arrowLeftImage from "./assets/arrow_left.png";
import arrowRightImage from "./assets/arrow_right.png";
import { useTranslation } from "react-i18next";
import * as PIXI from "pixi.js";
import { loader } from "graphql.macro";
import {
  LeaderboardsQuery,
  LeaderboardsQueryVariables,
} from "../../graphql/LeaderboardsQuery.generated";
import { useQuery } from "../../hooks/useQuery";
import { useParams } from "react-router-dom";
import {
  LeaderboardRecordsQuery,
  LeaderboardRecordsQueryVariables,
} from "../Leaderboards/graphql/LeaderboardRecordsQuery.generated";
import styles from "./HangmanDailyChallenges.module.scss";
import trophy from "./assets/trophy.png";
import classNames from "classnames";
import { Flag } from "../Flag";
import { Currency } from "../../graphql/generated";
import { useNumberFormatter } from "../../hooks/useNumberFormatter";
import { CurrencyImage } from "../CurrencyImage";
import { ProfileImage } from "../ProfileImage";

const LEADERBOARDS_QUERY = loader("../../graphql/LeaderboardsQuery.graphql");
const LEADERBOARD_RECORDS_QUERY = loader(
  "../Leaderboards/graphql/LeaderboardRecordsQuery.graphql"
);

const WIDTH = 1280;
const HEIGHT = 750;

const AVATAR_SIZE = {
  width: 40,
  height: 40,
};

interface AdditionalParams {
  language: string;
  roundsWin: number;
  elapsedTimeMs: number;
}

const STAGE_OPTIONS = {
  width: WIDTH,
  height: HEIGHT,
  options: {
    backgroundColor: 0xffffff,
    autoDensity: true,
  },
};
type LeaderboardsArray = NonNullable<LeaderboardsQuery["leaderboards"]>;

const MoreInfo = ({ showInfo, closeMoreInfo }: { showInfo: boolean, closeMoreInfo: () => void; }) => {
  const { t } = useTranslation();
  return (
    <>
      {showInfo && (
        <div className={classNames(styles.moreInfoContainer, "flex flex-col justify-between")}>
          <div className="flex" style={{ marginBottom: "15px" }}>
            <img src={closeInfo} alt="closeMoreInfo" className="cursor-pointer" onClick={closeMoreInfo} />{" "}
            <span
              className={styles.leaderboardText}
              style={{ fontSize: "22px", paddingTop: "5px" }}
            >
              {t("more_info").toLocaleLowerCase()}
            </span>
          </div>
          <div style={{ marginBottom: "15px" }}>
            <span
              className={styles.leaderboardText}
              style={{ fontSize: "22px" }}
            >
              {t("more_info_text").split(".")[0] + "."}
            </span>
          </div>
          <div style={{ marginBottom: "15px" }}>
            <span
              className={styles.leaderboardText}
              style={{ fontSize: "22px" }}
            >
              {t("more_info_text").split(".")[1] + "."}
            </span>
          </div>
          <div>
            <span
              className={styles.leaderboardText}
              style={{ fontSize: "22px" }}
            >
              {t("more_info_text").split(".")[2] + "."}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

const Records = React.memo(({ leaderboardId }: { leaderboardId: string }) => {
  const { t } = useTranslation();
  const [{ fetching, data }] = useQuery<
    LeaderboardRecordsQuery,
    LeaderboardRecordsQueryVariables
  >({
    query: LEADERBOARD_RECORDS_QUERY,
    variables: {
      leaderboardId: leaderboardId,
    },
    requestPolicy: "network-only",
  });
  const placeEnding = (place: number) => {
    if (place === 1) return "st";
    if (place === 2) return "nd";
    if (place === 3) return "rd";
    return "th";
  };
  const leaderboard = data?.leaderboard;

  if (fetching)
    return (
      <div
        className={classNames(styles.spinner, "absolute")}
        style={{ width: "50px", height: "50px", top: "300px", left: "600px" }}
      />
    );
  return (
    <>
      <div className={styles.leaderBoardContainer} style={{ left: "197px" }}>
        <div className={styles.leaderboardHeder}>
          <span className={styles.leaderboardHeaderText}>challenges</span>
          <img
            src={trophy}
            className="absolute"
            style={{ top: "-4px", left: "530px" }}
            alt="trophy"
          />
          <div style={{ width: "50px", height: "50px" }} />
        </div>
        <div className={styles.valuesStyle}>
          <span style={{ width: "10%" }} className={styles.leaderboardText} />
          <span style={{ width: "10%" }} className={styles.leaderboardText} />
          <span
            className={classNames(styles.leaderboardText, "underline")}
            style={{ width: "17.5%" }}
          >
            {t("name")}
          </span>
          <span
            className={classNames(styles.leaderboardText, "underline")}
            style={{ width: "15%" }}
          >
            {t("country_short")}
          </span>
          <span
            className={classNames(styles.leaderboardText, "underline")}
            style={{ width: "17.5%" }}
          >
            {t("language_field")}
          </span>
          <span
            className={classNames(styles.leaderboardText, "underline")}
            style={{ width: "15%" }}
          >
            {t("rounds")}
          </span>
          <span
            className={classNames(styles.leaderboardText, "underline")}
            style={{ width: "15%" }}
          >
            {t("time")}
          </span>
        </div>
        <div className={styles.leaderboardList} style={{ paddingTop: "36px" }}>
          {leaderboard?.records.map((record) => (
            <div className={styles.recordContainer} key={record.id}>
              <span
                className={classNames(styles.leaderboardText, "underline")}
                style={{ width: "10%" }}
              >
                {record.position + placeEnding(record.position)}
              </span>
              <span
                className={classNames("flex items-center justify-center")}
                style={{ width: "10%", paddingTop: "5px" }}
              >
                <ProfileImage
                  id={record.player.id}
                  name={record.player.name}
                  className="bg-gray-400 rounded-full"
                  {...AVATAR_SIZE}
                />
              </span>
              <span
                className={styles.leaderboardText}
                style={{ width: "17.5%" }}
              >
                {record.player.name}
              </span>
              <span
                className={styles.leaderboardText}
                style={{ width: "15%" }}
              >
                {record.player.country && (
                  <Flag country={record.player.country} />
                )}
              </span>
              <span
                className={styles.leaderboardText}
                style={{ width: "17.5%" }}
              >
                {(record as AdditionalParams).language}
              </span>
              <span
                className={styles.leaderboardText}
                style={{ width: "15%" }}
              >
                {(record as AdditionalParams).roundsWin}
              </span>
              <span className={styles.leaderboardText} style={{ width: "15%" }}>
                {new Date((record as AdditionalParams).elapsedTimeMs)
                  .toISOString()
                  .slice(11, 19)}
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
});

const LeaderboardRecords = ({
  leaderboards,
  currentLeaderboard,
  showInfo,
}: {
  leaderboards: LeaderboardsArray;
  currentLeaderboard: (idx: number) => void;
  showInfo: boolean;
}) => {
  const [
    selectedLeaderboardIndex,
    setSelectedLeaderboardIndex,
  ] = React.useState<number>(0);
  const { t } = useTranslation();
  const numberFormatter = useNumberFormatter();
  React.useEffect(() => {
    setSelectedLeaderboardIndex((selectedLeaderboardIndex) =>
      selectedLeaderboardIndex >= leaderboards.length
        ? 0
        : selectedLeaderboardIndex
    );
    currentLeaderboard(selectedLeaderboardIndex);
  }, [leaderboards, selectedLeaderboardIndex, currentLeaderboard]);

  const nextLeaderboard = () => {
    if (selectedLeaderboardIndex < leaderboards.length - 1) {
      setSelectedLeaderboardIndex(selectedLeaderboardIndex + 1);
    }
  };

  const prevLeaderboard = () => {
    if (selectedLeaderboardIndex !== 0) {
      setSelectedLeaderboardIndex(selectedLeaderboardIndex - 1);
    }
  };

  const prices = React.useMemo(() => {
    const prices = new Map<number, Map<Currency, number>>();
    for (const { from, to, amount } of leaderboards[selectedLeaderboardIndex]
      .prices) {
      if (from >= 1 || to <= 5)
        for (let i = from; i <= to; i++) {
          if (i > 5) continue;
          const positionPrices = prices.get(i) ?? new Map();
          const currencyAmount =
            (positionPrices.get(amount.currency) ?? 0) + amount.value;
          positionPrices.set(amount.currency, currencyAmount);
          prices.set(i, positionPrices);
        }
    }
    return prices;
  }, [leaderboards, selectedLeaderboardIndex]);

  return (
    <>
      <div
        style={{
          left: "115px",
        }}
        className={styles.arrow}
        onClick={prevLeaderboard}
      />
      <div
        style={{
          left: "1125px",
        }}
        className={styles.arrow}
        onClick={nextLeaderboard}
      />
      <div
        className={classNames(styles.rewardText, "p-0")}
        style={{
          position: "absolute",
          top: "635px",
          left: "795px",
        }}
      >
        {t("more_info").toLocaleLowerCase()}
      </div>
      <div
        className="absolute"
        style={{
          top: "592px",
          left: "246px",
        }}
      >
        {Array(5)
          .fill(null)
          .map((_, index) => {
            const awardsMap = prices.get(index + 1) ?? new Map();
            const awards = Object.values(Currency)
              .filter((currency) => awardsMap.has(currency))
              .map((currency) => ({
                currency,
                value: awardsMap.get(currency),
              }));

            if (awards.length === 0) return null;
            return (
              <div key={index} className="inline-block">
                {awards.map((award) => (
                  <div className="pr-2 inline-block" key={award.currency}>
                    <div className="flex items-center -mx-1">
                      <span className={styles.rewardText}>
                        {t(`leader_board_place_${index + 1}`)}
                      </span>
                      <span className={styles.rewardText}>
                        {numberFormatter.format(award.value)}
                      </span>
                      <div className="px-1">
                        <CurrencyImage
                          currency={award.currency}
                          width={18}
                          height={18}
                        />
                      </div>
                      {index !== prices.size - 1 && (
                        <span className={classNames(styles.rewardText)}>|</span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            );
          })}
      </div>
      <Records leaderboardId={leaderboards[selectedLeaderboardIndex].id} />
    </>
  );
};

export const Leaderboard = ({ goBack }: { goBack: () => void }) => {
  const { sectionId } = useParams<{
    sectionId: string;
  }>();
  const { t } = useTranslation();
  const [leaderboardName, setLeaderbordName] = React.useState<string>("");
  const [showInfo, setShowInfo] = React.useState<boolean>(false);
  const [{ fetching, data }] = useQuery<
    LeaderboardsQuery,
    LeaderboardsQueryVariables
  >({
    query: LEADERBOARDS_QUERY,
    variables: {
      filter: React.useMemo(
        () => ({
          rooms: [],
          sections: [sectionId],
        }),
        [sectionId]
      ),
    },
    requestPolicy: "network-only",
  });
  const leaderboards = data?.leaderboards ?? null;
  const textStyle = new PIXI.TextStyle({
    fontSize: 45,
    fontFamily: "Bradley Hand",
    fontWeight: "bold",
    fill: ["#505050", "#505050"],
    align: "center",
    padding: 5,
  });
  const moreInfo = () => {
    setShowInfo((prev) => !prev);
  };
  const currentLeaderboard = (idx: number) => {
    if (leaderboards) setLeaderbordName(leaderboards[idx].name);
  };
  return (
    <Layout header={<Header />}>
      {fetching ? (
        <div
          className={classNames(styles.spinner, "absolute")}
          style={{ width: "50px", height: "50px", top: "300px", left: "600px" }}
        />
      ) : (
        <>
          {leaderboards !== null && leaderboards.length > 0 && (
            <LeaderboardRecords
              leaderboards={leaderboards}
              currentLeaderboard={currentLeaderboard}
              showInfo={showInfo}
            />
          )}
          {(leaderboards === null || leaderboards.length === 0) && (
            <div
              className="absolute w-full flex justify-center"
              style={{ top: "278px", left: "0px" }}
            >
              <span className={styles.leaderboardText}>
                {t("hangman.no_leaderboard")}
              </span>
            </div>
          )}
        </>
      )}
      <MoreInfo showInfo={showInfo} closeMoreInfo={moreInfo} />
      <PixiStage {...STAGE_OPTIONS}>
        <Sprite image={bgImage} />
        <Sprite
          image={closeImage}
          y={25}
          x={1175}
          interactive
          click={goBack}
          buttonMode
        />
        <Text
          text={leaderboardName}
          style={textStyle}
          anchor={0.5}
          x={620}
          y={57}
        />
        <Sprite
          image={info}
          x={745}
          y={626}
          interactive
          buttonMode
          click={moreInfo}
        />
        <Sprite y={260} x={115} image={arrowLeftImage} />
        <Sprite y={260} x={1125} image={arrowRightImage} />
        <Sprite image={boardImage} y={99} x={190} />
      </PixiStage>
    </Layout>
  );
};
