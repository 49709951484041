import * as React from "react";
import { FriendRequestActivityContentFragmentFragment } from "../../../graphql/ActivityFragment.generated";
import { Trans, useTranslation } from "react-i18next";
import { usePendingFriendRequests } from "../../../hooks/usePendingFriendRequests";
import { PlayerFragmentFragment } from "../../../graphql/PlayerFragment.generated";
import { loader } from "graphql.macro";
import { useMutation } from "../../../hooks/useMutation";
import {
  AnswerFriendRequestMutation,
  AnswerFriendRequestMutationVariables,
} from "../../../graphql/AnswerFriendRequestMutation.generated";
import { useRegisterPopup } from "../../Register";
import { useGuestId } from "../../../hooks/useGuestId";

const ANSWER_FRIEND_REQUEST = loader(
  "../../../graphql/AnswerFriendRequestMutation.graphql"
);

export const FriendRequestActivity = ({
  actor,
  openProfile,
  content,
  setRead,
}: {
  actor: PlayerFragmentFragment | null;
  content: FriendRequestActivityContentFragmentFragment;
  openProfile: () => void;
  setRead: () => void;
}) => {
  const { t } = useTranslation();
  const friendRequests = usePendingFriendRequests();
  const [{ fetching }, answer] = useMutation<
    AnswerFriendRequestMutation,
    AnswerFriendRequestMutationVariables
  >(ANSWER_FRIEND_REQUEST);
  const showButtons = React.useMemo(() => {
    if (friendRequests === undefined) return false;

    return (
      friendRequests.find(
        (request) =>
          request.from.id === actor!.id &&
          request.created === content.initialized
      ) !== undefined
    );
  }, [friendRequests, actor, content]);

  const registration = useRegisterPopup();
  const [, , , getIsLoggedAsGuest] = useGuestId();

  const answerFriendRequest = React.useCallback(
    (value: boolean) => {
      setRead();
      if (getIsLoggedAsGuest()) {
        return registration.openRegisterPopup();
      } else {
        return answer({ answer: value, fromUser: actor!.id });
      }
    },
    [actor, answer, setRead, getIsLoggedAsGuest, registration]
  );
  return (
    <>
      <Trans i18nKey="notification.friend_request_activity">
        <button onClick={openProfile}>
          <strong>{{ actor: actor!.name }}</strong>
        </button>{" "}
        asked to be your friend
      </Trans>
      {showButtons && (
        <div className="overflow-hidden w-3/4 mt-2 mb-1">
          <div className="flex -mx-1">
            <div className="flex-1 px-1">
              <button
                className="btn  btn-sm w-full btn-green-600 rounded"
                disabled={fetching}
                onClick={() => answerFriendRequest(true)}
              >
                {t("accept")}
              </button>
            </div>
            <div className="flex-1 px-1">
              <button
                className="btn btn-sm w-full btn-gray-500 rounded"
                disabled={fetching}
                onClick={() => answerFriendRequest(false)}
              >
                {t("deny")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
