import React from "react";
import { useTranslation } from "react-i18next";
import { ChatTheme } from "../../graphql/generated";
import { useUser } from "../UserProvider";
import noResultsLight from "./assets/noResults.svg";
import noResultsDark from "./assets/DarkTheme/noResults.svg";
import noResultsContrast from "./assets/ContrastTheme/noResults.svg";

type SearchedChannelsProps = {
  renderChannels: any;
  renderChannel: any;
  showChannels: any;
  noResults: boolean;
};

const SearchedChannels = (props: SearchedChannelsProps) => {
  const { renderChannels, renderChannel, showChannels, noResults } = props;
  const { t } = useTranslation();
  const {user} = useUser();
  const imageSelection = () => {
    if(user.chatTheme === ChatTheme.Light) return noResultsLight;
    if(user.chatTheme === ChatTheme.Dark) return noResultsDark;
    return noResultsContrast
  }

  return (
    <>
      {noResults ? (
        <div className="flex flex-col items-center justify-center mt-10">
          <img src={imageSelection()} alt="No results" className="mb-2 search-no-results-image" />
          <span color="#ffffff">{t("no_results_found")}</span>
        </div>
      ) : renderChannels ? (
        renderChannels(showChannels, renderChannel)
      ) : (
        showChannels.map((channel: any) => renderChannel(channel))
      )}
    </>
  );
};
export default SearchedChannels;
