import * as React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { loader } from "graphql.macro";
import {
  LeaderboardsQuery,
  LeaderboardsQueryVariables,
} from "../../graphql/LeaderboardsQuery.generated";
import { useQuery } from "../../hooks/useQuery";
import { AbsoluteCenter } from "../AbsoluteCenter";
import { Loader } from "../Loader";
import { TournamentFragment } from "../Area/graphql/TournamentFragment.generated";
import { useRelativeTimeFormatter } from "../../hooks/useRelativeTimeFormatter";
import { CurrencyImage } from "../CurrencyImage";
import { useNumberFormatter } from "../../hooks/useNumberFormatter";
import { useOrdinalFormatter } from "../../hooks/useOrdinalFormatter";
import { LinkWithSound } from "../AudioProvider";
import { useIsInTable } from "../../hooks/useIsInTable";
import { useScreenType } from "../../hooks/useScreenType";
import {
  RIGHT_BAR_MOBILE_SIZE,
  RIGHT_BAR_TABLET_SIZE,
} from "../Bar/RightBar/Bar";

const TournamentsContext = React.createContext<(open: boolean) => void>(() => {
  throw new Error("Provider not found");
});

const LEADERBOARDS_QUERY = loader("../../graphql/LeaderboardsQuery.graphql");

function Tournament({ value }: { value: TournamentFragment }) {
  const { t } = useTranslation();
  const inTable = useIsInTable();
  const relativeTimeFormatter = useRelativeTimeFormatter();
  const numberFormatter = useNumberFormatter();
  const ordinalFormatter = useOrdinalFormatter();
  const firstPrice = value.prices.find((p) => p.from <= 1 && p.to >= 1);
  return (
    <div className="bg-blue-900 rounded p-2">
      <div className="flex justify-between text-white flex-shrink-0 -mx-1 overflow-hidden">
        <div className="px-1">
          <img
            src={`${process.env.REACT_APP_API_HOST}/tournaments/${value.logo}`}
            className="rounded bg-white"
            alt="logo"
            width={52}
            height={52}
          />
        </div>
        <div className="flex-1 flex flex-col px-1 text-white overflow-hidden">
          <div className="flex -mx-1 items-center justify-between">
            <div className="px-1 truncate">
              <span className="font-bold text-lg truncate">{value.name}</span>
            </div>
            <div className="px-1 flex-shrink-0">
              {t(
                value.active ? "leader_board_expiry" : "leader_board_completed",
                {
                  expiry: relativeTimeFormatter.format(new Date(value.end), {
                    addSuffix: true,
                  }),
                }
              )}
            </div>
          </div>
          <div>{t(`game.${value.section.type}`)}</div>
        </div>
      </div>
      {firstPrice !== undefined && (
        <div className="mt-4 text-white">
          <div className="flex -mx-2">
            <div className="px-2 flex-1 flex flex-col justify-between">
              <span className="text-sm leading-none font-bold block">
                {firstPrice.from !== firstPrice.to
                  ? `${ordinalFormatter.format(
                      firstPrice.from
                    )} - ${ordinalFormatter.format(firstPrice.to)}`
                  : `${ordinalFormatter.format(firstPrice.from)}`}
              </span>
              <span className="text-xs">
                {`${numberFormatter.format(firstPrice.amount.value)} ${t(
                  `currency.${firstPrice.amount.currency.toLowerCase()}`
                )}`}
              </span>
            </div>
            <div className="px-2 flex-shrink-0 flex items-center">
              <CurrencyImage
                currency={firstPrice.amount.currency}
                width={25}
                height={25}
              />
            </div>
          </div>
        </div>
      )}
      <div className="mt-4 text-white">
        <div className="flex -mx-2">
          <div className="px-2 flex-1 flex flex-col justify-between">
            <span className="text-sm leading-none font-bold block">
              {t("entry_fee")}
            </span>
            <span className="text-xs">
              {t("tournament_entry_fee_details", {
                price: `${numberFormatter.format(value.amount.value)} ${t(
                  `currency.${value.amount.currency.toLowerCase()}`
                )}`,
                games: value.gamesPerPurchase,
              })}
            </span>
          </div>
          <div className="px-2 flex-shrink-0 flex items-center">
            <CurrencyImage
              currency={value.amount.currency}
              width={25}
              height={25}
            />
          </div>
        </div>
      </div>
      {!inTable && (
        <div className="mt-4">
          <LinkWithSound
            className="btn btn-orange-600 rounded w-full"
            to={`/area/${value.section.id}`}
          >
            <div className="flex -mx-1 items-center justify-center">
              <span className="px-1 text-center flex-1 truncate">
                {t("view")}
              </span>
            </div>
          </LinkWithSound>
        </div>
      )}
    </div>
  );
}

const TournamentsContent = React.memo(() => {
  const [{ data }] = useQuery<LeaderboardsQuery, LeaderboardsQueryVariables>({
    query: LEADERBOARDS_QUERY,
    variables: {
      filter: {
        rooms: [],
        sections: [],
      },
    },
  });
  const { t } = useTranslation();
  if (data === undefined)
    return (
      <div className="flex-1 relative">
        <AbsoluteCenter>
          <Loader description={t("loading_tournaments")} />
        </AbsoluteCenter>
      </div>
    );
  else {
    const tournaments =
      data.leaderboards?.filter(
        (l) => l.__typename === "Tournament" && l.active
      ) ?? [];

    if (tournaments.length === 0)
      return (
        <div className="flex-1 relative">
          <AbsoluteCenter>
            <div className="p-4 text-center rounded bg-red-700">
              <span className="text-white font-bold block">
                {t("no_tournaments_found")}
              </span>
            </div>
          </AbsoluteCenter>
        </div>
      );
    else {
      return (
        <div className="pt-2">
          <div className="flex flex-col -my-2">
            {tournaments.map((t) => (
              <div className="py-2" key={t.id}>
                <Tournament value={t as any} />
              </div>
            ))}
          </div>
        </div>
      );
    }
  }
});

const Popup = ({ close }: { close: () => void }) => {
  const { t } = useTranslation();
  const screenType = useScreenType();

  const stylesMap = {
    DESKTOP: { bottom: 0, left: 0 },
    TABLET: { top: 0, right: 0, ...RIGHT_BAR_TABLET_SIZE },
    MOBILE: { top: 0, right: 0, ...RIGHT_BAR_MOBILE_SIZE },
  };

  const adjustedStyle = React.useMemo(() => stylesMap[screenType] || {}, [
    screenType,
  ]);

  return (
    <>
      <div
        className="absolute bg-black-alpha-30 top-0 left-0 right-0 bottom-0 z-20"
        onClick={close}
      />
      <div
        className="w-1/3 h-9/10 absolute shadow-2xl bg-blue-800 z-30"
        style={{ ...adjustedStyle }}
        aria-label={t("tournaments")}
      >
        <div className="flex flex-col h-full p-4">
          <div className="flex items-center justify-between text-white flex-shrink-0 -mx-2">
            <span className="font-bold text-lg truncate px-2">
              {t("tournaments")}
            </span>
            <div className="px-2 flex-shrink-0">
              <button onClick={close}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
          <TournamentsContent />
        </div>
      </div>
    </>
  );
};

export const TournamentsPanelProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const close = React.useCallback(() => setOpen(false), []);

  return (
    <TournamentsContext.Provider value={setOpen}>
      {children}
      {isOpen && <Popup close={close} />}
    </TournamentsContext.Provider>
  );
};

export const useTournamentsPanel = () => {
  return React.useContext(TournamentsContext);
};
