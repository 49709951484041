import * as React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { loader } from "graphql.macro";
import { useQuery } from "../../hooks/useQuery";
import {
  TableInfoQueryQuery,
  TableInfoQueryQueryVariables,
} from "./graphql/TableInfoQuery.generated";
import { AbsoluteCenter } from "../AbsoluteCenter";
import { Loader } from "../Loader";
import GameDefinitions from "../Games";
import { ProfileImage } from "../ProfileImage";
import classNames from "classnames";
import waiting from "./waiting.png";
import { useMyStatistics } from "../../hooks/useMyStatistics";
import {
  PlayerStatisticsQueryQuery,
  PlayerStatisticsQueryQueryVariables,
} from "./graphql/PlayerStatisticsQuery.generated";
import { Intent, JoinTable } from "../JoinTable";
import { ButtonWithSound } from "../AudioProvider";
import { useUser } from "../UserProvider";
import { PlayerStatisticsCompare } from "../Statistics";

type TableInfoContextType = (tableId: string) => void;
type Table = NonNullable<TableInfoQueryQuery["table"]>;

const PROFILE_IMAGE_HEIGHT = 46;
const PROFILE_IMAGE_WIDTH = 46;
const TABLE_INFO_QUERY = loader("./graphql/TableInfoQuery.graphql");
const PLAYER_STATISTICS_QUERY = loader(
  "./graphql/PlayerStatisticsQuery.graphql"
);

const TableInfoContext = React.createContext<TableInfoContextType>(() => {});
const PROFILE_CLASS_NAME = "rounded-lg border-white bg-gray-400 border-2";
//+4 to cater for border
const PROFILE_STYLE = {
  width: PROFILE_IMAGE_WIDTH + 4,
};

const ProfilePlaceHolder = React.memo<JSX.IntrinsicElements["div"]>(
  ({ className, style = {}, ...rest }) => {
    const { t } = useTranslation();
    return (
      <div
        style={{
          width: PROFILE_IMAGE_WIDTH,
          height: PROFILE_IMAGE_HEIGHT,
          ...style,
        }}
        className={classNames("overflow-hidden", "box-content", className)}
        {...rest}
      >
        <img
          width={PROFILE_IMAGE_WIDTH}
          height={PROFILE_IMAGE_HEIGHT}
          src={waiting}
          alt={t("waiting")}
        />
      </div>
    );
  }
);

const ProfileSelect = ({
  table,
  selected,
  setSelected,
}: {
  table: Table;
  selected: number | null;
  setSelected: (index: number) => void;
}) => {
  const { t } = useTranslation();
  const requiredPlayers = GameDefinitions[
    table.section.type
  ].tableData.getRequiredPlayers(table);

  const players = table.players;
  return (
    <div className="flex -mx-2 justify-center">
      {Array(requiredPlayers)
        .fill(null)
        .map((_, index) => {
          const player = players[index];
          return (
            <div className="px-2 text-white" key={index}>
              {player !== undefined ? (
                <button
                  className="block overflow-hidden"
                  onClick={() => setSelected(index)}
                  style={PROFILE_STYLE}
                >
                  <ProfileImage
                    id={player.id}
                    name={player.name}
                    width={PROFILE_IMAGE_WIDTH}
                    height={PROFILE_IMAGE_HEIGHT}
                    className={classNames(
                      PROFILE_CLASS_NAME,
                      "hover:border-orange-500",
                      "transition",
                      "duration-300",
                      "ease-in-out",
                      {
                        "border-orange-500 ": index === selected,
                      }
                    )}
                  />
                  <div className="mt-1 truncate text-xs text-center">
                    {player.name}
                  </div>
                </button>
              ) : (
                <div className="overflow-hidden">
                  <ProfilePlaceHolder className={PROFILE_CLASS_NAME} />
                  <div className="mt-1 truncate text-xs text-center">
                    {t("waiting")}
                  </div>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

const PlayerInfo = ({
  id,
  name,
  myStatistics,
  game,
}: {
  id: string;
  name: string;
  myStatistics: ReturnType<typeof useMyStatistics>;
  game: string;
}) => {
  const [{ data }] = useQuery<
    PlayerStatisticsQueryQuery,
    PlayerStatisticsQueryQueryVariables
  >({
    query: PLAYER_STATISTICS_QUERY,
    variables: { userId: id },
    requestPolicy: "cache-and-network",
  });

  const {
    user: { balances },
  } = useUser();
  const { t } = useTranslation();
  if (myStatistics === null || (data?.player?.statistics ?? null) === null)
    return (
      <AbsoluteCenter>
        <Loader description={t("loading_statistics")} />
      </AbsoluteCenter>
    );
  else
    return (
      <div className="text-white">
        <PlayerStatisticsCompare
          other={{
            gaming: data!.player!.statistics,
            balances: data!.player!.balances,
            name,
          }}
          me={{ gaming: myStatistics, balances, name: t("you") }}
          games={[game]}
          tournament={false}
        />
      </div>
    );
};

const TableInfo = ({
  myStatistics,
  table,
  onDismiss,
}: {
  myStatistics: ReturnType<typeof useMyStatistics>;
  table: Table;
  onDismiss: () => void;
}) => {
  const [selected, setSelected] = React.useState<number | null>(
    table.players?.[0] !== undefined ? 0 : null
  );
  React.useEffect(() => {
    setSelected((previous) => {
      const firstAvailableIndex = table.players.findIndex(
        (player) => player !== undefined
      );
      if (firstAvailableIndex < 0) return null;
      else if (previous === null || table.players[previous] === undefined)
        return firstAvailableIndex;
      else return previous;
    });
  }, [table]);

  const selectedPlayer =
    selected === null ? null : table.players[selected] ?? null;

  const { getDetails } = GameDefinitions[table.section.type].tableData;

  const { t } = useTranslation();
  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between text-white flex-shrink-0 mb-2 -mx-2">
        <span className="font-bold text-lg truncate px-2">{table.name}</span>
        <div className="px-2 flex-shrink-0">
          <button onClick={onDismiss}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      </div>
      <div className="flex-1 flex flex-col">
        <div className="bg-blue-900 text-white rounded p-2">
          {getDetails(table)}
        </div>
        <div className="flex-1 flex flex-col">
          <div className="mt-3 mb-1">
            <ProfileSelect
              table={table}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
          <div className="flex-1 relative">
            {selectedPlayer === null ? (
              <AbsoluteCenter>
                <div className="p-4 text-center">
                  <span className="text-white font-bold">
                    {t("no_player_selected")}
                  </span>
                </div>
              </AbsoluteCenter>
            ) : (
              <PlayerInfo
                id={selectedPlayer.id}
                name={selectedPlayer.name}
                key={selectedPlayer.id}
                myStatistics={myStatistics}
                game={table.section.type}
              />
            )}
          </div>
        </div>
        <JoinTable table={table} intent={Intent.List}>
          {(disabled, full, join) => {
            return (
              <div className="flex-shrink-0">
                <ButtonWithSound
                  disabled={disabled}
                  className={classNames(
                    "btn w-full rounded",
                    full ? "btn-orange-600" : "btn-green-600"
                  )}
                  onClick={() => {
                    join();
                    onDismiss();
                  }}
                >
                  {t(full ? "watch" : "join")}
                </ButtonWithSound>
              </div>
            );
          }}
        </JoinTable>
      </div>
      {/*<div className="flex-1 overflow-hidden relative">{children}</div>*/}
    </div>
  );
};

const DebouncedTableInfo = ({ children }: { children?: React.ReactNode }) => {
  const [ready, setReady] = React.useState(false);
  React.useEffect(() => {
    const id = window.setTimeout(() => setReady(true), 500);
    return () => window.clearTimeout(id);
  }, []);

  const { t } = useTranslation();

  if (!ready)
    return (
      <AbsoluteCenter>
        <Loader description={t("loading_table_info")} />
      </AbsoluteCenter>
    );
  else return <>{children}</>;
};

const TableInfoContainer = ({
  id,
  onDismiss,
}: {
  id: string;
  onDismiss: () => void;
}) => {
  const { t } = useTranslation();
  const [{ data, fetching }] = useQuery<
    TableInfoQueryQuery,
    TableInfoQueryQueryVariables
  >({
    query: TABLE_INFO_QUERY,
    variables: { tableId: id },
  });

  React.useEffect(() => {
    if (data === null) onDismiss();
  }, [data, onDismiss]);

  const myStatistics = useMyStatistics("cache-and-network");

  if (fetching)
    return (
      <AbsoluteCenter>
        <Loader description={t("loading_table_info")} />
      </AbsoluteCenter>
    );
  else if ((data?.table ?? null) === null) return null;
  else
    return (
      <TableInfo
        table={data!.table!}
        onDismiss={onDismiss}
        myStatistics={myStatistics}
      />
    );
};

const TableInfoModal = ({
  tableId,
  onDismiss,
}: {
  tableId: string;
  onDismiss: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className="w-1/3 p-4 absolute shadow-2xl bg-blue-800 top-0 bottom-0 right-0"
        aria-label={t("table_info")}
      >
        <DebouncedTableInfo key={tableId}>
          <TableInfoContainer id={tableId} onDismiss={onDismiss} />
        </DebouncedTableInfo>
      </div>
    </>
  );
};

export const TableInfoProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [tableId, setTableId] = React.useState<string | null>(null);
  const toggle = React.useCallback(
    (tableId: string) =>
      setTableId((prev) => (prev === tableId ? null : tableId)),
    []
  );
  const onDismiss = React.useCallback(() => setTableId(null), []);
  return (
    <TableInfoContext.Provider value={toggle}>
      {children}
      {tableId !== null && (
        <TableInfoModal tableId={tableId} onDismiss={onDismiss} />
      )}
    </TableInfoContext.Provider>
  );
};

export const useTableInfo = () => {
  return React.useContext<TableInfoContextType>(TableInfoContext);
};
