import React from "react";

const BackArrowIcon = ({
  backgroundColor,
  fillColor,
}: {
  backgroundColor?: string;
  fillColor?: string;
}) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="31.5"
      y="31.5005"
      width="31"
      height="31"
      rx="15.5"
      transform="rotate(-180 31.5 31.5005)"
      fill="#13151B"
    />
    <path
      d="M8.14645 15.6469C7.95118 15.8422 7.95118 16.1588 8.14645 16.354L11.3284 19.536C11.5237 19.7313 11.8403 19.7313 12.0355 19.536C12.2308 19.3408 12.2308 19.0242 12.0355 18.8289L9.20711 16.0005L12.0355 13.1721C12.2308 12.9768 12.2308 12.6602 12.0355 12.465C11.8403 12.2697 11.5237 12.2697 11.3284 12.465L8.14645 15.6469ZM23.5 15.5005L8.5 15.5005L8.5 16.5005L23.5 16.5005L23.5 15.5005Z"
      fill={fillColor}
    />
    <rect
      x="31.5"
      y="31.5005"
      width="31"
      height="31"
      rx="15.5"
      transform="rotate(-180 31.5 31.5005)"
      stroke={fillColor}
    />
  </svg>
);

export default BackArrowIcon;
