import { loader } from "graphql.macro";
import { Cache, Data, Variables } from "@urql/exchange-graphcache";
import { AvatarsQueryQuery } from "../../graphql/AvatarsQuery.generated";

const AVATARS_QUERY = loader("../../graphql/AvatarsQuery.graphql");

export const useAvatar = (result: Data, args: Variables, cache: Cache) => {
  const { id: avatarId } = args;
  const { useAvatar: useAvatarResult } = result;
  if (useAvatarResult === true) {
    cache.updateQuery<AvatarsQueryQuery>({ query: AVATARS_QUERY }, (data) => {
      if (data !== null) {
        (data as AvatarsQueryQuery).me!.avatars = ( data as AvatarsQueryQuery).me!.avatars.map(
          (avatar) => {
            if (avatar.id === avatarId) {
              avatar.price = null;
              avatar.active = true;
            } else if (avatar.active) {
              avatar.active = false;
            }
            return avatar;
          }
        );
      }
      return data;
    });
  }
};
