import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useAuthToken } from "../../hooks/useAuthToken";
import { Tooltip } from "../Tooltip";
import { useTranslation } from "react-i18next";
import { useGuestId } from "../../hooks/useGuestId";
import { useUser, useUserId } from "../UserProvider";
import { useQuery } from "../../hooks/useQuery";
import { StreamChat } from "stream-chat";
import { useProfilePictureReload } from "../../hooks/useProfilePictureReload";
import {
  ChatTokenQuery,
  ChatTokenQueryVariables,
} from "../PlatformChat/graphql/ChatTokenQuery.generated";
import { loader } from "graphql.macro";
import logOut from "../Header/assets/logOut.svg";
import { Link } from "react-router-dom";

const CHAT_TOKEN_QUERY = loader(
  "../PlatformChat/graphql/ChatTokenQuery.graphql"
);

export const LogoutButton = React.memo(
  ({ isMainScreen }: { isMainScreen?: boolean }) => {
    const apiKey = `${process.env.REACT_APP_CHAT_TOKEN}`;
    const chatClient = StreamChat.getInstance(apiKey);

    // If something wrong with client connection use this TODO

    // const {user} = useUser();
    // const [randomId] = useProfilePictureReload();
    // const id = useUserId();
    // const [result, reexecuteQuery] = useQuery<
    //   ChatTokenQuery,
    //   ChatTokenQueryVariables
    // >({
    //   query: CHAT_TOKEN_QUERY,
    //   pause: true,
    // });
    // React.useEffect(() => {
    //   if (result.fetching) return;
    //   if (chatClient.user === undefined) {
    //     //run query only if we need to set chat user and need token for this
    //     reexecuteQuery({ requestPolicy: "network-only" });
    //   }
    //   if (chatClient.user === undefined && result.data?.chatToken) {
    //     //connect user only if we have token and chat user is undefined
    //     chatClient.connectUser(
    //       {
    //         id: user.id,
    //         name: user.name,
    //         image: `${process.env.REACT_APP_API_HOST}/user/${id}?r=${randomId}`,
    //       },
    //       result.data.chatToken
    //     );
    //   }
    // }, [result, reexecuteQuery, chatClient, user, randomId, id]);

    const [, , reset] = useAuthToken();
    const [, , , , , clearIsLoggedAsGuest] = useGuestId();

    const { t } = useTranslation();
    return (
      <Tooltip label={t(isMainScreen ? "logout" : "back")}>
        {!isMainScreen ? (
          <Link to="/" className="w-6 h-6">
            <FontAwesomeIcon
              icon={faTimes}
              className="text-white"
              size="sm"
              style={{ width: "20px", height: "20px" }}
            />
          </Link>
        ) : (
          <button
            onClick={() => {
              reset();
              clearIsLoggedAsGuest();
              chatClient.disconnectUser();
            }}
            className="text-white"
            style={{ minWidth: "14px", minHeight: "14px" }}
          >
            <img
              style={{ minWidth: "14px", minHeight: "14px" }}
              src={logOut}
              alt=""
              className="w-4 h-4"
            />
          </button>
        )}
      </Tooltip>
    );
  }
);
