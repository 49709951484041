import { useMutation as useMutationInternal, UseMutationResponse } from "urql";
import { DocumentNode } from "graphql";

export const useMutation = <T = any, V = object>(
  query: string | DocumentNode,
  throwOnError: boolean = true
): UseMutationResponse<T, V> => {
  const result = useMutationInternal<T, V>(query);
  const [{ error }] = result;
  if (error !== undefined && throwOnError) throw error;

  return result;
};
