import * as React from "react";
import { calculateTimePercent } from "../utils";
import { useSyncedTimeProvider } from "../components/SyncedTimeProvider";

const getRemainingSeconds = (from: number, to: number, now: number) => {
  const initialSeconds = Math.floor((to - from) / 1000);
  const percent = calculateTimePercent(from, to, now);
  return {
    remaining: initialSeconds - Math.floor(percent * initialSeconds),
    percent: Math.round(percent * 100) / 100,
  };
};

export const useCountDown = (from: number, to: number) => {
  const syncedTimeProvider = useSyncedTimeProvider();
  const [state, setState] = React.useState(
    getRemainingSeconds(from, to, syncedTimeProvider())
  );
  React.useEffect(() => {
    setState(getRemainingSeconds(from, to, syncedTimeProvider()));
    const interval = setInterval(() => {
      const { percent, remaining } = getRemainingSeconds(
        from,
        to,
        syncedTimeProvider()
      );
      setState((prev) => {
        if (prev.percent === percent && prev.remaining === remaining)
          return prev;

        return {
          percent,
          remaining,
        };
      });
      if (remaining === 0) clearInterval(interval);
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, [from, to, syncedTimeProvider]);

  return state;
};
