import * as React from "react";
import { useMarquee } from "./index";
import { usePageVisibility } from "react-page-visibility";

function getStyle(time: number, paused: boolean) {
  return {
    paddingLeft: "100%",
    animation: `marquee ${time}s linear`,
    animationPlayState: paused ? "paused" : "running",
  };
}

export function Marquee() {
  const [message, onComplete] = useMarquee();
  const visible = usePageVisibility();
  const [paused, setPaused] = React.useState(false);
  if (message === undefined) return null;

  const style = getStyle(message.duration, paused || !visible);
  return (
    <div className="m-0 whitespace-no-wrap overflow-hidden text-white">
      {message.link === null ? (
        <span
          className="inline-block cursor-default"
          style={style}
          onAnimationEnd={onComplete}
          onMouseMove={() => setPaused(true)}
          onMouseOut={() => setPaused(false)}
        >
          {message.content}
        </span>
      ) : (
        <a
          href={message.link}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block"
          style={style}
          onAnimationEnd={onComplete}
          onMouseMove={() => setPaused(true)}
          onMouseOut={() => setPaused(false)}
        >
          {message.content}
        </a>
      )}
    </div>
  );
}
