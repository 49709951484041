import * as React from "react";
import { useNumberFormatter } from "../../hooks/useNumberFormatter";
import classNames from "classnames";

export const Row = ({
  left,
  right,
  center,
}: {
  left: number | null;
  right: number | null;
  center: React.ReactNode;
}) => {
  const numberFormatter = useNumberFormatter();
  return left === null && right === null ? null : (
    <div className="overflow-hidden">
      <div className="flex items-center py-1 -mx-2">
        {left !== null && (
          <div
            className={classNames("px-2", {
              "text-red-500": right !== null && left < right,
              "text-green-500": right !== null && left > right,
              "w-1/3 text-center": right !== null,
              "w-2/3 text-left": right === null,
            })}
          >
            {numberFormatter.format(left)}
          </div>
        )}
        <div
          className={classNames("w-1/3", "px-2", "inline-flex", {
            "justify-center": left !== null && right !== null,
            "justify-start": left === null,
            "justify-end": right === null,
          })}
        >
          {center}
        </div>
        {right !== null && (
          <div
            className={classNames("px-2", {
              "text-red-500": left !== null && right < left,
              "text-green-500": left !== null && right > left,
              "w-1/3 text-center": left !== null,
              "w-2/3 text-right": left === null,
            })}
          >
            {numberFormatter.format(right)}
          </div>
        )}
      </div>
    </div>
  );
};
