import React from "react";

const GuestIcon = ({
  backgroundColor,
  fillColor,
}: {
  backgroundColor?: string;
  fillColor?: string;
}) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill={backgroundColor} />
    <path
      d="M25.3928 14.8485C25.3928 17.5262 23.082 19.697 20.2315 19.697C17.381 19.697 15.0702 17.5262 15.0702 14.8485C15.0702 12.1707 17.381 10 20.2315 10C23.082 10 25.3928 12.1707 25.3928 14.8485Z"
      fill={fillColor}
    />
    <path
      d="M13.2 30C12.2804 30 11.8206 30 11.306 29.6425C10.9592 29.4015 10.5356 28.724 10.4709 28.3066C10.3748 27.6874 10.4999 27.4307 10.75 26.9172C12.3905 23.5499 16.4602 20.9091 20.3226 20.9091C24.1766 20.9091 27.852 23.5384 29.3242 26.8953C29.5766 27.4708 29.7028 27.7585 29.5918 28.3618C29.5154 28.7772 29.096 29.4192 28.7462 29.656C28.2383 30 27.7589 30 26.8 30H13.2Z"
      fill={fillColor}
    />
  </svg>
);

export default GuestIcon;
