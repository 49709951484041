import * as React from "react";
import { useFacebook } from "../Context";
import { loader } from "graphql.macro";
import { useClient } from "urql";
import {
  CreateFacebookOrderMutation,
  CreateFacebookOrderMutationVariables,
} from "./graphql/CreateFacebookOrderMutation.generated";
import {
  CompleteFacebookOrderMutation,
  CompleteFacebookOrderMutationVariables,
} from "./graphql/CompleteFacebookOrderMutation.generated";

const CREATE_FACEBOOK_ORDER_MUTATION = loader(
  "./graphql/CreateFacebookOrderMutation.graphql"
);

const COMPLETE_FACEBOOK_ORDER_MUTATION = loader(
  "./graphql/CompleteFacebookOrderMutation.graphql"
);

export const useFacebookPay = (): [
  boolean,
  (chipPackId: string) => Promise<boolean | undefined>
] => {
  const [ready] = useFacebook();
  const client = useClient();
  const purchase = React.useCallback(
    async (chipPackId: string) => {
      if (!ready) throw new Error("Sdk not loaded");

      const result = await client
        .mutation<
          CreateFacebookOrderMutation,
          CreateFacebookOrderMutationVariables
        >(CREATE_FACEBOOK_ORDER_MUTATION, {
          input: chipPackId,
        })
        .toPromise();

      if (
        result.error !== undefined ||
        result.data === undefined ||
        result.data.order == null
      )
        throw result.error || new Error("Order could not be created");

      const params: fb.PayDialogParams = {
        method: "pay",
        action: "purchaseitem",
        product: result.data.order.productUrl,
        request_id: result.data.order.requestId,
      };

      return new Promise<boolean | undefined>((resolve, reject) => {
        FB.ui(params, (data: fb.PayDialogResponse) => {
          if (data === undefined || data.error_code === 1383010) resolve(false);
          else if (data.error_message !== undefined)
            reject(new Error(data.error_message));
          else {
            client
              .mutation<
                CompleteFacebookOrderMutation,
                CompleteFacebookOrderMutationVariables
              >(COMPLETE_FACEBOOK_ORDER_MUTATION, {
                input: data.signed_request,
              })
              .toPromise()
              .then((data) => {
                if (
                  result.error !== undefined ||
                  data.data === undefined ||
                  data.data.order === null
                )
                  throw (
                    result.error || new Error("Order could not be confirmed")
                  );

                return data.data.order.fundsTransferred;
              })
              .then(resolve)
              .catch(reject);
          }
        });
      });
    },
    [client, ready]
  );

  return [ready, purchase];
};
