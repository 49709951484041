import * as React from "react";
import { BalanceUpdateTopupDetailsFragmentFragment } from "../../../hooks/useTransactions/graphql/TransactionsFragment.generated";
import { Trans } from "react-i18next";

export const BalanceUpdateTopupDetails = ({
  details,
}: {
  details: BalanceUpdateTopupDetailsFragmentFragment;
}) => {
  return (
    <Trans i18nKey="transaction.BalanceUpdateTopup_details">
      Top-up card <strong>{{ id: details.topupCardId }}</strong>
    </Trans>
  );
};
