import classNames from "classnames";
import React from "react";
import styles from "./Topbar.module.scss";

const TopbarItem = ({
  image,
  text,
  onClick,
  className,
}: {
  image: string;
  text: string;
  onClick: () => void;
  className?: string;
}) => {
  return (
    <div onClick={onClick} className={classNames(styles.topbarItem, className)}>
      <img src={image} alt="" className={styles.icon} />
      <p className={classNames(styles.text, "px-1")}>{text}</p>
    </div>
  );
};

export default TopbarItem;
