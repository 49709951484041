import * as React from "react";
import classNames from "classnames";

export const AbsoluteCenter = React.forwardRef<
  HTMLDivElement,
  { children: React.ReactNode } & JSX.IntrinsicElements["div"]
>(({ children, className, ...rest }, ref) => {
  return (
    <div
      ref={ref}
      className={classNames(
        "absolute top-0 left-0 right-0 bottom-0",
        className
      )}
      {...rest}
    >
      <div className="flex h-full items-center justify-center">{children}</div>
    </div>
  );
});
