import * as React from "react";
import { useScreenState } from "../ScreenState";
import { AbsoluteCenter } from "../AbsoluteCenter";
import { Loader } from "../Loader";

const FreeChipsPopup = React.lazy(() => import("./FreeChipsPopup"));

const FreeChipsContext = React.createContext<() => void>(() => {
  throw new Error("Provider not found");
});

export const FreeChipsProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [doForceDesktopScreenSize, resetScreenSize] = useScreenState();
  const [opened, setOpened] = React.useState<boolean>(false);
  const close = React.useCallback(() => {
    if (doForceDesktopScreenSize) {
      resetScreenSize();
    }

    return setOpened(false);
  }, [doForceDesktopScreenSize, resetScreenSize]);

  const open = () => setOpened(true);

  return (
    <FreeChipsContext.Provider value={open}>
      {children}
      <React.Suspense
        fallback={
          <AbsoluteCenter>
            <Loader description="" />
          </AbsoluteCenter>
        }
      >
        {opened && <FreeChipsPopup onClose={close} />}
      </React.Suspense>
    </FreeChipsContext.Provider>
  );
};

export const useFreeChips = () => {
  return React.useContext(FreeChipsContext);
};
