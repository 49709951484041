import * as React from "react";
import { BalanceUpdateAdjustmentActivityContentFragmentFragment } from "../../../graphql/ActivityFragment.generated";
import { Trans, useTranslation } from "react-i18next";
import { useNumberFormatter } from "../../../hooks/useNumberFormatter";

export const BalanceUpdateAdjustmentActivity = ({
  content,
  openProfile,
}: {
  content: BalanceUpdateAdjustmentActivityContentFragmentFragment;
  openProfile: () => void;
}) => {
  let amount = content.amounts.find((a) => a.value > 0);
  if (amount === undefined) amount = content.amounts[0];
  const formatter = useNumberFormatter();
  const { t } = useTranslation();
  const formattedAmount = formatter.format(amount.value);
  const formattedCurrency = t(
    `currency.${amount.currency.toString().toLowerCase()}`
  );
  return amount.value > 0 ? (
    <Trans i18nKey="notification.balance_update_adjustment_positive_activity">
      You have received a gift of <strong>{{ amount: formattedAmount }}</strong>{" "}
      {{
        currency: formattedCurrency,
      }}
    </Trans>
  ) : (
    <Trans i18nKey="notification.balance_update_adjustment_negative_activity">
      Your account has been adjustment by{" "}
      <strong>{{ amount: formattedAmount }}</strong>{" "}
      {{
        currency: formattedCurrency,
      }}
    </Trans>
  );
};
