import { loader } from "graphql.macro";
import { Cache, Data, Variables } from "@urql/exchange-graphcache";

const PLAYER_FRIENDSHIP_FRAGMENT = loader(
  "../../graphql/PlayerFriendshipFragment.graphql"
);

export const createFriendRequest = (
  result: Data,
  args: Variables,
  cache: Cache
) => {
  const { withUser: userId } = args;
  cache.writeFragment(PLAYER_FRIENDSHIP_FRAGMENT, {
    id: userId as string,
    friendship: {
      canAskForFriendship: false,
      areFriends: false,
      __typename: "FriendshipType",
    },
    __typename: "Player",
  });
};
