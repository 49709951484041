import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFacebookLogin } from "../../Facebook";
import { useLogin } from "../useLogin";
import { clearGuestId, clearIsLoggedAsGuest } from "../../../guestId";

const NAME = "facebook";

export const FacebookLogin = ({
  guestId,
  children,
  setError,
}: {
  guestId: string | null;
  children: (disabled: boolean, login: () => void) => void;
  setError: (errorMessage: string | null) => void;
}) => {
  const { t } = useTranslation();
  const login = useLogin(NAME, "website");
  const [ready, fbLogin, extra] = useFacebookLogin();
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  React.useEffect(() => {
    const errorText = errorMessage;
    if (errorText != null) {
      setErrorMessage(null);
      setError(t(errorText));
    }
  }, [errorMessage, setErrorMessage, setError, t]);
  const loginFacade = React.useCallback(async () => {
    setLoading(true);
    try {
      const result = await fbLogin(guestId ? {} : { scope: "email" });
      const { status, authResponse } = result;
      if (status !== "connected") {
        console.error("Wrong status", result);
        setLoading(false);
        return;
      }

      const { accessToken } = authResponse;
      if (guestId != null) {
        return await login(accessToken, { ...extra, guestId }).then(() => {
          if (guestId) {
            clearIsLoggedAsGuest();
            clearGuestId();
            window.location.href = "/";
          }
        });
      } else {
        return await login(accessToken, extra);
      }
    } catch (e) {
      setLoading(false);
      if (guestId != null) setErrorMessage("user_with_fb_account_already_exists");
    }
  }, [extra, fbLogin, login, guestId]);

  return <>{children(!ready || loading, loginFacade)}</>;
};
