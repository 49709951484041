import * as React from "react";
import { useQuery } from "../../hooks/useQuery";
import { gql, loader } from "graphql.macro";
import {
  DailyChallengesQuery,
  DailyChallengesQueryVariables,
} from "./graphql/DailyChallengesQuery.generated";
import { PixiStage } from "../PixiStage";
import { Container, Sprite, Text } from "@inlet/react-pixi";
import * as PIXI from "pixi.js";
import bgImage from "./assets/bg.png";
import closeImage from "./assets/close.png";
import arrowLeftImage from "./assets/arrow_left.png";
import arrowRightImage from "./assets/arrow_right.png";
import blueButtonImage from "./assets/blue_button.png";
import ellipseImage from "./assets/ellipse.png";
import playImage from "./assets/play.png";
import redButtonImage from "./assets/red_button.png";
import trophyImage from "./assets/trophy.png";
import xImage from "./assets/x.png";
import { Layout } from "../Layout";
import { Header } from "../Header";
import { useTranslation } from "react-i18next";
import { splitToChunks } from "../../utils";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuPopover,
} from "@reach/menu-button";
import { positionMatchWidth } from "@reach/popover";
import { useHistory, useParams } from "react-router-dom";
import {
  HangmanDefaultConfigQueryQuery,
  HangmanDefaultConfigQueryQueryVariables,
} from "./index.generated";
import { useMutation } from "../../hooks/useMutation";
import { HANGMAN_CREATE_OPEN_TABLE_MUTATION } from "../Games/hangman/CreateTable/index";
import {
  HangmanCreateOpenTableMutation,
  HangmanCreateOpenTableMutationVariables,
} from "../Games/hangman/CreateTable/graphql/HangmanCreateOpenTableMutation.generated";
import styles from "./HangmanDailyChallenges.module.scss";
import { HangmanGameMode, HangmanTableType } from "../../graphql/generated";
import { useClient } from "urql";
import { Leaderboard } from "./Leaderboard";
import Balances from "../Balances";

const WIDTH = 1280;
const HEIGHT = 750;

const STAGE_OPTIONS = {
  width: WIDTH,
  height: HEIGHT,
  options: {
    backgroundColor: 0xffffff,
    autoDensity: true,
  },
};

const HANGMAN_DEFAULT_CONFIG_QUERY = gql`
  query HangmanDefaultConfigQuery($sectionId: ID!) {
    hangmanDefaultConfig(sectionId: $sectionId) {
      settings {
        language
        levels
      }
      challenge {
        available
      }
    }
  }
`;

const DAILY_CHALLENGES_QUERY = loader("./graphql/DailyChallengesQuery.graphql");

const formatDateToShortFormat = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const dt = date.getDate();
  return `${dt < 10 ? "0" + dt : dt}/${
    month < 10 ? "0" + month : month
  }/${year}`;
};

const Calendar = ({
  title,
  weekDaysShortNames,
  statistics,
  setSelected,
  month,
  year,
  nextMonth,
  previousMonth,
}: {
  title: string;
  weekDaysShortNames: Array<string>;
  statistics: Array<{
    day: number;
    currentMonth: boolean;
    passed: boolean;
    today: boolean;
    roundsWin: number;
    elapsedTimeMs: number;
  }>;
  setSelected: ({
    date,
    roundsWin,
    elapsedTimeMs,
  }: {
    date: Date;
    roundsWin: number;
    elapsedTimeMs: number;
  }) => void;
  month: number;
  year: number;
  nextMonth: () => void;
  previousMonth: () => void;
}) => {
  const calendarWeeksMapped = splitToChunks(statistics, 7);

  const statsTextStyle = new PIXI.TextStyle({
    fontFamily: "Bradley Hand",
    fontWeight: "bold",
    fill: ["#505050", "#505050"],
    padding: 5,
  });

  const textStyle = new PIXI.TextStyle({
    fontSize: 45,
    fontFamily: "Bradley Hand",
    fontWeight: "bold",
    fill: ["#505050", "#505050"],
    align: "center",
    padding: 5,
  });
  return (
    <Container width={1000} y={185}>
      <Text text={title} style={textStyle} anchor={0.5} x={500} />
      {weekDaysShortNames.map((day, index) => {
        return (
          <Text
            key={index}
            text={day}
            style={{
              ...statsTextStyle,
              fontSize: 33,
              fill: ["black", "black"],
            }}
            x={310 + index * 70}
            y={27}
          />
        );
      })}
      {calendarWeeksMapped.map((week, weekIndex) => {
        return week.map((day, index) => {
          return (
            <Container
              x={310 + index * 70}
              y={110 + weekIndex * 42}
              key={index}
            >
              <Text
                text={
                  day.day < 10 ? ` ${day.day.toString()}` : day.day.toString()
                }
                style={{
                  ...statsTextStyle,
                  fontSize: 33,
                  fill: day.currentMonth
                    ? ["#505050", "#505050"]
                    : ["#b0b2b3", "#b0b2b3"],
                }}
                buttonMode={day.currentMonth}
                interactive
                pointerup={() => {
                  if (day.currentMonth)
                    setSelected({
                      date: new Date(year, month, day.day),
                      roundsWin: day.roundsWin,
                      elapsedTimeMs: day.elapsedTimeMs,
                    });
                }}
              />
              {day.passed && <Sprite image={xImage} x={5} y={5} />}
              {day.today && !day.passed && (
                <Sprite image={ellipseImage} x={-7} y={-6} />
              )}
            </Container>
          );
        });
      })}
      <Sprite
        image={arrowLeftImage}
        y={160}
        x={210}
        buttonMode
        interactive
        pointerup={previousMonth}
      />
      <Sprite
        image={arrowRightImage}
        y={160}
        x={810}
        buttonMode
        interactive
        pointerup={nextMonth}
      />
    </Container>
  );
};

const HangmanDailyChallenges = ({
  goToLeaderboard,
}: {
  goToLeaderboard: () => void;
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { sectionId } = useParams<{
    sectionId: string;
  }>();
  const [month, setMonth] = React.useState(new Date().getMonth());
  const [year, setYear] = React.useState(new Date().getFullYear());

  const nextMonth = React.useCallback(() => {
    setMonth((prev) => {
      if (prev === 11) {
        return 0;
      }
      return prev + 1;
    });
    setYear((prevYear) => {
      if (month === 11) {
        return prevYear + 1;
      }
      return prevYear;
    });
  }, [setMonth, setYear, month]);

  const previousMonth = React.useCallback(() => {
    setMonth((prev) => {
      if (prev === 0) {
        return 11;
      }
      return prev - 1;
    });
    setYear((prevYear) => {
      if (month === 0) {
        return prevYear - 1;
      }
      return prevYear;
    });
  }, [setMonth, setYear, month]);
  const [language, setLanguage] = React.useState("English");
  const client = useClient();
  const [statistics, setStatistics] = React.useState<
    | {
        [key: string]: {
          created: string;
          roundsWin: number;
          elapsedTimeMs: number;
        };
      }
    | undefined
  >();
  const [bestRestult, setBestResult] = React.useState<{
    roundsWin: number;
    elapsedTimeMs: number;
  }>();

  const [selectedResult, setSelectedResult] = React.useState<{
    date: Date;
    roundsWin: number;
    elapsedTimeMs: number;
  }>();

  const fetchMore = React.useCallback(() => {
    return client
      .query<DailyChallengesQuery, DailyChallengesQueryVariables>(
        DAILY_CHALLENGES_QUERY,
        {
          input: {
            month: month + 1,
            year: year,
            roomId: sectionId.substring(0, sectionId.indexOf(":")),
          },
        },
        { requestPolicy: "network-only" }
      )
      .toPromise()
      .then(({ data, error }) => {
        if (error !== undefined) {
          throw error;
        } else if ((data?.dailyChallenges ?? null) === null) {
          setStatistics(undefined);
        } else if (data !== null) {
          let maxRounds = 0;
          let minElapsedTimeMs = 0;
          setStatistics(
            data?.dailyChallenges?.reduce((obj, item) => {
              if (maxRounds < item.roundsWin) {
                maxRounds = item.roundsWin;
                minElapsedTimeMs = item.elapsedTimeMs;
              } else if (
                maxRounds === item.roundsWin &&
                minElapsedTimeMs > item.elapsedTimeMs
              ) {
                maxRounds = item.roundsWin;
                minElapsedTimeMs = item.elapsedTimeMs;
              }
              return {
                ...obj,
                [new Date(item["created"]).getDate().toString()]: {
                  roundsWin: item.roundsWin,
                  elapsedTimeMs: item.elapsedTimeMs,
                  created: item.created,
                },
              };
            }, {})
          );
          setBestResult({
            roundsWin: maxRounds,
            elapsedTimeMs: minElapsedTimeMs,
          });
          setSelectedResult({
            date:
              data?.dailyChallenges !== null &&
              data?.dailyChallenges[0] !== undefined
                ? new Date(data?.dailyChallenges[0].created) ??
                  new Date(year, month, 1)
                : new Date(year, month, 1),
            roundsWin:
              data?.dailyChallenges !== null &&
              data?.dailyChallenges[0] !== undefined
                ? data?.dailyChallenges[0].roundsWin ?? 0
                : 0,
            elapsedTimeMs:
              data?.dailyChallenges !== null &&
              data?.dailyChallenges[0] !== undefined
                ? data?.dailyChallenges[0].elapsedTimeMs ?? 0
                : 0,
          });
        }
      });
  }, [client, month, year, sectionId]);

  React.useEffect(() => {
    fetchMore();
  }, [fetchMore]);

  const [{ data: defaultConfig, fetching: fetchingDefaultConfig }] = useQuery<
    HangmanDefaultConfigQueryQuery,
    HangmanDefaultConfigQueryQueryVariables
  >({
    query: HANGMAN_DEFAULT_CONFIG_QUERY,
    variables: {
      sectionId,
    },
    requestPolicy: "network-only",
  });
  React.useEffect(() => {
    if (defaultConfig !== undefined && language === undefined) {
      setLanguage(
        defaultConfig.hangmanDefaultConfig?.settings[0].language ?? "English"
      );
    }
  }, [defaultConfig, language]);

  const [, createTable] = useMutation<
    HangmanCreateOpenTableMutation,
    HangmanCreateOpenTableMutationVariables
  >(HANGMAN_CREATE_OPEN_TABLE_MUTATION, false);

  const textStyle = new PIXI.TextStyle({
    fontSize: 45,
    fontFamily: "Bradley Hand",
    fontWeight: "bold",
    fill: ["#505050", "#505050"],
    align: "center",
    padding: 5,
  });
  const buttonTextStyle = new PIXI.TextStyle({
    fontSize: 25,
    fontFamily: "Bradley Hand",
    fontWeight: "bold",
    fill: ["white", "white"],
    align: "center",
    padding: 5,
  });
  const statsTextStyle = new PIXI.TextStyle({
    fontFamily: "Bradley Hand",
    fontWeight: "bold",
    fill: ["#505050", "#505050"],
    padding: 5,
  });

  const weekDaysShortNames = React.useMemo(
    () =>
      [
        "monday",
        "tuesday",
        "wednesday",
        "thuersday",
        "friday",
        "saturday",
        "sanday",
      ].map((day) => t(`hangman.week_days_short_names.${day}`)),
    [t]
  );

  const monthNames = React.useMemo(
    () =>
      [
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december",
      ].map((monthName) => t(`hangman.month.${monthName}`)),
    [t]
  );

  const today = new Date();
  const todayDay = today.getDate();
  const todayMonth = today.getMonth();
  const todayYear = today.getFullYear();

  const firstDayOfSelectedMonth = new Date(year, month, 1, 0, 0, 0, 0);
  const firstDayWeekDayNumber = firstDayOfSelectedMonth.getDay();
  const lastDayOfPreviousMonth = new Date(
    new Date(year, month + 1, 1, 0, 0, 0, 0).setDate(
      firstDayOfSelectedMonth.getDate() - 1
    )
  ).getDate();

  const firstDayOfNextMonth = new Date(
    month === 11 ? year + 1 : year,
    month === 11 ? 1 : month + 1,
    1,
    0,
    0,
    0,
    0
  );

  const lastDateOfCurrentMonth = new Date(
    new Date(
      month === 11 ? year + 1 : year,
      month === 11 ? 1 : month + 1,
      1,
      0,
      0,
      0,
      0
    ).setDate(firstDayOfNextMonth.getDate() - 1)
  );
  const lastDayOfCurrentMonth = lastDateOfCurrentMonth.getDate();
  const lastDayWeekDayNumber = lastDateOfCurrentMonth.getDay();

  const previousMonthDays = [
    ...Array(
      firstDayWeekDayNumber === 0 ? 6 : firstDayWeekDayNumber - 1
    ).keys(),
  ]
    .map((i) => lastDayOfPreviousMonth - i)
    .reverse()
    .map((day) => ({
      day,
      currentMonth: false,
      passed: false,
      today: false,
      roundsWin: 0,
      elapsedTimeMs: 0,
    }));

  const nextMonthDays = [
    ...Array(lastDayWeekDayNumber === 0 ? 0 : 7 - lastDayWeekDayNumber).keys(),
  ]
    .map((i) => i + 1)
    .map((day) => ({
      day,
      currentMonth: false,
      passed: false,
      today: false,
      roundsWin: 0,
      elapsedTimeMs: 0,
    }));

  const calendarDaysMapped = previousMonthDays
    .concat(
      [...Array(lastDayOfCurrentMonth + 1).keys()]
        .filter((k) => k > 0)
        .map((day, index) => {
          const statistic =
            statistics !== undefined ? statistics[day.toString()] : undefined;
          return {
            day,
            currentMonth: true,
            passed: statistic !== undefined,
            today:
              `${day}/${month}/${year}` ===
              `${todayDay}/${todayMonth}/${todayYear}`,
            roundsWin: statistic !== undefined ? statistic.roundsWin : 0,
            elapsedTimeMs:
              statistic !== undefined ? statistic.elapsedTimeMs : 0,
          };
        })
    )
    .concat(nextMonthDays);
  const calendarTitle = `${monthNames[month]} ${year.toString().substring(2)}`;

  return (
    <Layout
      header={
        <Header>
          <div className="flex items-center justify-center w-3/4">
            <Balances />
          </div>
        </Header>
      }
    >
      <div
        style={{
          position: "absolute",
          left: "480px",
          top: "550px",
          border: "2px solid black",
          borderRadius: "5px",
          backgroundColor: "white",
        }}
      >
        <div
          style={{
            display: "inline",
            fontFamily: "Bradley Hand",
            fontSize: 23,
          }}
        >
          {t("hangman.language").toUpperCase()}:
        </div>
        <div style={{ display: "inline" }}>
          <Menu>
            <MenuButton
              style={{
                fontFamily: "Bradley Hand",
                fontSize: 23,
                marginLeft: "10px",
                borderLeft: "2px solid black",
                paddingLeft: "2px",
              }}
            >
              {defaultConfig === undefined || fetchingDefaultConfig ? (
                <div className={styles.spinner} />
              ) : (
                <div>
                  {language.toLocaleUpperCase()}
                  <span aria-hidden style={{ marginLeft: "5px" }}>
                    ▾
                  </span>
                </div>
              )}
            </MenuButton>
            <MenuPopover position={positionMatchWidth}>
              <MenuItems className="p-0 overflow-hidden border-none shadow-lg text-center rounded">
                <div className="rounded-md bg-white shadow-xs">
                  {defaultConfig &&
                    defaultConfig.hangmanDefaultConfig?.settings.map(
                      (value) => {
                        return (
                          <div className="py-1" key={value.language}>
                            <MenuItem
                              onSelect={() => {
                                setLanguage(value.language);
                              }}
                              className="px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-blue-700 hover:text-white"
                            >
                              <div className="flex-1 px-1">
                                {value.language.toLocaleUpperCase()}
                              </div>
                            </MenuItem>
                          </div>
                        );
                      }
                    )}
                </div>
              </MenuItems>
            </MenuPopover>
          </Menu>
        </div>
      </div>
      <PixiStage {...STAGE_OPTIONS}>
        <Sprite image={bgImage} />
        <Sprite
          image={closeImage}
          y={25}
          x={1175}
          interactive
          click={() => {
            history.push("/");
          }}
          buttonMode
        />
        <Text
          text={t("hangman.daily_challenges")}
          style={textStyle}
          anchor={0.5}
          x={640}
          y={99}
        />
        <Calendar
          title={calendarTitle}
          weekDaysShortNames={weekDaysShortNames}
          statistics={calendarDaysMapped}
          setSelected={setSelectedResult}
          month={month}
          year={year}
          nextMonth={nextMonth}
          previousMonth={previousMonth}
        />
        <Container width={400} y={275} x={580}>
          <Text
            text={t("hangman.stats")}
            style={{ ...statsTextStyle, fontSize: 33 }}
            anchor={0.5}
            x={500}
          />
          <Text
            text={`${t("hangman.date")}: ${
              selectedResult?.date === undefined
                ? formatDateToShortFormat(new Date(year, month, 1))
                : formatDateToShortFormat(selectedResult.date)
            }`}
            style={{ ...statsTextStyle, fontSize: 23 }}
            x={300}
            y={30}
          />
          <Text
            text={`${t("hangman.result")}: ${t("hangman.rounds")} ${
              selectedResult?.roundsWin ?? 0
            } ${t("hangman.in")} ${
              selectedResult?.elapsedTimeMs === undefined
                ? 0
                : new Date(selectedResult?.elapsedTimeMs)
                    .toISOString()
                    .slice(11, 19)
            }`}
            style={{ ...statsTextStyle, fontSize: 23 }}
            x={300}
            y={73}
          />
          <Text
            text={`${t("hangman.personal_best")}: ${t("hangman.rounds")} ${
              bestRestult?.roundsWin ?? 0
            } ${t("hangman.in")} ${
              bestRestult?.elapsedTimeMs === undefined
                ? 0
                : new Date(bestRestult?.elapsedTimeMs)
                    .toISOString()
                    .slice(11, 19)
            }`}
            style={{ ...statsTextStyle, fontSize: 23 }}
            x={300}
            y={116}
          />
        </Container>
        <Container width={206} x={400} y={610}>
          <Sprite
            image={blueButtonImage}
            width={206}
            height={93}
            buttonMode
            interactive
            pointerup={goToLeaderboard}
          />
          <Sprite image={trophyImage} anchor={0.5} x={103} y={33} />
          <Text
            text={t("hangman.leaderboard").toLocaleUpperCase()}
            style={buttonTextStyle}
            anchor={0.5}
            x={103}
            y={78}
          />
        </Container>
        <Container width={206} x={656} y={610}>
          <Sprite
            image={redButtonImage}
            width={206}
            height={93}
            buttonMode
            interactive
            pointerup={() => {
              if (defaultConfig?.hangmanDefaultConfig?.challenge.available)
                createTable({
                  input: {
                    mode: HangmanGameMode.Challenge,
                    sectionId: sectionId ?? "",
                    name: "hangman",
                    type: HangmanTableType.Public,
                    numberOfPlayers: 1,
                    numberOfRounds: 1,
                    bet: 0,
                    levelOfDifficulty:
                      defaultConfig?.hangmanDefaultConfig?.settings[0]
                        .levels[0] ?? 1,
                    language: language ?? "English",
                  },
                }).then((result) => {
                  if (result.error) {
                    return result.error;
                  } else {
                    history.push(
                      `/area/${sectionId ?? ""}/${result!.data!
                        .hangmanCreateOpenTable!.id!}`
                    );
                    return null;
                  }
                });
            }}
          />
          <Sprite
            image={playImage}
            anchor={0.5}
            x={103}
            y={33}
            width={56}
            height={60}
          />
          <Text
            text={t("hangman.play").toLocaleUpperCase()}
            style={buttonTextStyle}
            anchor={0.5}
            x={103}
            y={78}
          />
        </Container>
      </PixiStage>
    </Layout>
  );
};

export const HangmanChallengeRoom = () => {
  const [showLeaderboard, setShowLeaderboard] = React.useState(false);
  const goToLeaderboard = () => {
    setShowLeaderboard(true);
  };

  const goToDailyChallenges = () => {
    setShowLeaderboard(false);
  };

  if (showLeaderboard) {
    return <Leaderboard goBack={goToDailyChallenges} />;
  }
  return <HangmanDailyChallenges goToLeaderboard={goToLeaderboard} />;
};
