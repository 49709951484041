import * as React from "react";
import trophy from "./assets/trophy.svg";
import { Tooltip } from "../Tooltip";
import { useTranslation } from "react-i18next";
import { useLeaderboards } from "../Leaderboards";

export const LeaderboardsButton = React.memo(
  ({ sectionIds }: { sectionIds: Array<string> }) => {
    const toggleLeaderboards = useLeaderboards();
    const { t } = useTranslation();
    return (
      <Tooltip label={t("leaderboards")}>
        <button
          onClick={() => {
            toggleLeaderboards(sectionIds);
          }}
          className="text-white"
        >
          <img src={trophy} alt="" />
        </button>
      </Tooltip>
    );
  }
);
