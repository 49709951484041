import * as React from "react";
import { useTranslation } from "react-i18next";
import { AbsoluteCenter } from "../AbsoluteCenter";
import { ButtonWithSound } from "../AudioProvider";
import { loader } from "graphql.macro";
import { DeleteMeMutation } from "./deleteMeMutation.generated";
import { useMutation } from "../../hooks/useMutation";
import { useAuthToken } from "../../hooks/useAuthToken";

const DELETE_ME_MUTATION = loader("./deleteMeMutation.graphql");

const Context = React.createContext<() => void>(undefined as any);

const Popup = ({ close }: { close: () => void }) => {
  const [, deleteProfile] = useMutation<DeleteMeMutation>(DELETE_ME_MUTATION);
  const [, , reset] = useAuthToken();

  const { t } = useTranslation();

  return (
    <div className="absolute bg-black-alpha-30 top-0 left-0 right-0 bottom-0 z-50">
      <AbsoluteCenter>
        <div
          aria-label={t("topup")}
          className="w-1/3 p-4 rounded shadow-xl bg-blue-800 border-blue-900 border-2"
        >
          {
            <>
              <div className="text-center text-white font-bold mb-2">
                {t("delete_me_insturction")}
              </div>

              <div className="flex -mx-2 mt-4">
                <div className="flex-1 px-2">
                  <ButtonWithSound
                    onClick={() => {
                      deleteProfile().then(() => {
                        reset();
                      });
                    }}
                    className="btn btn-green-600 btn-sm w-full rounded"
                  >
                    {t("submit")}
                  </ButtonWithSound>
                </div>
                <div className="flex-1 px-2">
                  <ButtonWithSound
                    className="btn btn-gray-500 btn-sm w-full rounded"
                    onClick={close}
                  >
                    {t("cancel")}
                  </ButtonWithSound>
                </div>
              </div>
            </>
          }
        </div>
      </AbsoluteCenter>
    </div>
  );
};

let key = 0;

export function DeleteMePanelProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [isOpen, setOpen] = React.useState(false);

  const toggle = React.useCallback(() => setOpen(true), []);
  const close = React.useCallback(() => {
    key++;
    setOpen(false);
  }, []);

  return (
    <Context.Provider value={toggle}>
      {children}
      {isOpen && <Popup close={close} key={key} />}
    </Context.Provider>
  );
}

export function useDeleteMePanel() {
  return React.useContext(Context);
}
