import { Subscription } from "use-subscription";

const storage =
  process.env.NODE_ENV === "production" ? localStorage : sessionStorage;
const EVENT_NAME = "token_change";
const ACCESS_TOKEN_KEY_NAME = "__MGSAT_V2__";

let evtTarget: EventTarget | HTMLDivElement;

try {
  evtTarget = new EventTarget();
} catch (error) {
  evtTarget = document.createElement("div");
}

let accessToken: string | null = null;

const parseTokens = () => {
  const raw = storage.getItem(ACCESS_TOKEN_KEY_NAME);
  accessToken = raw === null ? null : JSON.parse(raw);
};

parseTokens();

const update = (updatedValue: string | null) => {
  if (updatedValue == null) storage.removeItem(ACCESS_TOKEN_KEY_NAME);
  else storage.setItem(ACCESS_TOKEN_KEY_NAME, JSON.stringify(updatedValue));

  parseTokens();
  evtTarget.dispatchEvent(
    new CustomEvent<{ key: string }>(EVENT_NAME, {
      detail: { key: EVENT_NAME },
    })
  );
};

export const getAccessToken = () => {
  return accessToken;
};

export const clearAccessToken = () => {
  update(null);
};

export const setAccessToken = (token: string) => {
  update(token);
};

export const accessTokenSubscription: Subscription<string | null> = {
  getCurrentValue: () => {
    return accessToken;
  },
  subscribe: (callback) => {
    const listener = (evt: Event) => {
      if (evt instanceof CustomEvent) {
        const { detail } = evt;
        if (detail.key === EVENT_NAME) callback();
      }
    };
    evtTarget.addEventListener(EVENT_NAME, listener);
    return () => evtTarget.removeEventListener(EVENT_NAME, listener);
  },
};
