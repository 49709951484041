import classNames from "classnames";
import * as React from "react";

export const Presence = React.memo<{ presence: boolean }>(({ presence }) => {
  return (
    <div
      className={classNames("w-3", "h-3", "rounded-full", {
        "bg-red-600": !presence,
        "bg-green-600": presence,
      })}
    />
  );
});
