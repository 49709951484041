import * as React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useNotifications } from "../../hooks/useNotifications";
import { ActivityFragmentFragment } from "../../graphql/ActivityFragment.generated";
import { ProfileImage } from "../ProfileImage";
import {
  BalanceUpdateLeaderBoardAwardActivity,
  BalanceUpdateSendActivity,
  FriendRequestAcceptedActivity,
  FriendRequestActivity,
  FriendshipCanceledActivity,
  PurchaseActivity,
  UserMessageActivity,
  UserReferActivity,
  BalanceUpdateAdjustmentActivity,
  BalanceUpdateTournamentAwardActivity,
} from "./activities";
import isEqual from "react-fast-compare";
import { useIntersection } from "../../hooks/useIntersection";
import { Loader } from "../Loader";
import styles from "./Notifications.module.scss";
import classNames from "classnames";
import { AbsoluteCenter } from "../AbsoluteCenter";
import { useRelativeTimeFormatter } from "../../hooks/useRelativeTimeFormatter";
import { usePlayerProfile } from "../PlayerProfile";
import { PlayerFragmentFragment } from "../../graphql/PlayerFragment.generated";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import { GAME_IDS } from "../Games";
import { ButtonWithSound } from "../AudioProvider";
import platformImg from "./platform.png";
import { useScreenType } from "../../hooks/useScreenType";
import {
  RIGHT_BAR_MOBILE_SIZE,
  RIGHT_BAR_TABLET_SIZE,
} from "../Bar/RightBar/Bar";
const NotificationsContext = React.createContext<(open: boolean) => void>(
  () => {
    throw new Error("Provider not found");
  }
);

const AVATAR_WIDTH = 46;
const AVATAR_HEIGHT = 46;

const Component: {
  [key in string]: React.ComponentType<{
    actor: PlayerFragmentFragment | null;
    content: any;
    openProfile: () => void;
    setRead: () => void;
    id: string;
  }>;
} = {
  BalanceUpdateSendActivityContent: BalanceUpdateSendActivity,
  BalanceUpdateAdjustmentActivityContent: BalanceUpdateAdjustmentActivity,
  FriendRequestActivityContent: FriendRequestActivity,
  FriendRequestAcceptedActivityContent: FriendRequestAcceptedActivity,
  FriendshipCanceledActivityContent: FriendshipCanceledActivity,
  UserMessageContent: UserMessageActivity,
  PurchaseActivityContent: PurchaseActivity,
  BalanceUpdateLeaderBoardAwardContent: BalanceUpdateLeaderBoardAwardActivity,
  BalanceUpdateTournamentAwardContent: BalanceUpdateTournamentAwardActivity,
  UserReferContent: UserReferActivity,
};
const isDevelopment = process.env.NODE_ENV === "development";

const NotificationContent = ({
  id,
  actor,
  content,
  openProfile,
  setRead,
}: {
  id: string;
  actor: PlayerFragmentFragment | null;
  content: ActivityFragmentFragment["content"];
  openProfile: () => void;
  setRead: () => void;
}) => {
  const typeName = content.__typename;
  const Comp = Component[typeName];
  if (Comp === undefined) {
    if (isDevelopment)
      throw new Error("Could not find component for type:" + typeName);
    else return null;
  }
  return (
    <Comp
      id={id}
      actor={actor}
      content={content}
      openProfile={openProfile}
      setRead={setRead}
    />
  );
};

const Notification = React.memo<{
  container: React.MutableRefObject<HTMLDivElement | null>;
  activity: ActivityFragmentFragment;
  active: boolean;
  setLastRead: (date: Date) => void;
}>(({ container, activity, active, setLastRead }) => {
  const openProfile = usePlayerProfile();
  const relativeTimeFormatter = useRelativeTimeFormatter();
  const openProfileCallback = React.useCallback(() => {
    if (activity.actor) {
      setLastRead(new Date(activity.created));
      openProfile({
        ...activity.actor,
        games: GAME_IDS,
        tournament: false,
        tab:
          activity.content.__typename === "UserMessageContent"
            ? "message"
            : "statistics",
      });
    }
  }, [
    activity.actor,
    activity.content.__typename,
    activity.created,
    openProfile,
    setLastRead,
  ]);

  const setRead = React.useCallback(
    () => setLastRead(new Date(activity.created)),
    [activity.created, setLastRead]
  );

  const target = React.useRef<HTMLDivElement>(null);
  const intersecting = useIntersection(target, {
    root: container,
    once: true,
    defaultIntersecting: false,
  });
  return (
    <div
      className={classNames("py-2", "border-b-2", "border-blue-900", "px-4", {
        [styles.active]: active,
        [styles.inactive]: !active,
      })}
      ref={target}
    >
      <div className="overflow-hidden">
        <div className="flex -mx-2">
          <div className="flex-shrink-0 px-2">
            <div
              className="overflow-hidden bg-gray-200 rounded-full"
              style={{ width: AVATAR_WIDTH, height: AVATAR_HEIGHT }}
            >
              {activity.actor ? (
                intersecting && (
                  <button
                    className="block"
                    onClick={() => openProfileCallback()}
                  >
                    <ProfileImage
                      {...activity.actor}
                      width={AVATAR_WIDTH}
                      height={AVATAR_HEIGHT}
                    />
                  </button>
                )
              ) : (
                <img
                  alt="Platform"
                  src={platformImg}
                  width={AVATAR_WIDTH}
                  height={AVATAR_HEIGHT}
                />
              )}
            </div>
          </div>
          <div className="px-2 whitespace-normal text-gray-200 flex-1">
            <NotificationContent
              id={activity.id}
              actor={activity.actor ?? null}
              content={activity.content}
              openProfile={openProfileCallback}
              setRead={setRead}
            />
            <div className="text-sm text-gray-400">
              {relativeTimeFormatter.format(new Date(activity.created), {
                addSuffix: true,
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}, isEqual);

const NotificationsContent = React.memo(() => {
  const {
    notifications,
    lastRead,
    setLastRead,
    unread,
    fetchMore,
  } = useNotifications();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const loadingRef = useInfiniteScroll<HTMLDivElement>(
    { root: containerRef, rootMargin: "100px" },
    () => {
      const endCursor = notifications?.pageInfo?.endCursor ?? null;
      if (endCursor === null) return Promise.reject();
      return fetchMore(endCursor);
    }
  );

  const { t } = useTranslation();
  if (notifications === undefined)
    return (
      <div className="flex-1 relative">
        <AbsoluteCenter>
          <Loader description={t("loading_notifications")} />
        </AbsoluteCenter>
      </div>
    );
  else if (notifications.edges.length === 0)
    return (
      <div className="flex-1 relative">
        <AbsoluteCenter>
          <div className="p-4 text-center">
            <span className="text-white font-bold">
              {t("no_notifications_available")}
            </span>
          </div>
        </AbsoluteCenter>
      </div>
    );
  else
    return (
      <>
        <div className="flex-1 overflow-hidden relative">
          <div
            className={classNames(
              "flex",
              "flex-col",
              "-mb-2",
              "h-full",
              "overflow-auto",
              styles.scrollable
            )}
            ref={containerRef}
          >
            {notifications.edges.map(({ node }) => (
              <Notification
                container={containerRef}
                key={node.id}
                activity={node}
                active={new Date(node.created).getTime() > lastRead.getTime()}
                setLastRead={setLastRead}
              />
            ))}
            {notifications.pageInfo.hasNextPage && (
              <div ref={loadingRef} className="py-2">
                <Loader description={""} />
              </div>
            )}
          </div>
        </div>
        {unread > 0 && (
          <div className="flex-shrink-0 p-4">
            <ButtonWithSound
              className="btn btn-orange-600 w-full rounded"
              onClick={() => setLastRead(new Date())}
            >
              {t("mark_as_read")}
            </ButtonWithSound>
          </div>
        )}
      </>
    );
});

const Popup = ({ close }: { close: () => void }) => {
  const { t } = useTranslation();
  const screenType = useScreenType();

  const stylesMap = {
    DESKTOP: {bottom: 0},
    TABLET: { top: 0, ...RIGHT_BAR_TABLET_SIZE },
    MOBILE: { top: 0, ...RIGHT_BAR_MOBILE_SIZE },
  };
  const adjustedStyle = React.useMemo(() => stylesMap[screenType] || {}, [screenType]);

  return (
    <>
      <div
        className="absolute bg-black-alpha-30 top-0 left-0 right-0 bottom-0 z-20"
        onClick={close}
      />
      <div
        className={classNames(
          "absolute shadow-2xl bg-blue-800 right-0 z-30",
          screenType === "DESKTOP" ? "w-1/3" : "",
          screenType === "DESKTOP" ? "h-9/10" : ""
        )}
        style={{ ...adjustedStyle }}
        aria-label={t("notifications")}
      >
        <div className="flex flex-col h-full">
          <div className="flex items-center justify-between text-white flex-shrink-0 -mx-2 p-4">
            <span className="font-bold text-lg truncate px-2">
              {t("notifications")}
            </span>
            <div className="px-2 flex-shrink-0">
              <button onClick={close}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
          <NotificationsContent />
        </div>
      </div>
    </>
  );
};

export const NotificationsPanelProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const close = React.useCallback(() => setOpen(false), []);

  return (
    <NotificationsContext.Provider value={setOpen}>
      {children}
      {isOpen && <Popup close={close} />}
    </NotificationsContext.Provider>
  );
};

export const useNotificationsPanel = () => {
  return React.useContext(NotificationsContext);
};
