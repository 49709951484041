import { useTranslation } from "react-i18next";
import * as React from "react";
import { SUPPORTED_LOCALES } from "../config";

export const useOrdinalFormatter = () => {
  const { t, i18n } = useTranslation();

  return React.useMemo(
    () => ({
      format: (value: number) => {
        const rule = SUPPORTED_LOCALES[i18n.language].ordinal(value);
        const suffix = t(`ordinal.${rule}`);
        return `${value}${suffix}`;
      },
    }),
    [i18n.language, t]
  );
};
