import { loader } from "graphql.macro";
import { Cache, Data, Variables } from "@urql/exchange-graphcache";

const PLAYER_BAN_FRAGMENT = loader(
  "../../graphql/PlayerBanningFragment.graphql"
);

export const createBan = (result: Data, args: Variables, cache: Cache) => {
  const { withUser: userId } = args;
  cache.writeFragment(PLAYER_BAN_FRAGMENT, {
    id: userId as string,
    banning: {
      canAskForBanning: false,
      areBanned: true,
      __typename: "BanningType",
    },
    __typename: "Player",
  });
};
