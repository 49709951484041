import * as React from "react";
import produce from "immer";
import { TableOperation } from "../../graphql/generated";
import { CombinedError, useClient } from "urql";
import { useIsMounted } from "../useIsMounted";
import { loader } from "graphql.macro";
import { useSubscription } from "../useSubscription";
import {
  SectionTablesQuery,
  SectionTablesQueryVariables,
} from "./graphql/SectionTablesQuery.generated";
import { SectionTablesFragmentFragment } from "../../graphql/SectionTablesFragment.generated";
import {
  JoinSectionSubscription,
  JoinSectionSubscriptionVariables,
} from "./graphql/JoinSectionSubscription.generated";

type SectionTables = SectionTablesQuery["section"];

const SECTION_TABLES_QUERY = loader("./graphql/SectionTablesQuery.graphql");
const JOIN_SECTION_SUBSCRIPTION = loader(
  "./graphql/JoinSectionSubscription.graphql"
);

type Action =
  | {
      type: "MERGE_TABLES";
      payload: SectionTables;
    }
  | {
      type: "UPDATE_TABLES";
      payload: SectionTablesFragmentFragment;
    };

const mergePages = (
  prev: NonNullable<SectionTables>,
  current: NonNullable<SectionTables>
) => {
  return {
    ...current,
    tables: {
      edges: [...prev.tables.edges, ...current.tables.edges],
      pageInfo: current.tables.pageInfo,
      __typename: current.tables.__typename,
    },
  };
};

const reducer = produce((state: SectionTables, action: Action) => {
  switch (action.type) {
    case "MERGE_TABLES": {
      const data = action.payload;
      if (state === data) return state;
      else if (data === null) state = null;
      else if (state === null) state = data;
      else state = mergePages(state, data);
      break;
    }
    //TODO:If we dont have data for the operations.Save them and we will merge it once data arrives.
    // This to handle raise conditions that a table is removed via an operation but fetched data still shows it
    case "UPDATE_TABLES": {
      const { tables } = action.payload;
      if (state !== null) {
        const edges = state.tables.edges;
        let previousCursor: string | null = null;
        for (let { table, operation } of tables) {
          if (operation === TableOperation.Create)
            edges.unshift({ node: table, __typename: "TableEdge" });
          else {
            const idx = edges.findIndex((edge) => edge.node.id === table.id);
            //We dont know about this table
            if (idx >= 0) {
              if (operation === TableOperation.Delete) edges.splice(idx, 1);
              else edges[idx] = { node: table, __typename: "TableEdge" };
            }
          }
          if (
            operation === TableOperation.Delete &&
            state.tables.pageInfo.endCursor === table.cursor
          )
            state.tables.pageInfo.endCursor = previousCursor;
          else if (operation !== TableOperation.Delete)
            previousCursor = table.cursor;
        }
      }
    }
  }
  return state;
});

export const useSectionTables = (
  id: string
): [SectionTables, (cursor: string) => Promise<unknown>] => {
  const [state, dispatch] = React.useReducer(reducer, null);
  const client = useClient();
  const [error, setError] = React.useState<CombinedError | undefined>(
    undefined
  );
  const isMounted = useIsMounted();
  const fetchMore = React.useCallback(
    (cursor: string) => {
      return client
        .query<SectionTablesQuery, SectionTablesQueryVariables>(
          SECTION_TABLES_QUERY,
          { after: cursor, id },
          { requestPolicy: "network-only" }
        )
        .toPromise()
        .then(({ data, error }) => {
          if (error !== undefined) {
            throw error;
          } else {
            const dispatchData =
              data === undefined || data.section === null ? null : data.section;
            dispatch({
              type: "MERGE_TABLES",
              payload: dispatchData,
            });
          }
        })
        .catch((error) => {
          if (isMounted.current) setError(error);
        });
    },
    [client, dispatch, isMounted, id]
  );

  React.useEffect(() => {
    fetchMore("");
  }, [fetchMore]);

  const [{ data: subscriptionData }] = useSubscription<
    JoinSectionSubscription,
    JoinSectionSubscription,
    JoinSectionSubscriptionVariables
  >({
    query: JOIN_SECTION_SUBSCRIPTION,
    variables: { id },
  });

  React.useEffect(() => {
    if (
      subscriptionData === undefined ||
      subscriptionData.joinSection === null ||
      subscriptionData.joinSection.tables.length === 0
    )
      return;

    dispatch({
      type: "UPDATE_TABLES",
      payload: subscriptionData.joinSection,
    });
  }, [subscriptionData]);

  if (error !== undefined) throw error;

  return React.useMemo<
    [SectionTables, (cursor: string) => Promise<unknown>]
  >(() => {
    //const kokos: RoomTables = state;
    return [state, fetchMore];
  }, [fetchMore, state]);
};
