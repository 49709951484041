import * as React from "react";
import { useTranslation } from "react-i18next";
import { useTable } from "react-table";
import classNames from "classnames";
import GameDefinitions from "../../Games";
import styles from "./TablesList.module.scss";
import { AbsoluteCenter } from "../../AbsoluteCenter";
import { Intent, JoinTable } from "../../JoinTable";
import { useTableInfo } from "../../TableInfoProvider";
import { ButtonWithSound } from "../../AudioProvider";
import { Loader } from "../../Loader";
import { useInfiniteScroll } from "../../../hooks/useInfiniteScroll";
import { useCreateTable } from "../CreateTable";
import { SectionTablesQuery } from "../../../hooks/useSectionTables/graphql/SectionTablesQuery.generated";
import { SectionTableFragmentFragment } from "../../../graphql/SectionTableFragment.generated";
import { useSectionTables } from "../../../hooks/useSectionTables";
import { useCustomChatContext, useUser } from "../../UserProvider";

type Data = {
  table: SectionTableFragmentFragment;
  [key: string]: any;
};

const Th = ({
  className,
  children,
  ...rest
}: React.PropsWithChildren<JSX.IntrinsicElements["th"]>) => {
  return (
    <th className={classNames("px-4", "py-2", className)} {...rest}>
      {children}
    </th>
  );
};

const Td = ({
  className,
  children,
  ...rest
}: React.PropsWithChildren<JSX.IntrinsicElements["td"]>) => {
  return (
    <td
      className={classNames(
        "px-4",
        "border-t-2",
        "py-1",
        "truncate",
        "text-center",
        "border-gray-900",
        className
      )}
      {...rest}
    >
      {children}
    </td>
  );
};

function CreateTableButton({
  tournamentId,
  disabled,
  ...rest
}: { tournamentId?: string } & Omit<
  JSX.IntrinsicElements["button"],
  "onClick"
>) {
  const { user } = useUser();
  const canCreateTable = React.useMemo(() => {
    if (tournamentId === undefined) return true;
    const participation = user.tournamentParticipations.find(
      (p) => p.leaderboard.id === tournamentId
    );

    return participation !== undefined && participation.gamesRemaining > 0;
  }, [tournamentId, user.tournamentParticipations]);

  const createTable = useCreateTable();
  const { t } = useTranslation();
  return (
    <ButtonWithSound
      onClick={createTable}
      disabled={!canCreateTable || disabled}
      {...rest}
    >
      {t("create_table")}
    </ButtonWithSound>
  );
}

const TableData = ({
  tables,
  gameType,
  fetchMore,
  tournamentId,
}: {
  tables: NonNullable<SectionTablesQuery["section"]>["tables"];
  fetchMore: (cursor: string) => Promise<unknown>;
  gameType: string;
  tournamentId?: string;
}) => {
  const { useColumns, makeRow } = GameDefinitions[gameType].tableData;
  const data = React.useMemo<Array<Data>>(
    () => tables.edges.map((table) => makeRow(table.node)),
    [makeRow, tables]
  );
  const {setOpenedChat}  = useCustomChatContext();

  const isTournament = tournamentId !== undefined;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable<Data>({
    columns: useColumns(isTournament),
    data,
  });
  const isHangman = gameType === "hangman";
  const { t } = useTranslation();
  const showTableInfo = useTableInfo();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const loadingRef = useInfiniteScroll<HTMLDivElement>(
    { root: containerRef, rootMargin: "100px" },
    () => {
      const endCursor = tables.pageInfo.endCursor ?? null;
      if (endCursor === null) return Promise.reject();
      return fetchMore(endCursor);
    }
  );
  return (
    <div
      className={classNames("h-full", "overflow-y-auto", styles.scrollable)}
      ref={containerRef}
    >
      <table
        {...getTableProps({
          className: "table-fixed text-white w-full",
        })}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {!isTournament && (
                <Th className="w-1/8 text-left">{t("name")}</Th>
              )}
              {isHangman && (
                <Th className="w-1/6 text-center">{t("language_field")}</Th>
              )}
              {isHangman && (
                <Th className="w-1/6 text-center">{t("number_of_rounds")}</Th>
              )}
              {isHangman && (
                <Th className="w-1/6 text-center">{t("level_of_difficulty")}</Th>
              )}
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps({
                    className: column.className,
                    style: column.style,
                  })}
                >
                  {column.render("Header")}
                </Th>
              ))}
              <Th className="w-2/12">
                <CreateTableButton
                  className="btn btn-blue-600 btn-sm rounded-full w-full truncate"
                  tournamentId={tournamentId}
                />
              </Th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            const table = tables.edges[index];
            return (
              <tr
                onClick={() => !isTournament && showTableInfo(table.node.id)}
                {...row.getRowProps({
                  className: classNames(
                    { "cursor-pointer": !isTournament },
                    index % 2 === 0 ? styles.even : styles.odd
                  ),
                })}
              >
                {!isTournament && (
                  <Td>
                    <div className="text-left truncate">{table.node.name}</div>
                  </Td>
                )}
                {isHangman && (
                  <Td>
                    <div className="text-center truncate">
                      {table.node.metaData.language}
                    </div>
                  </Td>
                )}
                {isHangman && (
                  <Td>
                    <div className="text-center truncate">
                      {table.node.metaData.numberOfRounds}
                    </div>
                  </Td>
                )}
                {isHangman && (
                  <Td>
                    <div className="text-center truncate">
                      {table.node.metaData.levelOfDifficulty}
                    </div>
                  </Td>
                )}
                {row.cells.map((cell) => {
                  return (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  );
                })}
                <Td>
                  <JoinTable table={table.node} intent={Intent.List}>
                    {(disabled, full, join) => {
                      return (
                        <ButtonWithSound
                          className={classNames(
                            "btn",
                            "btn-sm",
                            "rounded-full",
                            "w-full",
                            full ? "btn-orange-600" : "btn-green-600"
                          )}
                          disabled={disabled}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            join();
                            setOpenedChat(false)
                            return false;
                          }}
                        >
                          {t(full ? "watch" : "join")}
                        </ButtonWithSound>
                      );
                    }}
                  </JoinTable>
                </Td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {tables.pageInfo.hasNextPage && (
        <div ref={loadingRef} className="py-2">
          <Loader description={""} />
        </div>
      )}
    </div>
  );
};

const Empty = React.memo<{ tournamentId?: string }>(({ tournamentId }) => {
  const { t } = useTranslation();

  return (
    <AbsoluteCenter>
      <div className="w-2/5 text-center">
        <span className="text-white font-bold block">
          {t("no_tables_found")}
        </span>
        <CreateTableButton
          className="btn btn-blue-600 btn-sm text-sm rounded mt-2 w-full"
          tournamentId={tournamentId}
        />
      </div>
    </AbsoluteCenter>
  );
});

export const Tables = React.memo<{
  sectionId: string;
  gameType: string;
  tournamentId?: string;
}>(({ sectionId, gameType, tournamentId }) => {
  const [data, fetchMore] = useSectionTables(sectionId);

  const { t } = useTranslation();
  if (data === null)
    return (
      <AbsoluteCenter>
        <Loader description={t("loading_tables")} />
      </AbsoluteCenter>
    );
  else if (data.tables.edges.length === 0)
    return <Empty tournamentId={tournamentId} />;
  else
    return (
      <TableData
        tables={data.tables}
        gameType={gameType}
        fetchMore={fetchMore}
        tournamentId={tournamentId}
      />
    );
});
