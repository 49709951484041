import * as React from "react";
import GameDefinitions from "../Games";
import { useHistory } from "react-router-dom";
import { RoomQuery } from "./RoomProvider/index.generated";

type Section = NonNullable<RoomQuery["room"]>["sections"][0];

const CreateTableModal = ({
  section,
  onDismiss,
}: {
  section: Section;
  onDismiss: () => void;
}) => {
  const Component = GameDefinitions[section.type].CreateTableComponent;
  const { push } = useHistory();
  const onTableCreated = React.useCallback(
    (tableId: string) => {
      onDismiss();
      push(`/area/${section.id}/${tableId}`);
    },
    [onDismiss, push, section.id]
  );

  return (
    <Component
      onTableCreated={onTableCreated}
      onDismiss={onDismiss}
      sectionId={section.id}
      isTournament={section.__typename === "TournamentSection"}
    />
  );
};

const CreateTableContext = React.createContext<() => void>(undefined as any);

export const CreateTableProvider = ({
  children,
  section,
}: React.PropsWithChildren<{
  section: Section;
}>) => {
  const [toggled, setToggled] = React.useState(false);
  const toggle = React.useCallback(() => setToggled(true), []);
  const onDismiss = React.useCallback(() => setToggled(false), []);
  return (
    <CreateTableContext.Provider value={toggle}>
      {children}
      {toggled && <CreateTableModal section={section} onDismiss={onDismiss} />}
    </CreateTableContext.Provider>
  );
};

export const useCreateTable = () => {
  return React.useContext(CreateTableContext);
};
