import * as React from "react";
import { loader } from "graphql.macro";
import {
  FriendsQueryQuery,
  FriendsQueryQueryVariables,
} from "../graphql/FriendsQuery.generated";
import { useClient, CombinedError } from "urql";
import { useIsMounted } from "./useIsMounted";

type FriendsType = NonNullable<FriendsQueryQuery["me"]>["friends"];
const FRIENDS_QUERY = loader("../graphql/FriendsQuery.graphql");

const mergePages = (prev: FriendsType, current: FriendsType) => {
  const existingIds = prev.edges.map(({ node }) => node.userB.id);
  for (const edge of current.edges)
    if (existingIds.indexOf(edge.node.userB.id) >= 0) return prev;

  return {
    edges: [...prev.edges, ...current.edges],
    pageInfo: current.pageInfo,
    __typename: current.__typename,
  };
};

/*Unfortunately we cannot use relay pagination for these.Due to network refresh
Check out https://github.com/FormidableLabs/urql/issues/672*/
export const useFriends = () => {
  const client = useClient();
  const [error, setError] = React.useState<CombinedError | undefined>(
    undefined
  );
  const [friends, setFriends] = React.useState<FriendsType | undefined>();
  const isMounted = useIsMounted();
  const fetchMore = React.useCallback(
    (cursor: string) => {
      return client
        .query<FriendsQueryQuery, FriendsQueryQueryVariables>(
          FRIENDS_QUERY,
          { after: cursor },
          { requestPolicy: "network-only" }
        )
        .toPromise()
        .then(({ data, error }) => {
          if (error !== undefined) {
            throw error;
          } else if ((data?.me ?? null) === null) {
            setFriends(undefined);
          } else if (isMounted.current) {
            const friends = data!.me!.friends;
            setFriends((prev) => {
              if (prev === undefined) return friends;
              return mergePages(prev, friends);
            });
          }
        })
        .catch((error) => {
          if (isMounted.current) setError(error);
        });
    },
    [client, isMounted]
  );

  React.useEffect(() => {
    fetchMore("");
  }, [fetchMore]);

  if (error !== undefined) throw error;

  return React.useMemo(
    () => ({
      friends,
      fetchMore,
    }),
    [fetchMore, friends]
  );
};
