import * as React from "react";
import { useTranslation } from "react-i18next";
import styles from "./VipInformation.module.scss";
import tabBlueIcon from "./assets/banner-blue.png";
import closeIcon from "./assets/blue-close-icon.png";
import { useShop } from "../Shop";
import { Currency } from "../../graphql/generated";
import vipIcon from "../../assets/vip-icon.png";
import clsx from "clsx";
import classNames from "classnames";
import Table from "./Table";

const Context = React.createContext<{ isOpen: boolean; setOpen: () => void }>(
  undefined as any
);

const Popup = ({ close }: { close: () => void }) => {
  const { t } = useTranslation();
  const [isInstructionTabActive, setIsInstructionTabActive] = React.useState(
    true
  );
  const openShop = useShop();
  const openVipShop = () => {
    close();
    openShop(Currency.RewardPoints);
  };

  return (
    <div className={styles.borderDiv}>
      <div className={`${styles.container} z-30`}>
        <div className={classNames(styles.containerColor, "z-40")}>
          <div>
            <div>
              <div className={styles.bannersWrapper}>
                <div
                  className={styles.banner}
                  onClick={() => setIsInstructionTabActive(true)}
                >
                  <img
                    src={tabBlueIcon}
                    alt="info"
                    className={clsx(
                      isInstructionTabActive ? "" : styles.darkerImg,
                      styles.tabSize
                    )}
                  />
                  <p className="text-base font-bold text-white">
                    {t("vip_popup.tabs.vip")}
                  </p>
                </div>
                <div
                  className={styles.banner}
                  onClick={() => setIsInstructionTabActive(false)}
                >
                  <img
                    src={tabBlueIcon}
                    alt="table"
                    className={clsx(
                      !isInstructionTabActive ? "" : styles.darkerImg,
                      styles.tabSize
                    )}
                  />

                  <p className="text-base font-bold text-white">
                    {t("vip_popup.tabs.benefits")}
                  </p>
                </div>
              </div>
              <div className={clsx("flex justify-end", styles.closeIcon)}>
                <button onClick={close}>
                  <img src={closeIcon} alt="close" />
                </button>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              "overflow-auto flex flex-col justify-between",
              styles.scrollableModalContent
            )}
          >
            <div className="flex-grow">
              {isInstructionTabActive && (
                <div>
                  <p className={styles.informationTitle}>
                    {t("vip_popup.information.title")}
                  </p>
                  <div className={styles.informationTitleLine} />
                  <p className={styles.fontStyle}>
                    &#x1F4E2; &nbsp;
                    {t("vip_popup.information.description.beginning")}
                    <span
                      onClick={openVipShop}
                      className="text-blue-700 underline cursor-pointer"
                    >
                      VIP
                    </span>
                    {t("vip_popup.information.description.end")} &nbsp;
                    &#x1F451;
                  </p>

                  <ul className="leading-9">
                    <li className={styles.fontStyle}>
                      {" "}
                      &#128172; {t("vip_popup.information.list.first")}
                    </li>
                    <li className={styles.fontStyle}>
                      {" "}
                      &#x1F933; {t("vip_popup.information.list.second")}
                    </li>
                    <li className={styles.fontStyle}>
                      {" "}
                      &#x1F929; {t("vip_popup.information.list.third")}
                    </li>
                    <li className={styles.fontStyle}>
                      {" "}
                      &#x1f525; {t("vip_popup.information.list.fourth")}
                    </li>
                    <li className={styles.fontStyle}>
                      {" "}
                      &#x1f4f5; {t("vip_popup.information.list.fifth")}
                    </li>
                  </ul>
                  <div className="flex flex-row justify-between">
                    <p className={styles.fontStyle}>
                      {t("vip_popup.bottom.description")} &#x1F911;
                    </p>
                    <div>
                      <div className={styles.vipIconWithText}>
                        <img src={vipIcon} alt="vip-icon" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!isInstructionTabActive && <Table />}
            </div>
            <div className="relative flex justify-center my-10">
              <div className={styles.borderJoinButton}>
                <button onClick={openVipShop} className={styles.joinButton}>
                  {t("vip_popup.bottom.button")}
                </button>
              </div>
              <div className={styles.vipIcon}>
                <img src={vipIcon} alt="vip-icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

let key = 0;

export function VipInstructionPopupProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [isOpen, setOpen] = React.useState(false);

  const toggle = {
    isOpen,
    setOpen: () => setOpen(true),
  };
  const close = React.useCallback(() => {
    key++;
    setOpen(false);
  }, []);

  return (
    <Context.Provider value={toggle}>
      {children}
      {isOpen && <Popup close={close} key={key} />}
    </Context.Provider>
  );
}

export function useVipInstructionPopup() {
  return React.useContext(Context);
}
