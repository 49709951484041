import * as React from "react";
import { BalanceUpdateLeaderBoardAwardContentFragmentFragment } from "../../../graphql/ActivityFragment.generated";
import { Trans, useTranslation } from "react-i18next";
import { useOrdinalFormatter } from "../../../hooks/useOrdinalFormatter";
import { useDateTimeFormatter } from "../../../hooks/useDateTimeFormatter";

export const BalanceUpdateLeaderBoardAwardActivity = ({
  content,
}: {
  content: BalanceUpdateLeaderBoardAwardContentFragmentFragment;
}) => {
  const ordinalFormatter = useOrdinalFormatter();
  const dateTimeFormatter = useDateTimeFormatter();
  const { t } = useTranslation();
  return (
    <Trans i18nKey="notification.balance_update_leader_board_award_activity">
      You have finished{" "}
      <strong>{{ position: ordinalFormatter.format(content.position) }}</strong>{" "}
      on leaderboard{" "}
      <strong>{{ leaderBoard: content.leaderBoard.name }}</strong> for game{" "}
      <strong>{{ game: t(`game.${content.leaderBoard.section.type}`) }}</strong>{" "}
      that ended on{" "}
      <strong>
        {{ end: dateTimeFormatter.format(new Date(content.leaderBoard.end)) }}
      </strong>
    </Trans>
  );
};
