import * as React from "react";
import { makeClient } from "../urql";
import { Status } from "../other/subscriptionClientWithStatus";
import { Client } from "urql";
import { useTranslation } from "react-i18next";

export type status = "CONNECTING" | "CONNECTED" | "DISCONNECTED";

export const useUrqlClient = (): [status, Client] | null => {
  const { i18n } = useTranslation();
  const [status, setStatus] = React.useState<status>();
  const clientRef = React.useRef<ReturnType<typeof makeClient> | null>(null);

  React.useEffect(() => {
    clientRef.current = makeClient(i18n);
    const [subscriptionClient, , destroy] = clientRef.current;
    const getInitialStatus = () => {
      switch (subscriptionClient.connectionStatus) {
        case Status.Disconnected:
          return "DISCONNECTED";
        case Status.Connecting:
        case Status.Reconnecting:
          return "CONNECTING";
        case Status.Connected:
        case Status.Reconnected:
          return "CONNECTED";
      }
    };
    setStatus(getInitialStatus());

    const listeners: Array<Function> = [];
    listeners.push(
      subscriptionClient.onConnecting(() => {
        setStatus("CONNECTING");
      })
    );
    listeners.push(
      subscriptionClient.onConnected(() => {
        setStatus("CONNECTED");
      })
    );
    listeners.push(
      subscriptionClient.onError((error: Error) => {
        console.error("Error on subscription", error);
      })
    );
    listeners.push(
      subscriptionClient.onReconnecting(() => {
        setStatus("CONNECTING");
      })
    );
    listeners.push(
      subscriptionClient.onReconnected(() => {
        setStatus("CONNECTED");
      })
    );
    listeners.push(
      subscriptionClient.onDisconnected(() => {
        setStatus("DISCONNECTED");
      })
    );

    return () => {
      clientRef.current = null;
      listeners.forEach((listener) => listener());
      destroy();
    };
  }, [i18n]);

  if (clientRef.current === null || status === undefined) return null;

  const [, client] = clientRef.current;
  return [status, client];
};
