import React from "react";

export const validateEmail = (email: string) => {
  return (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) !== null
  );
};

export const isValidPassword = (password: string) => {
  return (
    String(password).match(
      /^(.{0,9}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/
    ) === null
  );
};

export const isValidOtp = (otp: string) => {
  const parsed = parseInt(otp, 10);
  return !isNaN(parsed);
};

export const saveToLocalStorageWithExpiry = ({
  key,
  value,
  ttlInSeconds,
}: {
  key: string;
  value: any;
  ttlInSeconds: number;
}) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttlInSeconds * 1000,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getValueFromLocalStorage = (key: string) => {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const removeFromLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};
