import * as React from "react";
import { useTranslation } from "react-i18next";
import { FacebookLogin } from "./FacebookLogin";
import { AppleLogin } from "./AppleLogin";
import { GuestLogin } from "./GuestLogin";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import { useLogin } from "../useLogin";
import {
  clearGuestId,
  clearIsLoggedAsGuest,
  getGuestId,
} from "../../../guestId";
import { Link } from "react-router-dom";
import classNames from "classnames";
import styles from "./Login.module.scss";
import AppleIcon from "./icons/AppleIcon";
import GuestIcon from "./icons/GuestIcon";
import EmailIcon from "./icons/EmailIcon";
import GoogleIcon from "./icons/GoogleIcon";
import FacebookIcon from "./icons/FacebookIcon";

const CLIENT_ID: string = process.env.REACT_APP_GOOGLE_CLIENT_ID!;
const GOOGLE = "google";
export const EmailPath = "/email";

function Button({
  children,
  ...rest
}: React.PropsWithChildren<JSX.IntrinsicElements["button"]>) {
  return (
    <button
      className={classNames(
        styles.loginSelectButton,
        "w-full px-4 py-2 text-center text-white border-2"
      )}
      {...rest}
    >
      {children}
    </button>
  );
}

const LoginButton = React.memo<
  {
    name: string;
    disabled: boolean;
    onClick?: () => void;
    fullName?: string;
    image?: string;
    children?: JSX.Element;
  } & Omit<JSX.IntrinsicElements["button"], "disabled" | "onClick">
>(
  ({
    name,
    className,
    disabled,
    onClick,
    fullName,
    image,
    children,
    ...rest
  }) => {
    const { t } = useTranslation();
    return (
      <Button disabled={disabled} onClick={onClick} {...rest}>
        <div className="flex items-center -mx-2 text-2xl">
          <div className="w-10 h-10 mx-2 ">{children}</div>
          <div
            className={classNames(
              "flex-1 px-2 text-lg font-bold text-left",
              styles.chooseLoginText
            )}
          >
            {fullName ? t(fullName) : t("continue_with", { brand: name })}
          </div>
        </div>
      </Button>
    );
  }
);

const FacebookButton = ({
  guestId,
  setError,
}: {
  guestId: string | null;
  setError: (error: string | null) => void;
}) => {
  const [hovered, setHovered] = React.useState(false);
  return (
    <FacebookLogin guestId={guestId} setError={setError}>
      {(disabled, login) => (
        <LoginButton
          disabled={disabled}
          onClick={login}
          name="Facebook"
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
        >
          <FacebookIcon
            backgroundColor={hovered ? "white" : "#3889FF"}
            fillColor={hovered ? "#0068FF" : "white"}
          />
        </LoginButton>
      )}
    </FacebookLogin>
  );
};

const GoogleButton = ({
  guestId,
  setError,
}: {
  guestId: string | null;
  setError: (error: string | null) => void;
}) => {
  const [hovered, setHovered] = React.useState(false);
  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => loginFacade(codeResponse.code),
    flow: "auth-code",
  });
  const login = useLogin(GOOGLE, "website");
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const loginFacade = React.useCallback(
    async (googleAuthCode: string) => {
      setLoading(true);
      try {
        let extra;
        if (guestId == null) {
          extra = { origin: window.location.origin };
        } else {
          extra = { origin: window.location.origin, guestId: guestId };
        }
        return await login(googleAuthCode, extra).then(() => {
          if (guestId) {
            clearIsLoggedAsGuest();
            clearGuestId();
            window.location.href = "/";
          }
        });
      } catch (e: any) {
        setLoading(false);
        if (guestId != null) setError(t("user_with_account_already_exists"));
      }
    },
    [login, guestId]
  );

  return (
    <LoginButton
      disabled={loading}
      onClick={googleLogin}
      name="Google"
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      <GoogleIcon
        backgroundColor={hovered ? "white" : "#3889FF"}
        fillColor={hovered ? "#0068FF" : "white"}
      />
    </LoginButton>
  );
};

const AppleButton = ({
  guestId,
  setError,
}: {
  guestId: string | null;
  setError: (error: string | null) => void;
}) => {
  const [hovered, setHovered] = React.useState(false);
  return (
    <AppleLogin guestId={guestId} setError={setError}>
      {(disabled, onClick) => (
        <LoginButton
          disabled={disabled}
          onClick={onClick}
          name="Apple"
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
        >
          <AppleIcon
            backgroundColor={hovered ? "white" : "#3889FF"}
            fillColor={hovered ? "#0068FF" : "white"}
          />
        </LoginButton>
      )}
    </AppleLogin>
  );
};

const GuestButton = () => {
  const [hovered, setHovered] = React.useState(false);
  return (
    <GuestLogin>
      {(disabled, onClick) => (
        <LoginButton
          disabled={disabled}
          onClick={onClick}
          name=""
          fullName="continue_as_guest"
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
        >
          <GuestIcon
            backgroundColor={hovered ? "white" : "#3889FF"}
            fillColor={hovered ? "#0068FF" : "white"}
          />
        </LoginButton>
      )}
    </GuestLogin>
  );
};

const EmailButton = ({
  guestId,
  setError,
}: {
  guestId: string | null;
  setError: (error: string | null) => void;
}) => {
  const [hovered, setHovered] = React.useState(false);
  return (
    <Link to={EmailPath}>
      <LoginButton
        disabled={false}
        onClick={() => {}}
        name="Email"
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
      >
        <EmailIcon
          backgroundColor={hovered ? "white" : "#3889FF"}
          fillColor={hovered ? "#0068FF" : "white"}
        />
      </LoginButton>
    </Link>
  );
};

const ChooseLogin = ({
  isConvertGuestToRegularUser,
  setError,
}: {
  isConvertGuestToRegularUser: boolean;
  setError: (error: string | null) => void;
}) => {
  let guestId: string | null = null;
  if (isConvertGuestToRegularUser) {
    guestId = getGuestId();
  }
  return (
    <div className="w-full py-1 md:py-2">
      <div className="flex flex-col items-center justify-center w-full -my-2">
        <div className={classNames(styles.chooseLoginButton, "py-2")}>
          <FacebookButton guestId={guestId} setError={setError} />
        </div>
        <div className={classNames(styles.chooseLoginButton, "py-2")}>
          <GoogleOAuthProvider clientId={CLIENT_ID}>
            <GoogleButton guestId={guestId} setError={setError} />
          </GoogleOAuthProvider>
        </div>
        <div className={classNames(styles.chooseLoginButton, "py-2")}>
          <AppleButton guestId={guestId} setError={setError} />
        </div>
        <div className={classNames(styles.chooseLoginButton, "py-2")}>
          <EmailButton guestId={guestId} setError={setError} />
        </div>
        {!isConvertGuestToRegularUser && (
          <div className={classNames(styles.chooseLoginButton, "py-2")}>
            <GuestButton />
          </div>
        )}
      </div>
    </div>
  );
};

export default ChooseLogin;
