import * as React from "react";
import { Menu, MenuButton, MenuItems, MenuPopover, ARROW_STYLE } from "../Menu";
import { useTranslation } from "react-i18next";
import { Flag } from "../Flag";
import { SUPPORTED_LOCALES } from "../../config";
import { MenuItem } from "@reach/menu-button";

export const LanguageSelection = ({ mainScreen }: { mainScreen?: boolean }) => {
  const { t, i18n } = useTranslation();

  const language = SUPPORTED_LOCALES[i18n.language];
  if (language === undefined) return null;
  return (
    <Menu>
      <MenuButton className="block">
        <Flag country={language.flag} />
      </MenuButton>
      <MenuPopover
        portal={false}
        className="z-20"
        style={{
          transform: mainScreen ? "translateX(-100px)" : "",
        }}
      >
        <div
          style={
            mainScreen
              ? { ...ARROW_STYLE, left: "unset", right: 7 }
              : ARROW_STYLE
          }
        />
        <MenuItems
          className="p-0 rounded overflow-hidden border-none"
          style={{ marginTop: 10 }}
        >
          <div className="rounded shadow-xs" style={{ background: "#1B1F28" }}>
            {Object.entries(SUPPORTED_LOCALES).map(([key, value]) => {
              return (
                <div className="py-1" key={key}>
                  <MenuItem
                    onSelect={() => i18n.changeLanguage(key)}
                    className="px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-blue-700 hover:text-white"
                  >
                    <div className="flex w-full">
                      <div className="flex-shrink-0">
                        <Flag country={value.flag} />
                      </div>
                      <div className="flex-1 px-1 text-white">
                        {t(`language.${key}`)}
                      </div>
                    </div>
                  </MenuItem>
                </div>
              );
            })}
          </div>
        </MenuItems>
      </MenuPopover>
    </Menu>
  );
};
