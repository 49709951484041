import * as React from "react";
import { useLocalStorage } from "../../hooks/useStorage";
import Sound from "../../sound";

const incomingMessage = require("./sounds/incoming_message.mp3");
const button = require("./sounds/button.mp3");
const timer = require("./sounds/timer_count.mp3");

const SOUNDS_TO_LOAD = {
  [incomingMessage]: "incoming-message",
  [button]: "button",
  [timer]: "timer",
};

type AudioFile = "incoming-message" | "button" | "timer";

type AudioContextType = {
  play: (value: AudioFile) => void;
  muted: boolean;
  toggleMute: () => void;
};

const AudioContext = React.createContext<AudioContextType>(undefined as any);

export const AudioProvider = ({ children }: { children?: React.ReactNode }) => {
  const [sounds, setSounds] = React.useState<{ [key: string]: any }>({});
  const [muted, setMuted] = useLocalStorage("muted", false);
  const toggleMute = React.useCallback(() => setMuted(!muted), [
    muted,
    setMuted,
  ]);

  const play = React.useCallback(
    (value: AudioFile) => {
      const sound = sounds[value];
      if (sound !== undefined) sound.play();
    },
    [sounds]
  );

  React.useEffect(() => {
    if (muted) {
      Sound.mute();
      (window as any).adConfig({
        sound: "off",
      });
    } else {
      Sound.unmute();
      (window as any).adConfig({
        sound: "on",
      });
    }
  }, [muted]);

  const context = React.useMemo(
    () => ({
      play,
      muted,
      toggleMute,
    }),
    [muted, play, toggleMute]
  );

  React.useEffect(() => {
    let isCurrent = true;
    const sounds = Sound.sounds;
    sounds.load(Object.keys(SOUNDS_TO_LOAD));
    (sounds.whenLoaded as any) = () => {
      const loaded: { [key: string]: any } = {};
      for (let [key, value] of Object.entries(SOUNDS_TO_LOAD))
        loaded[value] = (sounds as any)[key];

      if (isCurrent) setSounds(loaded);
    };
    return () => {
      isCurrent = false;
    };
  }, []);
  return (
    <AudioContext.Provider value={context}>{children}</AudioContext.Provider>
  );
};

export const useAudio = () => {
  return React.useContext(AudioContext);
};
