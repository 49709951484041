import * as React from "react";
import { PropsWithChildren } from "react";
import { useAudio } from "./AudioProvider";

export const ButtonWithSound = ({
  onClick,
  ...rest
}: PropsWithChildren<JSX.IntrinsicElements["button"]>) => {
  const { play } = useAudio();
  return (
    <button
      {...rest}
      onClick={(props) => {
        play("button");
        if (onClick !== undefined) return onClick(props);
      }}
    />
  );
};
