import * as React from "react";
import {
  HangmanDefaultConfigQueryQuery,
  HangmanDefaultConfigQueryQueryVariables,
} from "./graphql/HangmanDefaultConfigQuery.generated";
import { loader } from "graphql.macro";
import { useQuery } from "../../../../hooks/useQuery";
import {
  HangmanCreateOpenTableMutation,
  HangmanCreateOpenTableMutationVariables,
} from "./graphql/HangmanCreateOpenTableMutation.generated";
import { useTranslation } from "react-i18next";
import { Loader } from "../../../Loader";
import { useMutation } from "../../../../hooks/useMutation";
import { AbsoluteCenter } from "../../../AbsoluteCenter";
import { useIsMounted } from "../../../../hooks/useIsMounted";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import {
  HangmanCreateTournamentTableMutation,
  HangmanCreateTournamentTableMutationVariables,
} from "./graphql/HangmanCreateTournamentTableMutation.generated";
import { HangmanForm } from "./Form";

export const HANGMAN_DEFAULT_CONFIG_QUERY = loader(
  "./graphql/HangmanDefaultConfigQuery.graphql"
);

export const HANGMAN_CREATE_OPEN_TABLE_MUTATION = loader(
  "./graphql/HangmanCreateOpenTableMutation.graphql"
);

const HANGMAN_CREATE_TOURNAMENT_TABLE_MUTATION = loader(
  "./graphql/HangmanCreateTournamentTableMutation.graphql"
);

function CreateOpenTable({
  sectionId,
  onTableCreated,
  onDismiss,
}: {
  sectionId: string;
  onTableCreated: (id: string) => void;
  onDismiss: () => void;
}) {

  const [{ data }] = useQuery<
    HangmanDefaultConfigQueryQuery,
    HangmanDefaultConfigQueryQueryVariables
  >({
    query: HANGMAN_DEFAULT_CONFIG_QUERY,
    variables: {
      sectionId,
    },
    requestPolicy: "network-only",
  });

  const [, createTable] = useMutation<
    HangmanCreateOpenTableMutation,
    HangmanCreateOpenTableMutationVariables
  >(HANGMAN_CREATE_OPEN_TABLE_MUTATION, false);

  const { t } = useTranslation();

  const config = data?.hangmanDefaultConfig;
  if (config === null)
    throw new Error("Game hangman not supported on this room");

  return (
    <>
      <div
        className="absolute bg-black-alpha-30 top-0 left-0 right-0 bottom-0 z-20"
        onClick={onDismiss}
      />
      <div
        className="w-1/3 p-4 absolute shadow-2xl bg-blue-800 top-0 bottom-0 right-0 z-30"
        aria-label={t("create_table")}
      >
        <div className="flex flex-col h-full">
          <div className="flex items-center justify-between text-white flex-shrink-0 mb-4 -mx-2">
            <span className="font-bold text-lg truncate px-2">
              {t("create_table")}
            </span>
            <div className="px-2 flex-shrink-0">
              <button onClick={onDismiss}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
          <div className="flex-1 overflow-hidden relative">
            {config === undefined ? (<AbsoluteCenter>
                <Loader description={t("loading_game_config")} />
              </AbsoluteCenter>) : (<HangmanForm
                sectionId={sectionId}
                config={config}
                onSubmit={(input) => {
                  return createTable({ input }).then((result) => {
                    if (result.error) {
                      return result.error;
                    } else {
                      onTableCreated(result!.data!.hangmanCreateOpenTable!.id!);
                      return null;
                    }
                  })}
                }
              />)}
          </div>
        </div>
      </div>
    </>
  );
}

function CreateTournamentTable({
  onTableCreated,
  sectionId,
  onDismiss,
}: {
  sectionId: string;
  onTableCreated: (id: string) => void;
  onDismiss: () => void;
}) {
  const [, createTable] = useMutation<
    HangmanCreateTournamentTableMutation,
    HangmanCreateTournamentTableMutationVariables
  >(HANGMAN_CREATE_TOURNAMENT_TABLE_MUTATION, false);

  const isMounted = useIsMounted();
  React.useEffect(() => {
    createTable({ sectionId: sectionId }).then((result) => {
      if (result.error) {
        if (isMounted.current) onDismiss();
      } else onTableCreated(result!.data!.hangmanCreateTournamentTable!.id!);
    });
  }, [sectionId, createTable, onTableCreated, isMounted, onDismiss]);
  return (
    <>
      <div className="absolute bg-black-alpha-30 top-0 left-0 right-0 bottom-0 z-20" />
      <AbsoluteCenter>
        <Loader description="Creating table" />
      </AbsoluteCenter>
    </>
  );
}

export const CreateTable = ({
  isTournament,
  ...rest
}: {
  sectionId: string;
  isTournament: boolean;
  onTableCreated: (id: string) => void;
  onDismiss: () => void;
}) => {
  if (!isTournament) return <CreateOpenTable {...rest} />;
  else return <CreateTournamentTable {...rest} />;
};
