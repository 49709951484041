import React from "react";

const AppleIcon = ({
  backgroundColor,
  fillColor,
}: {
  backgroundColor?: string;
  fillColor?: string;
}) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="20"
      cy="20"
      r="20"
      fill={backgroundColor ? backgroundColor : "#3889FF"}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.1745 8.44204C24.1745 9.56841 23.6601 10.6948 22.934 11.5075C22.1624 12.4058 20.8313 13.0759 19.7723 13.076C19.6514 13.076 19.5302 13.0617 19.4547 13.0475C19.4396 12.9904 19.4092 12.8193 19.4092 12.6482C19.4092 11.5075 20.0295 10.3811 20.6951 9.66822C21.5423 8.74148 22.9491 8.0428 24.1291 8C24.1594 8.12839 24.1745 8.28522 24.1745 8.44204ZM28.336 15.5372C28.3654 15.519 28.3855 15.5065 28.3949 15.4996C26.8217 13.3753 24.4315 13.3181 23.7658 13.318C22.7487 13.318 21.8382 13.6581 21.0728 13.9441C20.5178 14.1514 20.039 14.3303 19.6512 14.3303C19.2232 14.3303 18.7333 14.1437 18.1865 13.9354C17.4962 13.6724 16.7153 13.375 15.8542 13.375C12.9499 13.375 10 15.6421 10 19.9195C10 22.5857 11.0891 25.3945 12.4355 27.2053C13.6002 28.7452 14.6138 29.9999 16.066 29.9999C16.7547 29.9999 17.2597 29.7979 17.7907 29.5855C18.3795 29.3499 19.0002 29.1016 19.9387 29.1016C20.8863 29.1016 21.4529 29.3347 21.9993 29.5596C22.5083 29.7691 22.9999 29.9713 23.7659 29.9713C25.3542 29.9713 26.3981 28.6169 27.3965 27.2623C28.5159 25.7225 28.9849 24.2111 29 24.1399C28.9092 24.1114 25.8686 22.9565 25.8686 19.6914C25.8686 17.0634 27.9399 15.7822 28.336 15.5372Z"
      fill={fillColor ? fillColor : "white"}
    />
  </svg>
);

export default AppleIcon;
