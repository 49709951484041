import { Cache, Data, Variables } from "@urql/exchange-graphcache";
import { loader } from "graphql.macro";
import { SendBalanceMutation } from "../../graphql/SendBalanceMutation.generated";
import { MeQueryQuery } from "../../graphql/MeQuery.generated";

const BALANCES_FRAGMENT = loader("../../graphql/BalancesFragment.graphql");
const ME_QUERY = loader("../../graphql/MeQuery.graphql");

export const sendBalance = (result: Data, args: Variables, cache: Cache) => {
  const { sendBalance } = (result as unknown) as SendBalanceMutation;
  if (sendBalance === null) return;
  const loggedInUserId =
    (cache.readQuery({ query: ME_QUERY }) as MeQueryQuery)?.me?.id ?? null;

  for (const response of sendBalance) {
    const { userId, balances } = response;
    const __typename = loggedInUserId === userId ? "User" : "Player";
    cache.writeFragment(BALANCES_FRAGMENT, {
      id: userId,
      balances,
      __typename,
    });
  }
};
