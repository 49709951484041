import * as React from "react";
import { BalancesFragmentFragment } from "../../graphql/BalancesFragment.generated";
import { GameStatisticsFragmentFragment } from "../../graphql/GameStatisticsFragment.generated";
import { Versus } from "../Versus";
import { GameStatisticsCompare } from "./GameStatisticsCompare";
import { BalanceStatisticsCompare } from "./BalanceStatisticsCompare";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

type Balances = BalancesFragmentFragment["balances"];
type GameStatistics = GameStatisticsFragmentFragment["statistics"];
type Statistics = {
  balances: Balances;
  gaming: GameStatistics;
  name: string;
};

export const PlayerStatisticsCompare = ({
  me,
  other,
  games,
  tournament,
}: {
  me: Statistics | null;
  other: Statistics | null;
  games: Array<string>;
  tournament: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <>
      {me !== null && other !== null && (
        <div className="mb-4">
          <Versus nameA={me.name} nameB={other.name} />
        </div>
      )}
      <div
        className={classNames("bg-blue-900", "rounded", "mb-4", {
          "px-4 py-2": me === null || other === null,
        })}
      >
        <GameStatisticsCompare
          me={me?.gaming ?? null}
          other={other?.gaming ?? null}
          games={games}
          tournament={tournament}
        />
      </div>
      <div
        className={classNames("bg-blue-900", "rounded", {
          "px-4 py-2": me === null || other === null,
        })}
      >
        <div className="py-2 text-center text-lg font-bold">
          {t("balances")}
        </div>
        <BalanceStatisticsCompare
          other={other?.balances ?? null}
          me={me?.balances ?? null}
        />
      </div>
    </>
  );
};
