import { useQuery } from "../useQuery";
import {
  MyStatisticsQueryQuery,
  MyStatisticsQueryQueryVariables,
} from "./graphql/MyStatisticsQuery.generated";
import { loader } from "graphql.macro";
import { RequestPolicy } from "@urql/core";

const MY_STATISTICS_QUERY = loader("./graphql/MyStatisticsQuery.graphql");

export const useMyStatistics = (
  requestPolicy: RequestPolicy
): NonNullable<MyStatisticsQueryQuery["me"]>["statistics"] | null => {
  const [{ data }] = useQuery<
    MyStatisticsQueryQuery,
    MyStatisticsQueryQueryVariables
  >({
    query: MY_STATISTICS_QUERY,
    requestPolicy,
  });

  return data?.me?.statistics ?? null;
};
