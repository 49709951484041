import classNames from "classnames";
import React from "react";

const ChannelSkeleton = () => {
  return (
    <div
      className={classNames(
        "flex flex-col items-center justify-center",
        "channel-skeleton-container"
      )}
    >
      <div className="skeleton-header"></div>
      <div className="input-skeleton"></div>
    </div>
  );
};

export default ChannelSkeleton;
