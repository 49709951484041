import {
  SubscriptionHandler,
  useSubscription as useSubscriptionInternal,
  UseSubscriptionArgs as UseSubscriptionArgsInternal,
  UseSubscriptionResponse,
} from "urql";

export type UseSubscriptionArgs<V> = {
  throwOnError?: boolean;
} & UseSubscriptionArgsInternal<V>;

export const useSubscription = <T = any, R = T, V = object>(
  args: UseSubscriptionArgs<V>,
  handler?: SubscriptionHandler<T, R> | undefined
): UseSubscriptionResponse<R, V> => {
  const { throwOnError = true, ...rest } = args;
  const result = useSubscriptionInternal(rest, handler);
  const [{ error }] = result;
  if (error !== undefined && throwOnError) throw error;
  return result;
};
