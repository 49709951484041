import * as React from "react";
import { gql, loader } from "graphql.macro";
import classNames from "classnames";
import { TournamentQuery, TournamentQueryVariables } from "./index.generated";
import { useNumberFormatter } from "../../../hooks/useNumberFormatter";
import { useRelativeTimeFormatter } from "../../../hooks/useRelativeTimeFormatter";
import { useOrdinalFormatter } from "../../../hooks/useOrdinalFormatter";
import { useTranslation } from "react-i18next";
import { CurrencyImage } from "../../CurrencyImage";
import styles from "./TournamentsSidebar.module.scss";
import isEqual from "react-fast-compare";
import { useQuery } from "../../../hooks/useQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AbsoluteCenter } from "../../AbsoluteCenter";
import { Loader } from "../../Loader";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { ProfileImage } from "../../ProfileImage";
import { usePlayerProfile } from "../../PlayerProfile";
import GameDefinitions from "../../Games";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@reach/tabs";

const AMOUNT_FRAGMENT = loader("../../../graphql/AmountFragment.graphql");
const LEADERBORD_RECORD_FRAGMENT = loader(
  "../../../graphql/LeaderboardRecordFragment.graphql"
);

const RECORDS_TO_FETCH = 5;
const AVATAR_SIZE = {
  width: 42,
  height: 42,
};

const TOURNAMENT_QUERY = gql`
  query Tournament($id: ID!, $records: Int!) {
    tournament(id: $id) {
      id
      name
      start
      end
      active
      logo
      initialRanking
      config
      topGames
      qualifyGames
      section {
        id
        type
      }
      prices {
        from
        to
        amount {
          ...AmountFragment
        }
      }
      amount {
        ...AmountFragment
      }
      gamesPerPurchase
      records(limit: $records) {
        ...LeaderboardRecordFragment
      }
      me {
        ...LeaderboardRecordFragment
        position
        ... on TournamentRecord {
          win
          lose
          draw
          quit
          ranking
          gamesPlayed
          score
          gamesRemaining
        }
      }
    }
  }
  ${AMOUNT_FRAGMENT}
  ${LEADERBORD_RECORD_FRAGMENT}
`;

const TournamentsSidebarContext = React.createContext<
  (tournamentId: string) => void
>(undefined as any);

const Label = ({
  children,
  className,
  ...rest
}: React.PropsWithChildren<JSX.IntrinsicElements["div"]>) => {
  return (
    <div
      className={classNames(
        "p-2",
        "w-full",
        "rounded-lg",
        "text-white",
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

const RecordLabel = React.memo<
  {
    title: string;
    value: React.ReactNode;
  } & JSX.IntrinsicElements["div"]
>(({ title, value, className, ...rest }) => {
  return (
    <div
      className={classNames(
        "flex",
        "flex-col",
        "justify-between",
        "text-white",
        "overflow-hidden",
        className
      )}
      {...rest}
    >
      <span className="text truncate">{title}</span>
      {typeof value === "string" || typeof value === "number" ? (
        <span className="text-md font-bold truncate">{value}</span>
      ) : (
        value
      )}
    </div>
  );
});

const Record = React.memo<{
  record: NonNullable<TournamentQuery["tournament"]>["records"][0];
  position: number;
  game: string;
  initialRanking: number;
}>(({ record, position, game, initialRanking }) => {
  const ordinalFormatter = useOrdinalFormatter();
  const openProfile = usePlayerProfile();
  const { t } = useTranslation();
  return (
    <div className="flex items-center -mx-1">
      <div className="flex-shrink-0 px-1">
        <div
          style={AVATAR_SIZE}
          className={classNames(
            "rounded-full",
            "overflow-hidden",
            "flex",
            "items-center",
            "justify-center",
            "font-bold",
            "text-white",
            "bg-blue-600"
          )}
        >
          {position >= 1000 ? (
            <span className="text-xs">1000+</span>
          ) : (
            <span className="test-md">{ordinalFormatter.format(position)}</span>
          )}
        </div>
      </div>
      <button
        onClick={() =>
          openProfile({
            ...record.player,
            games: [game],
            tournament: true,
          })
        }
        className="flex-shrink-0 px-1"
      >
        <ProfileImage
          id={record.player.id}
          name={record.player.name}
          className="bg-gray-400 rounded-full"
          {...AVATAR_SIZE}
        />
      </button>
      <div className="flex-1 px-1 overflow-hidden">
        <RecordLabel title={t("name")} value={record.player.name} />
      </div>
      <div className="flex-shrink-0 px-1">
        <RecordLabel
          title={t("score")}
          value={Math.max(initialRanking + (record as any).score, 0)}
        />
      </div>
    </div>
  );
});

const TABS: Array<string> = ["information", "leaderboard"];
const TABS_WITH_PARTICIPATION: Array<string> = [...TABS, "participation"];

const Information = ({
  tournament,
}: {
  tournament: NonNullable<TournamentQuery["tournament"]>;
}) => {
  const ordinalFormatter = useOrdinalFormatter();
  const numberFormatter = useNumberFormatter();
  const { t } = useTranslation();
  const InfoComponent =
    GameDefinitions[tournament.section.type].TournamentInfoComponent;
  return (
    <>
      <span className="font-bold text-xl text-white">{t("game_label")}</span>
      <Label className="bg-blue-900 mt-1 mb-2">
        <div className="flex flex-col -my-1 text-sm">
          <InfoComponent config={tournament.config} />
        </div>
      </Label>
      <span className="font-bold text-lg text-white">{t("awards")}</span>
      <div className="flex flex-col -my-1 mt-1">
        {tournament.prices.map((price, index) => {
          let position = ordinalFormatter.format(price.from);
          if (price.from !== price.to)
            position = `${position} - ${ordinalFormatter.format(price.to)}`;
          return (
            <div className="py-1" key={index}>
              <Label className="bg-blue-900">
                <div className="flex -mx-2">
                  <div className="px-2 flex-1 flex flex-col justify-between">
                    <span className="text-sm leading-none font-bold block">
                      {position}
                    </span>
                    <span className="text-xs">
                      {`${numberFormatter.format(price.amount.value)} ${t(
                        `currency.${price.amount.currency.toLowerCase()}`
                      )}`}
                    </span>
                  </div>
                  <div className="px-2 flex-shrink-0 flex items-center">
                    <CurrencyImage
                      currency={price.amount.currency}
                      width={25}
                      height={25}
                    />
                  </div>
                </div>
              </Label>
            </div>
          );
        })}
      </div>
      <Label className="bg-blue-900 mt-2 text-sm">
        {tournament.topGames
          ? t("leader_board_info_top_games", {
              topGames: tournament.topGames,
            })
          : t("leader_board_info_default")}
        {tournament.qualifyGames > 0 && (
          <p className="mt-2">
            {t("leader_board_info_eligible", {
              qualifyGames: tournament.qualifyGames,
            })}
          </p>
        )}
      </Label>
    </>
  );
};

const Participation = ({
  tournament,
}: {
  tournament: NonNullable<TournamentQuery["tournament"]>;
}) => {
  const { t } = useTranslation();
  return (
    <Label className="bg-blue-900">
      {tournament.me?.__typename === "TournamentRecord" && (
        <div className="flex flex-col -my-1 text-sm">
          <div className="flex justify-between -mx-2 py-1">
            <div className="px-2">{t("win")}</div>
            <div className="px-2">{tournament.me!.win}</div>
          </div>
          <div className="flex justify-between -mx-2 py-1">
            <div className="px-2">{t("lose")}</div>
            <div className="px-2">{tournament.me!.lose}</div>
          </div>
          <div className="flex justify-between -mx-2 py-1">
            <div className="px-2">{t("draw")}</div>
            <div className="px-2">{tournament.me!.draw}</div>
          </div>
          <div className="flex justify-between -mx-2 py-1">
            <div className="px-2">{t("quit")}</div>
            <div className="px-2">{tournament.me!.quit}</div>
          </div>
          <div className="flex justify-between -mx-2 py-1">
            <div className="px-2">{t("games_played")}</div>
            <div className="px-2">{tournament.me!.gamesPlayed}</div>
          </div>
          {tournament.me!.__typename === "TournamentRecord" && (
            <div className="flex justify-between -mx-2 py-1">
              <div className="px-2">{t("games_remaining")}</div>
              <div className="px-2">
                {(tournament.me as any).gamesRemaining}
              </div>
            </div>
          )}
          <div className="flex justify-between -mx-2 py-1">
            <div className="px-2">{t("ranking")}</div>
            <div className="px-2">
              {tournament.me!.ranking + tournament.initialRanking}
            </div>
          </div>
          <div className="flex justify-between -mx-2 py-1">
            <div className="px-2">{t("score")}</div>
            <div className="px-2">
              {Math.max(0, tournament.me!.score + tournament.initialRanking)}
            </div>
          </div>
        </div>
      )}
    </Label>
  );
};

const TopNumbers = ({
  tournament,
}: {
  tournament: NonNullable<TournamentQuery["tournament"]>;
}) => {
  const { t } = useTranslation();
  const showMyPosition =
    tournament.me !== null &&
    tournament.records.length > 0 &&
    tournament.records.find((r) => r.id === tournament.me!.id) === undefined;
  if (tournament.records.length === 0)
    return (
      <div className="bg-red-700 p-2 text-white rounded mt-2 text-center">
        {t("no_leader_board_records_available")}
      </div>
    );
  return (
    <>
      {tournament.records.map((record, index) => (
        <div className="py-1" key={record.id}>
          <Label className="bg-blue-900">
            <Record
              record={record}
              position={index + 1}
              game={tournament.section.type}
              initialRanking={tournament.initialRanking}
            />
          </Label>
        </div>
      ))}
      {showMyPosition && (
        <div className="py-1" key={tournament.me!.id}>
          <Label className="bg-blue-900">
            <Record
              record={tournament.me!}
              position={tournament.me!.position}
              game={tournament.section.type}
              initialRanking={tournament.initialRanking}
            />
          </Label>
        </div>
      )}
    </>
  );
};

const TournamentTabs = ({
  tournament,
}: {
  tournament: NonNullable<TournamentQuery["tournament"]>;
}) => {
  const finalTabs = tournament.me !== null ? TABS_WITH_PARTICIPATION : TABS;
  const [tabIndex, setTabIndex] = React.useState(0);
  const { t } = useTranslation();
  return (
    <Tabs
      className="h-full overflow-hidden"
      index={tabIndex}
      onChange={setTabIndex}
    >
      <div className="flex flex-col h-full text-white">
        <div
          className={classNames(
            "flex",
            "items-center",
            "flex-shrink-0",
            styles.header
          )}
        >
          <TabList className="flex-1">
            {finalTabs.map((tab, index) => (
              <Tab
                key={index}
                className={classNames(
                  "px-4",
                  "py-3",
                  "border-r",
                  "border-black",
                  styles.headerButton,
                  {
                    "w-1/3": finalTabs.length === 3,
                    "w-1/2": finalTabs.length === 2,
                    [styles.active]: index === tabIndex,
                  }
                )}
              >
                {tab === "leaderboard"
                  ? t("top_value", { value: RECORDS_TO_FETCH })
                  : t(tab)}
              </Tab>
            ))}
          </TabList>
        </div>
        <TabPanels className="flex-1 overflow-hidden">
          <TabPanel
            className={classNames(
              "h-full",
              "p-4",
              "relative",
              "overflow-y-auto",
              styles.scrollable
            )}
          >
            <Information tournament={tournament} />
          </TabPanel>
          <TabPanel
            className={classNames(
              "h-full",
              "p-4",
              "relative",
              "overflow-y-auto",
              styles.scrollable
            )}
          >
            <TopNumbers tournament={tournament} />
          </TabPanel>
          {finalTabs.indexOf("participation") >= 0 && (
            <TabPanel className="h-full p-4 relative">
              <Participation tournament={tournament} />
            </TabPanel>
          )}
        </TabPanels>
      </div>
    </Tabs>
  );
};

const Tournament = React.memo<{
  leaderboard: NonNullable<TournamentQuery["tournament"]>;
  toggle: () => void;
}>(({ leaderboard, toggle }) => {
  const { t } = useTranslation();
  const relativeTimeFormatter = useRelativeTimeFormatter();
  return (
    <div className="flex flex-col h-full">
      <div className="p-4">
        <div className="flex justify-between text-white flex-shrink-0 -mx-2 overflow-hidden">
          <div className="flex items-center -mx-1 px-2 overflow-hidden">
            <div className="px-1">
              <img
                src={`${process.env.REACT_APP_API_HOST}/tournaments/${leaderboard.logo}`}
                className="rounded bg-white"
                alt="logo"
                width={52}
                height={52}
              />
            </div>
            <div className="flex-1 flex flex-col px-1 text-white overflow-hidden">
              <span className="font-bold text-lg truncate">
                {leaderboard.name}
              </span>
              <span>
                {t(
                  leaderboard.active
                    ? "leader_board_expiry"
                    : "leader_board_completed",
                  {
                    expiry: relativeTimeFormatter.format(
                      new Date(leaderboard.end),
                      {
                        addSuffix: true,
                      }
                    ),
                  }
                )}
              </span>
            </div>
          </div>
          <div className="px-2 flex-shrink-0">
            <button onClick={toggle}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      </div>
      <TournamentTabs tournament={leaderboard} />
    </div>
  );
}, isEqual);

function TournamentsSidebar({
  id,
  toggle,
}: {
  id: string;
  toggle: () => void;
}) {
  const [{ fetching, data }] = useQuery<
    TournamentQuery,
    TournamentQueryVariables
  >({
    query: TOURNAMENT_QUERY,
    variables: { id, records: RECORDS_TO_FETCH },
    requestPolicy: "cache-and-network",
  });
  const { t } = useTranslation();
  return (
    <>
      <div
        className="absolute bg-black-alpha-30 top-0 left-0 right-0 bottom-0 z-20"
        onClick={toggle}
      />
      <div
        className="w-1/3 absolute shadow-2xl bg-blue-800 top-0 bottom-0 right-0 z-30"
        aria-label={t("tournament_info")}
      >
        {fetching ? (
          <AbsoluteCenter>
            <Loader description={t("loading_tournament")} />
          </AbsoluteCenter>
        ) : (data?.tournament ?? null) === null ? (
          <AbsoluteCenter>
            <div className="p-4 text-center">
              <span className="text-white font-bold">
                {t("no_tournament_found")}
              </span>
            </div>
          </AbsoluteCenter>
        ) : (
          <Tournament leaderboard={data!.tournament!} toggle={toggle} />
        )}
      </div>
    </>
  );
}

export function TournamentsSidebarProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [tournamentId, setTournamentId] = React.useState<string>();
  const toggle = React.useCallback(
    (tournamentId: string) => setTournamentId(tournamentId),
    []
  );
  const onDismiss = React.useCallback(() => setTournamentId(undefined), []);

  return (
    <TournamentsSidebarContext.Provider value={toggle}>
      {children}
      {tournamentId !== undefined && (
        <TournamentsSidebar id={tournamentId} toggle={onDismiss} />
      )}
    </TournamentsSidebarContext.Provider>
  );
}

export function useTournamentsSidebar() {
  return React.useContext(TournamentsSidebarContext);
}
