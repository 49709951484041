import * as React from "react";
import { BalanceUpdateSystemGiftDetailsFragmentFragment } from "../../../hooks/useTransactions/graphql/TransactionsFragment.generated";
import { Trans } from "react-i18next";

export const BalanceUpdateSystemGiftDetails = ({
  details,
}: {
  details: BalanceUpdateSystemGiftDetailsFragmentFragment;
}) => {
  return (
    <Trans i18nKey="transaction.BalanceUpdateSystemGiftDetails_details">
      Received gift <strong>{{ name: details.name }}</strong> from platform
    </Trans>
  );
};
