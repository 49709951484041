import * as React from "react";
import { BalanceUpdateScratchCardWinDetailsFragmentFragment } from "../../../hooks/useTransactions/graphql/TransactionsFragment.generated";
import { Trans } from "react-i18next";

export const BalanceUpdateScratchCardWinDetails = ({
  details,
}: {
  details: BalanceUpdateScratchCardWinDetailsFragmentFragment;
}) => {
  const name = `${details.name} - ${details.displayId}`;
  return (
    <Trans i18nKey="transaction.BalanceUpdateScratchCardWinDetails_details">
      Scratch card <strong>{{ name }}</strong> has won
    </Trans>
  );
};
