import * as React from "react";
import { Layout } from "./Layout";
import { Header } from "./Header";
import { ExitButton } from "./Header";
import { useTranslation } from "react-i18next";

const EXIT_BUTTON_TIMEOUT = 60000;

const Context = React.createContext<() => void>(() => {
  throw Error("Provider not defined");
});

const HeaderRight = ({ sectionId }: { sectionId: string }) => {
  const { t } = useTranslation();
  const [showExitButton, setShowExitButton] = React.useState(false);
  //After 60 seconds. If game loader has not finished then most probably something is wrong.In those
  //cases its better to show exit button in order to let the user exit this abnormal state
  React.useEffect(() => {
    const l = setTimeout(() => setShowExitButton(true), EXIT_BUTTON_TIMEOUT);
    return () => clearTimeout(l);
  }, []);
  return (
    <div className="h-full flex justify-end items-center">
      <div className="flex -mx-1">
        {showExitButton && (
          <div className="px-1">
            <ExitButton
              onClick={`/area/${sectionId}`}
              label={t("exit_table")}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const GameLoader = ({
  children,
  sectionId,
}: {
  children: React.ReactNode;
  sectionId: string;
}) => {
  const [loading, setLoading] = React.useState(true);
  const ready = React.useCallback(() => setLoading(false), []);
  return (
    <Context.Provider value={ready}>
      <Layout
        header={
          loading ? (
            <Header
              right={<HeaderRight sectionId={sectionId} />}
              sectionIds={[sectionId]}
            />
          ) : undefined
        }
      >
        {children}
      </Layout>
    </Context.Provider>
  );
};

const Ready = ({ children }: { children: React.ReactNode }) => {
  const setReady = React.useContext(Context);
  React.useLayoutEffect(setReady, [setReady]);
  return <>{children}</>;
};

GameLoader.Ready = Ready;
