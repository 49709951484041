import * as React from "react";
import { Route, Router, Switch, useLocation } from "react-router-dom";
import { createBrowserHistory, createMemoryHistory } from "history";
import { Main } from "./Main";
import { I18nextProvider, useTranslation } from "react-i18next";
import { i18n } from "../i18n";
import { FacebookProvider } from "./Facebook";
import { AbsoluteCenter } from "./AbsoluteCenter";
import { Loader } from "./Loader";
import { FullScreenProvider } from "./FullScreenProvider";
import { AudioProvider } from "./AudioProvider";
import { MultiProvider } from "./MultiProvider";
import { FacebookCanvasLogin } from "./Login";
import { ErrorBoundary } from "./ErrorBoundary";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { EcommpayProvider } from "./Ecommpay";

const history =
  process.env.NODE_ENV === "production"
    ? createMemoryHistory({
        initialEntries: [window.location.pathname],
      })
    : createBrowserHistory();

const HtmlLocale = React.memo(() => {
  const {
    i18n: { language },
  } = useTranslation();
  React.useEffect(
    () => document.documentElement.setAttribute("lang", language),
    [language]
  );
  return null;
});

const ProvidersList = [
  <FullScreenProvider />,
  <AudioProvider />,
  <FacebookProvider />,
  <EcommpayProvider />,
];

const GaLocationTracker = () => {
  const location = useLocation();

  React.useEffect(() => {
    const page = location.pathname;
    ReactGA.set({ page });
    ReactGA.pageview(page);
    ReactPixel.pageView();
  }, [location]);
  return null;
};

export const App = () => {
  return (
    <ErrorBoundary>
      <I18nextProvider i18n={i18n}>
        <React.Suspense
          fallback={
            <AbsoluteCenter>
              <Loader description="Loading language" />
            </AbsoluteCenter>
          }
        >
          <HtmlLocale />
          <MultiProvider providers={ProvidersList}>
            <Router history={history}>
              <GaLocationTracker />
              <Switch>
                <Route path="/fbcanvas" exact>
                  <FacebookCanvasLogin />
                </Route>
                <Route path="*">
                  <Main />
                </Route>
              </Switch>
            </Router>
          </MultiProvider>
        </React.Suspense>
      </I18nextProvider>
    </ErrorBoundary>
  );
};
