import * as React from "react";
import { ButtonsSelect } from "./ButtonsSelect";
import { useTranslation } from "react-i18next";

export const TableTypeSelect = <T extends { [key: string]: string }>({
  disabled = false,
  onChange,
  value,
  values,
}: {
  disabled?: boolean;
  value: string;
  values: T;
  onChange: (value: keyof T) => void;
}) => {
  const { t } = useTranslation();
  return (
    <ButtonsSelect
      disabled={disabled}
      value={value}
      options={Object.values(values).map((type) => ({
        id: type,
        name: t(type.toLowerCase()),
      }))}
      onChange={(value) => onChange(value.id)}
    />
  );
};
