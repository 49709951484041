import * as React from "react";
import { useGuestId } from "../../../hooks/useGuestId";
import { useLogin } from "../useLogin";

const NAME = "guest";

export const GuestLogin = ({
  children,
}: {
  children: (disabled: boolean, login: () => void) => void;
}) => {
  const login = useLogin(NAME, "website");
  const [loading, setLoading] = React.useState(false);
  const [getGuestId] = useGuestId();
  const guestId = getGuestId();
  const loginFacade = React.useCallback(async () => {
    setLoading(true);
    try {
      return await login(guestId == null ? "" : guestId, {
        origin: window.location.origin,
      });
    } catch (e) {
      setLoading(false);
    }
  }, [login, guestId]);

  return <>{children(loading, loginFacade)}</>;
};
