import { useEffect } from "react";
import { Event } from "stream-chat";
import { useChatContext } from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { ChannelTypeSelect } from "./ChannelList";
import { useCustomChatContext, useUserId } from "../UserProvider";

export const changeChannelFilters = (
  channelType: ChannelTypeSelect,
  userId: string
) => {
  switch (channelType) {
    case "group":
      return { type: { $in: ["room", "group"] } };
    case "direct_friends":
      return { type: channelType, members: { $in: [userId] } };
    case "direct_others":
      return { type: channelType, members: { $in: [userId] } };
  }
};

export const useDirectMessageNewListener = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  channelType: ChannelTypeSelect
) => {
  const { client } = useChatContext<StreamChatGenerics>(
    "useMessageNewListener"
  );
  const { setUnreadFriends, setUnreadOthers } = useCustomChatContext();

  const userId = useUserId();

  useEffect(() => {
    const handleEvent = (event: Event<StreamChatGenerics>) => {
      const myMessage = event.message?.id.includes(userId);
      if (
        event.channel_type === "direct_others" &&
        channelType !== "direct_others" &&
        !myMessage
      ) {
        setUnreadOthers(true);
      }
      if (
        event.channel_type === "direct_friends" &&
        channelType !== "direct_friends" &&
        !myMessage
      ) {
        setUnreadFriends(true);
      }
    };

    client.on("message.new", handleEvent);

    return () => {
      client.off("message.new", handleEvent);
    };
  }, [channelType]);
};
