import * as React from "react";
import { ErrorMessage, Field, Formik } from "formik";
import classNames from "classnames";
import {
  Currency,
  MpirimpaPlayers,
  MpirimpaTableInput,
  MpirimpaTableType,
} from "../../../../graphql/generated";
import {
  MpirimpaDefaultConfigQueryQuery,
  MpirimpaDefaultConfigQueryQueryVariables,
} from "./graphql/MpirimpaDefaultConfigQuery.generated";
import { loader } from "graphql.macro";
import { useQuery } from "../../../../hooks/useQuery";
import {
  MpirimpaCreateOpenTableMutation,
  MpirimpaCreateOpenTableMutationVariables,
} from "./graphql/MpirimpaCreateOpenTableMutation.generated";
import { useTranslation } from "react-i18next";
import { Loader } from "../../../Loader";
import { useMutation } from "../../../../hooks/useMutation";
import { useCustomChatContext, useUser } from "../../../UserProvider";
import styles from "./CreateTable.module.scss";
import { Slider } from "../../../Slider";
import { useNumberFormatter } from "../../../../hooks/useNumberFormatter";
import { FieldContainer } from "../../common/createtable/FieldContainer";
import { SliderLabel } from "../../common/createtable/SliderLabel";
import { ButtonsSelect } from "../../common/createtable/ButtonsSelect";
import { TableTypeSelect } from "../../common/createtable/TableTypeSelect";
import { AbsoluteCenter } from "../../../AbsoluteCenter";
import { CombinedError } from "@urql/core/dist/types/utils/error";
import { useIsMounted } from "../../../../hooks/useIsMounted";
import { ButtonWithSound, useAudio } from "../../../AudioProvider";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import {
  MpirimpaCreateTournamentTableMutation,
  MpirimpaCreateTournamentTableMutationVariables,
} from "./graphql/MpirimpaCreateTournamentTableMutation.generated";
import { betByPoints } from "../utils";
import { useRegisterPopup } from "../../../Register";
import { useGuestId } from "../../../../hooks/useGuestId";

const MPIRIMPA_DEFAULT_CONFIG_QUERY = loader(
  "./graphql/MpirimpaDefaultConfigQuery.graphql"
);

const MPIRIMPA_CREATE_OPEN_TABLE_MUTATION = loader(
  "./graphql/MpirimpaCreateOpenTableMutation.graphql"
);

const MPIRIMPA_CREATE_TOURNAMENT_TABLE_MUTATION = loader(
  "./graphql/MpirimpaCreateTournamentTableMutation.graphql"
);

const MpirimpaTableConfigForm = ({
  sectionId,
  config,
  onSubmit,
}: {
  sectionId: string;
  config: NonNullable<MpirimpaDefaultConfigQueryQuery["mpirimpaDefaultConfig"]>;
  onSubmit: (input: MpirimpaTableInput) => Promise<CombinedError | null>;
}) => {
  const [error, setError] = React.useState<string>();
  const isMounted = useIsMounted();
  const { user } = useUser();
  const { t } = useTranslation();
  const showGroupCardSetsOption = config.groupCardSets !== null;
  const numberFormatter = useNumberFormatter();
  const { play } = useAudio();
  const [pointsValue, setPointsValue] = React.useState(config.points.default);
  const registration = useRegisterPopup();
  const [, , , getIsLoggedAsGuest] = useGuestId();
  const { setOpenedChat } = useCustomChatContext();

  const validationSchema = React.useMemo(
    () =>
      Yup.object().shape({
        bet: Yup.number()
          .min(config.bet.minimum)
          .max(config.bet.maximum)
          // eslint-disable-next-line no-template-curly-in-string
          .test("is-within-range", "${path} is not within range", (value) => {
            return (
              value !== null &&
              value !== undefined &&
              value % config.bet.step === 0
            );
          })
          .required(),
      }),
    [config]
  );

  const minimalValue = betByPoints(config.betByPoints, pointsValue);

  const sortedCommissions = React.useMemo(
    () => config.commissions.sort((a, b) => a.maxBet - b.maxBet),
    [config.commissions]
  );

  const calculatePrize = React.useCallback(
    (betValue: number, numberOfPlayers: number) => {
      for (let i = 0; i < sortedCommissions.length; i++) {
        if (betValue <= sortedCommissions[i].maxBet) {
          return (
            numberOfPlayers *
            betValue *
            (1 - sortedCommissions[i].commission / 100)
          );
        }
      }
      return 0;
    },
    [sortedCommissions]
  );

  const [prize, setPrize] = React.useState<number>();

  React.useEffect(() => {
    setPrize(calculatePrize(config.bet.default, 2));
  }, [config.bet.default]);

  return (
    <Formik
      initialValues={{
        name: user.name,
        turnTime: config.turnTime.default,
        bet: config.bet.default,
        points: config.points.default,
        players: MpirimpaPlayers.OneVsOne,
        type: MpirimpaTableType.Public,
        groupCardSets: config.groupCardSets ?? false,
      }}
      validationSchema={validationSchema}
      validate={(values) => {
        const errors: { [key: string]: string | undefined } = {};
        const availableBalance =
          user.balances.find(
            (balance) => balance.currency === Currency.BlueChips
          )?.value ?? 0;
        let multiplier = 1;
        if (values.players === MpirimpaPlayers.TwoVsTwo) multiplier = 2;
        if (values.bet * multiplier > availableBalance)
          errors.bet = t("insufficient_funds");
        return errors;
      }}
      onSubmit={(values) => {
        setOpenedChat(false);
        return onSubmit({
          sectionId,
          ...values,
        }).then((error) => {
          if (error != null && isMounted.current) {
            if (error.graphQLErrors.length > 0)
              setError(error.graphQLErrors[0].message);
            else if (error.networkError) setError(error.networkError.message);
          }
        });
      }}
    >
      {({
        values,
        isValid,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
      }) => {
        return (
          <form onSubmit={handleSubmit} className="flex flex-col h-full">
            {error && (
              <div className="p-4 text-center rounded bg-red-700 mb-4 flex-shrink-0">
                <span className="text-white font-bold block">{error}</span>
              </div>
            )}
            <div
              className={classNames(
                "flex-1",
                "overflow-auto",
                styles.scrollable
              )}
            >
              <FieldContainer name={t("name")}>
                <Field
                  name="name"
                  className="form-input w-full py-1 block rounded"
                />
              </FieldContainer>
              <FieldContainer name={t("turn_time")}>
                <Slider
                  disabled={isSubmitting}
                  value={values.turnTime}
                  min={config.turnTime.minimum}
                  max={config.turnTime.maximum}
                  step={config.turnTime.step}
                  onChange={(value) => {
                    setFieldTouched("turnTime", true);
                    setFieldValue("turnTime", value);
                  }}
                />
                <SliderLabel
                  value={t("seconds_value", {
                    value: numberFormatter.format(values.turnTime),
                  })}
                />
              </FieldContainer>
              <FieldContainer name={t("bet")}>
                <Slider
                  disabled={isSubmitting}
                  value={
                    typeof values.bet !== "number" ? minimalValue : values.bet
                  }
                  min={minimalValue}
                  max={config.bet.maximum}
                  step={config.bet.step}
                  onChange={(value) => {
                    setFieldTouched("bet", true);
                    setFieldValue("bet", value);
                    setPrize(
                      calculatePrize(
                        value,
                        values.players === MpirimpaPlayers.OneVsOne ? 2 : 4
                      )
                    );
                  }}
                />
                {values.bet < minimalValue &&
                  setFieldValue("bet", minimalValue)}
                <Field
                  disabled={isSubmitting}
                  name="bet"
                  type="number"
                  className="form-input rounded py-0  text-sm rounded shadow-xl text-center w-2/5"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = Number(e.target.value);
                    if (
                      newValue >= config.bet.minimum &&
                      newValue <= config.bet.maximum
                    ) {
                      setFieldTouched("bet", true);
                      setFieldValue("bet", newValue);
                      setPrize(
                        calculatePrize(
                          newValue,
                          values.players === MpirimpaPlayers.OneVsOne ? 2 : 4
                        )
                      );
                    }
                  }}
                />
                <ErrorMessage
                  name="bet"
                  className="mt-2 font-bold text-red-600"
                  component="div"
                />
              </FieldContainer>
              <FieldContainer name={t("prize")}>
                <div className="form-input rounded py-0  text-sm rounded shadow-xl text-center w-2/5">
                  {prize}
                </div>
              </FieldContainer>
              <FieldContainer name={t("mpirimpa.points")}>
                <Slider
                  disabled={isSubmitting}
                  value={values.points}
                  min={config.points.minimum}
                  max={config.points.maximum}
                  step={config.points.step}
                  onChange={(value) => {
                    setFieldTouched("points", true);
                    setFieldValue("points", value);
                    setPointsValue(value);
                  }}
                />
                <SliderLabel value={numberFormatter.format(values.points)} />
              </FieldContainer>
              <div className="overflow-hidden">
                <div className="flex -mx-2">
                  <div className="w-1/2 px-2">
                    <FieldContainer name={t("players")}>
                      <ButtonsSelect
                        disabled={isSubmitting}
                        value={values.players}
                        options={Object.values(MpirimpaPlayers).map(
                          (value) => ({
                            id: value,
                            name: t(`mpirimpa.${value.toLowerCase()}`),
                          })
                        )}
                        onChange={(value) => {
                          setFieldTouched("players", true);
                          setFieldValue("players", value.id);
                          setPrize(
                            calculatePrize(
                              values.bet,
                              value.id === "ONE_VS_ONE" ? 2 : 4
                            )
                          );
                        }}
                      />
                    </FieldContainer>
                  </div>
                  <div className="w-1/2 px-2">
                    <FieldContainer name={t("table_type")}>
                      <TableTypeSelect
                        disabled={isSubmitting}
                        values={MpirimpaTableType}
                        value={values.type}
                        onChange={(value) => {
                          if (
                            getIsLoggedAsGuest() &&
                            value.toUpperCase() === "PRIVATE"
                          )
                            registration.openRegisterPopup();
                          else {
                            setFieldTouched("type", true);
                            setFieldValue("type", value);
                          }
                        }}
                      />
                    </FieldContainer>
                  </div>
                </div>
              </div>
              {showGroupCardSetsOption && (
                <FieldContainer className="mt-4">
                  <label className="inline-flex items-center justify-between w-full">
                    <span className="text-white text-lg font-bold">
                      {t("mpirimpa.group_card_sets")}
                    </span>
                    <Field
                      type="checkbox"
                      name="groupCardSets"
                      className="form-checkbox h-6 w-6 text-green-600"
                      onClick={() => play("button")}
                    />
                  </label>
                  <div className="text-white text-left mt-2">
                    {t("mpirimpa.group_card_sets_explanation")}
                  </div>
                </FieldContainer>
              )}
            </div>
            <ButtonWithSound
              type="submit"
              disabled={isSubmitting || !isValid}
              className="btn w-full btn-blue-600 rounded mt-4 flex-shrink-0"
            >
              {!isSubmitting ? t("create_table") : t("loading")}
            </ButtonWithSound>
          </form>
        );
      }}
    </Formik>
  );
};

function CreateOpenTable({
  sectionId,
  onTableCreated,
  onDismiss,
}: {
  sectionId: string;
  onTableCreated: (id: string) => void;
  onDismiss: () => void;
}) {
  const [{ data }] = useQuery<
    MpirimpaDefaultConfigQueryQuery,
    MpirimpaDefaultConfigQueryQueryVariables
  >({
    query: MPIRIMPA_DEFAULT_CONFIG_QUERY,
    variables: {
      sectionId,
    },
  });
  const [, createTable] = useMutation<
    MpirimpaCreateOpenTableMutation,
    MpirimpaCreateOpenTableMutationVariables
  >(MPIRIMPA_CREATE_OPEN_TABLE_MUTATION, false);

  const { t } = useTranslation();

  const config = data?.mpirimpaDefaultConfig;
  if (config === null)
    throw new Error("Game mpirimpa not supported on this room");

  return (
    <>
      <div
        className="absolute bg-black-alpha-30 top-0 left-0 right-0 bottom-0 z-20"
        onClick={onDismiss}
      />
      <div
        className="w-1/3 p-4 absolute shadow-2xl bg-blue-800 top-0 bottom-0 right-0 z-30"
        aria-label={t("create_table")}
      >
        <div className="flex flex-col h-full">
          <div className="flex items-center justify-between text-white flex-shrink-0 mb-4 -mx-2">
            <span className="font-bold text-lg truncate px-2">
              {t("create_table")}
            </span>
            <div className="px-2 flex-shrink-0">
              <button onClick={onDismiss}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
          <div className="flex-1 overflow-hidden relative">
            {config === undefined ? (
              <AbsoluteCenter>
                <Loader description={t("loading_game_config")} />
              </AbsoluteCenter>
            ) : (
              <MpirimpaTableConfigForm
                sectionId={sectionId}
                config={config}
                onSubmit={(input) =>
                  createTable({ input }).then((result) => {
                    if (result.error) {
                      return result.error;
                    } else {
                      onTableCreated(
                        result!.data!.mpirimpaCreateOpenTable!.id!
                      );
                      return null;
                    }
                  })
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function CreateTournamentTable({
  onTableCreated,
  sectionId,
  onDismiss,
}: {
  sectionId: string;
  onTableCreated: (id: string) => void;
  onDismiss: () => void;
}) {
  const [, createTable] = useMutation<
    MpirimpaCreateTournamentTableMutation,
    MpirimpaCreateTournamentTableMutationVariables
  >(MPIRIMPA_CREATE_TOURNAMENT_TABLE_MUTATION, false);

  const isMounted = useIsMounted();
  React.useEffect(() => {
    createTable({ sectionId: sectionId }).then((result) => {
      if (result.error) {
        if (isMounted.current) onDismiss();
      } else onTableCreated(result!.data!.mpirimpaCreateTournamentTable!.id!);
    });
  }, [sectionId, createTable, onTableCreated, isMounted, onDismiss]);
  return (
    <>
      <div className="absolute bg-black-alpha-30 top-0 left-0 right-0 bottom-0 z-20" />
      <AbsoluteCenter>
        <Loader description="Creating table" />
      </AbsoluteCenter>
    </>
  );
}

export const CreateTable = ({
  isTournament,
  ...rest
}: {
  sectionId: string;
  isTournament: boolean;
  onTableCreated: (id: string) => void;
  onDismiss: () => void;
}) => {
  if (!isTournament) return <CreateOpenTable {...rest} />;
  else return <CreateTournamentTable {...rest} />;
};
