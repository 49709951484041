import React from "react";

const Context = React.createContext<((message: string) => void) | undefined>(
  undefined as any
);

export function ChannelOnSendMessageProvider({
  onChat,
  children,
}: React.PropsWithChildren<{ onChat?: (message: string) => void }>) {
  return <Context.Provider value={onChat}>{children}</Context.Provider>;
}

export function useChannelOnSendMessage() {
  return React.useContext(Context);
}
