import * as React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useQuery } from "../../hooks/useQuery";
import {
  ReferConfigQuery,
  ReferConfigQueryVariables,
} from "./graphql/ReferConfigQuery.generated";
import { loader } from "graphql.macro";
import { AbsoluteCenter } from "../AbsoluteCenter";
import { Loader } from "../Loader";
import { Amount } from "../../graphql/generated";
import classNames from "classnames";
import { CurrencyImage } from "../CurrencyImage";
import { FormattedNumber } from "../FormattedNumber";
import styles from "./Invitations.module.scss";
import { useFacebook, useFacebookInvite } from "../Facebook";
import { REFERRAL_QUERY_PARAM_NAME } from "../../config";
import { ShareButton } from "./ShareButton";
import { ButtonWithSound } from "../AudioProvider";
import { useScreenType } from "../../hooks/useScreenType";
import { RIGHT_BAR_MOBILE_SIZE, RIGHT_BAR_TABLET_SIZE } from "../Bar/RightBar/Bar";

const REFER_CONFIG_QUERY = loader("./graphql/ReferConfigQuery.graphql");

const InvitationsContext = React.createContext<(open: boolean) => void>(() => {
  throw new Error("Provider not found");
});

const ORIGIN =
  window.location.origin === undefined
    ? window.location.protocol +
      "//" +
      window.location.hostname +
      (window.location.port ? ":" + window.location.port : "")
    : window.location.origin;

const Label = ({
  children,
  className,
  ...rest
}: { children: React.ReactNode } & JSX.IntrinsicElements["div"]) => {
  return (
    <div
      className={classNames("text-white", "w-full", "rounded-lg", className)}
      {...rest}
    >
      {children}
    </div>
  );
};

const PriceLabel = React.memo<{ amount: Amount }>(({ amount }) => {
  const { t } = useTranslation();
  return (
    <Label className={classNames(styles.amount, "mb-2")}>
      <div className="overflow-hidden p-2">
        <div className="flex items-center -mx-1">
          <div className="px-1">
            <CurrencyImage currency={amount.currency} width={40} height={40} />
          </div>
          <div className="flex flex-col justify-between px-1">
            <span className="text-2xl leading-none font-bold">
              <FormattedNumber value={amount.value} />
            </span>
            <span className="text-xs">
              {t(`currency.${amount.currency.toLowerCase()}`)}
            </span>
          </div>
        </div>
      </div>
    </Label>
  );
});

const Amounts = ({ amounts }: { amounts: Array<Amount> }) => {
  return (
    <>
      {amounts.map((amount, index) => (
        <PriceLabel key={index} amount={amount} />
      ))}
    </>
  );
};

const FacebookInviteButton = ({ redirectUrl }: { redirectUrl: string }) => {
  const { t } = useTranslation();
  const [disabled, onClick] = useFacebookInvite(
    t("social_invitation.title"),
    redirectUrl
  );
  return (
    <ButtonWithSound
      disabled={disabled}
      className="btn btn-orange-600 w-full rounded"
      onClick={onClick}
    >
      {t("refer")}
    </ButtonWithSound>
  );
};

const InvitationsButton = ({ redirectUrl }: { redirectUrl: string }) => {
  const [, isFacebookCanvas] = useFacebook();
  return isFacebookCanvas ? (
    <FacebookInviteButton redirectUrl={redirectUrl} />
  ) : (
    <ShareButton redirectUrl={redirectUrl} />
  );
};

const InvitationsContent = React.memo(() => {
  const [{ data }] = useQuery<ReferConfigQuery, ReferConfigQueryVariables>({
    query: REFER_CONFIG_QUERY,
    variables: {
      input: {
        callbackUrl: ORIGIN,
        queryParameterName: REFERRAL_QUERY_PARAM_NAME,
      },
    },
  });
  const { t } = useTranslation();
  if (data === undefined)
    return (
      <div className="flex-1 relative">
        <AbsoluteCenter>
          <Loader description={t("loading_invitations_config")} />
        </AbsoluteCenter>
      </div>
    );
  else if (data.referConfig === null)
    return (
      <div className="flex-1 relative">
        <AbsoluteCenter>
          <div className="p-4 text-center rounded bg-red-700">
            <span className="text-white font-bold block">
              {t("no_invitations_configured")}
            </span>
          </div>
        </AbsoluteCenter>
      </div>
    );
  else {
    return (
      <>
        <div className="flex-1">
          <div className="mt-6">
            <div className="text-white font-bold text-shadow mb-2">
              {t("for_each_friend_you_refer")}
            </div>
            <Amounts amounts={data.referConfig.refer} />
          </div>

          <div className="mt-6">
            <div className="text-white font-bold text-shadow mb-2">
              {t("your_friend_will_also_receive_extra")}
            </div>
            <Amounts amounts={data.referConfig.referralBy} />
          </div>
          <div className="text-white text-sm mt-2">{`* ${t(
            "referral_info"
          )}`}</div>
        </div>
        <div className="flex-shrink-0">
          <InvitationsButton redirectUrl={data.referConfig.redirectUrl} />
        </div>
      </>
    );
  }
});

const Popup = ({ close }: { close: () => void }) => {
  const { t } = useTranslation();
  const screenType = useScreenType();
  const stylesMap = {
    DESKTOP: { bottom: 0, left: 0 },
    TABLET: { top: 0, right: 0, ...RIGHT_BAR_TABLET_SIZE },
    MOBILE: { top: 0, right: 0, ...RIGHT_BAR_MOBILE_SIZE },
  };

  const adjustedStyle = React.useMemo(() => stylesMap[screenType] || {}, [
    screenType,
  ]);

  return (
    <>
      <div
        className="absolute bg-black-alpha-30 top-0 left-0 right-0 bottom-0 z-20"
        onClick={close}
      />
      <div
        className="w-1/3 h-9/10 absolute shadow-2xl bg-blue-800 z-30"
        style={{ ...adjustedStyle }}
        aria-label={t("invitations")}
      >
        <div className="flex flex-col h-full p-4">
          <div className="flex items-center justify-between text-white flex-shrink-0 -mx-2">
            <span className="font-bold text-lg truncate px-2">
              {t("invitations_title")}
            </span>
            <div className="px-2 flex-shrink-0">
              <button onClick={close}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
          <InvitationsContent />
        </div>
      </div>
    </>
  );
};

export const InvitationsPanelProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const close = React.useCallback(() => setOpen(false), []);

  return (
    <InvitationsContext.Provider value={setOpen}>
      {children}
      {isOpen && <Popup close={close} />}
    </InvitationsContext.Provider>
  );
};

export const useInvitationsPanel = () => {
  return React.useContext(InvitationsContext);
};
