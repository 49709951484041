import React from "react";
import styles from "./VipInformation.module.scss";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const Table = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.tableTabWrapper}>
      <p className={styles.userRightsTitle}>{t("vip_popup.rights.title")}</p>
      <div className={styles.informationTitleLine} />

      <div className={classNames("overflow-x-auto", styles.tableScrollHidden)}>
        <table className="w-11/12 text-center">
          <thead>
            <tr className="pb-2 mb-2 border-b-2">
              <th className="text-left">
                {t("vip_popup.rights.table.header.rights")}
              </th>
              <th>
                <div className="px-5">
                  {t("vip_popup.rights.table.header.guest")}
                </div>
              </th>
              <th>{t("vip_popup.rights.table.header.standard")}</th>
              <th>
                <div className="px-5">
                  {t("vip_popup.rights.table.header.vip")}
                </div>
              </th>
            </tr>
          </thead>
          <tbody className={styles.fontStyle}>
            <tr>
              <td className="text-left">
                {t("vip_popup.rights.table.first_column.free_chips")}
              </td>
              <td>{t("vip_popup.rights.table.values.yes")}</td>
              <td>{t("vip_popup.rights.table.values.yes")}</td>
              <td>{t("vip_popup.rights.table.values.yes")}</td>
            </tr>
            <tr>
              <td className="text-left">
                {t("vip_popup.rights.table.first_column.interstilliar_ads")}
              </td>
              <td>{t("vip_popup.rights.table.values.yes")}</td>
              <td>{t("vip_popup.rights.table.values.yes")}</td>
              <td>{t("vip_popup.rights.table.values.no")}</td>
            </tr>
            <tr>
              <td className="text-left">
                {t("vip_popup.rights.table.first_column.purchase_of_chips")}
              </td>
              <td>{t("vip_popup.rights.table.values.no")}</td>
              <td>{t("vip_popup.rights.table.values.yes")}</td>
              <td>{t("vip_popup.rights.table.values.yes")}</td>
            </tr>
            <tr>
              <td className="text-left">
                {t("vip_popup.rights.table.first_column.change_of_name")}
              </td>
              <td>{t("vip_popup.rights.table.values.no")}</td>
              <td>{t("vip_popup.rights.table.values.no")}</td>
              <td>{t("vip_popup.rights.table.values.yes")}</td>
            </tr>
            <tr>
              <td className="text-left">
                {t("vip_popup.rights.table.first_column.upload_avatar")}
              </td>
              <td>{t("vip_popup.rights.table.values.no")}</td>
              <td>{t("vip_popup.rights.table.values.no")}</td>
              <td>{t("vip_popup.rights.table.values.yes")}</td>
            </tr>
            <tr>
              <td className="text-left">
                {t("vip_popup.rights.table.first_column.group_chat")}
              </td>
              <td>{t("vip_popup.rights.table.values.no")}</td>
              <td>{t("vip_popup.rights.table.values.yes")}</td>
              <td>{t("vip_popup.rights.table.values.yes")}</td>
            </tr>
            <tr>
              <td className="text-left">
                {t("vip_popup.rights.table.first_column.live_chat")}
              </td>
              <td>{t("vip_popup.rights.table.values.no")}</td>
              <td>{t("vip_popup.rights.table.values.with_friends")}</td>
              <td>{t("vip_popup.rights.table.values.with_everybody")}</td>
            </tr>
            <tr>
              <td className="text-left">
                {t("vip_popup.rights.table.first_column.sending_picture")}
              </td>
              <td>{t("vip_popup.rights.table.values.no")}</td>
              <td>{t("vip_popup.rights.table.values.no")}</td>
              <td>{t("vip_popup.rights.table.values.yes")}</td>
            </tr>
            <tr>
              <td className="text-left">
                {t("vip_popup.rights.table.first_column.more_chips")}
              </td>
              <td>{t("vip_popup.rights.table.values.no")}</td>
              <td>{t("vip_popup.rights.table.values.no")}</td>
              <td>{t("vip_popup.rights.table.values.yes")}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
