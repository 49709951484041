import * as React from "react";
import { BalanceUpdateTournamentAwardDetailsFragmentFragment } from "../../../hooks/useTransactions/graphql/TransactionsFragment.generated";
import { Trans } from "react-i18next";
import { useOrdinalFormatter } from "../../../hooks/useOrdinalFormatter";

export const BalanceUpdateTournamentAwardDetails = ({
  details,
}: {
  details: BalanceUpdateTournamentAwardDetailsFragmentFragment;
}) => {
  const ordinalFormatter = useOrdinalFormatter();
  return (
    <Trans i18nKey="transaction.BalanceUpdateTournamentAwardDetails_details">
      Tournament awards in which you finished{" "}
      <strong>{{ position: ordinalFormatter.format(details.position) }}</strong>
    </Trans>
  );
};
