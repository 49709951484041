import { loader } from "graphql.macro";
import { useQuery } from "./useQuery";
import {
  PendingFriendRequestsQueryQuery,
  PendingFriendRequestsQueryQueryVariables,
} from "../graphql/PendingFriendRequestsQuery.generated";

const PENDING_FRIEND_REQUESTS_QUERY = loader(
  "../graphql/PendingFriendRequestsQuery.graphql"
);

export const usePendingFriendRequests = ():
  | NonNullable<PendingFriendRequestsQueryQuery["me"]>["pendingFriendRequests"]
  | undefined => {
  const [{ data }] = useQuery<
    PendingFriendRequestsQueryQuery,
    PendingFriendRequestsQueryQueryVariables
  >({
    query: PENDING_FRIEND_REQUESTS_QUERY,
  });

  return data === undefined
    ? undefined
    : data?.me?.pendingFriendRequests ?? undefined;
};
