import * as React from "react";
import styles from "./Login.module.scss";
import classNames from "classnames";
import spectrumLogo from "./assets/spectrumLogo.svg";
import {
  COF_URL,
  CONTACT_US_URL,
  FACEBOOK_URL,
  FORUM_URL,
  HELP_URL,
  INSTAGRAM_URL,
  PRIVACY_POLICY_URL,
  SECURITY_URL,
  T_AND_C_URL,
  TWITTER_URL,
  YOUTUBE_URL,
} from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";
import { ExitButton } from "../../Header";
import { AbsoluteCenter } from "../../AbsoluteCenter";
import { ButtonWithSound } from "../../AudioProvider";
import cards from "./assets/Cards.svg";
import SignUp, { FinishSignUpPath } from "../SignUp";
import ConfirmSignUp from "../SignUp/FinalConfirm";
import ChooseLogin, { EmailPath } from "./ChooseLogin";
import {
  FinishAssignPassword,
  FinishAssignPasswordPath,
  RequestAssignPassword,
  RequestAssignPasswordPath,
} from "./email/AssignPassword";
import EmailLogin, { ResetPasswordPath, SignUpPath } from "./EmailLogin";
import ForgotPassword, { FinishResetPasswordPath } from "../ResetPassword";
import ConfirmResetPassword from "../ResetPassword/FinishResetPassword";
import BackArrowIcon from "./icons/BackArrowIcon";

const MAIN_PAGE_PATH = "/";

const LOGIN_PAGES_PATH = [
  MAIN_PAGE_PATH,
  EmailPath,
  ResetPasswordPath,
  FinishResetPasswordPath,
  SignUpPath,
  FinishSignUpPath,
  FinishAssignPasswordPath,
  RequestAssignPasswordPath,
];

const Login = ({
  isConvertGuestToRegularUser,
  setError,
}: {
  isConvertGuestToRegularUser: boolean;
  setError: (error: string | null) => void;
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [hoveredBack, setHoveredBack] = React.useState(false);
  const [isMainLoginPage, setIsMainLoginPage] = React.useState(true);

  React.useEffect(() => {
    if (LOGIN_PAGES_PATH.find((path) => path === pathname) === undefined) {
      window.history.pushState({}, "", "/");
      setIsMainLoginPage(true);
    } else {
      if (pathname === MAIN_PAGE_PATH) {
        setIsMainLoginPage(true);
      } else {
        setIsMainLoginPage(false);
      }
    }
  }, [pathname]);

  React.useEffect(() => {}, []);

  return (
    <div className={styles.mainContainer}>
      <div className={classNames(styles.centerLogo)}>
        {!isMainLoginPage && (
          <Link
            to={"/"}
            onMouseOver={() => setHoveredBack(true)}
            onMouseOut={() => setHoveredBack(false)}
            onClick={() => setHoveredBack(false)}
          >
            <div className="flex items-center">
              <div className="w-8 h-8 mr-3">
                <BackArrowIcon fillColor={hoveredBack ? "#0068FF" : "white"} />
              </div>
              <span
                className={styles.backToMainScreen}
                style={{ color: hoveredBack ? "#0068FF" : "white" }}
              >
                {t("signinScreens.back_to_main")}
              </span>
            </div>
          </Link>
        )}
        <img src={spectrumLogo} alt="" className={styles.logoImage} />
      </div>
      <div className={classNames(styles.loginContainer, "flex")}>
        <div
          className={classNames(
            styles.girlContainer,
            styles.loginForm,
            "relative"
          )}
        >
          <img src={spectrumLogo} alt="" className={styles.logoImg} />
          {!isMainLoginPage && (
            <Link
              to={"/"}
              className={styles.backToMainButton}
              onMouseOver={() => setHoveredBack(true)}
              onMouseOut={() => setHoveredBack(false)}
              onClick={() => setHoveredBack(false)}
            >
              <div className="flex items-center mt-8">
                <div className="w-8 h-8 mr-3">
                  <BackArrowIcon
                    fillColor={hoveredBack ? "#0068FF" : "white"}
                  />
                </div>
                <span
                  className={styles.backToMainScreen}
                  style={{ color: hoveredBack ? "#0068FF" : "white" }}
                >
                  {t("signinScreens.back_to_main")}
                </span>
              </div>
            </Link>
          )}
        </div>
        <div className={classNames(styles.loginForm, "flex h-full")}>
          {isConvertGuestToRegularUser && (
            <div className="absolute top-0 right-0 m-2">
              <ExitButton onClick="/" label={t("exit_registration")} />
            </div>
          )}
          <div className="flex flex-col items-center justify-between w-full">
            {isMainLoginPage && (
              <ChooseLogin
                isConvertGuestToRegularUser={isConvertGuestToRegularUser}
                setError={setError}
              />
            )}
            {pathname === EmailPath && <EmailLogin />}
            {pathname === RequestAssignPasswordPath && (
              <RequestAssignPassword />
            )}
            {pathname === FinishAssignPasswordPath && <FinishAssignPassword />}
            {pathname === SignUpPath && <SignUp />}
            {pathname === FinishSignUpPath && <ConfirmSignUp />}
            {pathname === ResetPasswordPath && <ForgotPassword />}
            {pathname === FinishResetPasswordPath && <ConfirmResetPassword />}

            <div className={classNames(styles.bottomGirl)}>
              <div className={classNames(styles.bottomText)}>
                <div className="flex flex-col items-center justify-center w-full">
                  <p
                    className="text-white"
                    style={{
                      fontWeight: 400,
                      fontSize: "10px",
                      lineHeight: "15px",
                      maxWidth: "360px",
                    }}
                  >
                    * {t("signinScreens.firstLine.before")}{" "}
                    <b>{t("signinScreens.firstLine.disabled")}</b>{" "}
                    {t("signinScreens.firstLine.after")}
                  </p>
                </div>
                <div className="flex flex-col items-center justify-center w-full">
                  <p
                    className="text-white"
                    style={{
                      fontWeight: 400,
                      fontSize: "10px",
                      lineHeight: "15px",
                      maxWidth: "360px",
                    }}
                  >
                    * {t("signinScreens.secondLine.before")}
                    <a
                      href={T_AND_C_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      {t("signinScreens.secondLine.terms")}
                    </a>{" "}
                    {t("signinScreens.secondLine.and")}{" "}
                    <a
                      href={PRIVACY_POLICY_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("signinScreens.secondLine.privacy_policy")}
                    </a>{" "}
                    {t("signinScreens.secondLine.after")}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.smallFooter}>
              <div className={styles.smallFooterHeader}>
                <a
                  // href={PRIVACY_POLICY_URL}
                  // target="_blank"
                  // rel="noopener noreferrer"
                  className={styles.footerLinks}
                >
                  Contact Settings
                </a>
                <div className={styles.separator} />
                <a
                  href={PRIVACY_POLICY_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classNames(styles.footerLinks, styles.hideLink)}
                >
                  {t("privacy_policy")}
                </a>
                <div
                  className={classNames(styles.separator, styles.hideLink)}
                />
                <a
                  href={T_AND_C_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classNames(styles.footerLinks, styles.hideLink)}
                >
                  {t("terms_and_conditions")}
                </a>
                <div
                  className={classNames(styles.separator, styles.hideLink)}
                />
                <a
                  href={CONTACT_US_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.footerLinks}
                >
                  {t("contact_us")}
                </a>{" "}
              </div>
              <div className={styles.cardsImg}>
                <img src={cards} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.midScreenFooter}>
        <div className={styles.smallFooterHeader}>
          <a
            // href={PRIVACY_POLICY_URL}
            // target="_blank"
            // rel="noopener noreferrer"
            className={styles.footerLinks}
          >
            Contact Settings
          </a>
          <div className={styles.separator} />
          <a
            href={PRIVACY_POLICY_URL}
            target="_blank"
            rel="noopener noreferrer"
            className={classNames(styles.footerLinks, styles.hideLink)}
          >
            {t("privacy_policy")}
          </a>
          <div className={classNames(styles.separator, styles.hideLink)} />
          <a
            href={T_AND_C_URL}
            target="_blank"
            rel="noopener noreferrer"
            className={classNames(styles.footerLinks, styles.hideLink)}
          >
            {t("terms_and_conditions")}
          </a>
          <div className={classNames(styles.separator, styles.hideLink)} />
          <a
            href={CONTACT_US_URL}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.footerLinks}
          >
            {t("contact_us")}
          </a>{" "}
        </div>
        <div>
          <img src={cards} alt="" />
        </div>
      </div>
      <div className={classNames(styles.footerHeader, "flex-wrap")}>
        <div
          className={classNames(
            styles.linksHeader,
            "w-full",
            "md:w-9/12",
            "text-white",
            "text-center"
          )}
        >
          <a href={HELP_URL} target="_blank" rel="noopener noreferrer">
            {t("help")}
          </a>{" "}
          |{" "}
          <a href={FORUM_URL} target="_blank" rel="noopener noreferrer">
            {t("forum")}
          </a>{" "}
          |{" "}
          <a
            href={PRIVACY_POLICY_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("privacy_policy")}
          </a>{" "}
          |{" "}
          <a href={T_AND_C_URL} target="_blank" rel="noopener noreferrer">
            {t("terms_and_conditions")}
          </a>{" "}
          |{" "}
          <a href={COF_URL} target="_blank" rel="noopener noreferrer">
            {t("cof_conditions")}
          </a>{" "}
          |{" "}
          <a href={CONTACT_US_URL} target="_blank" rel="noopener noreferrer">
            {t("contact_us")}
          </a>{" "}
          |{" "}
          <a href={SECURITY_URL} target="_blank" rel="noopener noreferrer">
            {t("security")}
          </a>
        </div>
        <div
          className={classNames(
            styles.socialLinks,
            "w-full",
            "md:w-3/12",
            "md:flex",
            "text-sm",
            "lg:text-base",
            "justify-center",
            "text-white",
            "hidden"
          )}
        >
          <a
            href={FACEBOOK_URL}
            className="px-2 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebookF} fixedWidth />
          </a>
          <a
            href={TWITTER_URL}
            className="px-2 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} fixedWidth />
          </a>
          <a
            href={INSTAGRAM_URL}
            className="px-2 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} fixedWidth />
          </a>
          <a
            href={YOUTUBE_URL}
            className="px-2 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faYoutube} fixedWidth />
          </a>
        </div>
      </div>
      <div
        className={classNames(
          styles.footer,
          "text-white",
          "text-center",
          "w-full"
        )}
      >
        {`©${new Date().getFullYear()} ${t("signinScreens.copyright")}`}
      </div>
    </div>
  );
};

const ConvertUserError = ({
  errorMessage,
  close,
}: {
  errorMessage: string | null;
  close: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <AbsoluteCenter>
      <div className="w-1/3 p-4 bg-blue-800 border-2 border-blue-900 rounded shadow-xl">
        {errorMessage !== null && (
          <div className="p-2 mt-2 text-white bg-red-700 rounded">
            {errorMessage}
          </div>
        )}
        <ButtonWithSound
          className="w-full mt-2 rounded btn btn-gray-500 btn-sm"
          onClick={close}
        >
          {t("close")}
        </ButtonWithSound>
      </div>
    </AbsoluteCenter>
  );
};

export const ConvertGuestToRegularUserLogin = () => {
  const [error, setError] = React.useState<string | null>(null);
  const setErrorMessage = React.useCallback(
    (error: string | null) => {
      setError(error);
    },
    [setError]
  );
  const closeErrorMessage = React.useCallback(() => setError(null), [setError]);
  return (
    <>
      <Login isConvertGuestToRegularUser={true} setError={setErrorMessage} />
      {error && (
        <ConvertUserError errorMessage={error} close={closeErrorMessage} />
      )}
    </>
  );
};

export const ProductionLogin = () => {
  return <Login isConvertGuestToRegularUser={false} setError={() => {}} />;
};
