import * as React from "react";
import { useForceRender } from "./useForceRender";
import { gsap } from "gsap";

export const useAnimatedCounter = (
  value: number,
  conf: { from?: number; duration?: number } = {}
) => {
  const counter = React.useRef({ curr: conf.from ?? value });
  const forceRender = useForceRender();
  React.useEffect(() => {
    const tween = gsap.to(counter.current, {
      curr: value,
      onUpdate: forceRender,
      duration: conf.duration,
    });
    return () => {
      tween.kill();
    };
  }, [conf.duration, forceRender, value]);
  return counter.current.curr;
};
