import {
  useQuery as useQueryInternal,
  UseQueryArgs as UseQueryArgsInternal,
  UseQueryResponse,
} from "urql";

export type UseQueryArgs<V> = {
  throwOnError?: boolean;
} & UseQueryArgsInternal<V>;

export const useQuery = <T = any, V = object>({
  throwOnError = true,
  ...rest
}: UseQueryArgs<V>): UseQueryResponse<T, V> => {
  const result = useQueryInternal(rest);
  const [{ error }] = result;
  if (error !== undefined && throwOnError) throw error;

  return result;
};
