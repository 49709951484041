import * as React from "react";
import create from "zustand";
import { gql } from "graphql.macro";
import {
  TranslateMutation,
  TranslateMutationVariables,
} from "./index.generated";
import { useClient } from "urql";
import shallow from "zustand/shallow";
import { useTranslation } from "react-i18next";

type Message = {
  language: string;
  id: string;
  fetching: boolean;
  original: string;
  showTranslated: boolean;
  data: string;
  translated: boolean;
};

type State = {
  [key: string]: Message | undefined;
};

const TRANSLATE_MESSAGE = gql`
  mutation translate($input: TranslationInput!) {
    translate(input: $input)
  }
`;

const useStore = create<State>(() => ({}));

export function useMessageTranslation(
  id: string,
  original: string
): [Message, () => void] {
  const client = useClient();
  const { i18n } = useTranslation();
  const activeLanguage = i18n.language;
  const message = useStore(
    React.useCallback(
      (state) => {
        let message = state[id];
        if (message !== undefined && message.language !== activeLanguage) {
          useStore.setState({ [message.id]: undefined });
          message = undefined;
        }
        if (message === undefined) {
          message = {
            language: activeLanguage,
            id,
            fetching: false,
            original,
            showTranslated: false,
            data: original,
            translated: false,
          };
        }

        return message;
      },
      [id, original, activeLanguage]
    ),
    shallow
  );

  const translate = React.useCallback(() => {
    if (message.fetching) return;
    useStore.setState({ [message.id]: message });
    const update = (id: string, message: Partial<Omit<Message, "id">>) => {
      useStore.setState({ [id]: { ...useStore.getState()[id]!, ...message } });
    };
    if (message.showTranslated) update(id, { showTranslated: false });
    else if (message.translated) update(id, { showTranslated: true });
    else {
      update(id, { fetching: true });
      client
        .mutation<TranslateMutation, TranslateMutationVariables>(
          TRANSLATE_MESSAGE,
          {
            input: { text: message.original, language: message.language },
          }
        )
        .toPromise()
        .then((data) => {
          if (
            data.error === undefined &&
            data.data !== undefined &&
            data.data.translate !== null
          )
            update(id, { data: data.data.translate, showTranslated: true });
        })
        .finally(() => update(id, { fetching: false, translated: true }));
    }
  }, [client, id, message]);

  return React.useMemo(() => [message, translate], [message, translate]);
}
