/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react";
import { useTranslation } from "react-i18next";
import { TableDataDefinition } from "../../types";
import { FormattedNumber } from "../../../FormattedNumber";
import {
  BelotePlayers,
  BeloteCoincheTableType,
} from "../../../../graphql/generated";
import { SectionTableFragmentFragment } from "../../../../graphql/SectionTableFragment.generated";
import { Column } from "react-table";

type TableType = Omit<SectionTableFragmentFragment, "metaData"> & {
  metaData: {
    points: number;
    turnTime: number;
    bet: number;
    type: BeloteCoincheTableType;
    players: BelotePlayers;
  };
};

type RowData = {
  players: number;
  points: number;
  turnTime: number;
  bet: number;
  users: BelotePlayers;
};

export const tableData: TableDataDefinition<RowData> = {
  useColumns: (isTournament: boolean) => {
    const { t } = useTranslation();
    return React.useMemo(() => {
      let result: Array<Column<RowData>> = [
        {
          Header: t<string>("players"),
          accessor: "players",
          Cell: ({ row }) =>
            `${row.original.players} / ${
              row.original.users === BelotePlayers.OneVsOne ? 2 : 4
            }`,
        },
        {
          Header: t<string>("belote.points"),
          accessor: "points",
          Cell: ({ row }) => <FormattedNumber value={row.original.points} />,
        },
        {
          Header: t<string>("turn_time"),
          accessor: "turnTime",
          Cell: ({ row }) => <FormattedNumber value={row.original.turnTime} />,
        },
      ];

      if (!isTournament)
        result = result.concat([
          {
            Header: t<string>("bet"),
            accessor: "bet",
            Cell: ({ row }) => <FormattedNumber value={row.original.bet} />,
          },
        ]);
      return result;
    }, [isTournament, t]);
  },
  makeRow: (table: TableType) => ({
    players: table.players.length,
    points: table.metaData.points,
    turnTime: table.metaData.turnTime,
    bet: table.metaData.bet,
    users: table.metaData.players,
  }),

  calculateEntryFee: (table) => {
    return table.metaData.type === BeloteCoincheTableType.Private
      ? null
      : table.metaData.bet;
  },

  getRequiredPlayers: (table) =>
    table.metaData.players === BelotePlayers.OneVsOne ? 2 : 4,

  getDetails: (table: TableType) => {
    const { t } = useTranslation();
    return (
      <>
        <div className="flex -mx-2 justify-between">
          <div className="px-2">{t("players")}</div>
          <div className="px-2">{`${table.players.length} / ${
            table.metaData.players === BelotePlayers.OneVsOne ? 2 : 4
          }`}</div>
        </div>
        <div className="flex -mx-2 justify-between">
          <div className="px-2">{t("belote.points")}</div>
          <div className="px-2">
            <FormattedNumber value={table.metaData.points} />
          </div>
        </div>
        <div className="flex -mx-2 justify-between">
          <div className="px-2">{t("turn_time")}</div>
          <div className="px-2">
            <FormattedNumber value={table.metaData.turnTime} />
          </div>
        </div>
        <div className="flex -mx-2 justify-between">
          <div className="px-2">{t("bet")}</div>
          <div className="px-2">
            <FormattedNumber value={table.metaData.bet} />
          </div>
        </div>
      </>
    );
  },
};
