import { loader } from "graphql.macro";
import { Cache, Data, Variables } from "@urql/exchange-graphcache";
import { BannedUsersQueryQuery } from "../../graphql/BannedUsersQuery.generated";

const PLAYER_BAN_FRAGMENT = loader(
  "../../graphql/PlayerBanningFragment.graphql"
);

const BANNED_USERS_QUERY = loader("../../graphql/BannedUsersQuery.graphql");

export const cancelBan = (result: Data, args: Variables, cache: Cache) => {
  if (result !== null) {
    const { toUser: userId } = args;
    cache.writeFragment(PLAYER_BAN_FRAGMENT, {
      id: userId as string,
      banning: {
        canAskForBanning: true,
        areBanned: false,
        __typename: "BanningType",
      },
      __typename: "Player",
    });
    cache.updateQuery<BannedUsersQueryQuery>(
      { query: BANNED_USERS_QUERY, variables: { after: "" } },
      (data) => {
        if (data !== null)
          (data as BannedUsersQueryQuery).me!.bannedUsers.edges = (data as BannedUsersQueryQuery).me!.bannedUsers.edges.filter(
            (edge) => edge.node.userB.id !== userId
          );
        return data;
      }
    );
  }
};
