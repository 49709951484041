import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNumberFormatter } from "../../../hooks/useNumberFormatter";

export function TournamentInfo({ config }: { config: any }) {
  const { t } = useTranslation();
  const numberFormatter = useNumberFormatter();
  return (
    <>
      <div className="flex justify-between -mx-2 py-1">
        <div className="px-2">{t("type")}</div>
        <div className="px-2">{t(`game.tichu`)}</div>
      </div>
      <div className="flex justify-between -mx-2 py-1">
        <div className="px-2">{t("turn_time")}</div>
        <div className="px-2">
          {t("seconds_value", { value: config.turnTime })}
        </div>
      </div>
      <div className="flex justify-between -mx-2 py-1">
        <div className="px-2">{t("tichu.points")}</div>
        <div className="px-2">{numberFormatter.format(config.points)}</div>
      </div>
    </>
  );
}
