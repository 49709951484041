import { map, pipe } from "wonka";
import { Exchange } from "urql";
import { i18n } from "i18next";
import { getAccessToken } from "../authToken";

export const contextExchange = (i18nInstance: i18n): Exchange => {
  return ({ forward }) => {
    return (operations$) => {
      return pipe(
        operations$,
        map((op) => {
          const token = getAccessToken();
          let headers = {
            ...((op.context.fetchOptions as any)?.headers ?? {}),
            "X-LOCALE": i18nInstance.language,
          };
          if (token !== null)
            headers = {
              ...headers,
              Authorization: `Bearer ${token}`,
            };
          return {
            ...op,
            context: {
              ...op.context,
              fetchOptions: {
                ...op.context.fetchOptions,
                headers,
              },
            },
          };
        }),
        forward
      );
    };
  };
};
