import "react-app-polyfill/stable";
import * as React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { App } from "./components/App";
import * as Sentry from "@sentry/browser";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import "./index.css";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID!);
ReactPixel.init(process.env.REACT_APP_FB_PIXEL!);

if (process.env.REACT_APP_SENTRY_DSN !== "")
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    ignoreErrors: [
      "Non-Error exception captured with keys: currentTarget, isTrusted, target, type", //Happens when there was an error loading an image with suspense.
      "ChunkLoadError", //Happens when we push a new client update and the customer is using the previous client resulting in new chunks to fail loading,
      "TagError", // Google ads
    ],
  });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
