import * as React from "react";
import { useLocalStorage } from "./useStorage";
export const useReferral = (): [
  string | null,
  (referral: string) => void,
  () => void
] => {
  const [referral, setReferral, reset] = useLocalStorage<string | null>(
    "referral",
    null
  );

  const setValue = React.useCallback(
    (referral: string) => setReferral(referral),
    [setReferral]
  );

  return React.useMemo(() => [referral, setValue, reset], [
    referral,
    setValue,
    reset,
  ]);
};
