import * as React from "react";
import { gql } from "graphql.macro";
import { useQuery } from "../../hooks/useQuery";
import { useTranslation } from "react-i18next";
import { MarqueeQuery, MarqueeQueryVariables } from "./index.generated";
export * from "./Marquee";

const MARQUEE_QUERY = gql`
  query Marquee($locale: String!) {
    marquee(locale: $locale) {
      id
      link
      content
      duration
      delay
    }
  }
`;

const Context = React.createContext<
  [NonNullable<MarqueeQuery["marquee"]>[0] | undefined, () => void]
>([undefined, () => {}]);

function MarqueMessages({
  messages,
  children,
}: React.PropsWithChildren<{
  messages: NonNullable<MarqueeQuery["marquee"]>;
}>) {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const onComplete = React.useCallback(() => {
    let nextIndex = currentIndex + 1;
    if (nextIndex >= messages.length) nextIndex = 0;
    const nextMessage = messages[nextIndex];
    if (nextMessage === undefined) return;
    const timeout = window.setTimeout(() => {
      setCurrentIndex(nextIndex);
    }, nextMessage.delay * 1000);
    return () => {
      window.clearTimeout(timeout);
    };
  }, [currentIndex, messages]);

  const currentMessage = messages[currentIndex];
  const ctx = React.useMemo<
    [NonNullable<MarqueeQuery["marquee"]>[0] | undefined, () => void]
  >(() => [currentMessage, onComplete], [currentMessage, onComplete]);
  return <Context.Provider value={ctx}>{children}</Context.Provider>;
}

export function MarqueeProvider({ children }: { children?: React.ReactNode }) {
  const { i18n } = useTranslation();
  const locale = i18n.language;
  const [{ data }] = useQuery<MarqueeQuery, MarqueeQueryVariables>({
    query: MARQUEE_QUERY,
    variables: {
      locale,
    },
  });

  return (
    <MarqueMessages messages={data?.marquee ?? []}>{children}</MarqueMessages>
  );
}

export function useMarquee() {
  return React.useContext(Context);
}
