import { useSubscription } from "use-subscription";
import {
  accessTokenSubscription,
  clearAccessToken,
  setAccessToken,
} from "../authToken";

export const useAuthToken = (): [
  string | null,
  (token: string) => void,
  () => void
] => {
  const value = useSubscription(accessTokenSubscription);

  return [value, setAccessToken, clearAccessToken];
};
