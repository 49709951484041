import classNames from "classnames";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthToken } from "../../../hooks/useAuthToken";
import { useAskSendEmails } from "../../AskSendEmails";
import { emailSignInRequest } from "../api";
import LoginInput from "../LoginInput";
import SubmitButton from "../SubmitButton";
import { validateEmail } from "../utils";
import { RequestAssignPasswordPath } from "./email/AssignPassword";
import styles from "./Login.module.scss";

export const SignUpPath = "/signup";
export const ResetPasswordPath = "/reset-password";

const EmailLogin = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [emailError, setEmailError] = useState(false);
  const [invalidFormat, setInvalidFormat] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [, setToken] = useAuthToken();
  const { setDoAskSendEmailsOnLogin } = useAskSendEmails();
  const { t } = useTranslation();

  const getEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError(false);
    setPasswordError(false);
    setInvalidFormat(false);
  };
  const getPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setPasswordError(false);
  };

  const submitForm = async () => {
    const validEmail = validateEmail(email);
    if (!validEmail) {
      setInvalidFormat(true);
    }
    if (validEmail) {
      const response: string = await emailSignInRequest({ email, password });
      if (response.includes("Error")) {
        setPasswordError(true);
        setEmailError(true);
      } else {
        setDoAskSendEmailsOnLogin(true);
        setToken(response);
      }
    }
  };

  return (
    <div className="w-full">
      <div className="flex flex-col items-center justify-center w-full">
        <p className={classNames(styles.emailLoginHeader, "mb-3 text-white")}>
          {t("signinScreens.sign_in")}
        </p>
        <p className={classNames(styles.emailLoginSubHeader, "text-white")}>
          {t("signinScreens.need_account")}{" "}
          <Link
            to={SignUpPath}
            className={classNames(styles.emailLoginLinkText, "underline")}
          >
            <span>{t("signinScreens.sign_up")}</span>
          </Link>
        </p>

        <div className={classNames(styles.inputWrapper, "relative")}>
          <div className={classNames(styles.inputWrapper, "mb-6")}>
            <div className={styles.passwordWithTooltipContainer}>
              <LoginInput
                name={t("signinScreens.email")}
                getValue={getEmail}
                error={emailError || invalidFormat}
              />
            </div>
            {(emailError || invalidFormat) && (
              <p className={styles.errorMessage}>
                {invalidFormat
                  ? t("signinScreens.invalid_email_format")
                  : t("signinScreens.invalid_credentials")}
              </p>
            )}

            <div className={styles.passwordWithTooltipContainer}>
              <LoginInput
                name={t("signinScreens.password")}
                icon={true}
                getValue={getPassword}
                error={passwordError}
              />
            </div>
            {passwordError && (
              <p className={styles.errorMessage}>
                {t("signinScreens.invalid_credentials")}
              </p>
            )}
          </div>
          <SubmitButton
            name={t("signinScreens.sign_in")}
            onClick={submitForm}
          />
          <div
            className={classNames(
              styles.emailLoginLinks,
              "flex items-center justify-center"
            )}
          >
            <div
              className={classNames(styles.forgotPassword, "flex items-center")}
            >
              <Link
                to={ResetPasswordPath}
                className={classNames(styles.emailLoginLinkText, "underline")}
              >
                <span>{t("signinScreens.forgot_password")}</span>
              </Link>
            </div>
            <div className={styles.emailLoginLinkSeparator} />
            <div
              className={classNames(
                styles.dontHavePassword,
                "flex items-center"
              )}
            >
              <Link
                to={RequestAssignPasswordPath}
                className={classNames(styles.emailLoginLinkText, "underline")}
              >
                <span>{t("signinScreens.dont_have_password")}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailLogin;
