import React, { useState } from "react";
import classNames from "classnames";
import styles from "../ProductionLogin/Login.module.scss";
import { useHistory } from "react-router-dom";
import LoginInput from "../LoginInput";
import SubmitButton from "../SubmitButton";
import { saveToLocalStorageWithExpiry, validateEmail } from "../utils";
import { resetPasswordRequest } from "../api";
import { DEFAULT_OTP_TTL_IN_SEC } from "../ProductionLogin/email/AssignPassword";
import { useTranslation } from "react-i18next";

export const FinishResetPasswordPath = "/finish-reset-password";

const ForgotPassword = () => {
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [generalError, setGeneralError] = useState(false);
  const [isNotAllowedError, setIsNotAllowedError] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const getEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError(false);
    setIsNotAllowedError(false);
    setGeneralError(false);
  };

  const submitForm = async () => {
    const validEmail = validateEmail(email);
    if (!validEmail) setEmailError(true);
    if (validEmail) {
      resetPasswordRequest({
        email,
      }).then(({ error, otpId, cause }) => {
        if (error) {
          console.log(error);
          setGeneralError(true);
        } else if (otpId === null && cause !== undefined) {
          if (cause === "Invalid email") setEmailError(true);
          if (cause === "Not allowed reset password")
            setIsNotAllowedError(true);
        } else {
          saveToLocalStorageWithExpiry({
            key: "otpId",
            value: otpId,
            ttlInSeconds:
              process.env.REACT_APP_OTP_TTL_IN_SEC === undefined
                ? DEFAULT_OTP_TTL_IN_SEC
                : parseInt(process.env.REACT_APP_OTP_TTL_IN_SEC, 10),
          });
          saveToLocalStorageWithExpiry({
            key: "email",
            value: email,
            ttlInSeconds:
              process.env.REACT_APP_OTP_TTL_IN_SEC === undefined
                ? DEFAULT_OTP_TTL_IN_SEC
                : parseInt(process.env.REACT_APP_OTP_TTL_IN_SEC, 10),
          });
          history.push(FinishResetPasswordPath);
        }
      });
    }
  };

  return (
    <div className="w-full py-1 md:py-2">
      <div className="flex flex-col items-center justify-center w-full -my-2">
        <p className={classNames(styles.emailLoginHeader, "mb-3 text-white")}>
          {t("signinScreens.forgot_password")}
        </p>
        <p className={classNames(styles.emailLoginSubHeader, "text-white")}>
          {t("signinScreens.recovery_link")}
        </p>

        <div className={classNames(styles.inputWrapper, "relative")}>
          <div className={classNames(styles.inputWrapper, "mb-6")}>
            <div className={styles.passwordWithTooltipContainer}>
              <LoginInput
                name={t("signinScreens.email")}
                getValue={getEmail}
                error={emailError || isNotAllowedError || generalError}
              />
            </div>

            {(emailError || isNotAllowedError || generalError) && (
              <p className={styles.errorMessage}>
                {t(
                  emailError
                    ? "signinScreens.invalid_email_format"
                    : isNotAllowedError
                    ? "signinScreens.do_not_allow_reset_password_error"
                    : "signinScreens.general_error"
                )}
              </p>
            )}
          </div>
          <SubmitButton
            name={t("signinScreens.send_code")}
            onClick={submitForm}
          />
          <div
            className={classNames(
              styles.emailLoginLinks,
              "flex items-center justify-center"
            )}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
