import React from "react";
import styles from "./Login.module.scss";
import tooltipImage from "./assets/tooltip.svg";
import tooltipInstructionTriangle from "./assets/tooltip-instruction-triangle.svg";
import { useTranslation } from "react-i18next";

const Tooltip = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.tooltipContainer}>
      <div className={styles.tooltipInstruction}>
        <p>{t("signinScreens.password_reqirements.first")}</p>
        <p>{t("signinScreens.password_reqirements.second")}</p>
        <p>{t("signinScreens.password_reqirements.third")}</p>
        <p>Contain special characters</p>
      </div>
      <div className={styles.tooltipInstructionTriangle}>
        <img
          src={tooltipInstructionTriangle}
          alt="tooltip-instruction-triangle"
        />
      </div>
      <div className={styles.tooltip}>
        <img src={tooltipImage} alt="tooltip" />
      </div>
    </div>
  );
};

export default Tooltip;
