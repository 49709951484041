import "./Menu.css";
import { PropsWithChildren } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { MenuItem, MenuLink } from "@reach/menu-button";
import { Link } from "react-router-dom";
export * from "@reach/menu-button";

export const ARROW_STYLE: React.CSSProperties = {
  position: "absolute",
  width: 0,
  height: 0,
  left: 7,
  borderLeft: "10px solid transparent",
  borderRight: "10px solid transparent",
  borderBottom: "10px solid #1B1F28",
};

export const HeaderMenuItem = ({
  onSelect,
  icon,
  children,
}: PropsWithChildren<{
  onSelect: () => void;
  icon: IconProp;
}>) => {
  return (
    <MenuItem
      onSelect={onSelect}
      className="px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-blue-700 hover:text-white"
    >
      <div className="flex w-full">
        <div className="flex-shrink-0">
          <FontAwesomeIcon icon={icon} fixedWidth className="mr-3" />
        </div>
        <div className="flex-1">{children}</div>
      </div>
    </MenuItem>
  );
};

export const HeaderMenuLink = ({
  href,
  onSelect,
  icon,
  children,
}: PropsWithChildren<{
  href: string;
  onSelect?: () => void;
  icon: IconProp;
}>) => {
  return (
    <MenuLink
      onSelect={onSelect}
      href={href}
      className="px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-blue-700 hover:text-white"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="flex w-full">
        <div className="flex-shrink-0">
          <FontAwesomeIcon icon={icon} fixedWidth className="mr-3" />
        </div>
        <div className="flex-1">{children}</div>
      </div>
    </MenuLink>
  );
};

export const HeaderLink = ({
  to,
  onSelect,
  icon,
  children,
}: PropsWithChildren<{
  to: string;
  onSelect?: () => void;
  icon: IconProp;
}>) => {
  return (
    <Link onSelect={onSelect} to={to}>
      <div className="flex w-full px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-blue-700 hover:text-white">
        <div className="flex-shrink-0">
          <FontAwesomeIcon icon={icon} fixedWidth className="mr-3" />
        </div>
        <div className="flex-1">{children}</div>
      </div>
    </Link>
  );
};
