import * as React from "react";
import styles from "./SliderLabel.module.scss";
import classNames from "classnames";

export const SliderLabel = React.memo<{ value: string }>(({ value }) => {
  return (
    <span
      className={classNames(
        "bg-white",
        "px-4",
        "text-sm",
        "rounded",
        "shadow-xl",
        "text-center",
        "w-2/5",
        "truncate",
        "overflow-hidden",
        styles.label
      )}
    >
      {value}
    </span>
  );
});
