import * as React from "react";
import { loader } from "graphql.macro";
import {
  BannedUsersQueryQuery,
  BannedUsersQueryQueryVariables,
} from "../graphql/BannedUsersQuery.generated";
import { useClient, CombinedError } from "urql";
import { useIsMounted } from "./useIsMounted";

type BannedPlayersType = NonNullable<
  BannedUsersQueryQuery["me"]
>["bannedUsers"];
const BANNED_PLAYERS_QUERY = loader("../graphql/BannedUsersQuery.graphql");

const mergePages = (prev: BannedPlayersType, current: BannedPlayersType) => {
  const existingIds = prev.edges.map(({ node }) => node.userB.id);
  for (const edge of current.edges)
    if (existingIds.indexOf(edge.node.userB.id) >= 0) return prev;

  return {
    edges: [...prev.edges, ...current.edges],
    pageInfo: current.pageInfo,
    __typename: current.__typename,
  };
};

/*Unfortunately we cannot use relay pagination for these.Due to network refresh
Check out https://github.com/FormidableLabs/urql/issues/672*/
export const useBannedPlayers = () => {
  const client = useClient();
  const [error, setError] = React.useState<CombinedError | undefined>(
    undefined
  );
  const [bannedPlayers, setBannedPlayers] = React.useState<
    BannedPlayersType | undefined
  >();
  const isMounted = useIsMounted();
  const fetchMore = React.useCallback(
    (cursor: string) => {
      return client
        .query<BannedUsersQueryQuery, BannedUsersQueryQueryVariables>(
          BANNED_PLAYERS_QUERY,
          { after: cursor },
          { requestPolicy: "network-only" }
        )
        .toPromise()
        .then(({ data, error }) => {
          if (error !== undefined) {
            throw error;
          } else if ((data?.me ?? null) === null) {
            setBannedPlayers(undefined);
          } else if (isMounted.current) {
            const bannedPlayers = data!.me!.bannedUsers;
            setBannedPlayers((prev) => {
              if (prev === undefined) return bannedPlayers;
              return mergePages(prev, bannedPlayers);
            });
          }
        })
        .catch((error) => {
          if (isMounted.current) setError(error);
        });
    },
    [client, isMounted]
  );

  React.useEffect(() => {
    fetchMore("");
  }, [fetchMore]);

  if (error !== undefined) throw error;

  return React.useMemo(
    () => ({
      bannedPlayers,
      fetchMore,
    }),
    [fetchMore, bannedPlayers]
  );
};
