import * as React from "react";
import PeerTime from "../peertime";

const ID = "WEB_PLAYER";

export const SyncedTimeContext = React.createContext<() => number>(
  undefined as any
);

const ts = new PeerTime({
  id: ID,
  peers: [`${process.env.REACT_APP_API_HOST}/timesync`],
  send: (frame) => {
    return fetch(frame.to, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: frame.fid!,
        from: frame.from,
      }),
    })
      .then((fetchResult) => {
        if (!fetchResult.ok) return Promise.reject();
        else return fetchResult.json();
      })
      .then((reply) => {
        ts.receive(reply);
      });
  },
});

export const SyncedTimeProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  React.useEffect(() => {
    let isCurrent = true;
    ts.sync().then(() => {
      if (isCurrent) ts.start();
    });

    return () => {
      isCurrent = false;
      ts.stop();
    };
  }, []);

  const dateProvider: () => number = React.useCallback(() => {
    return ts.now();
  }, []);

  return (
    <SyncedTimeContext.Provider value={dateProvider}>
      {children}
    </SyncedTimeContext.Provider>
  );
};

export const useSyncedTimeProvider = () => {
  return React.useContext(SyncedTimeContext);
};
