import * as React from "react";

export const VIRTUAL_ITEM_WIDTH = 60;
export const VIRTUAL_ITEM_HEIGHT = 60;

export const calculatePosition = (
  {
    left,
    top,
    right,
    bottom,
    width,
    height,
  }: {
    left?: number;
    top?: number;
    right?: number;
    bottom?: number;
    width: number;
    height: number;
  },
  rect: DOMRect
) => {
  if (left === undefined && right === undefined)
    throw new Error("One of left | right must be defined");
  else if (bottom === undefined && top === undefined)
    throw new Error("One of top | bottom must be defined");

  //Calculate left using right
  if (left === undefined)
    left = rect.width - right! - width + (width - VIRTUAL_ITEM_WIDTH) / 2;
  else left = left + (width - VIRTUAL_ITEM_WIDTH) / 2;

  //Calculate top using bottom
  if (top === undefined)
    top = rect.height - bottom! - height - VIRTUAL_ITEM_HEIGHT;
  else top = top + height;

  return {
    left,
    top,
  };
};

export const VirtualItem = React.memo<{
  name: string;
  logo: string;
}>(({ name, logo }) => (
  <img
    className="object-scale-down"
    width={VIRTUAL_ITEM_WIDTH}
    height={VIRTUAL_ITEM_HEIGHT}
    src={`${process.env.REACT_APP_API_HOST}/virtual-items/${logo}`}
    alt={name}
  />
));
