import * as React from "react";
import {
  SliderHandle,
  SliderInput,
  SliderInputProps,
  SliderOrientation,
  SliderTrack,
  SliderRange,
} from "@reach/slider";
import "./style.css";
import styles from "./Slider.module.css";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { clamp } from "../../utils";
import { ButtonWithSound } from "../AudioProvider";

const Input = ({
  children,
  className,
  orientation = SliderOrientation.Horizontal,
  ...rest
}: { children?: React.ReactNode; className?: string } & Omit<
  SliderInputProps,
  "children"
>) => {
  return (
    <SliderInput
      className={classNames("p-0", className, {
        "w-full": orientation === SliderOrientation.Horizontal,
        [styles.inputHorizontal]: orientation === SliderOrientation.Horizontal,
        [styles.inputVertical]: orientation !== SliderOrientation.Horizontal,
      })}
      orientation={orientation}
      {...rest}
    >
      <SliderTrack
        className={classNames(
          "rounded",
          "bg-transparent",
          "border-b",
          "border-r",
          styles.track
        )}
      >
        <SliderRange
          className={classNames({
            [styles.highlightHorizontal]:
              orientation === SliderOrientation.Horizontal,
            [styles.highlightVertical]:
              orientation !== SliderOrientation.Horizontal,
          })}
        />
        {children}
        <SliderHandle
          className={classNames(
            "bg-blue-600",
            "border",
            "border-black",
            "rounded-full",
            {
              [styles.handleHorizontal]:
                orientation === SliderOrientation.Horizontal,
              [styles.handleVertical]:
                orientation !== SliderOrientation.Horizontal,
            }
          )}
        />
      </SliderTrack>
    </SliderInput>
  );
};

export const Slider = ({
  value,
  min = 0,
  max = 100,
  step = 1,
  handleAlignment = "contain",
  onChange,
  disabled = false,
  ...rest
}: { value: number; onChange: (value: number) => void } & Omit<
  SliderInputProps,
  "value" | "defaultValue" | "onChange" | "children"
>) => {
  //Code copied from jquery ui slider.Makes sure the value stays in range with accordance to the step
  const safeOnChange = (newValue: number) => {
    const valModStep = (newValue - min) % step;
    let alignValue = newValue - valModStep;
    if (Math.abs(valModStep) * 2 >= step)
      alignValue += valModStep > 0 ? step : -step;
    // Since JavaScript has problems with large floats, round
    // the final value to 5 digits after the decimal point (see #4124)
    return onChange(parseFloat(alignValue.toFixed(5)));
  };
  const onButtonChange = (step: number) => () => {
    safeOnChange(clamp(value + step, min, max));
  };
  return (
    <div className="flex items-center w-full">
      <div className="flex-shrink-0 pr-1">
        <ButtonWithSound
          disabled={disabled}
          type="button"
          className="btn btn-green-600 btn-sm text-sm rounded-full"
          onClick={onButtonChange(step * -1)}
        >
          <FontAwesomeIcon icon={faMinus} />
        </ButtonWithSound>
      </div>
      <div className="flex-1 px-1">
        <Input
          value={value}
          min={min}
          max={max}
          step={step}
          onChange={safeOnChange}
          handleAlignment={handleAlignment}
          disabled={disabled}
          {...rest}
        />
      </div>
      <div className="flex-shrink-0 pl-1">
        <ButtonWithSound
          disabled={disabled}
          type="button"
          className="btn btn-green-600 btn-sm text-sm rounded-full"
          onClick={onButtonChange(step)}
        >
          <FontAwesomeIcon icon={faPlus} />
        </ButtonWithSound>
      </div>
    </div>
  );
};
