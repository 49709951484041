import * as React from "react";
import { useSubscription } from "../../hooks/useSubscription";
import {
  TableInvitationsSubscription,
  TableInvitationsSubscriptionVariables,
} from "./graphql/TableInvitationsSubscription.generated";
import { loader } from "graphql.macro";
import { pipe, scan } from "wonka";
import produce from "immer";
import { ProfileImage } from "../ProfileImage";
import { useTranslation } from "react-i18next";
import { useCountDown } from "../../hooks/useCountDown";
import { useBehaviourSubject, useSource } from "../../hooks/useSource";
import { useClient } from "urql";
import {
  CancelInvitationMutationMutation,
  CancelInvitationMutationMutationVariables,
} from "./graphql/CancelInvitationMutation.generated";
import { AbsoluteCenter } from "../AbsoluteCenter";
import { ButtonWithSound, LinkWithSound, useAudio } from "../AudioProvider";
import { useSyncedTimeProvider } from "../SyncedTimeProvider";

const TABLE_INVITATIONS_SUBSCRIPTION = loader(
  "./graphql/TableInvitationsSubscription.graphql"
);

const CANCEL_INVITATION_MUTATION = loader(
  "./graphql/CancelInvitationMutation.graphql"
);

type Invitation = Omit<
  NonNullable<TableInvitationsSubscription["invitation"]>,
  "start" | "end"
> & {
  start: number;
  end: number;
};

type Message =
  | {
      type: "ADD_INVITATION";
      payload: Invitation;
    }
  | {
      type: "CLEAR_EXPIRED";
    }
  | {
      type: "POP";
    }
  | {
      type: "CLEAR";
    };

const reducer = produce((state: Array<Invitation>, message: Message) => {
  switch (message.type) {
    case "ADD_INVITATION":
      state.push(message.payload);
      return state;
    case "CLEAR_EXPIRED":
      const now = Date.now();
      return state.filter(({ end }) => end > now);
    case "POP":
      return state.slice(1);
    case "CLEAR":
      state = [];
      return state;
    default:
      return state;
  }
});

const InvitationData = ({
  onCancel,
  onJoin,
  invitation: { id, from, table, start, end },
  onTick,
}: {
  onCancel: (id: string) => void;
  onJoin: () => void;
  invitation: Invitation;
  onTick?: (remaining: number) => void;
}) => {
  const { remaining } = useCountDown(start, end);
  React.useEffect(() => {
    if (onTick !== undefined && remaining > 0) onTick(remaining);
  }, [onTick, remaining]);
  const { t } = useTranslation();
  return (
    <div
      aria-label={t("invitation")}
      className="w-1/3 p-4 rounded shadow-xl bg-blue-800 border-blue-900 border-2"
    >
      <div className="flex -mx-2">
        <div className="flex-shrink-0 px-2 ">
          <ProfileImage
            id={from.id}
            name={from.name}
            width={54}
            height={54}
            className="rounded bg-blue-900"
          />
        </div>
        <div className="flex-1 px-2">
          <div className="inline-flex flex-col h-full w-full justify-between overflow-hidden text-white items-start">
            <span className="text-white font-bold truncate">{from.name}</span>
            <span>
              {t("invite_details", { game: t(`game.${table.section.type}`) })}
            </span>
          </div>
        </div>
      </div>
      <div className="flex -mx-2 mt-4">
        <div className="flex-1 px-2">
          <LinkWithSound
            onClick={onJoin}
            to={`/area/${table.section.id}/${table.id}`}
            className="btn btn-green-600 btn-sm w-full rounded"
          >
            {`${t("join")} (${remaining})`}
          </LinkWithSound>
        </div>
        <div className="flex-1 px-2">
          <ButtonWithSound
            className="btn btn-gray-500 btn-sm w-full rounded"
            onClick={() => onCancel(id)}
          >
            {t("cancel")}
          </ButtonWithSound>
        </div>
      </div>
    </div>
  );
};

const Popup = ({
  invitations,
  onCancel,
  onJoin,
}: {
  invitations: Array<Invitation>;
  onCancel: (id: string) => void;
  onJoin: () => void;
}) => {
  const audio = useAudio();
  return (
    <div className="absolute bg-black-alpha-30 top-0 left-0 right-0 bottom-0 z-50">
      <AbsoluteCenter>
        <InvitationData
          invitation={invitations[0]}
          onCancel={onCancel}
          onJoin={onJoin}
          onTick={() => audio.play("timer")}
        />
      </AbsoluteCenter>
    </div>
  );
};

export const TableInvitations = () => {
  const syncedTime = useSyncedTimeProvider();
  const [{ data }] = useSubscription<
    unknown,
    TableInvitationsSubscription,
    TableInvitationsSubscriptionVariables
  >({
    query: TABLE_INVITATIONS_SUBSCRIPTION,
    throwOnError: false,
  });

  const [subscription$$, update] = useBehaviourSubject(
    React.useMemo<Message | null>(
      () =>
        data?.invitation
          ? {
              type: "ADD_INVITATION",
              payload: {
                ...data.invitation,
                start: new Date(data.invitation.start).getTime(),
                end: new Date(data.invitation.end).getTime(),
              },
            }
          : null,
      [data]
    )
  );

  const state = useSource(
    React.useMemo(() => {
      return pipe(
        subscription$$,
        scan<Message | null, Array<Invitation>>((previous, message) => {
          if (message === null) return previous;
          return reducer(previous, message!);
        }, [])
      );
    }, [subscription$$]),
    []
  );

  React.useEffect(() => {
    const id = setInterval(() => {
      const now = syncedTime();
      const found = state.find(({ end }) => end < now);
      if (found)
        update({
          type: "CLEAR_EXPIRED",
        });
    }, 500);

    return () => {
      window.clearInterval(id);
    };
  }, [state, syncedTime, update]);

  const client = useClient();

  const onCancel = React.useCallback(
    (id: string) => {
      update({ type: "POP" });
      //No need to respond to success.Its a fire and forget mutation
      client
        .mutation<
          CancelInvitationMutationMutation,
          CancelInvitationMutationMutationVariables
        >(CANCEL_INVITATION_MUTATION, { id })
        .toPromise()
        .catch((e) => {
          console.error("Error canceling invitation", e);
        });
    },
    [client, update]
  );

  const onJoin = React.useCallback(() => update({ type: "CLEAR" }), [update]);

  return (
    <>
      {state.length > 0 && (
        <Popup invitations={state} onCancel={onCancel} onJoin={onJoin} />
      )}
    </>
  );
};
