import * as React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChessKnight,
  faTimes,
  faArrowAltRight,
  faArrowAltLeft,
  faImage,
  faGift,
  faClawMarks,
  faUserPlus,
  faShoppingBag,
  faMedal,
  faUndo,
  faUser,
  faMagic,
  faPaperPlane,
  faPiggyBank,
  faAward,
  faVideo,
  faComment,
} from "@fortawesome/pro-solid-svg-icons";
import { useInfiniteScroll } from "../../hooks/useInfiniteScroll";
import { useIntersection } from "../../hooks/useIntersection";
import { AbsoluteCenter } from "../AbsoluteCenter";
import { Loader } from "../Loader";
import classNames from "classnames";
import { useTransactions } from "../../hooks/useTransactions";
import { useRelativeTimeFormatter } from "../../hooks/useRelativeTimeFormatter";
import isEqual from "react-fast-compare";
import { TransactionFragmentFragment } from "../../hooks/useTransactions/graphql/TransactionsFragment.generated";
import { AmountFragmentFragment } from "../../graphql/AmountFragment.generated";
import { Currency } from "../../graphql/generated";
import { CurrencyImage } from "../CurrencyImage";
import { useNumberFormatter } from "../../hooks/useNumberFormatter";
import styles from "./Transactions.module.scss";
import {
  BalanceUpdateReceivedFromUserDetails,
  BalanceUpdateSendToUserDetails,
  BalanceUpdateTableDetails,
  BalanceUpdateAvatarPurchaseDetails,
  BalanceUpdateVirtualItemGiftDetails,
  BalanceUpdateVirtualItemGiftInChatDetails,
  BalanceUpdateScratchCardWinDetails,
  BalanceUpdateScratchCardPurchaseDetails,
  BalanceUpdateReferDetails,
  BalanceUpdateWelcomeDetails,
  BalanceUpdateReferredByDetails,
  BalanceUpdatePurchaseDetails,
  BalanceUpdateLeaderBoardAwardDetails,
  BalanceUpdateRollbackDetails,
  BalanceUpdateSystemGiftDetails,
  BalanceUpdateAdjustmentDetails,
  BalanceUpdateTopupDetails,
  BalanceUpdateTournamentPurchaseDetails,
  BalanceUpdateTournamentAwardDetails,
  BalanceUpdateRewardedAdDetails,
  BalanceUpdateHintDetails,
} from "./details";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useScreenType } from "../../hooks/useScreenType";
import {
  RIGHT_BAR_MOBILE_SIZE,
  RIGHT_BAR_TABLET_SIZE,
} from "../Bar/RightBar/Bar";

const TransactionsContext = React.createContext<(open: boolean) => void>(() => {
  throw new Error("Provider not found");
});

const isDevelopment = process.env.NODE_ENV === "development";

const AVATAR_WIDTH = 46;
const AVATAR_HEIGHT = 46;

const Balances = ({
  amounts,
  previous,
}: {
  amounts: Array<AmountFragmentFragment>;
  previous: Array<AmountFragmentFragment>;
}) => {
  const numberFormatter = useNumberFormatter();
  const [active, setActive] = React.useState(false);
  return (
    <div className={classNames("px-2", "py-1", styles.balances)}>
      <div
        className="flex items-end -mx-2 text-sm"
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
      >
        {amounts
          .filter(({ currency }) => currency !== Currency.RealMoney)
          .map(function (amount) {
            const previousAmount = previous.find(
              (prevAmount) => prevAmount.currency === amount.currency
            );
            const diff = amount.value - (previousAmount?.value ?? amount.value);
            const same = diff === 0;
            return (
              <div
                className={classNames(
                  "px-2",
                  "flex-1",
                  "flex",
                  "flex-col",
                  "items-center",
                  "justify-center",
                  "flex-shrink-0",
                  "ease-in-out",
                  "transition-opacity",
                  "duration-150",
                  { "opacity-50": same && !active }
                )}
                key={amount.currency}
              >
                {!same && (
                  <div
                    className={classNames("text-center", "text-xs", {
                      "text-green-500": diff >= 0,
                      "text-red-500": diff < 0,
                    })}
                  >
                    {`( ${diff > 0 ? "+" : "-"} ${numberFormatter.format(
                      Math.abs(diff)
                    )} )`}
                  </div>
                )}
                <div className="flex items-center -mx-1">
                  <div className="px-1">
                    <CurrencyImage
                      currency={amount.currency}
                      width={18}
                      height={18}
                    />
                  </div>
                  <span className="font-bold px-1">
                    {numberFormatter.format(amount.value)}
                  </span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const withTransaction = (
  Component: React.ComponentType<{
    details: any;
  }>,
  Avatar: React.ComponentType<{
    transaction?: TransactionFragmentFragment;
  }>
) => {
  return ({
    transaction,
    previous,
    intersecting,
  }: {
    transaction: TransactionFragmentFragment;
    previous?: TransactionFragmentFragment;
    intersecting: boolean;
  }) => {
    const relativeTimeFormatter = useRelativeTimeFormatter();
    return (
      <div className="flex -mx-2">
        <div className="flex-shrink-0 px-2">
          <div
            className="overflow-hidden bg-gray-200 rounded-full flex items-center justify-center"
            style={{ width: AVATAR_WIDTH, height: AVATAR_HEIGHT }}
          >
            {intersecting && <Avatar transaction={transaction} />}
          </div>
        </div>
        <div className="px-2 whitespace-normal text-gray-200 flex-1">
          <Component details={transaction.details} />
          <div className="my-1">
            <Balances
              amounts={transaction.balances}
              previous={previous?.balances ?? []}
            />
          </div>
          <div className="text-sm text-gray-400">
            {relativeTimeFormatter.format(new Date(transaction.created), {
              addSuffix: true,
            })}
          </div>
        </div>
      </div>
    );
  };
};

const FontAwesomeAvatar = ({ icon }: { icon: IconProp }) => {
  return (
    <FontAwesomeIcon
      fixedWidth={true}
      icon={icon}
      size="1x"
      className="text-black text-gray-700"
    />
  );
};

const Component: {
  [key in string]: React.ComponentType<{
    transaction: TransactionFragmentFragment;
    previous?: TransactionFragmentFragment;
    intersecting: boolean;
  }>;
} = {
  BalanceUpdateTableDetails: withTransaction(BalanceUpdateTableDetails, () => (
    <FontAwesomeAvatar icon={faChessKnight} />
  )),
  BalanceUpdateReceivedFromUserDetails: withTransaction(
    BalanceUpdateReceivedFromUserDetails,
    () => <FontAwesomeAvatar icon={faArrowAltRight} />
  ),
  BalanceUpdateSendToUserDetails: withTransaction(
    BalanceUpdateSendToUserDetails,
    () => <FontAwesomeAvatar icon={faArrowAltLeft} />
  ),
  BalanceUpdateAvatarPurchaseDetails: withTransaction(
    BalanceUpdateAvatarPurchaseDetails,
    () => <FontAwesomeAvatar icon={faImage} />
  ),
  BalanceUpdateVirtualItemGiftDetails: withTransaction(
    BalanceUpdateVirtualItemGiftDetails,
    () => <FontAwesomeAvatar icon={faGift} />
  ),
  BalanceUpdateVirtualItemGiftInChatDetails: withTransaction(
    BalanceUpdateVirtualItemGiftInChatDetails,
    () => <FontAwesomeAvatar icon={faGift} />
  ),
  BalanceUpdateScratchCardPurchaseDetails: withTransaction(
    BalanceUpdateScratchCardPurchaseDetails,
    () => <FontAwesomeAvatar icon={faClawMarks} />
  ),
  BalanceUpdateScratchCardWinDetails: withTransaction(
    BalanceUpdateScratchCardWinDetails,
    () => <FontAwesomeAvatar icon={faClawMarks} />
  ),
  BalanceUpdateReferDetails: withTransaction(BalanceUpdateReferDetails, () => (
    <FontAwesomeAvatar icon={faUserPlus} />
  )),
  BalanceUpdateWelcomeDetails: withTransaction(
    BalanceUpdateWelcomeDetails,
    () => <FontAwesomeAvatar icon={faUser} />
  ),
  BalanceUpdateReferredByDetails: withTransaction(
    BalanceUpdateReferredByDetails,
    () => <FontAwesomeAvatar icon={faUserPlus} />
  ),
  BalanceUpdatePurchaseDetails: withTransaction(
    BalanceUpdatePurchaseDetails,
    () => <FontAwesomeAvatar icon={faShoppingBag} />
  ),
  BalanceUpdateLeaderBoardAwardDetails: withTransaction(
    BalanceUpdateLeaderBoardAwardDetails,
    () => <FontAwesomeAvatar icon={faMedal} />
  ),
  BalanceUpdateRollbackDetails: withTransaction(
    BalanceUpdateRollbackDetails,
    () => <FontAwesomeAvatar icon={faUndo} />
  ),
  BalanceUpdateSystemGiftDetails: withTransaction(
    BalanceUpdateSystemGiftDetails,
    () => <FontAwesomeAvatar icon={faMagic} />
  ),
  BalanceUpdateAdjustmentDetails: withTransaction(
    BalanceUpdateAdjustmentDetails,
    () => <FontAwesomeAvatar icon={faPaperPlane} />
  ),
  BalanceUpdateTopupDetails: withTransaction(BalanceUpdateTopupDetails, () => (
    <FontAwesomeAvatar icon={faPiggyBank} />
  )),
  BalanceUpdateTournamentPurchaseDetails: withTransaction(
    BalanceUpdateTournamentPurchaseDetails,
    () => <FontAwesomeAvatar icon={faAward} />
  ),
  BalanceUpdateTournamentAwardDetails: withTransaction(
    BalanceUpdateTournamentAwardDetails,
    () => <FontAwesomeAvatar icon={faAward} />
  ),
  BalanceUpdateRewardedAdDetails: withTransaction(
    BalanceUpdateRewardedAdDetails,
    () => <FontAwesomeAvatar icon={faVideo} />
  ),
  BalanceUpdateHintDetails: withTransaction(BalanceUpdateHintDetails, () => (
    <FontAwesomeAvatar icon={faComment} />
  )),
};

const TransactionDetails = ({
  transaction,
  previous,
  intersecting,
}: {
  transaction: TransactionFragmentFragment;
  previous?: TransactionFragmentFragment;
  intersecting: boolean;
}) => {
  const typeName = transaction.details.__typename;
  const Comp = Component[typeName];
  if (Comp === undefined) {
    if (isDevelopment)
      throw new Error("Could not find component for type:" + typeName);
    else return null;
  }
  return (
    <Comp
      transaction={transaction}
      previous={previous}
      intersecting={intersecting}
    />
  );
};

const Transaction = React.memo<{
  container: React.MutableRefObject<HTMLDivElement | null>;
  transaction: TransactionFragmentFragment;
  previous?: TransactionFragmentFragment;
}>(({ container, transaction, previous }) => {
  const target = React.useRef<HTMLDivElement>(null);
  const intersecting = useIntersection(target, {
    root: container,
    once: true,
    defaultIntersecting: false,
  });
  return (
    <div className="py-2 px-4" ref={target}>
      <div className="overflow-hidden text-gray-200">
        <TransactionDetails
          transaction={transaction}
          previous={previous}
          intersecting={intersecting}
        />
      </div>
    </div>
  );
}, isEqual);

const TransactionsContent = React.memo(() => {
  const { transactions, fetchMore } = useTransactions();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const loadingRef = useInfiniteScroll<HTMLDivElement>(
    { root: containerRef, rootMargin: "100px" },
    () => {
      const endCursor = transactions?.pageInfo?.endCursor ?? null;
      if (endCursor === null) return Promise.reject();
      return fetchMore(endCursor);
    }
  );

  const { t } = useTranslation();
  if (transactions === undefined)
    return (
      <div className="flex-1 relative">
        <AbsoluteCenter>
          <Loader description={t("loading_transactions")} />
        </AbsoluteCenter>
      </div>
    );
  else if (transactions.edges.length === 0)
    return (
      <div className="flex-1 relative">
        <AbsoluteCenter>
          <div className="p-4 text-center">
            <span className="text-white font-bold">
              {t("no_transactions_available")}
            </span>
          </div>
        </AbsoluteCenter>
      </div>
    );
  else
    return (
      <>
        <div className="flex-1 overflow-hidden relative">
          <div
            className={classNames(
              "flex",
              "flex-col",
              "-mb-2",
              "h-full",
              "overflow-auto",
              styles.scrollable
            )}
            ref={containerRef}
          >
            {transactions.edges.map(({ node }, index) => (
              <Transaction
                transaction={node}
                previous={transactions.edges[index + 1]?.node}
                key={node.id}
                container={containerRef}
              />
            ))}
            {transactions.pageInfo.hasNextPage && (
              <div ref={loadingRef} className="py-2">
                <Loader description={""} />
              </div>
            )}
          </div>
        </div>
      </>
    );
});

const Popup = ({ close }: { close: () => void }) => {
  const { t } = useTranslation();
  const screenType = useScreenType();
  const stylesMap = {
    DESKTOP: { bottom: 0, right: 0 },
    TABLET: { top: 0, right: 0, ...RIGHT_BAR_TABLET_SIZE },
    MOBILE: { top: 0, right: 0, ...RIGHT_BAR_MOBILE_SIZE },
  };

  const adjustedStyle = React.useMemo(() => stylesMap[screenType] || {}, [
    screenType,
  ]);

  return (
    <>
      <div
        className="absolute bg-black-alpha-30 top-0 left-0 right-0 bottom-0 z-20"
        onClick={close}
      />
      <div
        className="w-1/3 absolute shadow-2xl bg-blue-800 z-30 h-9/10"
        style={{ ...adjustedStyle }}
        aria-label={t("transactions")}
      >
        <div className="flex flex-col h-full">
          <div className="flex items-center justify-between text-white flex-shrink-0 -mx-2 p-4">
            <span className="font-bold text-lg truncate px-2">
              {t("transactions")}
            </span>
            <div className="px-2 flex-shrink-0">
              <button onClick={close}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
          <TransactionsContent />
        </div>
      </div>
    </>
  );
};

export const TransactionsPanelProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const close = React.useCallback(() => setOpen(false), []);

  return (
    <TransactionsContext.Provider value={setOpen}>
      {children}
      {isOpen && <Popup close={close} />}
    </TransactionsContext.Provider>
  );
};

export const useTransactionsPanel = () => {
  return React.useContext(TransactionsContext);
};
