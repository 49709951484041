import * as React from "react";

type Context = [boolean, React.Dispatch<React.SetStateAction<boolean>>];

const GlobalLoaderContext = React.createContext<Context>(undefined as any);

export const GlobalLoaderProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const context = React.useState(false);
  return (
    <GlobalLoaderContext.Provider value={context}>
      {children}
    </GlobalLoaderContext.Provider>
  );
};

export const useGlobalLoader = () => {
  return React.useContext(GlobalLoaderContext);
};
