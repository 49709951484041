import * as React from "react";
import hotkeys, { HotkeysEvent } from "hotkeys-js";

type CallbackFn = (event: KeyboardEvent, handler: HotkeysEvent) => void;
const HOTKEYS_ENABLED =
  String(process.env.REACT_APP_ENABLE_HOT_KEYS!).toLowerCase() === "true";
export const useHotKeys = (keys: string, callback: CallbackFn) => {
  React.useEffect(() => {
    if (!HOTKEYS_ENABLED) return;
    hotkeys(keys, callback);

    return () => hotkeys.unbind(keys, callback);
  }, [callback, keys]);
};
