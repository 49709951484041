import * as React from "react";
import { PropsWithChildren } from "react";
import styles from "./Header.module.css";
import classNames from "classnames";
import { useUser } from "../UserProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamation,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/pro-solid-svg-icons";
import { useSyncedTimeProvider } from "../SyncedTimeProvider";
import spectrumLogo from "./assets/spectrumLogo.svg";
import { MuteButton } from "./MuteButton";
import { FullscreenButton } from "./FullscreenButton";
import { LanguageSelection } from "./LanguageSelection";
import Topbar from "../Bar/Topbar";
import { useLocation } from "react-router";
import { HelpButton } from "./HelpButton";
import { isNotMainScreenDesktopWidth } from "../../utils";
import { LeaderboardsButton } from "./LeaderboardsButton";
import { ChatBotButton } from "./ChatBotButton";

export const ProfileHeaderAction = ({
  className,
  children,
  ...rest
}: PropsWithChildren<JSX.IntrinsicElements["button"]>) => {
  return (
    <button
      className={classNames(
        "block",
        "absolute",
        "leading-none",
        "bottom-0",
        "right-0",
        "flex",
        "items-center",
        "justify-center",
        "rounded",
        "bg-red-600",
        "text-white",
        "text-xs",
        "pulse-animate",
        className,
        styles.headerAction
      )}
      {...rest}
    >
      {children}
    </button>
  );
};

export const NotificationsCounter = React.memo<{
  unread: number;
  onClick: () => void;
}>(({ unread, onClick }) => {
  return (
    <ProfileHeaderAction onClick={onClick} className="bg-red-600">
      {unread}
    </ProfileHeaderAction>
  );
});

export const ScratchCardNotification = React.memo<{ onClick: () => void }>(
  ({ onClick }) => {
    return (
      <ProfileHeaderAction onClick={onClick} className="bg-orange-600">
        <FontAwesomeIcon
          icon={faExclamation}
          className="text-white"
          size="sm"
        />
      </ProfileHeaderAction>
    );
  }
);

const HeaderMenu = React.memo<{
  id: string;
  name: string;
  hasFreeScratchCard: boolean;
  mainScreen?: boolean;
  topBarEvent: (visible: boolean) => void;
  children: React.ReactNode;
  sectionIds?: Array<string>;
}>(
  ({
    id,
    name,
    hasFreeScratchCard,
    children,
    mainScreen,
    topBarEvent,
    sectionIds,
  }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const handleClick = () => {
      topBarEvent(!isOpen);
      setIsOpen(!isOpen);
    };

    const isMainScreenDesk = isNotMainScreenDesktopWidth(mainScreen ? "/" : "");

    return isMainScreenDesk ? (
      <div className="flex items-center justify-between w-full">
        <div className={styles.headerLogo}>
          <img src={spectrumLogo} alt="" />
        </div>
        <div className="relative">
          <LanguageSelection mainScreen={isMainScreenDesk} />
        </div>
      </div>
    ) : (
      <div className="relative flex items-center">
        {mainScreen ? (
          <div className={styles.headerLogo}>
            <img src={spectrumLogo} alt="" />
          </div>
        ) : (
          <FontAwesomeIcon
            icon={isOpen ? faChevronUp : faChevronDown}
            className="mr-4 text-white cursor-pointer"
            size="sm"
            onClick={handleClick}
          />
        )}
        <div
          className={classNames("flex items-center", styles.leftHeaderButton)}
        >
          <HelpButton />
        </div>
        <div
          className={classNames("flex items-center", styles.leftHeaderButton)}
        >
          <ChatBotButton />
        </div>
        <div
          className={classNames("flex items-center", styles.leftHeaderButton)}
        >
          <MuteButton />
        </div>
        <div
          className={classNames("flex items-center", styles.leftHeaderButton)}
        >
          <FullscreenButton />
        </div>
        <div style={{ marginRight: !mainScreen && sectionIds ? "15px" : 0 }}>
          <LanguageSelection />
        </div>
        {!mainScreen && sectionIds && (
          <div className="flex items-center">
            <LeaderboardsButton sectionIds={sectionIds} />
          </div>
        )}
      </div>
    );
  }
);

const UserDetails = ({
  children,
  mainScreen,
  topBarEvent,
  sectionIds,
}: {
  children?: React.ReactNode;
  mainScreen?: boolean;
  topBarEvent: (visible: boolean) => void;
  sectionIds?: Array<string>;
}) => {
  const syncedTimeProvider = useSyncedTimeProvider();
  const {
    user: { id, name, nextFreeScratchCard },
  } = useUser();
  const [hasFreeScratchCard, setHasFreeScratchCard] = React.useState(false);

  React.useEffect(() => {
    let hasFreeScratchCard = false;
    if (nextFreeScratchCard !== null) {
      const date = new Date(nextFreeScratchCard);
      hasFreeScratchCard = date <= new Date(syncedTimeProvider());
    }
    setHasFreeScratchCard(hasFreeScratchCard);
  }, [nextFreeScratchCard, syncedTimeProvider]);
  return (
    <div className="flex items-center w-full">
      <div className="flex-shrink-0 w-full px-2">
        <HeaderMenu
          id={id}
          name={name}
          hasFreeScratchCard={hasFreeScratchCard}
          mainScreen={mainScreen}
          topBarEvent={topBarEvent}
          sectionIds={sectionIds}
        >
          {children}
        </HeaderMenu>
      </div>
    </div>
  );
};

export const Header = ({
  menu,
  right,
  children,
  mainScreen,
  sectionIds,
}: React.PropsWithChildren<{
  menu?: React.ReactNode;
  right?: React.ReactNode;
  mainScreen?: boolean;
  sectionIds?: Array<string>;
}>) => {
  const [isVisibleTopBar, setIsVisibleTopBar] = React.useState(!!mainScreen);
  const [isNotMainScreenDesk, setIsNotMainScreenDesk] = React.useState<boolean>(
    false
  );

  React.useEffect(() => {
    const onResize = () => {
      const isScreen = isNotMainScreenDesktopWidth(mainScreen ? "/" : "");

      setIsNotMainScreenDesk(isScreen);
    };
    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  const handleTopBar = (visible: boolean) => {
    setIsVisibleTopBar(visible);
  };

  return (
    <div>
      <div
        className={classNames(
          "flex",
          `${!mainScreen ? "justify-between" : ""}`,
          styles.header
        )}
        style={{ height: isNotMainScreenDesk ? "60px" : "72px" }}
      >
        <div
          className={`flex items-center ${
            mainScreen
              ? isNotMainScreenDesk
                ? "w-full justify-center"
                : "w-1/3"
              : ""
          }`}
        >
          <UserDetails
            mainScreen={mainScreen}
            topBarEvent={handleTopBar}
            sectionIds={sectionIds}
          >
            {menu}
          </UserDetails>
        </div>
        {(!isNotMainScreenDesk || !mainScreen) && (
          <div
            className={`flex items-center ${
              mainScreen ? "w-1/3" : "flex-grow justify-center"
            }`}
          >
            {children}
          </div>
        )}

        <div className={!isNotMainScreenDesk && mainScreen ? "w-1/3" : ""}>
          {right}
        </div>
      </div>
      {isVisibleTopBar && !isNotMainScreenDesk && (
        <Topbar isMainScreen={mainScreen} />
      )}
    </div>
  );
};
