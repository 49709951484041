import * as React from "react";
import { useTranslation } from "react-i18next";
import { PlayerProfile } from "./Profile";
import { Profile } from "./types";
import { AbsoluteCenter } from "../AbsoluteCenter";
import { Loader } from "../Loader";
import { useScreenType } from "../../hooks/useScreenType";
import { useRightBar } from "../Bar/RightBar";
import {
  RIGHT_BAR_MOBILE_SIZE,
  RIGHT_BAR_TABLET_SIZE,
} from "../Bar/RightBar/Bar";

const PlayerProfileContext = React.createContext<
  (profile: Profile, isMainScreen?: boolean) => void
>(() => {
  throw new Error("Provider not found");
});

const DebouncedProfile = ({ children }: { children?: React.ReactNode }) => {
  const [ready, setReady] = React.useState(false);
  React.useEffect(() => {
    const id = window.setTimeout(() => setReady(true), 500);
    return () => window.clearTimeout(id);
  }, []);

  const { t } = useTranslation();

  if (!ready)
    return (
      <AbsoluteCenter>
        <Loader description={t("loading_profile")} />
      </AbsoluteCenter>
    );
  else return <>{children}</>;
};

const Popup = ({
  profile,
  close,
  isMainScreen,
}: {
  profile: Profile;
  close: () => void;
  isMainScreen: boolean;
}) => {
  const { t } = useTranslation();
  const [isRigtBarOpened] = useRightBar();
  const screenType = useScreenType();

  const stylesMap = {
    DESKTOP: { bottom: 0, height: "91%" },
    TABLET: { top: 0, ...RIGHT_BAR_TABLET_SIZE },
    MOBILE: { top: 0, ...RIGHT_BAR_MOBILE_SIZE },
  };

  const adjustedSyle = React.useMemo(() => stylesMap[screenType] || {}, [
    screenType,
  ]);

  return (
    <>
      <div
        className="absolute top-0 bottom-0 left-0 right-0 z-20 bg-black-alpha-30"
        onClick={close}
      />
      <div
        className="absolute top-0 bottom-0 left-0 z-30 w-full h-full"
        aria-label={t("player_profile")}
      >
        <div
          className="w-2/5 bg-blue-800 shadow-2xl"
          style={
            isMainScreen || isRigtBarOpened
              ? { position: "absolute", right: 0, ...adjustedSyle }
              : { height: "100%" }
          }
        >
          <DebouncedProfile key={profile.id}>
            <PlayerProfile profile={profile} close={close} />
          </DebouncedProfile>
        </div>
      </div>
    </>
  );
};

export const PlayerProfileProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [profile, setProfile] = React.useState<Profile | null>(null);
  const [isMainScreen, setIsMainScreen] = React.useState(false);
  const close = React.useCallback(() => setProfile(null), []);
  const setProfileCallback = React.useCallback(
    (profile: Profile, isMainScreen?: boolean) => {
      setIsMainScreen(isMainScreen ?? false);
      return setProfile(profile);
    },
    []
  );

  return (
    <PlayerProfileContext.Provider value={setProfileCallback}>
      {children}
      {profile !== null && (
        <Popup profile={profile} close={close} isMainScreen={isMainScreen} />
      )}
    </PlayerProfileContext.Provider>
  );
};

export const usePlayerProfile = () => {
  return React.useContext(PlayerProfileContext);
};
