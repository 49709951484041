import { useFacebook } from "./Context";
import * as React from "react";

export const useFacebookInvite = (
  title: string,
  href: string
): [boolean, () => void] => {
  const [loaded] = useFacebook();
  const onClick = React.useCallback(
    () =>
      FB.ui({
        method: "share",
        href,
        quote: title,
      }),
    [href, title]
  );

  return [!loaded, onClick];
};
