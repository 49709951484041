import { useUserId } from './../components/UserProvider';
import {
  OthersChatUserListQuery,
  OthersChatUserListQueryVariables,
} from "./../graphql/OthersChatUserListQuery.generated";
import * as React from "react";
import { loader } from "graphql.macro";
import { useClient, CombinedError } from "urql";
import { useIsMounted } from "./useIsMounted";
import { DefaultGenerics, StreamChat } from "stream-chat";

export const CHAT_OTHERS_REQUEST_LIMIT = 20;

export type ChatOthersResponse = {
  hasNextPage: boolean;
  others: ChatOtherType[];
};

export type ChatOtherType = {
  id: string;
  name: string;
  image: string;
};

const OTHER_CHAT_USERS_QUERY = loader(
  "../graphql/OthersChatUserListQuery.graphql"
);

const mergePages = (
  prev: NonNullable<ChatOthersResponse>,
  current: NonNullable<ChatOthersResponse>
) => {
  const existingIds = prev.others.map((others) => others.id);
  for (const friend of current.others)
    if (existingIds.indexOf(friend.id) >= 0) return prev;

  return {
    others: [...prev.others, ...current.others],
    hasNextPage: current.hasNextPage,
  };
};

/*Unfortunately we cannot use relay pagination for these.Due to network refresh
Check out https://github.com/FormidableLabs/urql/issues/672*/
export const useOthersChatUsers = (chatClient: StreamChat<DefaultGenerics>) => {
  const graphQlClient = useClient();
  const [error, setError] = React.useState<CombinedError | undefined>(
    undefined
  );
  const [others, setOthers] = React.useState<ChatOthersResponse | undefined>();
  const isMounted = useIsMounted();
  const fetch = React.useCallback(
    (name: string, offset: number, more: boolean = true) => {
      return graphQlClient
        .query<OthersChatUserListQuery, OthersChatUserListQueryVariables>(
          OTHER_CHAT_USERS_QUERY,
          {
            input: {
              name: name,
              limit: CHAT_OTHERS_REQUEST_LIMIT,
              offset: offset,
            },
          },
          { requestPolicy: "network-only" }
        )
        .toPromise()
        .then(({ data, error }) => {
          if (error !== undefined) {
            throw error;
          } else if ((data?.othersChatUserList ?? null) === null) {
            setOthers(undefined);
          } else if (isMounted.current) {
            const others = data!.othersChatUserList!;
            if (data!.othersChatUserList!.ids.length === 0) {
              setOthers((prev) => {
                if (prev === undefined || prev === null || !more)
                  return { hasNextPage: false, others: [] };
                return mergePages(prev, { hasNextPage: false, others: [] });
              });
            } else {
              chatClient
                .queryUsers({
                  id: {
                    $in: others.ids,
                  },
                })
                .then((chatClientsResponse) => {
                  const chatClients = chatClientsResponse.users.map((user) => ({
                    id: user.id,
                    name: user.name ?? "",
                    image: typeof user.image === "string" ? user.image : "",
                  }));
                  const current = {
                    hasNextPage: data!.othersChatUserList!.hasNextPage,
                    others: chatClients,
                  };
                  setOthers((prev) => {
                    if (prev === undefined || prev === null || !more)
                      return current;
                    return mergePages(prev, current);
                  });
                });
            }
          }
        })
        .catch((error) => {
          if (isMounted.current) setError(error);
        });
    },
    [graphQlClient, isMounted, chatClient]
  );

  React.useEffect(() => {
    fetch("", 0);
  }, [fetch]);

  if (error !== undefined) throw error;

  return React.useMemo(
    () => ({
      others: others,
      fetch: fetch,
    }),
    [fetch, others]
  );
};
