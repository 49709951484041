import { loader } from "graphql.macro";
import { Cache, Data, Variables } from "@urql/exchange-graphcache";
import { PendingFriendRequestsQueryQuery } from "../../graphql/PendingFriendRequestsQuery.generated";
import { AnswerFriendRequestMutation } from "../../graphql/AnswerFriendRequestMutation.generated";
import { FriendsQueryQuery } from "../../graphql/FriendsQuery.generated";

const PENDING_FRIEND_REQUESTS_QUERY = loader(
  "../../graphql/PendingFriendRequestsQuery.graphql"
);

const FRIENDS_QUERY = loader("../../graphql/FriendsQuery.graphql");

const PLAYER_FRIENDSHIP_FRAGMENT = loader(
  "../../graphql/PlayerFriendshipFragment.graphql"
);

export const answerFriendRequest = (
  result: Data,
  args: Variables,
  cache: Cache
) => {
  const { fromUser: userId } = args;
  cache.updateQuery<PendingFriendRequestsQueryQuery>(
    { query: PENDING_FRIEND_REQUESTS_QUERY },
    (data) => {
      if (data !== null) {
        (data as PendingFriendRequestsQueryQuery).me!.pendingFriendRequests = (data as PendingFriendRequestsQueryQuery).me!.pendingFriendRequests.filter(
          (friendRequest) => friendRequest.from.id !== userId
        );
      }
      return data;
    }
  );
  if (result !== null) {
    const friendShip = ((result as unknown) as AnswerFriendRequestMutation)
      .answerFriendRequest;
    if (friendShip !== null) {
      cache.updateQuery<FriendsQueryQuery>(
        { query: FRIENDS_QUERY, variables: { after: "" } },
        (data) => {
          if (data !== null) {
            const friends = (data as FriendsQueryQuery).me!.friends;
            friends.edges.unshift({
              __typename: "FriendShipEdge",
              node: friendShip,
            });
          }
          return data;
        }
      );
      cache.writeFragment(PLAYER_FRIENDSHIP_FRAGMENT, {
        id: friendShip.userB.id,
        friendship: {
          canAskForFriendship: false,
          areFriends: true,
          __typename: "FriendshipType",
        },
        __typename: "Player",
      });
    }
  }
};
