import * as React from "react";
import { useTranslation } from "react-i18next";
import { loader } from "graphql.macro";
import styles from "./SendGift.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import {
  VirtualItemsQuery,
  VirtualItemsQueryVariables,
} from "../../Games/common/game/SendGift/graphql/VirtualItemsQuery.generated";
import { Currency } from "../../../graphql/generated";
import classNames from "classnames";
import { VirtualItem } from "../../Games/common/game/VirtualItem";
import { Amount } from "../../Amount";
import { useQuery } from "../../../hooks/useQuery";
import { useUser } from "../../UserProvider";
import { AbsoluteCenter } from "../../AbsoluteCenter";
import { Loader } from "../../Loader";
import { ButtonWithSound } from "../../AudioProvider";

const VIRTUAL_ITEMS_QUERY = loader("../../Games/common/game/SendGift/graphql/VirtualItemsQuery.graphql");

const VirtualItemContainer = ({
  active,
  name,
  logo,
  setActive,
  amount,
}: {
  active: boolean;
  name: string;
  logo: string;
  amount: {
    value: number;
    currency: Currency;
  };
  setActive: () => void;
}) => {
  return (
    <button
      className={classNames(
        "w-full",
        "p-4",
        "rounded",
        "border-2",
        "bg-blue-900",
        "shadow",
        "transition-colors",
        "duration-300",
        "ease-in-out",
        { "border-orange-500": active },
        { "border-blue-900": !active }
      )}
      onClick={setActive}
    >
      <div className="flex items-center justify-center mb-3">
        <VirtualItem logo={logo} name={name} />
      </div>
      <Amount value={amount.value} currency={amount.currency} />
    </button>
  );
};

const SendGiftContent = ({
  sendGift,
}: {
  sendGift: ({item, logo}: {item: string; logo: string}) => void;
}) => {
  const [{ data }] = useQuery<VirtualItemsQuery, VirtualItemsQueryVariables>({
    query: VIRTUAL_ITEMS_QUERY,
  });

  const { t } = useTranslation();
  const [sending, setSending] = React.useState(false);
  const [active, setActive] = React.useState<
    NonNullable<VirtualItemsQuery["virtualItems"]>[0]
  >();
  const {
    user: { balances },
  } = useUser();
  const buttonDisabled = React.useMemo(() => {
    if (active === undefined) return true;
    const {
      amount: { currency, value },
    } = active;
    return (
      balances.find(
        (balance) => balance.currency === currency && balance.value >= value
      ) === undefined
    );
  }, [active, balances]);
  const virtualItems = data?.virtualItems ?? null;
  if (virtualItems === null)
    return (
      <AbsoluteCenter>
        <Loader description={t("loading_items")} />
      </AbsoluteCenter>
    );
  else if (virtualItems.length === 0)
    return (
      <AbsoluteCenter>
        <div className="p-4 text-center rounded bg-red-700">
          <span className="text-white font-bold block">
            {t("no_virtual_items_configured")}
          </span>
        </div>
      </AbsoluteCenter>
    );
  return (
    <>
      <div className="flex flex-col h-full">
        <div
          className={classNames("flex-1", "overflow-auto", styles.scrollable)}
        >
          <div className="overflow-hidden">
            <div className="flex flex-wrap -mb-4 -mx-2">
              {virtualItems.map((item) => (
                <div className="w-1/2 px-2 mb-4" key={item.id}>
                  <VirtualItemContainer
                    active={active !== undefined && active.id === item.id}
                    setActive={() => setActive(item)}
                    {...item}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex-shrink-0 mt-4">
          <ButtonWithSound
            className="btn btn-orange-600 w-full rounded"
            disabled={buttonDisabled || sending}
            onClick={() => {
              setSending(true);
              sendGift({item: active!.id, logo: active!.logo});
              setSending(false);
            }}
          >
            {t("send")}
          </ButtonWithSound>
        </div>
      </div>
    </>
  );
};

export const SendGiftModal = ({
  name,
  onSendGift,
  close,
}: {
  name: string;
  onSendGift: ({item, logo}: {item: string; logo: string}) => void;
  close: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className="fixed bg-black-alpha-30 top-0 left-0 right-0 bottom-0 z-1000"
        onClick={close}
      />
      <div
        className={classNames("p-4 absolute shadow-2xl bg-blue-800 top-0 right-0 bottom-0 z-30", styles.modalWrapper)}
        aria-label={t("send_gift", { user: name })}
      >
        <div className="flex flex-col h-full">
          <div className="flex items-center justify-between text-white flex-shrink-0 mb-4 -mx-2">
            <span className="font-bold text-lg truncate px-2">
              {t("send_gift", { user: name })}
            </span>
            <div className="px-2 flex-shrink-0">
              <button onClick={close}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
          <div className="flex-1 overflow-hidden relative">
            <SendGiftContent
              sendGift={({item, logo}) => onSendGift({item, logo})}
            />
          </div>
        </div>
      </div>
    </>
  );
};
