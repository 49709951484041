import * as React from "react";
import { useTranslation } from "react-i18next";

export function TournamentInfo({ config }: { config: any }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex justify-between -mx-2 py-1">
        <div className="px-2">{t("type")}</div>
        <div className="px-2">{t(`game.hangman`)}</div>
      </div>
    </>
  );
}
