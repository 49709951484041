import * as React from "react";
import {
  DESKTOP_MIN_WIDTH,
  screenType,
  TABLET_MIN_WIDTH,
} from "../other/screenType";

export const useScreenType = () => {
  const getScreenType = React.useCallback(
    () =>
      document.documentElement.clientWidth < DESKTOP_MIN_WIDTH
        ? document.documentElement.clientWidth < TABLET_MIN_WIDTH
          ? "MOBILE"
          : "TABLET"
        : "DESKTOP",
    []
  );
  const [type, setType] = React.useState<screenType>(getScreenType());

  React.useEffect(() => {
    const onResize = () => {
      setType(getScreenType);
    };
    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  return type;
};
