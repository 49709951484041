import * as React from "react";
import { loader } from "graphql.macro";
import { useClient, CombinedError } from "urql";
import { useIsMounted } from "../useIsMounted";
import {
  TransactionsQueryQuery,
  TransactionsQueryQueryVariables,
} from "./graphql/TransactionsQuery.generated";

type TransactionsType = NonNullable<
  TransactionsQueryQuery["me"]
>["transactions"];
const TRANSACTIONS_QUERY = loader("./graphql/TransactionsQuery.graphql");

const mergePages = (prev: TransactionsType, current: TransactionsType) => {
  const existingIds = new Set<string>(prev.edges.map(({ node }) => node.id));
  for (const edge of current.edges)
    if (existingIds.has(edge.node.id)) return prev;

  return {
    edges: [...prev.edges, ...current.edges],
    pageInfo: current.pageInfo,
    __typename: current.__typename,
  };
};

/*Unfortunately we cannot use relay pagination for these.Due to network refresh
Check out https://github.com/FormidableLabs/urql/issues/672*/
export const useTransactions = () => {
  const client = useClient();
  const [error, setError] = React.useState<CombinedError | undefined>(
    undefined
  );
  const [transactions, setTransactions] = React.useState<
    TransactionsType | undefined
  >();
  const isMounted = useIsMounted();
  const fetchMore = React.useCallback(
    (cursor: string) => {
      return client
        .query<TransactionsQueryQuery, TransactionsQueryQueryVariables>(
          TRANSACTIONS_QUERY,
          { after: cursor },
          { requestPolicy: "network-only" }
        )
        .toPromise()
        .then(({ data, error }) => {
          if (error !== undefined) {
            throw error;
          } else if ((data?.me ?? null) === null) {
            setTransactions(undefined);
          } else if (isMounted.current) {
            const transactions = data!.me!.transactions;
            setTransactions((prev) => {
              if (prev === undefined) return transactions;
              return mergePages(prev, transactions);
            });
          }
        })
        .catch((error) => {
          if (isMounted.current) setError(error);
        });
    },
    [client, isMounted]
  );

  React.useEffect(() => {
    fetchMore("");
  }, [fetchMore]);

  if (error !== undefined) throw error;

  return React.useMemo(
    () => ({
      transactions,
      fetchMore,
    }),
    [fetchMore, transactions]
  );
};
