import * as React from "react";
import classNames from "classnames";
import { isFunction } from "../utils";
import { useSizeObserve } from "../hooks/useSizeObserve";

export const Layout = ({
  children,
  className,
  header,
  ...rest
}: {
  header?: React.ReactNode;
  children: ((rect: DOMRect | null) => React.ReactNode) | React.ReactNode;
} & JSX.IntrinsicElements["div"]) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const rect = useSizeObserve(ref, isFunction(children));
  return (
    <div className="flex flex-col h-full">
      {header !== undefined && <div className="flex-shrink-0">{header}</div>}
      <div
        className={classNames(
          "flex-1",
          "overflow-hidden",
          "relative",
          className
        )}
        ref={ref}
        {...rest}
      >
        {isFunction(children) ? children(rect) : children}
      </div>
    </div>
  );
};
