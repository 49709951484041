import * as React from "react";
import { useTranslation } from "react-i18next";
import { formatDistanceToNow } from "date-fns";
import { SUPPORTED_LOCALES } from "../config";

type options = Omit<Parameters<typeof formatDistanceToNow>[1], "locale">;

const getLocale = (locale: string): Locale =>
  require(`date-fns/locale/${locale}/index.js`);

export const useRelativeTimeFormatter = () => {
  const { i18n } = useTranslation();
  return React.useMemo(
    () => ({
      format: (date: Date, options: options = {}) =>
        formatDistanceToNow(date, {
          ...options,
          locale: getLocale(SUPPORTED_LOCALES[i18n.language].dateFns),
        }),
    }),
    [i18n.language]
  );
};
