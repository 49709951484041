import * as React from "react";
import { _ReactPixi, Stage, useApp } from "@inlet/react-pixi";
import { gsap } from "gsap";

const MergeTickers = ({ children }: { children?: React.ReactNode }) => {
  const app = useApp();
  React.useLayoutEffect(() => {
    app.ticker.stop();
    const update = () => app.ticker.update();
    gsap.ticker.add(update);
    return () => {
      gsap.ticker.remove(update);
    };
  }, [app]);
  return <>{children}</>;
};

export const PixiStage = ({ children, ...rest }: _ReactPixi.IStage) => {
  return (
    <Stage {...rest} raf={false} renderOnComponentChange={false}>
      <MergeTickers>{children}</MergeTickers>
    </Stage>
  );
};
