import * as React from "react";
import { useTranslation } from "react-i18next";
import { AbsoluteCenter } from "../AbsoluteCenter";
import { ButtonWithSound, LinkWithSound } from "../AudioProvider";
import styles from "./Register.module.scss";
import Portal from "@reach/portal";

const Context = React.createContext<{ openRegisterPopup: () => void }>(
  undefined as any
);

const RegisterPopup = ({ close }: { close: () => void }) => {
  const { t } = useTranslation();

  return (
    <Portal>
      <div className="absolute bg-black-alpha-30 top-0 left-0 right-0 bottom-0 z-55">
        <AbsoluteCenter>
          <div aria-label={t("register")} className={styles.RegisterWindow}>
            <ButtonWithSound
              className={styles.CloseRegisterPopup}
              onClick={close}
            />
            <div className={styles.RegisterWindowText}>
              {t("please_register")}
            </div>
            <LinkWithSound to={"/login"} onClick={close}>
              <div className={styles.RegisterButton}>
                <div className={styles.RegisterText}>{t("register")}</div>
              </div>
            </LinkWithSound>
          </div>
        </AbsoluteCenter>
      </div>
    </Portal>
  );
};

let key = 0;

export function RegisterProvider({ children }: { children?: React.ReactNode }) {
  const [isOpen, setOpen] = React.useState(false);

  const openRegisterPopup = React.useCallback(() => setOpen(true), []);
  const close = React.useCallback(() => {
    key++;
    setOpen(false);
  }, []);

  return (
    <Context.Provider value={{ openRegisterPopup: openRegisterPopup }}>
      {children}
      {isOpen && <RegisterPopup close={close} key={key} />}
    </Context.Provider>
  );
}

export function useRegisterPopup() {
  return React.useContext(Context);
}
