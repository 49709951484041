import { MpirimpaPlayers } from "../../../graphql/generated";

export const getEntryFee = (players: MpirimpaPlayers, bet: number) => {
  let teams;
  let playersPerTeam;
  switch (players) {
    case MpirimpaPlayers.OneVsOne:
      teams = 2;
      playersPerTeam = 1;
      break;
    case MpirimpaPlayers.TwoVsTwo:
      teams = 2;
      playersPerTeam = 2;
      break;
  }

  return bet * ((teams - 1) * playersPerTeam);
};

export const getRequiredPlayers = (players: MpirimpaPlayers) =>
  players === MpirimpaPlayers.OneVsOne ? 2 : 4;

export const betByPoints = (array: Array<{ maxPoints: number; minBet: number }>, pointsValue: number) => {
  const availableRanges = array.filter(e => e.maxPoints >= pointsValue);
  return availableRanges[0].minBet
}
