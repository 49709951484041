import * as React from "react";
import { useTranslation } from "react-i18next";
import { AbsoluteCenter } from "../AbsoluteCenter";
import { ButtonWithSound } from "../AudioProvider";
import { gql } from "graphql.macro";
import {
  UserToogleAgreementGetEmailMutation,
  UserToogleAgreementGetEmailMutationVariables,
} from "./index.generated";
import { useMutation } from "../../hooks/useMutation";
import { useUser } from "../UserProvider";
import { useAskSendEmails } from ".";
import { useScreenType } from "../../hooks/useScreenType";

const SET_SEND_EMAIL_MUTATION = gql`
  mutation userToogleAgreementGetEmail($agreement: Boolean!) {
    userToogleAgreementGetEmail(agreement: $agreement)
  }
`;

const PopupContainer = ({ children }: { children?: React.ReactNode }) => {
  const screenType = useScreenType();

  return (
    <div className="absolute bg-black-alpha-30 top-0 left-0 right-0 bottom-0 z-50">
      {screenType === "DESKTOP" ? (
        <AbsoluteCenter>{children}</AbsoluteCenter>
      ) : (
        <div className="flex justify-center items-center h-screen">
          {children}
        </div>
      )}
    </div>
  );
};

const Popup = () => {
  const [, setSendEmail] = useMutation<
    UserToogleAgreementGetEmailMutation,
    UserToogleAgreementGetEmailMutationVariables
  >(SET_SEND_EMAIL_MUTATION);

  const { t } = useTranslation();
  const {
    setDoAskSendEmailsOnLogin,
    setOpenAskSendEmailsPopup,
  } = useAskSendEmails();

  return (
      <PopupContainer>
        <div
          aria-label={t("topup")}
          className="w-1/3 p-4 rounded shadow-xl bg-blue-800 border-blue-900 border-2"
          style={{ minWidth: 300 }}
        >
          <>
            <div className="text-center text-white font-bold mb-2">
              {t("send_email_instructions")}
            </div>
            <div className="flex -mx-2 mt-4">
              <div className="flex-1 px-2">
                <ButtonWithSound
                  onClick={() =>
                    setSendEmail({ agreement: true }).then(() => {
                      setDoAskSendEmailsOnLogin(false);
                      setOpenAskSendEmailsPopup(false);
                    })
                  }
                  className="btn btn-green-600 btn-sm w-full rounded"
                >
                  {t("submit")}
                </ButtonWithSound>
              </div>
              <div className="flex-1 px-2">
                <ButtonWithSound
                  className="btn btn-gray-500 btn-sm w-full rounded"
                  onClick={() => {
                    setSendEmail({ agreement: false }).then(() => {
                      setDoAskSendEmailsOnLogin(false);
                      setOpenAskSendEmailsPopup(false);
                    });
                  }}
                >
                  {t("cancel")}
                </ButtonWithSound>
              </div>
            </div>
          </>
        </div>
      </PopupContainer>
  );
};

export function SendEmailPopup() {
  const { user } = useUser();
  const { doAskSendEmailsOnLogin, openAskSendEmailsPopup } = useAskSendEmails();

  if (
    (user.requestAgreement && doAskSendEmailsOnLogin) ||
    openAskSendEmailsPopup
  ) {
    return <Popup />;
  } else {
    return null;
  }
}
