import { tableData } from "./tableData";
import { CreateTable } from "./CreateTable";
import * as React from "react";
import { TournamentInfo } from "./TournamentInfo";

const GameComponent = React.lazy(() => import("./Game"));

const GameData = {
  tictactoe: {
    tableData,
    CreateTableComponent: CreateTable,
    GameComponent,
    TournamentInfoComponent: TournamentInfo,
  },
};
export default GameData;
