import * as React from "react";
import { useHotKeys } from "../../hooks/useHotKeys";
import { DevelopmentLogin } from "./DevelopmentLogin";
import { ProductionLogin } from "./ProductionLogin";

const TOGGLE_HOTKEY = "ctrl+y";

export const Login = () => {
  const [devLogin, setDevLogin] = React.useState(false);

  useHotKeys(
    TOGGLE_HOTKEY,
    React.useCallback(() => setDevLogin((prev) => !prev), [])
  );

  return devLogin ? <DevelopmentLogin /> : <ProductionLogin />;
};
