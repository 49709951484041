import classNames from "classnames";
import React, { PropsWithChildren } from "react";

import {
  ChannelListMessengerProps,
  ChatDown,
  LoadingChannels,
} from "stream-chat-react";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { ErrorBoundary } from "../ErrorBoundary";
import { useUser } from "../UserProvider";
import { ChannelTypeSelect } from "./ChannelList";

const CustomChannelListMessenger = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  props: PropsWithChildren<ChannelListMessengerProps<StreamChatGenerics>> & {channelType: ChannelTypeSelect}
) => {
  const {
    children,
    error = null,
    loading,
    LoadingErrorIndicator = ChatDown,
    LoadingIndicator = LoadingChannels,
    channelType
  } = props;

  const {user } =  useUser();
  const isVip =
    user.vipUntil && new Date(user.vipUntil).getTime() > new Date().getTime();
  const notAllowedAddChat = !isVip && channelType === "direct_others";

  if (error) {
    return <LoadingErrorIndicator type="Connection Error" />;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <div className={classNames(notAllowedAddChat ? "chat-list-container-not-vip" : "chat-list-container")}>
        <div aria-label="Channel list" className="list-box" role="listbox">
          <ErrorBoundary
            onError={() => {
              //TODO it is temporary fix of issue which happens when user creates chat with friend and then do unfriend
              //the cause of issue was not found
            }}
          >
            {children}
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};
export default CustomChannelListMessenger;
