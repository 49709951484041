import * as React from "react";
import { useTranslation } from "react-i18next";
import { AbsoluteCenter } from "../AbsoluteCenter";
import { ButtonWithSound } from "../AudioProvider";

const Context = React.createContext<{ openForbidJoinGamePopup: () => void }>(
  undefined as any
);

const Popup = ({ close }: { close: () => void }) => {
  const { t } = useTranslation();

  return (
    <div className="absolute bg-black-alpha-30 top-0 left-0 right-0 bottom-0 z-50">
      <AbsoluteCenter>
        <div
          aria-label={t("topup")}
          className="w-1/3 p-4 rounded shadow-xl bg-blue-800 border-blue-900 border-2"
        >
          {
            <>
              <div className="text-center text-white font-bold mb-2">
                {t("table_has_banned_relations")}
              </div>

              <div className="flex -mx-2 mt-4">
                <div className="flex-1 px-2">
                  <ButtonWithSound
                    className="btn btn-gray-500 btn-sm w-full rounded"
                    onClick={close}
                  >
                    {t("ok")}
                  </ButtonWithSound>
                </div>
              </div>
            </>
          }
        </div>
      </AbsoluteCenter>
    </div>
  );
};

let key = 0;

export function JoinTablePopupProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [isOpen, setOpen] = React.useState(false);

  const openForbidJoinGamePopup = React.useCallback(() => setOpen(true), []);
  const close = React.useCallback(() => {
    key++;
    setOpen(false);
  }, []);

  return (
    <Context.Provider value={{ openForbidJoinGamePopup }}>
      {children}
      {isOpen && <Popup close={close} key={key} />}
    </Context.Provider>
  );
}

export function useJoinTablePopup() {
  return React.useContext(Context);
}
