import React from "react";
import classNames from "classnames";
import styles from "./ProductionLogin/Login.module.scss";

type SubmitButtonType = {
  name: string;
  onClick: () => void;
};

const SubmitButton = ({ name, onClick }: SubmitButtonType) => {
  return (
    <button type="submit" className={styles.loginButton} onClick={onClick}>
      {name}
    </button>
  );
};
export default SubmitButton;
