import { Cache, Data, Variables } from "@urql/exchange-graphcache";
import { loader } from "graphql.macro";
import { MeQueryQuery } from "../../graphql/MeQuery.generated";
import {
  ActivityUpdateFragmentFragment,
  BalancesUpdateFragmentFragment,
  NextFreeScratchCardUpdateFragmentFragment,
  TournamentParticipationUpdateFragmentFragment,
  ChatConfigUpdateFragmentFragment,
} from "../../graphql/MeSubscription.generated";
import { NotificationsQueryQuery } from "../../graphql/NotificationsQuery.generated";
import {
  FriendRequestAcceptedActivityContentFragmentFragment,
  FriendRequestActivityContentFragmentFragment,
  FriendshipCanceledActivityContentFragmentFragment,
} from "../../graphql/ActivityFragment.generated";
import { PendingFriendRequestsQueryQuery } from "../../graphql/PendingFriendRequestsQuery.generated";
import { FriendsQueryQuery } from "../../graphql/FriendsQuery.generated";

const ME_QUERY = loader("../../graphql/MeQuery.graphql");
const NOTIFICATIONS_QUERY = loader("../../graphql/NotificationsQuery.graphql");
const BALANCES_FRAGMENT = loader("../../graphql/BalancesFragment.graphql");
const PLAYER_FRIENDSHIP_FRAGMENT = loader(
  "../../graphql/PlayerFriendshipFragment.graphql"
);

const PENDING_FRIEND_REQUESTS_QUERY = loader(
  "../../graphql/PendingFriendRequestsQuery.graphql"
);

const FRIENDS_QUERY = loader("../../graphql/FriendsQuery.graphql");

const isBalancesUpdate = (data: {
  __typename?: string;
}): data is BalancesUpdateFragmentFragment => {
  return (
    (data as BalancesUpdateFragmentFragment).__typename === "BalancesUpdate"
  );
};

const isNextFreeScratchCardUpdate = (data: {
  __typename?: string;
}): data is NextFreeScratchCardUpdateFragmentFragment => {
  return (
    (data as NextFreeScratchCardUpdateFragmentFragment).__typename ===
    "NextFreeScratchCardUpdate"
  );
};

const isActivityUpdate = (data: {
  __typename?: string;
}): data is ActivityUpdateFragmentFragment => {
  return (
    (data as ActivityUpdateFragmentFragment).__typename === "ActivityUpdate"
  );
};

const isTournamentParticipationUpdate = (data: {
  __typename?: string;
}): data is TournamentParticipationUpdateFragmentFragment => {
  return (
    (data as TournamentParticipationUpdateFragmentFragment).__typename ===
    "TournamentParticipationUpdate"
  );
};

const isFriendRequestActivity = (data: {
  __typename?: string;
}): data is FriendRequestActivityContentFragmentFragment => {
  return (
    (data as FriendRequestActivityContentFragmentFragment).__typename ===
    "FriendRequestActivityContent"
  );
};

const isFriendRequestAcceptedActivity = (data: {
  __typename?: string;
}): data is FriendRequestAcceptedActivityContentFragmentFragment => {
  return (
    (data as FriendRequestAcceptedActivityContentFragmentFragment)
      .__typename === "FriendRequestAcceptedActivityContent"
  );
};

const isFriendshipCanceledActivity = (data: {
  __typename?: string;
}): data is FriendshipCanceledActivityContentFragmentFragment => {
  return (
    (data as FriendshipCanceledActivityContentFragmentFragment).__typename ===
    "FriendshipCanceledActivityContent"
  );
};

const isChatConfigUpdate = (data: {
  __typename?: string;
}): data is ChatConfigUpdateFragmentFragment => {
  return (
    (data as ChatConfigUpdateFragmentFragment).__typename === "ChatConfigUpdate"
  );
};

export const me = (result: Data, args: Variables, cache: Cache) => {
  const { userData } = result;
  if (isBalancesUpdate(userData as any)) {
    const { userId, balances } = userData as BalancesUpdateFragmentFragment;
    cache.writeFragment(BALANCES_FRAGMENT, {
      id: userId,
      balances,
      __typename: "User",
    });
  } else if (isNextFreeScratchCardUpdate(userData as any)) {
    cache.updateQuery<MeQueryQuery>({ query: ME_QUERY }, (data) => {
      (data as MeQueryQuery).me!.nextFreeScratchCard = (userData as NextFreeScratchCardUpdateFragmentFragment).nextFreeScratchCard;
      return data;
    });
  } else if (isChatConfigUpdate(userData as any)) {
    cache.updateQuery<MeQueryQuery>({ query: ME_QUERY }, (data) => {
      (data as MeQueryQuery).me!.isChatEnabled = (userData as ChatConfigUpdateFragmentFragment).isChatEnabled;
      return data;
    });
  } else if (isTournamentParticipationUpdate(userData as any)) {
    cache.updateQuery<MeQueryQuery>({ query: ME_QUERY }, (data) => {
      const updatedParticipation = userData as TournamentParticipationUpdateFragmentFragment;
      (data as MeQueryQuery).me!.tournamentParticipations = (data as MeQueryQuery).me!.tournamentParticipations.map(
        (p) => {
          if (p.id === updatedParticipation.participationId)
            return {
              ...p,
              gamesRemaining: updatedParticipation.gamesRemaining,
            };
          else return p;
        }
      );
      return data;
    });
  } else if (isActivityUpdate(userData as any)) {
    const activityUpdate = userData as ActivityUpdateFragmentFragment;
    const updateFriendShipFragment = (
      canAskForFriendship: boolean,
      areFriends: boolean
    ) => {
      cache.writeFragment(PLAYER_FRIENDSHIP_FRAGMENT, {
        id: activityUpdate.activity.actor!.id,
        friendship: {
          canAskForFriendship,
          areFriends,
          __typename: "FriendshipType",
        },
        __typename: "Player",
      });
    };
    if (isFriendRequestActivity(activityUpdate.activity.content)) {
      updateFriendShipFragment(false, false);
      cache.updateQuery<PendingFriendRequestsQueryQuery>(
        { query: PENDING_FRIEND_REQUESTS_QUERY },
        (data) => {
          if (data !== null) {
            const pendingFriendRequests = (data as PendingFriendRequestsQueryQuery)
              .me!.pendingFriendRequests;
            pendingFriendRequests.unshift({
              from: activityUpdate.activity.actor!,
              created: (activityUpdate.activity
                .content as FriendRequestActivityContentFragmentFragment)
                .initialized,
              __typename: "FriendRequest",
            });
          }
          return data;
        }
      );
    } else if (
      isFriendRequestAcceptedActivity(activityUpdate.activity.content)
    ) {
      updateFriendShipFragment(false, true);
    } else if (isFriendshipCanceledActivity(activityUpdate.activity.content)) {
      updateFriendShipFragment(true, false);
      cache.updateQuery<FriendsQueryQuery>(
        { query: FRIENDS_QUERY, variables: { after: "" } },
        (data) => {
          if (data !== null)
            (data as FriendsQueryQuery).me!.friends.edges = (data as FriendsQueryQuery).me!.friends.edges.filter(
              (edge) => edge.node.userB.id !== activityUpdate.activity.actor!.id
            );
          return data;
        }
      );
    }
    cache.updateQuery<NotificationsQueryQuery>(
      { query: NOTIFICATIONS_QUERY, variables: { after: "" } },
      (data) => {
        //Data can be null if query has not been loaded yet
        if (data !== null) {
          const activities = (data as NotificationsQueryQuery).me!.activities;
          activities.edges.unshift({
            __typename: "ActivityEdge",
            node: activityUpdate.activity,
          });
        }
        return data;
      }
    );
  }
};
