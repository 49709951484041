import * as React from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import { useFacebookInvite } from "../Facebook";
import { useTranslation } from "react-i18next";

const ICON_SIZE = 48;

export const ShareButton = ({ redirectUrl }: { redirectUrl: string }) => {
  const { t } = useTranslation();
  const title = t("social_invitation.title");
  const [disabled, onClick] = useFacebookInvite(title, redirectUrl);
  return (
    <div className="flex justify-between">
      <button onClick={onClick} disabled={disabled}>
        <FacebookIcon size={ICON_SIZE} className="rounded" />
      </button>

      <TwitterShareButton url={redirectUrl} title={title}>
        <TwitterIcon size={ICON_SIZE} className="rounded" />
      </TwitterShareButton>

      <ViberShareButton url={redirectUrl} title={title} separator=" ">
        <ViberIcon size={ICON_SIZE} className="rounded" />
      </ViberShareButton>

      <WhatsappShareButton url={redirectUrl} title={title} separator=" ">
        <WhatsappIcon size={ICON_SIZE} className="rounded" />
      </WhatsappShareButton>

      <TelegramShareButton url={redirectUrl} title={title}>
        <TelegramIcon size={ICON_SIZE} className="rounded" />
      </TelegramShareButton>

      <EmailShareButton
        url={redirectUrl}
        subject={title}
        body={t("social_invitation.mail_body")}
        separator=" "
      >
        <EmailIcon size={ICON_SIZE} className="rounded" />
      </EmailShareButton>
    </div>
  );
};
