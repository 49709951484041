import * as React from "react";
import { ProfileImage } from "../../../ProfileImage";
import { useCustomChatContext, useUser } from "../../../UserProvider";
import { useGuestId } from "../../../../hooks/useGuestId";
import { Link } from "react-router-dom";
import { CONVERT_GUEST_PATH } from "../../../../guestId";
import { useFacebook } from "../../../Facebook";
import { useTranslation } from "react-i18next";
import { StreamChat } from "stream-chat";
import { useAuthToken } from "../../../../hooks/useAuthToken";
import styles from "./Bar.module.scss";
import { useRightBar } from "..";
import { useShop } from "../../../Shop";
import shopIcon from "../../../../assets/shop.svg";
import chatIcon from "../../../../assets/message.svg";
import facebookChatIcon from "../../../../assets/facebook-messenger.svg";
import exitIcon from "../../assets/exitButton.svg";
import notificationIcon from "../../../../assets/bell.svg";
import helpIcon from "../../../../assets/helpIcon.svg";
import competition from "../../assets/competition.jpg";
import freeChipsImage from "../../assets/scratchCards.jpg";
import refferFriend from "../../assets/refferFriend.jpg";
import myFriends from "../../assets/myFriends.jpg";
import banned from "../../assets/banned.jpg";
import transactions from "../../assets/transactions.jpg";
import topUp from "../../assets/topUp.jpg";
import { Currency } from "../../../../graphql/generated";
import { useNotificationsPanel } from "../../../Notifications";
import { useNotifications } from "../../../../hooks/useNotifications";
import { HELP_URL } from "../../../../config";
import { useTournamentsPanel } from "../../../Tournaments";
import { useInvitationsPanel } from "../../../Invitations";
import { useFriendsPanel } from "../../../Friends";
import { useBannedPlayersPanel } from "../../../BannedPlayers";
import { useTransactionsPanel } from "../../../Transactions";
import { useTopupPanel } from "../../../Topup";
import { useScreenType } from "../../../../hooks/useScreenType";
import { usePlayerProfile } from "../../../PlayerProfile";
import { GAME_IDS } from "../../../Games";
import { useRegisterPopup } from "../../../Register";
import { LIKE_BUTTON_HEIGHT } from "../../../../other/screenType";
import { useScreenState } from "../../../ScreenState";
import { calculateNextFreeScratchCardTime, countDown } from "../../Topbar";
import { useSyncedTimeProvider } from "../../../SyncedTimeProvider";
import { useFreeChips } from "../../../FreeChips";
import { useVipInstructionPopup } from "../../../VipInformation";
import vipIcon from "../../../../assets/vip-popup.png";
import classNames from "classnames";

export const RIGHT_BAR_TABLET_SIZE = {
  width: 410,
  height: `calc(100vh - ${LIKE_BUTTON_HEIGHT}px)`,
};
export const RIGHT_BAR_MOBILE_SIZE = {
  width: "100%",
  height: "100vh",
};

const LogOut = () => {
  const { t } = useTranslation();
  const apiKey = `${process.env.REACT_APP_CHAT_TOKEN}`;
  const chatClient = StreamChat.getInstance(apiKey);
  const [, , reset] = useAuthToken();
  const [, , , , , clearIsLoggedAsGuest] = useGuestId();

  return (
    <div
      className={styles.logout}
      onClick={() => {
        reset();
        clearIsLoggedAsGuest();
        chatClient.disconnectUser();
      }}
    >
      {t("logout")}
    </div>
  );
};

const Register = () => {
  const { t } = useTranslation();
  return (
    <Link to={CONVERT_GUEST_PATH} className={styles.register}>
      {t("register")}
    </Link>
  );
};

const Bottom = () => {
  const [, , , getIsLoggedAsGuest] = useGuestId();
  const isLoggedAsGuest = getIsLoggedAsGuest();
  const [, isFacebookCanvas] = useFacebook();

  return (
    <div className={styles.bottom}>
      {isLoggedAsGuest && <Register />}
      {!isFacebookCanvas && <LogOut />}
    </div>
  );
};

const BigButton = ({
  image,
  text,
  onClick,
}: {
  image: string;
  text: string;
  onClick: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div onClick={onClick} className={styles.bigButton}>
      <img src={image} alt={text} className={styles.bigButtonIcon} />
      <p className={styles.bigButtonText}>{t(text)}</p>
    </div>
  );
};

const TopUp = () => {
  const openTopup = useTopupPanel();

  return <BigButton image={topUp} text="topup" onClick={openTopup} />;
};

const Transactions = () => {
  const openTransactions = useTransactionsPanel();
  return (
    <BigButton
      image={transactions}
      text="transactions"
      onClick={() => openTransactions(true)}
    />
  );
};

const BannedPlayers = () => {
  const openBannedPlayers = useBannedPlayersPanel();

  return (
    <BigButton
      image={banned}
      text="banned_players"
      onClick={() => openBannedPlayers(true)}
    />
  );
};

const MyFriends = () => {
  const openFriends = useFriendsPanel();
  return (
    <BigButton
      image={myFriends}
      text="friends"
      onClick={() => openFriends(true)}
    />
  );
};

const InviteFriends = () => {
  const openInvitations = useInvitationsPanel();

  return (
    <BigButton
      image={refferFriend}
      text="invitations"
      onClick={() => openInvitations(true)}
    />
  );
};

const FreeChips = () => {
  const { t } = useTranslation();
  const [, , closeRightBar] = useRightBar();
  const [, resetScreenState] = useScreenState();
  const openFreeChips = useFreeChips();
  const syncedTimeProvider = useSyncedTimeProvider();
  const {
    user: { nextFreeScratchCard },
  } = useUser();
  const openFreeChipsClick = React.useCallback(() => {
    closeRightBar();
    resetScreenState();
    openFreeChips();
  }, [openFreeChips]);

  const [nextFreeScratchCardTime, setNextFreeScratchCardTime] = React.useState<
    number | null
  >(
    calculateNextFreeScratchCardTime(
      nextFreeScratchCard,
      new Date(syncedTimeProvider())
    )
  );

  React.useEffect(() => {
    const currentRemaining = calculateNextFreeScratchCardTime(
      nextFreeScratchCard,
      new Date(syncedTimeProvider())
    );

    if ((currentRemaining ?? 0) <= 0) return;

    const id = window.setInterval(() => {
      setNextFreeScratchCardTime(
        calculateNextFreeScratchCardTime(
          nextFreeScratchCard,
          new Date(syncedTimeProvider())
        )
      );
    }, 1000);

    return () => {
      window.clearInterval(id);
    };
  }, [nextFreeScratchCard, syncedTimeProvider]);

  return (
    <div className="relative cursor-pointer">
      <BigButton
        image={freeChipsImage}
        text="free_chips"
        onClick={openFreeChipsClick}
      />
    </div>
  );
};

const Competitions = () => {
  const openTournaments = useTournamentsPanel();

  return (
    <BigButton
      image={competition}
      text="tournaments"
      onClick={() => openTournaments(true)}
    />
  );
};

const BigButtons = () => {
  return (
    <div className={styles.bigButtons}>
      <div className={styles.bigButtonsRow}>
        <Competitions />
        <FreeChips />
      </div>
      <div className={styles.bigButtonsRow}>
        <InviteFriends />
        <MyFriends />
      </div>
      <div className={styles.bigButtonsRow}>
        <BannedPlayers />
        <Transactions />
      </div>
      <div className={styles.bigButtonsRow}>
        <TopUp />
      </div>
    </div>
  );
};

const SmallButton = ({
  image,
  text,
  onClick,
  children,
}: {
  image: string;
  text: string;
  onClick: () => void;
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation();
  return (
    <div onClick={onClick} className={styles.smallButton}>
      <img src={image} alt={text} />
      <p>{t(text)}</p>
      {children}
    </div>
  );
};

const BecomeVip = () => {
  const { t } = useTranslation();
  const [, , close] = useRightBar();
  const { setOpen } = useVipInstructionPopup();
  const handleClick = () => {
    close();
    setOpen();
  };

  return (
    <div
      className={classNames(styles.smallButton, "cursor-pointer")}
      onClick={handleClick}
    >
      <img src={vipIcon} alt="vip-icon" className="w-4 h-4 " />
      <p className={styles.name}>Become a vip</p>
    </div>
  );
};

const FacebookChatBot = () => {
  const { t } = useTranslation();
  return (
    <a
      className={classNames(styles.smallButton, "cursor-pointer no-underline")}
      target="_blank"
      href={process.env.REACT_APP_FACEBOOK_CHAT}
    >
      <img src={facebookChatIcon} alt="vip-icon" className="w-4 h-4" />
      <p className={styles.name}>Facebook {t("chat")}</p>
    </a>
  );
};

const Help = () => {
  const { t } = useTranslation();

  return (
    <a
      className={styles.smallButton}
      href={HELP_URL}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={helpIcon} alt="help" />
      <p className={styles.name}>{t("help")}</p>
    </a>
  );
};

const Notifications = () => {
  const openNotifications = useNotificationsPanel();
  const { unread } = useNotifications();
  const onClick = React.useCallback(() => {
    openNotifications(true);
  }, [openNotifications]);

  return (
    <SmallButton
      image={notificationIcon}
      text="notifications"
      onClick={onClick}
    >
      {unread > 0 && (
        <div className={styles.notificationsCount}>
          <p>{unread}</p>
        </div>
      )}
    </SmallButton>
  );
};

const Chat = () => {
  const [, , , getIsLoggedAsGuest] = useGuestId();
  const registration = useRegisterPopup();
  const { setOpenedChat } = useCustomChatContext();

  const onClick = () => {
    if (getIsLoggedAsGuest()) registration.openRegisterPopup();
    else setOpenedChat(true);
  };

  return <SmallButton image={chatIcon} text="chat" onClick={onClick} />;
};

const Shop = () => {
  const openShop = useShop();
  const [, , closeRightBar] = useRightBar();
  const [, resetScreenState] = useScreenState();

  const onClick = React.useCallback(() => {
    closeRightBar();
    resetScreenState();
    openShop(Currency.BlueChips);
  }, []);

  return <SmallButton image={shopIcon} text="shop" onClick={onClick} />;
};

const SmallButtons = () => {
  const screenType = useScreenType();
  const isNotMobileSize = React.useMemo(() => screenType !== "MOBILE", [
    screenType,
  ]);

  return (
    <div className={styles.smallButtons}>
      <Shop />
      <FacebookChatBot />
      {isNotMobileSize && <Chat />}
      <Notifications />
      <Help />
      <BecomeVip />
    </div>
  );
};

const Body = () => {
  return (
    <div className={styles.body}>
      <div className={styles.buttons}>
        <SmallButtons />
        <BigButtons />
      </div>
      <Bottom />
    </div>
  );
};

const Exit = () => {
  const [, , close] = useRightBar();
  return (
    <div className={styles.exit} onClick={close}>
      <img src={exitIcon} alt="exit" />
    </div>
  );
};

const Profile = () => {
  const openProfile = usePlayerProfile();
  const {
    user: { id, name },
  } = useUser();

  return (
    <div
      className={styles.profile}
      onClick={() =>
        openProfile(
          {
            id,
            name,
            games: GAME_IDS,
            tournament: false,
          },
          true
        )
      }
    >
      <ProfileImage
        id={id}
        name={name}
        className={styles.avatar}
        width={40}
        height={40}
      />
      <p className={styles.name}>{name}</p>
    </div>
  );
};

const Header = () => {
  return (
    <div className={styles.header}>
      <Profile />
      <Exit />
    </div>
  );
};

export default React.memo(() => {
  const [, , close] = useRightBar();
  const screenType = useScreenType();

  const adjustedStyle =
    screenType === "TABLET" ? RIGHT_BAR_TABLET_SIZE : RIGHT_BAR_MOBILE_SIZE;

  return (
    <>
      <div
        className="absolute top-0 bottom-0 left-0 right-0 z-20 bg-white-alpha-60"
        onClick={close}
      />
      <div
        className={styles.bar}
        style={{
          ...adjustedStyle,
        }}
        aria-label="mobile-bar"
      >
        <Header />
        <Body />
      </div>
    </>
  );
});
