import * as React from "react";
import { gql, loader } from "graphql.macro";
import { useMutation } from "../../../hooks/useMutation";
import {
  PurchaseEntryMutation,
  PurchaseEntryMutationVariables,
} from "./index.generated";
import { ButtonWithSound } from "../../AudioProvider";
import { useShop } from "../../Shop";
import { useUser } from "../../UserProvider";
import { TournamentFragment } from "../graphql/TournamentFragment.generated";
import { useTranslation } from "react-i18next";
import Portal from "@reach/portal";
import { ContentArea } from "../../Authenticated/ContentArea";
import { AbsoluteCenter } from "../../AbsoluteCenter";
import { useNumberFormatter } from "../../../hooks/useNumberFormatter";
import { useRegisterPopup } from "../../Register";
import { useGuestId } from "../../../hooks/useGuestId";

const TOURNAMENT_PARTICIPATION_FRAGMENT = loader(
  "../../../graphql/TournamentParticipationFragment.graphql"
);

const PURCHASE_ENTRY_MUTATION = gql`
  mutation PurchaseEntry($id: ID!) {
    purchaseEntry(id: $id) {
      ...TournamentParticipation
    }
  }
  ${TOURNAMENT_PARTICIPATION_FRAGMENT}
`;

const Popup = ({
  close,
  tournament,
}: {
  close: () => void;
  tournament: TournamentFragment;
}) => {
  const { t } = useTranslation();
  const numberFormatter = useNumberFormatter();
  const price = `${numberFormatter.format(tournament.amount.value)} ${t(
    `currency.${tournament.amount.currency.toLowerCase()}`
  )}`;
  const openShop = useShop();
  const {
    user: { balances },
  } = useUser();
  const hasBalance =
    balances.find((b) => b.currency === tournament.amount.currency)!.value >=
    tournament.amount.value;
  const [{ fetching }, purchase] = useMutation<
    PurchaseEntryMutation,
    PurchaseEntryMutationVariables
  >(PURCHASE_ENTRY_MUTATION);
  const registration = useRegisterPopup();
  const [, , , getIsLoggedAsGuest] = useGuestId();
  
  return (
    <Portal>
      <ContentArea>
        <div className="absolute bg-black-alpha-30 top-0 left-0 right-0 bottom-0 z-50">
          <AbsoluteCenter>
            <div
              aria-label={t("invitation")}
              className="w-1/3 p-4 rounded shadow-xl bg-blue-800 border-blue-900 border-2"
            >
              <div className="flex px-2 text-white font-bold">
                {t("tournament_join_confirmation", {
                  price: price,
                  games: tournament.gamesPerPurchase,
                })}
              </div>
              <div className="flex -mx-2 mt-4">
                <div className="flex-1 px-2">
                  <ButtonWithSound
                    disabled={fetching}
                    onClick={() => {
                      if(getIsLoggedAsGuest()) registration.openRegisterPopup();
                      else if (hasBalance) purchase({ id: tournament.id });
                      else {
                        openShop(tournament.amount.currency);
                        close();
                      }
                    }}
                    className="btn btn-green-600 btn-sm w-full rounded"
                  >
                    {t("purchase")}
                  </ButtonWithSound>
                </div>
                <div className="flex-1 px-2">
                  <ButtonWithSound
                    className="btn btn-gray-500 btn-sm w-full rounded"
                    onClick={close}
                  >
                    {t("cancel")}
                  </ButtonWithSound>
                </div>
              </div>
            </div>
          </AbsoluteCenter>
        </div>
      </ContentArea>
    </Portal>
  );
};

export function PurchaseTournamentEntryButton({
  tournament,
  children,
  ...rest
}: React.PropsWithChildren<
  { tournament: TournamentFragment } & Omit<
    JSX.IntrinsicElements["button"],
    "onClick" | "disabled"
  >
>) {
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const close = React.useCallback(() => setOpen(false), []);

  return (
    <>
      <ButtonWithSound onClick={() => setOpen(true)} {...rest}>
        {children}
      </ButtonWithSound>
      {isOpen && <Popup close={close} tournament={tournament} />}
    </>
  );
}
