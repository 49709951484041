import * as React from "react";
import { useLogin } from "./useLogin";
import { ButtonWithSound } from "../AudioProvider";

const NAME = "development";

const DEV_USERS =
  process.env.NODE_ENV === "production"
    ? {}
    : {
        "c2dba5fb-021f-31bf-858b-97348cfe5040":
          "Richard Aldbidjifhacc Letuchyescu (facebook)",
        "b4efa9e5-9596-39fc-afd1-4e74751e424c": "Dimitris Zenios (google)",
        "31807db9-2e95-3d8d-b48f-5c8d888203bd": "Dimitris Zenios (facebook)",
        "f0871ae6-0333-3b12-b345-6b76ffa402e5": "Test test (facebook)",
        "cd405d1d-207b-4c40-a9a2-4e4b741abf13":
          "Kostakis Konstantinou (facebook)",
        "cfab6e42-d1e9-30e2-b4e5-fcac319c0f39":
          "Joshua Aldbiabjgicji Fergieescu (facebook)",
        "d84ee06b-85a4-3e75-bc49-0276ec112d7a":
          "Dimitris Zenios MySoft (google)",
      };

export const DevelopmentLogin = () => {
  const [loading, setLoading] = React.useState(false);
  const login = useLogin(NAME, "website");

  const loginFacade = React.useCallback(
    async (userId: string) => {
      setLoading(true);
      try {
        return await login(userId);
      } catch (e) {
        setLoading(false);
      }
    },
    [login]
  );

  return (
    <div className="max-w-md mx-auto">
      <ul>
        {Object.entries(DEV_USERS).map(([key, value]) => {
          return (
            <li key={key}>
              <ButtonWithSound
                className="btn btn-sm btn-green-900 rounded w-full my-2"
                disabled={loading}
                onClick={() => loginFacade(key)}
              >
                {value}
              </ButtonWithSound>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
