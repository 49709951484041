import * as React from "react";
import { UserProvider, useUser } from "../UserProvider";
import { useUrqlClient } from "../../hooks/useUrqlClient";
import { Provider } from "urql";
import { ContentArea } from "./ContentArea";
import { Route, Switch, Redirect } from "react-router-dom";
import { TableInvitations } from "../TableInvitations";
import { Lobby } from "../Lobby";
import { ShopProvider } from "../Shop";
import { ScratchCardsProvider } from "../ScratchCards";
import { PlayerProfileProvider } from "../PlayerProfile";
import { LeaderboardsProvider } from "../Leaderboards";
import { AbsoluteCenter } from "../AbsoluteCenter";
import { Loader } from "../Loader";
import { useTranslation } from "react-i18next";
import { NotificationsPanelProvider } from "../Notifications";
import { MultiProvider } from "../MultiProvider";
import { FriendsPanelProvider } from "../Friends";
import { TableInfoProvider } from "../TableInfoProvider";
import { InvitationsPanelProvider } from "../Invitations";
import { TransactionsPanelProvider } from "../Transactions";
import { SyncedTimeProvider } from "../SyncedTimeProvider";
import styles from "./Authenticated.module.scss";
import classNames from "classnames";
import { ButtonWithSound } from "../AudioProvider";
import { Promotion } from "../Promotion";
import { MarqueeProvider } from "../MarqueeProvider";
import { TopupPanelProvider } from "../Topup";
import { Area } from "../Area";
import { TournamentsPanelProvider } from "../Tournaments";
import { DeleteMePanelProvider } from "../DeleteMe";
import { BannedPlayersPanelProvider } from "../BannedPlayers";
import { JoinTablePopupProvider } from "../JoinTablePopup";
import { SendEmailPopup } from "../AskSendEmails/SendEmailPopup";
import { HangmanChallengeRoom } from "../HangmanDailyChallenges";
import { ConvertGuestToRegularUserLogin } from "../Login/ProductionLogin";
import { useGuestId } from "../../hooks/useGuestId";
import { RegisterProvider } from "../Register";
import * as PIXI from "pixi.js";
import Chat from "../PlatformChat/StreamChatComponentWrapper";
import { RightBarProvider } from "../Bar/RightBar";
import { ScreenStateProvider } from "../ScreenState";
import { FreeChipsProvider } from "../FreeChips";
import { VipInstructionPopupProvider } from "../VipInformation";

//Redirect to active table happens only once. When we login.
const RedirectToActiveTable = React.memo(() => {
  const {
    user: { activeTables },
  } = useUser();

  const [tables, setTables] = React.useState<typeof activeTables | null>(
    activeTables
  );
  React.useEffect(() => {
    setTables(null);
  }, []);

  const table = tables?.[0] ?? null;
  return table !== null ? (
    <Redirect to={`/area/${table.section.id}/${table.id}`} />
  ) : null;
});

const ProvidersList = [
  <ShopProvider />,
  <ScratchCardsProvider />,
  <PlayerProfileProvider />,
  <JoinTablePopupProvider />,
  <FriendsPanelProvider />,
  <BannedPlayersPanelProvider />,
  <NotificationsPanelProvider />,
  <InvitationsPanelProvider />,
  <TournamentsPanelProvider />,
  <TransactionsPanelProvider />,
  <LeaderboardsProvider />,
  <TableInfoProvider />,
  <TopupPanelProvider />,
  <MarqueeProvider />,
  <DeleteMePanelProvider />,
  <FreeChipsProvider />,
];

const OutOfContentProvidersList = [
  <ScreenStateProvider />,
  <RegisterProvider />,
  <RightBarProvider />,
  <VipInstructionPopupProvider />,
];

export const Authenticated = ({
  token,
  clearToken,
}: {
  token: string;
  clearToken: () => void;
}) => {
  PIXI.settings.FAIL_IF_MAJOR_PERFORMANCE_CAVEAT = false;
  const client = useUrqlClient();
  const [, , , getIsLoggedAsGuest] = useGuestId();
  const { t } = useTranslation();
  const ConnectingToServer = () => (
    <AbsoluteCenter>
      <Loader description={t("connecting_to_server")} />
    </AbsoluteCenter>
  );
  if (client === null) return <ConnectingToServer />;

  const [status, urqlClient] = client;
  if (status === "CONNECTING") {
    return <ConnectingToServer />;
  } else if (status === "DISCONNECTED") {
    return (
      <AbsoluteCenter>
        <div className="w-auto max-w-lg p-4 text-center bg-red-700 rounded">
          <span className="block font-bold text-white">
            {t("connection_interrupted")}
          </span>
          <ButtonWithSound
            className="w-full mt-2 rounded btn btn-gray-500 btn-sm"
            onClick={() => window.location.reload()}
          >
            {t("reload")}
          </ButtonWithSound>
        </div>
      </AbsoluteCenter>
    );
  } else {
    return (
      <Switch>
        {getIsLoggedAsGuest() && (
          <Route path="/login">
            <ConvertGuestToRegularUserLogin />
          </Route>
        )}
        <Route>
          <SyncedTimeProvider>
            <Provider value={urqlClient}>
              <UserProvider token={token} logout={clearToken}>
                <MultiProvider providers={OutOfContentProvidersList}>
                  <ContentArea
                    className={classNames("shadow-2xl", styles.authenticated)}
                    banner
                  >
                    <MultiProvider providers={ProvidersList}>
                      <Chat />
                      <Switch>
                        <Route path="/area/:sectionId/:tableId?">
                          <Area />
                        </Route>
                        <Route path="/hangman/challenge/:sectionId">
                          <HangmanChallengeRoom />
                        </Route>
                        <Route path="*">
                          <Lobby />
                        </Route>
                      </Switch>
                      <SendEmailPopup />
                      <TableInvitations />
                      <Promotion />
                    </MultiProvider>
                  </ContentArea>
                </MultiProvider>
                <RedirectToActiveTable />
              </UserProvider>
            </Provider>
          </SyncedTimeProvider>
        </Route>
      </Switch>
    );
  }
};
