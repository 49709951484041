import React from "react";
import { useBannedPlayersPanel } from "../../BannedPlayers";
import { useFriendsPanel } from "../../Friends";
import { useInvitationsPanel } from "../../Invitations";
import { useTopupPanel } from "../../Topup";
import { useTournamentsPanel } from "../../Tournaments";
import { useTransactionsPanel } from "../../Transactions";
import TopbarItem from "./TopbarItem";
import competition from "../assets/competition.jpg";
import banned from "../assets/banned.jpg";
import myFriends from "../assets/myFriends.jpg";
import refferFriend from "../assets/refferFriend.jpg";
import freeChipsImage from "../assets/scratchCards.jpg";
import topUp from "../assets/topUp.jpg";
import transactions from "../assets/transactions.jpg";
import { useTranslation } from "react-i18next";
import styles from "./Topbar.module.scss";
import { useUser } from "../../UserProvider";
import { useSyncedTimeProvider } from "../../SyncedTimeProvider";
import { useFreeChips } from "../../FreeChips";

export const calculateNextFreeScratchCardTime = (
  nextFreeScratchCard: string | null,
  current: Date
) => {
  if (nextFreeScratchCard == null) return null;

  const date = new Date(nextFreeScratchCard);
  return Math.max(date.getTime() - current.getTime(), 0);
};

export const countDown = (remaining: number) => {
  const pad = (n: number) => (n < 10 ? "0" + n : n);

  const days = Math.floor(remaining / (1000 * 60 * 60 * 24));
  let hours = Math.floor(
    (remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((remaining % (1000 * 60)) / 1000);
  if (days > 0) hours += days * 24;
  if (hours > 99) hours = 99;

  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};

const Topbar = ({ isMainScreen }: { isMainScreen?: boolean }) => {
  const { t } = useTranslation();
  const openInvitations = useInvitationsPanel();
  const openFriends = useFriendsPanel();
  const openTournaments = useTournamentsPanel();
  const openFreeChips = useFreeChips();
  const openBannedPlayers = useBannedPlayersPanel();
  const openTopup = useTopupPanel();
  const openTransactions = useTransactionsPanel();
  const openFreeChipsClick = React.useCallback(() => openFreeChips(), [
    openFreeChips,
  ]);
  const syncedTimeProvider = useSyncedTimeProvider();
  const {
    user: { nextFreeScratchCard },
  } = useUser();

  const [nextFreeScratchCardTime, setNextFreeScratchCardTime] = React.useState<
    number | null
  >(
    calculateNextFreeScratchCardTime(
      nextFreeScratchCard,
      new Date(syncedTimeProvider())
    )
  );

  React.useEffect(() => {
    const currentRemaining = calculateNextFreeScratchCardTime(
      nextFreeScratchCard,
      new Date(syncedTimeProvider())
    );

    if ((currentRemaining ?? 0) <= 0) return;

    const id = window.setInterval(() => {
      setNextFreeScratchCardTime(
        calculateNextFreeScratchCardTime(
          nextFreeScratchCard,
          new Date(syncedTimeProvider())
        )
      );
    }, 1000);

    return () => {
      window.clearInterval(id);
    };
  }, [nextFreeScratchCard, syncedTimeProvider]);

  return (
    <div
      style={{
        height: "100px",
        width: "100%",
        background: "#13151B",
        position: isMainScreen ? "unset" : "absolute",
        zIndex: 2,
      }}
      className="flex items-center justify-between px-4"
    >
      <TopbarItem
        image={competition}
        text={t("tournaments")}
        onClick={() => openTournaments(true)}
        className="mr-2"
      />
      <div className="relative">
        <TopbarItem
          image={freeChipsImage}
          text={t("free_chips")}
          onClick={openFreeChipsClick}
          className="mr-2"
        />
      </div>
      <TopbarItem
        image={refferFriend}
        text={t("invitations")}
        onClick={() => openInvitations(true)}
        className="mr-2"
      />
      <TopbarItem
        image={myFriends}
        text={t("friends")}
        onClick={() => openFriends(true)}
        className="mr-2"
      />
      <TopbarItem
        image={banned}
        text={t("banned_players")}
        onClick={() => openBannedPlayers(true)}
        className="mr-2"
      />
      <TopbarItem
        image={topUp}
        text={t("topup")}
        onClick={openTopup}
        className="mr-2"
      />
      <TopbarItem
        image={transactions}
        text={t("transactions")}
        onClick={() => openTransactions(true)}
      />
    </div>
  );
};

export default Topbar;
