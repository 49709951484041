import * as React from "react";
import { BalanceUpdateLeaderBoardAwardDetailsFragmentFragment } from "../../../hooks/useTransactions/graphql/TransactionsFragment.generated";
import { Trans } from "react-i18next";
import { useOrdinalFormatter } from "../../../hooks/useOrdinalFormatter";

export const BalanceUpdateLeaderBoardAwardDetails = ({
  details,
}: {
  details: BalanceUpdateLeaderBoardAwardDetailsFragmentFragment;
}) => {
  const ordinalFormatter = useOrdinalFormatter();
  return (
    <Trans i18nKey="transaction.BalanceUpdateLeaderBoardAwardDetails_details">
      Leaderboard awards in which you finished{" "}
      <strong>{{ position: ordinalFormatter.format(details.position) }}</strong>
    </Trans>
  );
};
