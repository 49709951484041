import * as React from "react";
import { Trans } from "react-i18next";

export const BalanceUpdateWelcomeDetails = () => {
  return (
    <Trans i18nKey="transaction.BalanceUpdateWelcomeDetails_details">
      Joined the platform
    </Trans>
  );
};
