import { TableDataDefinition } from "../types";
import { tableData as mpirimpaCyTableData } from "./tableData/mpirimpaCy";
import { tableData as mpirimpaElTableData } from "./tableData/mpirimpaEl";
import { CreateTable } from "./CreateTable";
import * as React from "react";
import { makeTournamentInfo } from "./TournamentInfo";

const GameComponent = React.lazy(() => import("./Game"));

const makeDefinition = (game: string, tableData: TableDataDefinition) => ({
  tableData,
  CreateTableComponent: CreateTable,
  GameComponent,
  TournamentInfoComponent: makeTournamentInfo(game),
});

const DEFINITIONS = {
  mpirimpa_cy: mpirimpaCyTableData,
  mpirimpa_el: mpirimpaElTableData,
};

const GameData = Object.entries(DEFINITIONS).reduce(
  (prev, [key, value]) => ({ ...prev, [key]: makeDefinition(key, value) }),
  {}
);

export default GameData;
